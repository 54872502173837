import { http } from "src/common/http";
import { QualityCheckParam } from "./quality.check.param";
import { QualityCheckEntity } from "./quality.check.entity";

class QualityCheckService {


	

	list = async (param: QualityCheckParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order-quality",
			method: "GET",
			params: param,
		});
	};

	add = async (data: QualityCheckEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order-quality",
			method: "POST",
			data,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order-quality/" + id,
			method: "GET",
			data: id,
		});
	};

	update = async (data: QualityCheckEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order-quality/" + data.id,
			method: "PUT",
			data,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order-quality/" + id,
			method: "DELETE",
			data: id,
		});
	};
}
export default new QualityCheckService();
