import styled from "@emotion/styled";
import { Input, Table } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../common/Util";
import qrcodeService from "../../module/tools/service/qrcode.service";

const { Search } = Input;

export const AppletQcCode: FC = () => {
	const [text, setText] = useState("");

	const { t } = useTranslation();

	const handtext = (e: any) => {
		setText(e.target.value);
	};

	const dataSource = [
		{
			key: "1",
			page: "下单页",
			address: "/pages/index/index?directType=shop&directData=",
			parameter: "shopid: 门店ID, 门店列表页导出获取或编辑时url参数中查看",
		},
		{
			key: "2",
			page: "活动详情页",
			address: "/pages/index/index?directType=activityDetail&directData=|||",
			parameter: "directData格式为 id|||type,例: 16|||5, id: 活动IDtype,活动类型( 1:充值活动 2:会员卡活动 3:优惠卷活动 4:报名活动 5:宣传活动 6:套餐活动 7:邀请活动 8:登录活动 9:推荐活动",
		},

			{
			key: "3",
			page: "套餐活动",
			address: "/pages/package/index?activityId=",
			parameter: " id: 套餐活动ID",
		},
		{
			key: "4",
			page: "兑换中心",
			address: "pages/redeem/redeemEntry/index",
			parameter: " ",
		},
		{
			key: "5",
			page: "会员卡购买",
			address: "pages/membership/pages/payment/index?id=&shopId=",
			parameter: "id:会员卡配置列表页中会员卡配置id;shopid: 主站点id ",
		},





		// {
		// 	key: "3",
		// 	page: "活动列表页",
		// 	address: "/pages/activity/ActivityList",
		// 	parameter: "",
		// },
		// {
		// 	key: "4",
		// 	page: "推荐活动详情页",
		// 	address: "/pages/activity/RecommendDetail?id=",
		// 	parameter: " id: 活动ID",
		// },
		// {
		// 	key: "5",
		// 	page: "套餐下单页",
		// 	address: "/pages/activity/ActivityPay?id=",
		// 	parameter: " id: 活动ID",
		// },
		// {
		// 	key: "6",
		// 	page: "会员卡购买页",
		// 	address: "/pages/membershipCard/MonthCard?id=",
		// 	parameter: " id: 会员卡ID",
		// },
		// {
		// 	key: "7",
		// 	page: "申请推广员入口二维码",
		// 	address: "/pages/promote/apply",
		// 	parameter: "",
		// },
	];

	const onSearch = () => {
		qrcodeService.create(text).then((data: any) => {
			Util.processBlobImg(data.data);
		});
	};

	const columns = [
		{
			title: t("visit page"),
			dataIndex: "page",
		},
		{
			title: t("link address(Click to enter automatically)"),
			dataIndex: "address",
			render: (text: string) => {
				return (
					<div className='click' onClick={() => setText(text)}>
						{text}
					</div>
				);
			},
		},
		{
			title: "Parameter Description",
			dataIndex: "parameter",
		},
	];

	return (
		<Container>
			<div style={{ fontSize: "24px", textAlign: "center" }}>小程序码生成</div>
			<br />
			<div style={{ color: "red" }}>有限次数，请谨慎使用</div>
			<br />
			<Search
				placeholder='请输入页面链接（例如：pages/index/index）'
				value={text}
				onChange={handtext}
				allowClear
				enterButton='生成'
				size='large'
				onSearch={onSearch}
			/>
			<Table dataSource={dataSource} columns={columns} />
		</Container>
	);
};
const Container = styled.div`
	width: 80%;
	padding: 10px;
	margin: 0 auto;

	.click {
		cursor: pointer;
		color: #4085f4;
	}
`;
