import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { InputNumber, message, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
import membershipCardService from "../service/membership.card.service";
import { MembershipCardType } from "./membership.card.type";

const { Option } = Select;

interface meituanDealCardList {
	subCardName?: string;
	cardId?: number;
	cardName?: string;
	cardNum?: number;
	cardType?: number;
	id?: number;
}

export const SelectMembershipCardByType = (props: Props) => {
	const [typeInfo, setTypeInfo] = useState<number>();
	const [cardTypeId, setCardTypeId] = useState<number>(); // 父卡id
	const [cardId, setCardId] = useState<number>(); // 子卡id
	const [cardArr, setCardArr] = useState([]);
	const [cardTitle, setCardTitle] = useState<string>("");
	const [cardName, setCardName] = useState<string>(); // 父卡name
	const [subCardName, setSubCardName] = useState<string>(); // 子卡name
	const [subCardArr, setSubCardArr] = useState([]);

	const [cardNum, setCardNum] = useState<number>(1);
	const [itemArr, setItemArr] = useState<meituanDealCardList[] | undefined>([]);
	const [idArr, setIdArr] = useState<(number | undefined)[]>([]);
	const { t } = useTranslation();

	const choosedCardList = useCallback(async () => {
		if (props.value) {
			setItemArr(props.value);
			console.log(props.value);
		}
	}, [props.value]);

	useEffect(() => {
		choosedCardList();
	}, [choosedCardList]);

	const cardList = useCallback(async () => {
		if (!typeInfo) {
			return;
		}

		const { data } = await membershipCardService.list({ title: cardTitle, cardType: typeInfo });
		setCardArr(data.data);
	}, [cardTitle, typeInfo]);

	useEffect(() => {
		cardList();
	}, [cardList]);

	const addItem = () => {
		const arr: meituanDealCardList[] = itemArr ? itemArr : [];
		if (!cardNum) {
			message.error("请选择数量!");
			return;
		}
		const cardInfo = {
			cardId: Number(cardId),
			cardType: Number(typeInfo),
			cardName: cardName,
			cardNum: Number(cardNum),
			cardTypeId: Number(cardTypeId),
			subCardName: subCardName,
		};
		arr?.push(cardInfo);
		const newArr = arr.concat();
		setItemArr(newArr);
		props.onChange && props.onChange(newArr);
	};

	const remove = (index: number) => {
		const arr: meituanDealCardList[] = itemArr ? itemArr.concat() : [];
		arr.splice(index, 1);
		setItemArr(arr);

		// Get all ids
		const idsArr = arr.map((row) => {
			return row.id;
		});
		setIdArr(idsArr);
		props.onChange && props.onChange(arr);
	};

	const handleTypeChange = (e: number) => {
		console.log("leixing", e);

		setTypeInfo(e);
		setCardId(undefined);
	};

	const getSubCardList = useCallback(async () => {
		if (!cardTypeId) return;
		const { data } = await membershipCardService.detail(cardTypeId);
		console.log(data.data);
		setSubCardArr(data.data.cardConfigList);
		// console.log(subCardName)
	}, [cardTypeId]);

	useEffect(() => {
		getSubCardList();
	}, [getSubCardList]);

	return (
		<Container>
			<div className={"select"}>
				<div className={"left"}>
					<MembershipCardType value={typeInfo} onChange={(e: number) => handleTypeChange(e)} />
				</div>
				&nbsp;&nbsp;
				<div className={"right"}>
					<Select
						labelInValue
						placeholder={t("chooseCard")}
						allowClear
						onChange={(e: { value?: number; label?: string }) => {
							setCardTypeId(e.value || undefined);
							setCardName(e.label);
							console.log(e);
						}}
						showSearch
						optionFilterProp={"children"}
						onSearch={(e) => {
							setCardTitle(e);
						}}
					>
						{cardArr &&
							cardArr.map((item: any) => {
								return (
									<Option key={item.id} style={{ color: idArr.indexOf(item.id) > -1 && "#1f6eb9" }}>
										{item.id + " " + item.title + "【" + item.productName + "】"}
									</Option>
								);
							})}
					</Select>
				</div>
				&nbsp;&nbsp;
				<div className={"right"}>
					<Select
						labelInValue
						placeholder={t("chooseSubCard")}
						allowClear
						onChange={(e: { value?: number; label?: string }) => {
							setCardId(e.value || undefined);
							setSubCardName(e.label);
							console.log(e);
							console.log(e.label);
						}}
					>
						{cardArr &&
							subCardArr.map((item: any) => {
								return (
									<Option key={item.id} style={{ color: idArr.indexOf(item.id) > -1 && "#1f6eb9" }}>
										{item.id + " " + item.title}
									</Option>
								);
							})}
					</Select>
				</div>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<div className='num'>
					<div>数量:</div>
					&nbsp;&nbsp;
					<div>
						{props.readonly && <InputNumber value={1} onChange={() => {
									setCardNum(1);
								}}/>}

						{!props.readonly && (
							<InputNumber
								onChange={(e) => {
									setCardNum(Number(e));
								}}
							/>
						)}
					</div>
				</div>
				<div className={"icon"}>
					<PlusOutlined style={{ color: "#096dd9" }} onClick={addItem} />
				</div>
			</div>
			<div>
				{itemArr?.length !== 0 &&
					itemArr?.map((item, index) => {
						return (
							<div key={index}>
								{item.cardType && t(membershipCardService.cardType[item.cardType])}- {item.cardName}- {item.subCardName} - {item.cardNum||1 }张
								<MinusOutlined style={{ color: "#f5222d", marginLeft: "15px" }} className={"icon"} onClick={() => remove(index)} />
							</div>
						);
					})}
			</div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;

	.select {
		display: flex;
		justify-content: left;

		.left {
			width: 140px;
		}

		.right {
			width: 300px;
		}

		.num {
			min-width: 150px;
			display: flex;
			align-items: center;
		}

		.icon {
			line-height: 32px;
			font-size: 18px;
			cursor: pointer;
		}
	}
`;
