import { http } from "../../../common/http";
import { RoleParam } from "../param/role.param";

class RolesService {
	list = async (param: RoleParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/role/all",
			method: "GET",
			params: param,
		});
	};
}

export default new RolesService();
