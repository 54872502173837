import { Column, ColumnConfig } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	list?: any;
}
export const OrderBarComponent = (props: Props) => {
	const [data, setData] = useState<Record<string, any>[]>([]);
    const {t} = useTranslation();
	const config: ColumnConfig = {
		data,
		xField: "time",
		yField: "orderCount",
		label: {
			// 可手动配置 label 数据标签位置
			position: "middle",
			// 'top', 'bottom', 'middle',
			// 配置样式
			style: {
				fill: "#FFFFFF",
				opacity: 0.6,
			},
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		meta: {
			time: {
				alias: t("date"),
			},
			orderCount: {
				alias: t("total"),
			},
		},
	};
	useEffect(() => {
		setData(props.list);
	}, [props]);
	return <Column {...config} />;
};
