import { http } from "../../../common/http";
import { InstructionRecognitionParam } from "../param/instruction.recognition.param";



class InstructionRecognitionService {
	list = async (params: InstructionRecognitionParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + "/equipmentOperateLog",
			params,
		});
	};
}
export default new InstructionRecognitionService();
