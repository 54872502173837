import { http } from "../../../common/http";
import { FirstOrderEntity } from "../entity/first.order.entity";
import { FirstOrderParam } from "../param/first.order.param";
class FirstOrderService {
	list = async (param: FirstOrderParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/firstOrder",
			method: "GET",
			params: param,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/firstOrder/"+id,
			method: "DELETE",
			data: id,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/firstOrder/"+id,
			method: "GET",
			data:id,
		});
	};

	update = async (data: FirstOrderEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/firstOrder/"+data.id,
			method: "PUT",
			data,
		});
	};

	
	add = async (data: FirstOrderEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/firstOrder",
			method: "POST",
			data,
		});
	};






}

export default new FirstOrderService();
