import { http } from "../../../common/http";
import { GroupMessageEntity } from "../entity/groupMessageEntity";
import axios from "axios";
import { GroupMessageRequestBody } from "../entity/groupMessageRequestBody";

class GroupMessageService {
    getToken = async () => {
        const res = await http({
            url:
                process.env.REACT_APP_GO_API +
                `/url-proxy/get?url=https://qyapi.weixin.qq.com/cgi-bin/gettoken&corpid=wwc2b9fc25ad2cf1ab&corpsecret=UKDEQvRlqdlAyXhKaP7pJns9jA4WMN_vm3d0PmesOdI`,
            method: "GET",
        });
        return JSON.parse(res.data.data);
    };

    fetchMessage = async (token: string, data: GroupMessageRequestBody) => {
        const res = await http({
            url:
                process.env.REACT_APP_GO_API +
                `/url-proxy/post?url=https://qyapi.weixin.qq.com/cgi-bin/externalcontact/groupchat/statistic&access_token=${token}`,
            method: "POST",
            data,
        });
        return JSON.parse(res.data.data);
    };
}

export default new GroupMessageService();
