import { http } from "../../../common/http";
import { SiteLevel } from "../entity/site.entity"
import { SiteOffParam } from "../param/site.off.param";
import { SiteParam } from "../param/site.param";
class SiteService {
    levelArray = [
        {
            "value": 1,
            "title": "masterSite"
        },
        {
            "value": 2,
            "title": "subSite"
        }
    ]

    getLevelTitleByValue = (level: number): string => {
        let titleFlag!: string;
        this.levelArray.forEach(item => {
            if (item.value === level) {
                titleFlag = item.title
            }
        });

        return titleFlag;
    }

    /**
     * 获取所有站点
     * @returns {*}
     */
    async all(params: any) {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/shop/all`,
            params
        })
    }
    async levelSite(param: SiteLevel) {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/shop/all?level=` + param.level
        })
    }
    async list(param: SiteParam) {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/shop`,
            params: param,
        })
    }
    async export() {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/shop/export`,
            responseType: 'blob',
        })
    }
    async delete(param: any) {
        return http({
            method: 'DELETE',
            url: `${process.env.REACT_APP_ADMIN_API}/shop/` + param.id,
        })
    }
    async update(param: any) {
        return http({
            method: 'PUT',
            url: `${process.env.REACT_APP_ADMIN_API}/shop/` + param.id,
            data: param,
        })
    }
    async add(param: any) {
        return http({
            method: 'POST',
            url: `${process.env.REACT_APP_ADMIN_API}/shop`,
            data: param,
        })
    }
    async detail(param: any) {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/shop/` + param.id,
        })
    }

    async shopOffLog(param: SiteOffParam) {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/shop-off-log`,
            params: param,
        })
    }

    async shopOffExport(params: SiteOffParam) {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/shop-off-log/export`,
            params,
            responseType: 'blob',
        })
    }


    
    
}
export default new SiteService();