import { RiseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import React, { FC } from "react";
import BarRank from "../../common/components/BarRank";
import Pie from "../../common/components/pie";
import BaiduMap from "./components/BaiduMap";

const Top: FC = () => {

    return (
        <Container>
            <div className={"top"}>
                <div className={"up"}>
                    <div style={{ width: "30%", height: "100%" }}>
                        <Pie />
                    </div>
                    <div className={"center"}>
                        <div className={"title"}>睿洗数据展示中心</div>
                        <div className={"content"}>
                            <div>
                                <div>135892</div>
                                <div>今日订单量</div>
                            </div>
                            <div>
                                <div className={"money"}>￥2588792.32</div>
                                <div>今日收入</div>
                            </div>
                        </div>
                    </div>
                    <div className={"userCount"}>
                        <div><span>723590</span><RiseOutlined style={{ color: "red" }} />2114</div>
                        <div>用户量</div>
                    </div>
                </div>
                <div className={"down"}>
                    <div className={"left"}> <BarRank title={'站点洗车量排行'} /></div>
                    <div className={'center'}>
                        <div className={'map'}><BaiduMap /></div>
                        <div>
                            <div> 17:02 雨蜡洗 太仓超级中心</div>
                            <div> 17:02 两桶水精洗 靓车汇-元联科创园</div>
                        </div>
                    </div>
                    <div className={"right"}>
                        <div className={"bar"}><BarRank title={'施工项目排行'} /></div>
                        <div className={"bar"}><BarRank title={'员工绩效排名'} /></div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Top;

const Container = styled('div')`
    .top {
  width: 100%;
  height: 100%;
  background: RGB(15,13,40);
  color: #FFF;

  .up {
    display: flex;
    justify-content: space-around;
    height: 30%;

    .center {
      width: 50%;

      .title {
        font-size: 52px;
        text-align: center;
        border: 3px solid #9c9c9c;
      }

      .content {
        margin-top: 6%;
        display: flex;
        justify-content: space-around;
        font-size: 32px;
        text-align: center;

        .money {
          color: RGB(241,172,51);
          font-weight: bold;
        }
      }
    }

    .userCount {
      width:30%;
      height:100%;
      font-size: 42px;
      text-align: center;
      padding-top: 4%;

      div span {
        font-size: 60px;
      }
    }
  }

  .down {
    display: flex;
    justify-content: space-between;
    height: 70%;

    .left, .right {
      width: 30%;
    }

    .left {
    }

    .center {
      width: 40%;

      .map {
        width: 100%;
        height: 90%;
      }
    }

    .right {
      .bar {
        height: 50%;
      }
    }
  }
}
`