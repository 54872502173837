import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, message, Radio } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SingleSelectProductBySite } from "src/module/product/component/single.select.product.by.shop ";
import { MultiSelectCouponByType } from "../../module/coupon/component/MultiSelectCouponByType";
import rateGiftService from "../../module/rate.gift/service/rate.gift.service";

export const RateGiftDetail: FC = () => {
	const { t } = useTranslation();
	const { RangePicker } = DatePicker;
	const params = useParams();
	const [form] = useForm();
	const navigate = useNavigate();

	useEffect(() => {
		if (params.id) {
			rateGiftService.detail(Number(params.id)).then((res) => {
				if (res.data.code === 200) {
					form.setFieldsValue({ ...res.data.data, time: [moment(res.data.data.startTime), moment(res.data.data.endTime)] });
				}
			});
		}
	}, [form, params.id]);

	const onFinish = async (value: any) => {
		const param = {
			...value,
			time: undefined,
			startTime: value.time[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.time[1].format("YYYY-MM-DD") + " 23:59:59",
		};

		if (!params.id) {
			const { data } = await rateGiftService.add(param);
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/activity/rate-gift");
			} else {
				message.error(data.msg);
			}
		} else {
			const { data } = await rateGiftService.update({
				...param,
				id: Number(params.id),
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/activity/rate-gift");
			} else {
				message.error(data.msg);
			}
		}
	};

	return (
		<Container>
			<Form labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} style={{ padding: 20 }} form={form} onFinish={onFinish}>
				<Form.Item label={t("title")} name='title'>
					<Input style={{ width: 300 }} placeholder={t("title")} />
				</Form.Item>
				<Form.Item label={t("couponProduct")} name={"productId"}>
				<SingleSelectProductBySite/>
				</Form.Item>
				<Form.Item
					label={t("choosedCoupon")}
					name='giftCouponIds'
					required
				>
					<MultiSelectCouponByType />
				</Form.Item>

				<Form.Item label={t("availablePeriod")} name='time'>
					<RangePicker />
				</Form.Item>

				<Form.Item label={t("status")} name='status'>
					<Radio.Group>
						<Radio value={2}>{t("unlock")}</Radio>
						<Radio value={1}>{t("lock")}</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
					<Button type='primary' htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
