import { http } from "../../../common/http";

class QrcodeService {

    create = async (page: string) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/applet/qcCode',
            method: 'POST',
            data: {page},
            responseType: 'blob'
        })
    }
}

export default new QrcodeService();