import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, message, Radio } from "antd";

import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import questionService from "src/module/question/service/question.service";
import MyEditor from "src/common/components/MyEditor";

export const QuestionDetail: FC = () => {
	const [editorLoading, setEditorLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const params = useParams();
	const [form] = useForm();

	useEffect(() => {
		if (params.id) {
			questionService.detail(Number(params.id)).then((res) => {
				if (res.data.code === 200) {
					form.setFieldsValue({
						...res.data.data,
					});
					setEditorLoading(true)	
				}
			});
		}else(setEditorLoading(true))
	}, [form, params.id]);

	const onFinish = async (value: any) => {
		if (!params.id) {
			const { data } = await questionService.add(value);
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/question");
			} else {
				message.error(data.msg);
			}
		} else {
			const { data } = await questionService.update({
				...value,
				id: Number(params.id),
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/question");
			} else {
				message.error(data.msg);
			}
		}
	};

	return (
		<Container>
			<Form labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} style={{ padding: 20 }} onFinish={onFinish} form={form}>
				<Form.Item label={t("title")} name='title'>
					<Input placeholder={t("title")} />
				</Form.Item>

				<Form.Item label={t("status")} name='status'>
					<Radio.Group>
						<Radio value={1}>{t("onSale")}</Radio>
						<Radio value={2}>{t("off")}</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item label={t("sort")} name='sort'>
					<Input placeholder={t("sort")} />
				</Form.Item>

				<Form.Item label={t("content")} name='content'>
				{editorLoading && <MyEditor />}
				</Form.Item>
				<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
					<Button type='primary' htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
