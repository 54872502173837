import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Form, message, Select, Space, Table, Switch } from "antd";
import { SelectSite } from "../../module/site/component/select.site";

import { useNavigate } from "react-router-dom";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { EquipmentsEntity } from "../../module/equipments/entity/equipments.entity";
import { EquipmentsParam } from "../../module/equipments/param/equipments.param";
import equipmentsService from "../../module/equipments/service/equipments.service";
import { checkPermission } from "src/module/admin/service/permission.service";

const { Option } = Select;

export const Equipment: FC = () => {
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState(0);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [dataList, setDataList] = useState<EquipmentsEntity[]>([]);
	const [searchObj, setSearchObj] = useState<EquipmentsParam>({ customPageSize: 15, customPageNum: 1 });

	const init = useCallback(async () => {
		const { data } = await equipmentsService.List({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const onSearch = (value: any) => {
		setSearchObj({ ...value, customPageSize: 15, customPageNum: 1 });
	};

	const washDReset = async (param: EquipmentsEntity) => {
		const { data } = await equipmentsService.washDReset(param);

		if (data.code === 200) {
			message.success("操作成功");
		} else message.error("操作失败");
	};
	const zhuMReset = async (id: number | undefined) => {
		const { data } = await equipmentsService.zhuMReset(id);
		if (data.code === 200) {
			message.success("复位成功，请检查机器状态");
		} else {
			message.error("操作失败");
		}
	};
	const shutDown = async (param: number | undefined) => {
		const { data } = await equipmentsService.shutDown(param);

		if (data.code === 200) {
			message.success("操作成功");
		} else message.error("操作失败");
	};

	const cancelOrder = async (param: number | undefined) => {
		const { data } = await equipmentsService.cancelOrder(param);

		if (data.code === 200) {
			message.success("操作成功");
		} else message.error("操作失败");
	};

	const switchAutoUpdateStatus = async (record: EquipmentsEntity) => {
		const { data } = await equipmentsService.update({ ...record, autoStatus: record.autoStatus === 0 ? 1 : 0 });
		if (data.code === 200) {
			message.success(t("success"));
			init();
		} else {
			message.error(data.msg);
		}
	};

	const openRecycleSystem = (ip: string) => {
		window.open("https://recycle.splashchina.com/new?ip=" + ip);
	};

	const columns = [
		{ title: t("id"), dataIndex: "id" },
		{ title: t("equipmentName"), dataIndex: "name" },
		{ title: t("shopNumber"), dataIndex: "shopNumber" },
		// { title: t("plcIp"), dataIndex: "ipAddress" },
		{
			title: t("识别/plc/机器 IP"),
			render: (record: any) => {
				return (
					<>
						<a href={`http://${record.cameraToken}`} target='_blank' rel='noreferrer'>
							{record.cameraToken}
						</a>
						<br />
						<span>{record.ipAddress || "/"}</span>
						<br />
						<span>{record.machineIp || "/"}</span>
					</>
				);
			},
		},

		// {
		// 	title: t("cameraIp"),
		// 	dataIndex: "cameraToken",
		// 	render: (text: string) => {
		// 		return (
		// 			<a href={`http://${text}`} target='_blank' rel='noreferrer'>
		// 				{" "}
		// 				{text}
		// 			</a>
		// 		);
		// 	},
		// },
		{ title: "Water(m³)", dataIndex: "water" },
		{ title: "Power(kWh)", dataIndex: "power" },
		{
			title: t("autoUpdate"),
			render: (record: EquipmentsEntity) => {
				return (
					<Switch
						checkedChildren={t("autoUpdateOpen")}
						unCheckedChildren={t("autoUpdateClose")}
						checked={Boolean(record.autoStatus)}
						onClick={() => switchAutoUpdateStatus(record)}
					/>
				);
			},
		},
		{
			title: t("equipmentStatus"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? t("enable") : t("Disable");
			},
		},
		{
			title: t("machineStatus"),
			dataIndex: "machineStatus",
			render: (text: keyof typeof equipmentsService.machineStatus, record: EquipmentsEntity) =>
				record.brand === 1 || record.brand === 2 || record.brand === 4 || record.brand === 5 ? equipmentsService.machineStatus[text] : "不受监控",
		},
		{ title: t("lastRunTime"), dataIndex: "lastRunTime" },
		{
			title: t("equipmentOperationStatus"),
			dataIndex: "runState",
			render: (text: number) => {
				return text === 0 ? t("ready") : t("running");
			},
		},
		{
			title: t("errorCode"),
			dataIndex: "errorCode",
			render: (text: keyof typeof equipmentsService.errorCode) => equipmentsService.errorCode[text],
		},
		{
			title: t("action"),
			render: (record: EquipmentsEntity) => {
				return (
					<div>
						{checkPermission(70) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/equipments/" + record.id);
								}}
							>
								{t("modify")}
							</Button>
						)}

						{record.brand === 1 && record.isRestoration === 1 && (
							<Button
								style={{
									marginRight: "20px",
									marginTop: 5,
									backgroundColor: "#e6a23c",
									color: "#ffffff",
									width: "67px",
								}}
								onClick={() => {
									washDReset({ id: record.id, type: 1 });
								}}
							>
								F3
							</Button>
						)}
						{record.brand === 1 && record.isRestoration === 1 && (
							<Button
								style={{
									marginRight: "20px",
									marginTop: 5,
									backgroundColor: "#e6a23c",
									color: "#ffffff",
									width: "67px",
								}}
								onClick={() => washDReset({ id: record.id, type: 2 })}
							>
								OK
							</Button>
						)}
						{record.brand === 1 && record.isRestoration === 1 && (
							<Button
								style={{
									marginRight: "20px",
									marginTop: 5,
									backgroundColor: "#e6a23c",
									color: "#ffffff",
								}}
								onClick={() => washDReset({ id: record.id, type: 3 })}
							>
								CANCEL
							</Button>
						)}
						{record.brand === 5 && record.isRestoration === 1 && (
							<Button danger style={{ marginRight: "20px", marginTop: 5 }} onClick={() => washDReset({ id: record.id, type: 3 })}>
								RESET
							</Button>
						)}
						{(record.brand === 2 || record.brand === 4) && record.isRestoration === 1 && (
							<Button type='primary' danger style={{ marginTop: 5 }} onClick={() => zhuMReset(record.id)}>
								远程复位
							</Button>
						)}

						{record.brand === 4 && record.isRestoration === 1 && (
							<Button
								type='default'
								danger
								style={{ margin: "0 20px" }}
								onClick={() => shutDown(record.id)}
							>
								紧急停止
							</Button>
						)}

						{record.brand === 4 && record.isRestoration === 1 && (
							<Button
								type="dashed"
								danger
								onClick={() => cancelOrder(record.id)}
							>
								取消指令
							</Button>
						)}

						{!!record.recycleSystemIp && (
							<Button type='default' onClick={() => openRecycleSystem(record.recycleSystemIp!)}>
								查看水循环
							</Button>
						)}
					</div>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' style={{ marginBottom: 20 }} onFinish={onSearch}>
				<Form.Item label={t("site.name")} name='shopId'>
					<SelectSite />
				</Form.Item>
				<Form.Item label={t("siteStatus")} name='status'>
					<Select placeholder={t("siteStatus")} allowClear>
						<Option value='1'>{t("enable")}</Option>
						<Option value='2'>{t("Disable")}</Option>
					</Select>
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						{checkPermission(69) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/equipments/add");
								}}
							>
								{t("add")}
							</Button>
						)}
					</Space>
				</Form.Item>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => {
					return record.id + "";
				}}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.ant-form-item {
		width: 300px;
	}
`;
