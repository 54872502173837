import { DatabaseOutlined, DollarOutlined, SearchOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, DatePicker, Progress, Radio, Select, Space } from "antd";
import moment from "moment";
import { FC, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { getInitialProps, useTranslation } from "react-i18next";
import { getMonth, getQuarterS, getYearFirstLastDay } from "../../../common/timeFormatting";
import { floatFormat, styleCalc } from "../../../common/Util";
import { Bar } from "../../../module/overview/components/bar";
import { OrderBarComponent } from "./components/OrderBarComponent";
import { PieComponents } from "../../../module/overview/components/pie";
// import { SelectCity } from "../../../module/overview/components/select.city";
import { SelectCity } from "src/module/overview/components/select.city";
import { cityObj, IncomingStructEntity, searchObj } from "../../../module/overview/entity/statistics.entity";
import statisticsService from "../../../module/overview/service/statistics.service";
import { SelectSite } from "../../../module/site/component/select.site";
import { CouponStatistics } from "./components/CouponStatistics";

const { Option } = Select;
const format = "YYYY-MM-DD";
export const Statistics: FC = () => {
	// 站点状态数据
	const [shopStatus, setShopStatus] = useState({
		shopCloseCount: 0,
		shopMaintainCount: 0,
		shopOpenCount: 0,
		shopPrepareCount: 0,
		shopRestCount: 0,
	});
	// 日最高订单量
	const [maxOrder, setMaxOrder] = useState({
		orderCount: 0,
		time: "",
	});
	// 日均洗车量
	const [dayCarWash, setDayCarWash] = useState({
		betweenDay: 0,
		orderCount: 0,
	});
	// 外洗订单排名
	const [washout, setWashout] = useState([]);
	// 内清订单排名
	const [qinDynasty, setQinDynasty] = useState([]);
	// 美容订单排名
	const [beautyRanked, setBeautyRanked] = useState([]);
	// 新用户数据
	const [user, setUser] = useState({
		newUserCount: 0,
		newUserRingRatioCount: 0,
		newUserYearOnYearCount: 0,
		orderRingRatioUserCount: 0,
		orderUserCount: 0,
		orderYearOnYearUserCount: 0,
		repeatedlyOrderUserCount: 0,
		repeatedlyRingRatioOrderUserCount: 0,
		repeatedlyYearOnYearOrderUserCount: 0,
		sequential: 0,
		yearOnYear: 0,
	});
	// 用户留存率
	const [retentionRate, setRetentionRate] = useState({
		retention: 0,
		sequentialRetention: 0,
		yearOnYearRetention: 0,
	});
	// 用券用户量
	const [couponUser, setCouponUser] = useState({
		users: 0,
		userSequential: 0,
		userYearOnYear: 0,
	});
	// 洗车频次
	const [WashingFrequency, setWashingFrequency] = useState({
		frequency: 0,
		WashingFrequencySequential: 0,
		WashingFrequencyYearOnYear: 0,
	});
	// 订单数据
	const [data, setData] = useState([]);
	// 收入结构
	const [pieData, setPieData] = useState([
		{
			type: "订单",
			value: 0,
		},
		{
			type: "充值",
			value: 0,
		},
		{
			type: "套餐",
			value: 0,
		},
		{
			type: "会员卡",
			value: 0,
		},
	]);
	const [incomingData, setIncomingData] = useState<IncomingStructEntity>({
		cardIncome: 0,
		orderIncome: 0,
		packageIncome: 0,
		rechargeIncome: 0,
		totalCardIncome: 0,
		totalOrderIncome: 0,
		totalPackageIncome: 0,
		remainingBalance: 0,
	});
	// 服务类型
	const [productList, setProductList] = useState([]);
	// 时间类型
	const [dateType, setDateType] = useState("1");
	// 查询条件
	const [searchObj, setSearchObj] = useState<searchObj>({
		startTime: `${moment().format(format)} 00:00:00`,
		endTime: moment().format(format) + " 23:59:59",
		polymerizeType: 1,
	});
	// 省市区id
	const [cityObj, setCityObj] = useState<cityObj>({});
	// 订单数量统计及ATV
	const [orderCountAndATV, setOrderCountAndATV] = useState<Record<string, number>>({});

	const { t } = useTranslation();

	const openDateChange = (e: any) => {
		setSearchObj({
			...searchObj,
			openStartTime: e ? e[0].format(format) + " 00:00:00" : "",
			openEndTime: e ? e[1].format(format) + " 23:59:59" : "",
		});
	};
	const DateChange = (e: any) => {
		setSearchObj({
			...searchObj,
			startTime: e ? e[0].format(format) + " 00:00:00" : "",
			endTime: e ? e[1].format(format) + " 23:59:59" : "",
		});
		setDateType("0");
	};
	const siteChange = (e: number) => {
		setSearchObj({
			...searchObj,
			shopId: e,
		});
	};
	const onSearch = useCallback(
		(Obj: any) => {
			// 门店状态
			statisticsService.getShopStatus(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					setShopStatus(res.data.data);
				}
			});
			// 日最高订单量
			statisticsService.getMaxOrder(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					setMaxOrder(res.data.data);
				} else {
					setMaxOrder({
						orderCount: 0,
						time: "",
					});
				}
			});
			// 日均洗车量
			statisticsService.getDayCarWash(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					setDayCarWash(res.data.data);
				}
			});
			// 外洗订单排名
			statisticsService.getWashout(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					const list = res.data.data;
					const countList = list.map((item: any) => {
						return item.orderCount;
					});
					const maxCoupons = Math.max(...countList);
					list.forEach((item: any) => {
						item.percent = floatFormat(item.orderCount / maxCoupons, 2) * 100;
						item.name = item.shopNumber;
						item.count = item.orderCount;
					});
					setWashout(list);
				}
			});
			// 内清订单排名
			statisticsService.getQinDynasty(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					const list = res.data.data;
					const countList = list.map((item: any) => {
						return item.orderCount;
					});
					const maxCoupons = Math.max(...countList);
					list.forEach((item: any) => {
						item.percent = floatFormat(item.orderCount / maxCoupons, 2) * 100;
						item.name = item.shopNumber;
						item.count = item.orderCount;
					});
					setQinDynasty(list);
				}
			});
			// 美容订单排名
			statisticsService.getBeautyRanked(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					const list = res.data.data;
					const countList = list.map((item: any) => {
						return item.orderCount;
					});
					const maxCoupons = Math.max(...countList);
					list.forEach((item: any) => {
						item.percent = floatFormat(item.orderCount / maxCoupons, 2) * 100;
						item.name = item.shopNumber;
						item.count = item.orderCount;
					});
					setBeautyRanked(list);
				}
			});
			// 新用户数据
			statisticsService.getNewsUser(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					const userObj = res.data.data;
					// 用户环比
					userObj.sequential = Math.floor(Math.round(((userObj.newUserCount - userObj.newUserRingRatioCount) / userObj.newUserRingRatioCount) * 100 * 100) / 100);
					//用户同比
					userObj.yearOnYear = Math.floor(Math.round(((userObj.newUserCount - userObj.newUserYearOnYearCount) / userObj.newUserYearOnYearCount) * 100 * 100) / 100);
					// 留存率
					const retentionRate = {
						retention: 0,
						sequentialRetention: 0,
						yearOnYearRetention: 0,
					};
					retentionRate.retention = Math.floor(Math.round((userObj.repeatedlyOrderUserCount / userObj.orderUserCount) * 100 * 100) / 100);
					const sequential = Math.floor(Math.round((userObj.repeatedlyRingRatioOrderUserCount / userObj.orderRingRatioUserCount) * 100 * 100) / 100);
					const yearOnYearRetention = Math.floor(Math.round((userObj.repeatedlyYearOnYearOrderUserCount / userObj.orderYearOnYearUserCount) * 100 * 100) / 100);
					retentionRate.sequentialRetention = Math.floor(Math.round(((retentionRate.retention - sequential) / sequential) * 100 * 100) / 100);

					retentionRate.yearOnYearRetention = Math.floor(Math.round(((retentionRate.retention - yearOnYearRetention) / yearOnYearRetention) * 100 * 100) / 100);
					// retentionRate.yearOnYearRetention=yearOnYearRetention
					setUser(userObj);
					setRetentionRate(retentionRate);
				}
			});
			// 用券用户量
			statisticsService.getCouponUser(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					const obj = res.data.data;
					setCouponUser({
						users: obj.intervalCount,
						userSequential: Math.floor(Math.round(((obj.intervalCount - obj.ringRatioCount) / obj.ringRatioCount) * 100 * 100) / 100),
						userYearOnYear: Math.floor(Math.round(((obj.intervalCount - obj.yearOnYearCount) / obj.yearOnYearCount) * 100 * 100) / 100),
					});
				}
			});
			// 洗车频次
			statisticsService.getWashingFrequency(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					const obj = res.data.data;
					const frequency = obj.orderCount / obj.userCount;
					const WashingFrequencySequential = obj.orderRingRatioCount / obj.userRingRatioCount;
					const WashingFrequencyYearOnYear = obj.orderYearOnYearCount / obj.userYearOnYearCount;
					setWashingFrequency({
						frequency: Math.round(frequency * 100) / 100,
						WashingFrequencySequential: Math.round((frequency - WashingFrequencySequential) / WashingFrequencySequential),
						WashingFrequencyYearOnYear: Math.round((frequency - WashingFrequencyYearOnYear) / WashingFrequencyYearOnYear),
					});
				}
			});
			// 订单聚合数据
			getOrderList(Obj);
			// 收入结构
			statisticsService.getIncomeStructure(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					const obj = res.data.data;
					const pie = [
						{
							type: t("order"),
							value: obj.orderIncome,
						},
						{
							type: t("recharge"),
							value: obj.rechargeIncome,
						},
						{
							type: t("combo"),
							value: obj.packageIncome,
						},
						{
							type: t("vipCard"),
							value: obj.cardIncome,
						},
					];
					setPieData(pie);
					setIncomingData(obj);
				}
			});

			statisticsService.getOrderCountAndATV(Obj).then((res) => {
				if (res.data.code === 200 && res.data.data) {
					setOrderCountAndATV(res.data.data);
				}
			});
		},
		[t]
	);
	const getOrderList = (Obj: any) => {
		statisticsService.getOrders(Obj).then((res) => {
			if (res.data.code === 200 && res.data.data) {
				setData(res.data.data);
			}
		});
	};
	const ParticleSizeChange = (e: string) => {
		const Obj = { ...searchObj, ...cityObj, polymerizeType: e };
		// 订单聚合数据
		getOrderList(Obj);
		setSearchObj({
			...searchObj,
			polymerizeType: e,
		});
	};
	const handleModalInputChange = (e: any) => {
		setCityObj(e);
	};

	const handleSelectChange = (type: string, e: number) => {
		setSearchObj({
			...searchObj,
			[type]: e,
		});
	};
	const onDateTypeChange = (e: any) => {
		setDateType(e.target.value);
		switch (e.target.value) {
			case "1":
				setSearchObj({
					...searchObj,
					startTime: `${moment().format(format)} 00:00:00`,
					endTime: moment().format(format) + " 23:59:59",
				});
				break;
			case "2": {
				const Month = getMonth();
				setSearchObj({
					...searchObj,
					startTime: `${Month[0]} 00:00:00`,
					endTime: Month[1] + " 23:59:59",
				});
				break;
			}
			case "3": {
				const season = getQuarterS();
				setSearchObj({
					...searchObj,
					startTime: `${season[0]} 00:00:00`,
					endTime: season[1] + " 23:59:59",
				});
				break;
			}
			case "4": {
				const yearlist = getYearFirstLastDay();
				setSearchObj({
					...searchObj,
					startTime: `${yearlist[0]} 00:00:00`,
					endTime: yearlist[1] + " 23:59:59",
				});
				break;
			}
		}
	};
	const productTypeList = () => {
		statisticsService.goodsTypeList().then((res) => {
			if (res.data.code === 200) {
				setProductList(res.data.data);
			}
		});
	};
	useLayoutEffect(() => {
		productTypeList();
	}, []);

	useEffect(() => {
		const obj = {
			startTime: `${moment().format(format)} 00:00:00`,
			endTime: moment().format(format) + " 23:59:59",
			polymerizeType: 1,
		};
		onSearch(obj);
	}, [onSearch]);

	return (
		<Container>
			<div className='data-headers'>
				<div className='search-list'>
					<div className='search-item-title'>{t("siteType")}</div>
					<div className='search-item-input'>
						<Select
							placeholder={t("pleaseChooseSiteType")}
							style={{ width: styleCalc(142) + "px", marginLeft: styleCalc(12) + "px" }}
							allowClear
							onChange={(e) => handleSelectChange("shopTypes", e)}>
							<Option value='1'>{t("express")}</Option>
							<Option value='2'>{t("superCenter")}</Option>
						</Select>
					</div>
				</div>
				<div className='search-list'>
					<div className='search-item-title'>{t("serviceType")}</div>
					<div className='search-item-input'>
						<Select
							placeholder={t("pleaseChooseServiceType")}
							style={{ width: styleCalc(143) + "px", marginLeft: styleCalc(12) + "px" }}
							allowClear
							onChange={(e) => handleSelectChange("productTypeIds", e)}>
							{productList.map((item: any) => {
								return (
									<Option value={item.id} key={item.id}>
										{getInitialProps().initialLanguage === "zh-CN" ? item.title : item.enTitle}
									</Option>
								);
							})}
						</Select>
					</div>
				</div>
				<div className='search-list'>
					<div className='search-item-title'>{t("site")}</div>
					<div className='search-item-input' style={{ width: styleCalc(230) + "px", marginLeft: styleCalc(12) + "px" }}>
						<SelectSite onChange={siteChange} />
					</div>
				</div>
				<div className='search-list'>
					<div className='search-item-title'>{t("opening.date")}</div>
					<div className='search-item-input' style={{ marginLeft: styleCalc(12) + "px" }}>
						<DatePicker.RangePicker onChange={openDateChange} />
					</div>
				</div>
				<div className='search-list'>
					<div className='search-item-title'>{t("siteCity")}</div>
					<div className='search-item-input'>
						<SelectCity provinceId={cityObj.provinceId} cityId={cityObj.cityId} districtId={cityObj.districtId} onChange={handleModalInputChange} />
					</div>
				</div>
				<div className='search-list'>
					<div className='search-item-title'>{t("orderPeriod")}</div>
					<div className='search-item-input' style={{ marginLeft: styleCalc(12) + "px" }}>
						<DatePicker.RangePicker value={[searchObj.startTime ? moment(searchObj.startTime) : null, searchObj.endTime ? moment(searchObj.endTime) : null]} onChange={DateChange} />
					</div>
				</div>
				<div className='search-list'>
					<Radio.Group value={dateType} onChange={onDateTypeChange}>
						<Radio.Button value='1'>{t("today")}</Radio.Button>
						<Radio.Button value='2'>{t("thisMonth")}</Radio.Button>
						<Radio.Button value='3'>{t("thisQuarter")}</Radio.Button>
						<Radio.Button value='4'>{t("thisYear")}</Radio.Button>
					</Radio.Group>
				</div>
				<div className='search-list'>
					<Button type='primary' onClick={() => onSearch({ ...searchObj, ...cityObj })} icon={<SearchOutlined />}>
						{t("search")}
					</Button>
				</div>
			</div>
			<div className='data-center-status'>
				<div className='left'>
					<div className='nav'>{t("siteStatus")}</div>
					<div className='status-list'>
						<div className='status-item'>
							<div className='status-item-left'>
								<img className='image' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/yingyezhong.png' alt='' />
								<span className='span'>{t("open")}</span>
							</div>
							<div className='status-item-right'>{shopStatus.shopOpenCount}</div>
						</div>
						<div className='status-item' style={{ background: "rgb(255, 242, 241)" }}>
							<div className='status-item-left'>
								<img className='image' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/xiuxizhong.png' alt='' />
								<span className='span'>{t("takeABreak")}</span>
							</div>
							<div className='status-item-right' style={{ color: "#FB2113" }}>
								{shopStatus.shopRestCount}
							</div>
						</div>
						<div className='status-item' style={{ background: "rgba(255, 113, 53, 0.06)" }}>
							<div className='status-item-left'>
								<img className='image' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/weihuzhong.png' alt='' />
								<span className='span'>{t("maintain")}</span>
							</div>
							<div className='status-item-right' style={{ color: "#FF7135" }}>
								{shopStatus.shopMaintainCount}
							</div>
						</div>
						<div className='status-item' style={{ background: "rgba(141, 59, 245, 0.06)" }}>
							<div className='status-item-left'>
								<img className='image' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/jingqingqidai.png' alt='' />
								<span className='span'>{t("construction")}</span>
							</div>
							<div className='status-item-right' style={{ color: "#8D3BF5" }}>
								{shopStatus.shopPrepareCount}
							</div>
						</div>
						<div className='status-item' style={{ background: "rgba(119, 119, 119, 0.06)" }}>
							<div className='status-item-left'>
								<img className='image' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/yiguanbi.png' alt='' />
								<span className='span'>{t("closed")}</span>
							</div>
							<div className='status-item-right' style={{ color: "#777777" }}>
								{shopStatus.shopCloseCount}
							</div>
						</div>
					</div>
				</div>
				<div className='card'>
					<img className='image' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/orderBgImg.png' alt='' />
					<div className='content'>
						<div className={"top"}>
							<div className='count'>{t("dailyMaximumOrder")}</div>
							<div className='time'>{maxOrder.time}</div>
						</div>
						<div className='content-bottom'>
							<img className='bottom-image' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/car.png' alt='' />
							<span className='bottom-count'>{maxOrder.orderCount}</span>
						</div>
					</div>
				</div>
				<div className='card'>
					<img className='image' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/watshBgImg.png' alt='' />
					<div className='content'>
						<div className={"top"}>
							<div className='count'>{t("averageDailyCarWa")}</div>
						</div>
						<div className='content-bottom'>
							<img className='bottom-image' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/car.png' alt='' />
							<span className='bottom-count'>{dayCarWash.orderCount && dayCarWash.betweenDay ? Math.round((dayCarWash.orderCount / dayCarWash.betweenDay) * 100) / 100 : 0}</span>
						</div>
					</div>
				</div>
			</div>
			<div className='data-center-ranking'>
				<div className='watchRanking'>
					<Bar list={washout} nav={t("expressWashOrderRanking")} color={"linear-gradient(270deg, #90EBFF 0%, #00A4FF 100%)"} />
				</div>
				<div className='watchRanking'>
					<Bar list={qinDynasty} nav={t("interiorCleanOrderRanking")} color={"linear-gradient(270deg, #8EF1DD 0%, #23D4A2 100%)"} />
				</div>
				<div className='watchRanking'>
					<Bar list={beautyRanked} nav={t("detailingOrderRanking")} color={"linear-gradient(270deg, #919DFC 0%, #6334F4 100%)"} />
				</div>
			</div>
			<div className='data-center-Progress'>
				<div className='Progress-list'>
					<div className={"left"}>
						<div className='nav'>{t("newUser")}</div>
						<div className='footer'>
							<div className='footer-left'>
								<img className='images' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/kehuzuqun.png' alt='' />
								<div className='desc'>
									<div className='desc-top'>
										{t("userIncreased")}
										<span className='span'>{user.newUserCount ? user.newUserCount : 0}</span>
									</div>
									<div className='desc-bottom'>
										{t("chainRatio")}
										<span className='span' style={{ color: "#6767FF" }}>
											{user.sequential ? user.sequential : "-"}%
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='right'>
						<Progress type='circle' percent={Math.abs(user.yearOnYear)} strokeWidth={8} width={70} strokeColor={"#6767FF"} format={() => `${user.yearOnYear ? user.yearOnYear : "-"}%`} />
					</div>
				</div>
				<div className='Progress-list'>
					<div className={"left"}>
						<div className='nav'>{t("numberOfUsersUsedCoupons")}</div>
						<div className='footer'>
							<div className='footer-left'>
								<img className='images' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/youhuiquan.png' alt='' />
								<div className='desc'>
									<div className='desc-top'>
										{t("user")}
										<span className='span'>{couponUser.users ? couponUser.users : 0}</span>
									</div>
									<div className='desc-bottom'>
										{t("chainRatio")}
										<span className='span' style={{ color: "#2B64F4" }}>
											{couponUser.userSequential ? couponUser.userSequential : "-"}%
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='right'>
						<Progress
							type='circle'
							percent={Math.abs(couponUser.userYearOnYear)}
							strokeWidth={8}
							width={70}
							strokeColor={"#2B64F4"}
							format={() => `${couponUser.userYearOnYear ? couponUser.userYearOnYear : "-"}%`}
						/>
					</div>
				</div>
				<div className='Progress-list'>
					<div className={"left"}>
						<div className='nav'>{t("washFrequency")}</div>
						<div className='footer'>
							<div className='footer-left'>
								<img className='images' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/pinlv.png' alt='' />
								<div className='desc'>
									<div className='desc-top'>
										{t("frequency")}
										<span className='span'>{WashingFrequency.frequency ? WashingFrequency.frequency : 0}</span>
									</div>
									<div className='desc-bottom'>
										{t("chainRatio")}
										<span className='span' style={{ color: "#28D88A" }}>
											{WashingFrequency.WashingFrequencySequential ? WashingFrequency.WashingFrequencySequential : "-"}%
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='right'>
						<Progress
							type='circle'
							percent={Math.abs(WashingFrequency.WashingFrequencyYearOnYear)}
							strokeWidth={8}
							width={70}
							strokeColor={"#28D88A"}
							format={() => `${WashingFrequency.WashingFrequencyYearOnYear ? WashingFrequency.WashingFrequencyYearOnYear : "-"}%`}
						/>
					</div>
				</div>
				<div className='Progress-list'>
					<div className={"left"}>
						<div className='nav'>{t("retention")}</div>
						<div className='footer'>
							<div className='footer-left'>
								<img
									className='images'
									style={{ width: styleCalc(49) + "px", height: styleCalc(49) + "px" }}
									src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/liuchunlv.png'
									alt=''
								/>
								<div className='desc'>
									<div className='desc-top'>
										{t("retention")}
										<span className='span'>{retentionRate.retention ? retentionRate.retention : 0}%</span>
									</div>
									<div className='desc-bottom'>
										{t("chainRatio")}
										<span className='span' style={{ color: "#FF8B04" }}>
											{retentionRate.sequentialRetention ? retentionRate.sequentialRetention : "-"}%
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='right'>
						<Progress
							type='circle'
							percent={Math.abs(retentionRate.yearOnYearRetention)}
							strokeWidth={8}
							width={70}
							strokeColor={"#FF8B04"}
							format={() => `${retentionRate.yearOnYearRetention ? retentionRate.yearOnYearRetention : "-"}%`}
						/>
					</div>
				</div>
			</div>
			<div className='coupon-user-card-combo' style={{display:"none"}}>
				<div className='first'>
					<CouponStatistics value={searchObj} />
				</div>
			</div>
			<div className='data-footer'>
				<div className='data-footer-left'>
					<div className='nav-top'>
						<span className='span'>{t("orderData")}</span>
						<div className='tips'>
							<Space>
								<span>
									<DatabaseOutlined style={{ color: "#2264F6" }} />
									&nbsp;{t("order")}:&nbsp;{orderCountAndATV.orderCount}
								</span>
								<span>
									<DollarOutlined style={{ color: "#2264F6" }} />
									&nbsp;{t("ATV")}:&nbsp;{orderCountAndATV.atv}
								</span>
								<span>
									<DatabaseOutlined style={{ color: "#2264F6" }} />
									&nbsp;{t("totalOrder")}:&nbsp;{orderCountAndATV.totalOrderCount}
								</span>
								<span>
									<DollarOutlined style={{ color: "#2264F6" }} />
									&nbsp;{t("totalATV")}:&nbsp;{orderCountAndATV.totalATV}
								</span>
							</Space>
						</div>
						<div className='select-right'>
							<Select defaultValue='1' style={{ width: styleCalc(83) + "px" }} onChange={(e) => ParticleSizeChange(e)}>
								<Option value='1'>{t("orderHour")}</Option>
								<Option value='2'>{t("orderDay")}</Option>
								<Option value='3'>{t("orderWeek")}</Option>
								<Option value='4'>{t("orderMonth")}</Option>
								<Option value='5'>{t("orderYear")}</Option>
							</Select>
						</div>
					</div>
					<div className='nav-bottom'>
						<OrderBarComponent list={data} />
					</div>
				</div>
				<div className='data-footer-right'>
					<div className='nav'>{t("incomeStructure")}</div>
					<div className='pie-bottom' style={{ height: styleCalc(146) + "px" }}>
						<PieComponents list={pieData} />
					</div>
					<div className='list-bottom'>
						<div className='item-top-one'>
							<div className='item-top-one-type'>
								<div className='bgType' />
								<span className='span'>{t("order")}</span>
							</div>
							<div className='item-top-one-count'>{incomingData.orderIncome}</div>
						</div>
						<div className='item-top-one'>
							<div className='item-top-one-type'>
								<span className='span'>{t("totalOrderIncome")}</span>
							</div>
							<div className='item-top-one-count'>{incomingData.totalOrderIncome}</div>
						</div>
						<div className='item-top-one'>
							<div className='item-top-one-type'>
								<div className='bgType' style={{ background: "#36C6EC" }} />
								<span className='span'>{t("recharge")}</span>
							</div>
							<div className='item-top-one-count'>{incomingData.rechargeIncome}</div>
						</div>
						<div className='item-top-one'>
							<div className='item-top-one-type'>
								<span className='span'>{t("remainingBalance")}</span>
							</div>
							<div className='item-top-one-count'>{incomingData.remainingBalance}</div>
						</div>
						<div className='item-top-one'>
							<div className='item-top-one-type'>
								<div className='bgType' style={{ background: "#FF6945" }} />
								<span className='span'>{t("combo")}</span>
							</div>
							<div className='item-top-one-count'>{incomingData.packageIncome}</div>
						</div>
						<div className='item-top-one'>
							<div className='item-top-one-type'>
								<span className='span'>{t("totalComboIncome")}</span>
							</div>
							<div className='item-top-one-count'>{incomingData.totalPackageIncome}</div>
						</div>
						<div className='item-top-one'>
							<div className='item-top-one-type'>
								<div className='bgType' style={{ background: "#F8D31A" }} />
								<span className='span'>{t("vipCard")}</span>
							</div>
							<div className='item-top-one-count'>{incomingData.cardIncome}</div>
						</div>
						<div className='item-top-one'>
							<div className='item-top-one-type'>
								<span className='span'>{t("totalCardIncome")}</span>
							</div>
							<div className='item-top-one-count'>{incomingData.totalCardIncome}</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	min-height: ${styleCalc(1016)}px;
	background: #f0f2f5;
	font-family: PingFangSC-Regular, PingFang SC, serif;
	padding-bottom: ${styleCalc(1)}px;

	.data-headers,
	.data-center-status,
	.data-center-ranking,
	.data-center-Progress,
	.data-footer {
		width: 100%;
		margin-bottom: ${styleCalc(16)}px;
	}

	.data-headers {
		min-height: ${styleCalc(99)}px;
		background: #ffffff;
		border-radius: ${styleCalc(9)}px;
		padding: ${styleCalc(17)}px ${styleCalc(17)}px 0 ${styleCalc(17)}px;

		display: flex;
		justify-content: start;
		flex-wrap: wrap;

		.search-list {
			height: auto;
			display: flex;
			align-items: center;
			margin-bottom: ${styleCalc(18)}px;
			margin-left: ${styleCalc(18)}px;

			.search-item-title {
				font-size: ${styleCalc(11)}px;
				font-weight: 400;
				color: #333333;
			}

			.ant-radio-button-checked {
				background: #1b8fff;
			}

			.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
				color: #ffffff;
			}
		}
	}

	.data-center-status {
		display: flex;
		align-items: center;
		justify-content: space-between;
		/* flex-wrap: wrap; */

		.left {
			width: ${styleCalc(751)}px;
			height: ${styleCalc(104)}px;
			background: #ffffff;
			border-radius: ${styleCalc(9)}px;
			padding: ${styleCalc(14)}px ${styleCalc(15)}px ${styleCalc(21)}px ${styleCalc(18)}px;

			.nav {
				font-size: ${styleCalc(14)}px;
				font-weight: 600;
				color: #333333;
				margin-bottom: ${styleCalc(6)}px;
			}

			.status-list {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.status-item {
					width: ${styleCalc(138)}px;
					height: ${styleCalc(43)}px;
					background: rgba(43, 100, 244, 0.06);
					border-radius: ${styleCalc(6)}px;
					display: flex;
					align-items: center;
					justify-content: space-around;

					.status-item-left {
						display: flex;
						align-items: center;

						.image {
							width: ${styleCalc(30)}px;
							height: ${styleCalc(30)}px;
						}

						.span {
							font-size: ${styleCalc(10)}px;
							font-weight: 400;
							color: #333333;
							margin-left: ${styleCalc(6)}px;
						}
					}

					.status-item-right {
						font-size: ${styleCalc(16)}px;
						font-weight: 600;
						color: #2b64f4;
					}
				}
			}
		}

		.card {
			width: ${styleCalc(176)}px;
			height: ${styleCalc(104)}px;
			background: linear-gradient(134deg, #1b64fb 0%, #8fb4fd 100%);
			border-radius: ${styleCalc(9)}px;
			position: relative;

			.image {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}

			.content {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 90;
				padding: ${styleCalc(8)}px ${styleCalc(4)}px ${styleCalc(22)}px ${styleCalc(10)}px;

				.top {
					display: flex;
					justify-content: space-between;
					background: none;
					height: ${styleCalc(32)}px;

					.count {
						font-size: ${styleCalc(13)}px;
						font-weight: 600;
						color: #ffffff;
						word-break: break-all;
					}

					.time {
						font-size: ${styleCalc(10)}px;
						font-weight: 400;
						color: #ffffff;
						margin-top: ${styleCalc(3)}px;
					}
				}

				.content-bottom {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 0;

					.bottom-image {
						width: ${styleCalc(36)}px;
						height: ${styleCalc(36)}px;
					}

					.bottom-count {
						font-size: ${styleCalc(22)}px;
						font-weight: 600;
						color: #ffffff;
						margin-left: ${styleCalc(10)}px;
					}
				}
			}
		}
	}

	.data-center-ranking {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.watchRanking {
			width: ${styleCalc(368)}px;
			height: ${styleCalc(300)}px;
			background: #ffffff;
			border-radius: ${styleCalc(9)}px;
			padding: ${styleCalc(15)}px ${styleCalc(18)}px;
		}
	}

	.data-center-Progress {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.Progress-list {
			width: ${styleCalc(273)}px;
			height: ${styleCalc(89)}px;
			background: #ffffff;
			border-radius: ${styleCalc(9)}px;
			padding: ${styleCalc(10)}px ${styleCalc(16)}px;
			display: flex;
			justify-content: space-between;

			.nav {
				font-size: ${styleCalc(13)}px;
				font-weight: 600;
				color: #333333;
			}

			.footer {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.footer-left {
					display: flex;
					align-items: center;

					.images {
						width: ${styleCalc(43)}px;
						height: ${styleCalc(32)}px;
					}

					.desc {
						margin-left: ${styleCalc(15)}px;

						.desc-top {
							font-size: ${styleCalc(9)}px;
							font-weight: 400;
							color: #777777;

							.span {
								font-size: ${styleCalc(15)}px;
								font-weight: 600;
								color: #333333;
								margin-left: ${styleCalc(5)}px;
							}
						}

						.desc-bottom {
							font-size: ${styleCalc(9)}px;
							font-weight: 400;
							color: #777777;

							.span {
								font-size: ${styleCalc(15)}px;
								font-weight: 600;
								color: #6767ff;
								margin-left: ${styleCalc(5)}px;
							}
						}
					}
				}

				.right {
					width: ${styleCalc(55)}px;
					height: ${styleCalc(55)}px;

					.ant-progress-text {
						font-size: ${styleCalc(13)}px;
						font-weight: 550;
						color: #333333;
					}
				}
			}
		}
	}

	.coupon-user-card-combo {
		display: flex;
		justify-content: space-between;
		padding: 10rem 0;

		.first,
		.second,
		.third {
			width: 368rem;
			background-color: #fff;
			border-radius: 8rem;
		}
	}

	.data-footer {
		background: #f0f2f5;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.data-footer-left {
			width: ${styleCalc(847)}px;
			height: ${styleCalc(478)}px;
			background: #ffffff;
			border-radius: ${styleCalc(16)}px;
			padding: ${styleCalc(15)}px;

			.nav-top {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.span {
					font-size: ${styleCalc(13)}px;
					font-weight: 600;
					color: #333333;
				}

				.tips {
					font-size: 12rem;

					span {
						margin-left: 10rem;
					}
				}

				.select-right {
					width: ${styleCalc(83)}px;
					height: ${styleCalc(23)}px;
					border-radius: ${styleCalc(2)}px;

					.ant-select-selector {
						height: 24rem;

						.ant-select-selection-item {
							line-height: 24rem;
						}
					}
				}
			}

			.nav-bottom {
				height: ${styleCalc(428)}px;
				margin-top: ${styleCalc(5)}px;
			}
		}

		.data-footer-right {
			width: ${styleCalc(273)}px;
			height: ${styleCalc(478)}px;
			background: #ffffff;
			border-radius: ${styleCalc(12)}px;
			padding: ${styleCalc(15)}px ${styleCalc(18)}px;

			.nav {
				font-size: ${styleCalc(13)}px;
				font-weight: 600;
				color: #333333;
			}

			.list-bottom {
				width: ${styleCalc(201)}px;
				margin: 0 auto;
				margin-top: ${styleCalc(12)}px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				text-align: left;

				.item-top-one {
					margin-top: ${styleCalc(12)}px;
					width: 50%;

					.item-top-one-type {
						width: 100%;
						display: flex;

						.bgType {
							width: ${styleCalc(13)}px;
							height: ${styleCalc(13)}px;
							background: #2264f6;
							border-radius: ${styleCalc(2)}px;
							margin-right: ${styleCalc(5)}px;
						}

						.span {
							font-size: ${styleCalc(10)}px;
							font-weight: 400;
							color: #777777;
						}
					}

					.item-top-one-count {
						font-size: ${styleCalc(16)}px;
						font-weight: 500;
						color: #333333;
					}
				}
			}
		}
	}
`;
