import styled from "@emotion/styled";
import { Button, Col, DatePicker, Input, message, Row, Select, Space, Table } from "antd";
import { AxiosResponse } from "axios";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkPermission } from "src/module/admin/service/permission.service";
import { SelectEquipment } from "src/module/equipments/component/select.equipment";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import Util, { dateFormat } from "../../common/Util";
import { CarwashEntity } from "../../module/carwash/entity/carwash.entity";
import { CarwashParam } from "../../module/carwash/params/carwash.param";
import carwashService from "../../module/carwash/service/carwash.service";
import { SelectSite } from "../../module/site/component/select.site";

const { RangePicker } = DatePicker;
const { Option } = Select;
export const CarwashList: FC = () => {
	const { t } = useTranslation();

	const [dataList, setDataList] = useState<CarwashEntity[]>([]);

	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// 	total: 0,
	// });

	const [searchArr, setSearchArr] = useState<CarwashParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
	});

	const [searchObj, setSearchObj] = useState<CarwashParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	const [total, setTotal] = useState<number>(0);

	const [loading, setLoading] = useState(true);

	const changeAttr = (type: string, e: any) => {
		setSearchArr({
			...searchArr,
			[type]: e,
		});
	};

	const onDateChange = (date: any, dateString: any) => {
		setSearchArr({
			...searchArr,
			startTime: dateString[0],
			endTime: dateString[1],
		});
	};

	const search = () => {
		setSearchObj({
			...searchArr,
			startTime: searchArr.startTime ? moment(searchArr.startTime).format(dateFormat) + " 00:00:00" : undefined,
			endTime: searchArr.endTime ? moment(searchArr.endTime).format(dateFormat) + " 23:59:59" : undefined,
			customPageSize: 15,
		customPageNum: 1,
		});
	};

	const init = useCallback(async () => {
		setLoading(true);
		const { data } = await carwashService.list({
			...searchObj,
			
		});
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
		setLoading(false);
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const download = async () => {
		const res: AxiosResponse<BlobPart> = await carwashService.export(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "carwashList");
		message.success(t("export.success"));
	};

	const columns = [
		{
			title: t("site.name"),
			dataIndex: "shopName",
		},
		{
			title: t("equipmentName"),
			dataIndex: "equipmentName",
		},
		{
			title: t("license"),
			dataIndex: "license",
		},
		{
			title: t("equipmentAddress"),
			dataIndex: "equipmentAddress",
		},
		{
			title: t("operateType"),
			dataIndex: "operateType",
		},
		{
			title: t("operateDatetime"),
			dataIndex: "operateDatetime",
		},
	];
	return (
		<Container>
			<div className='headers'>
				<Row gutter={20}>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("period")}：</div>
							<RangePicker
								defaultValue={[
									moment(searchArr.startTime && searchArr.startTime.toString()),
									moment(searchArr.endTime && searchArr.endTime.toString()),
								]}
								showTime={false}
								onChange={onDateChange}
							/>
						</div>
					</Col>

					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("site")}：</div>
							<SelectSite onChange={(e: number) => changeAttr("shopId", e)} />
						</div>
					</Col>

					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("equipmentName")}：</div>
							<SelectEquipment  onChange={(e:number) => changeAttr("equipmentName", e)}/>
							
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("theCarType")}：</div>
							<Select allowClear placeholder={t("theCarType")} style={{ width: 200 }} onChange={(e) => changeAttr("type", e)}>
								<Option value={0}>{t("all")}</Option>
								<Option value={1}>{t("Vip")}</Option>
								<Option value={2}>{t("ordinary")}</Option>
							</Select>
						</div>
					</Col>

					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("licenseNumber")}：</div>
							<Input
								allowClear
								className='searchInput'
								onChange={(e) => {
									changeAttr("license", e.target.value);
								}}
								placeholder={t("licenseNumber")}
							/>
						</div>
					</Col>

					<Col span={8}>
						<Space>
							<Button type={"primary"} onClick={search}>
								{t("search")}
							</Button>
							{checkPermission(233) && (
								<Button type={"primary"} onClick={download}>
									{t("export")}
								</Button>
							)}
						</Space>
					</Col>
				</Row>
			</div>
			<Table
				columns={columns}
				loading={loading}
				dataSource={dataList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setSearchObj({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
					// setPaginationObj({
					// 	...paginationObj,
					// 	customPageNum: pagination.current ?? 1,
					// 	customPageSize: pagination.pageSize ?? 15,
					// });
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;

	.headers {
		width: 100%;

		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			.searchItem {
				min-width: 150px;
				text-align: right;
			}
		}
	}
`;
