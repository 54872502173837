import styled from "@emotion/styled";
import { Select } from "antd";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { getInitialProps, useTranslation } from "react-i18next";
import statisticsService from "../service/statistics.service";
const { Option } = Select;

interface Props {
	value?: [number, number, number];
	onChange?: any;
	useArray?: boolean;
	provinceId?: number | string;
	cityId?: number | string;
	districtId?: number | string;
}
interface cityObj {
	provinceId?: number;
	cityId?: number;
	districtId?: number;
}
const city: any = {
	provinceId: 0,
	cityId: 0,
	districtId: 0,
};
export const SelectCity = (props: Props) => {
	const { t } = useTranslation();
	// 省市区id
	const [searchObj, setSearchObj] = useState<cityObj>({
		provinceId: 0,
		cityId: 0,
		districtId: 0,
	});
	// 省
	const [provinceList, setProvinceList] = useState([]);
	// 市
	const [cityList, setCityList] = useState([]);
	// 区
	const [districtList, setDistrictList] = useState([]);

	const getProvinceList = useCallback(async () => {
		statisticsService.cityListAll({ areaLevel: 1 }).then((res) => {
			if (res.data.code === 200) {
				setProvinceList(res.data.data);
			}
		});
	}, []);
	const handleModalInputChange = useCallback(
		async (type: string, e: any) => {
			if (type === "provinceId") {
				statisticsService.cityListAll({ parentId: e, areaLevel: 2 }).then((res) => {
					if (res.data.code === 200) {
						setCityList(res.data.data);
					}
				});
				setSearchObj({
					...searchObj,
					cityId: 0,
					districtId: 0,
					[type]: e,
				});
				const objCity = {
					...searchObj,
					cityId: 0,
					districtId: 0,
					[type]: e,
				};
				if (props.useArray) {
					props.onChange([objCity.provinceId, objCity.cityId, objCity.districtId]);
					return;
				}
				props.onChange(objCity);
			}
			if (type === "cityId") {
				statisticsService.cityListAll({ parentId: e, areaLevel: 3 }).then((res) => {
					if (res.data.code === 200) {
						setDistrictList(res.data.data);
					}
				});
				setSearchObj({
					...searchObj,
					districtId: 0,
					[type]: e,
				});
				const objCity = {
					...searchObj,
					districtId: 0,
					[type]: e,
				};
				if (props.useArray) {
					props.onChange([objCity.provinceId, objCity.cityId, objCity.districtId]);
					return;
				}
				props.onChange(objCity);
			}
			if (type === "districtId") {
				setSearchObj({
					...searchObj,
					[type]: e,
				});
				const objCity = {
					...searchObj,
					[type]: e,
				};
				if (props.useArray) {
					props.onChange([objCity.provinceId, objCity.cityId, objCity.districtId]);
					return;
				}
				props.onChange(objCity);
			}
		},
		[searchObj, props]
	);
	useLayoutEffect(() => {
		getProvinceList();
	}, [getProvinceList]);
	useEffect(() => {
		if (props) {
			let provinceId, cityId, districtId;
			if (props.value && Array.isArray(props.value)) {
				[provinceId, cityId, districtId] = props.value;
			} else {
				[provinceId, cityId, districtId] = [props.provinceId, props.cityId, props.districtId];
			}

			setSearchObj({
				provinceId: Number(provinceId)?Number(provinceId):undefined,
				cityId: Number(cityId ?? 0),
				districtId: Number(districtId ?? 0),
			});
			if (provinceId && city.provinceId !== provinceId) {
				statisticsService.cityListAll({ parentId: Number(provinceId), areaLevel: 2 }).then((res) => {
					if (res.data.code === 200) {
						setCityList(res.data.data);
					}
				});
			}
			if (cityId && city.cityId !== cityId) {
				statisticsService.cityListAll({ parentId: Number(cityId), areaLevel: 3 }).then((res) => {
					if (res.data.code === 200) {
						setDistrictList(res.data.data);
					}
				});
			}
			city.provinceId = Number(provinceId ?? 0);
			city.cityId = Number(cityId ?? 0);
			city.districtId = Number(districtId ?? 0);
		}
	}, [props]);

	return (
		<Container>
			<Select
				allowClear
				value={searchObj.provinceId}
				placeholder={t("pleaseChooseProvince")}
				style={{ width: 124 }}
				onChange={(e) => handleModalInputChange("provinceId", e)}
			>
				{provinceList.map((item: any) => {
					return (
						<Option key={item.id} value={item.id}>
							{getInitialProps().initialLanguage === "zh-CN" ? item.fullName : item.enFullName}
						</Option>
					);
				})}
			</Select>
			<Select
				allowClear
				value={searchObj.cityId === 0 ? void 0 : searchObj.cityId}
				style={{ width: 124, marginLeft: 12 }}
				placeholder={t("pleaseChooseCity")}
				onChange={(e) => handleModalInputChange("cityId", e)}
			>
				{cityList.map((item: any) => {
					return (
						<Option key={item.id} value={item.id}>
							{getInitialProps().initialLanguage === "zh-CN" ? item.fullName : item.enFullName}
						</Option>
					);
				})}
			</Select>
			<Select
				allowClear
				value={searchObj.districtId === 0 ? void 0 : searchObj.districtId}
				style={{ width: 124, marginLeft: 12 }}
				placeholder={t("district")}
				onChange={(e) => handleModalInputChange("districtId", e)}
			>
				{districtList.map((item: any) => {
					return (
						<Option key={item.id} value={item.id}>
							{getInitialProps().initialLanguage === "zh-CN" ? item.fullName : item.enFullName}
						</Option>
					);
				})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	.ant-select-selection-item::first {
		color: #777777;
	}
`;
