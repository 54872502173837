import styled from "@emotion/styled";
import { Select } from "antd";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StringProp } from "../../../common/entity/props.entity";
import { ResponseEntity } from "../../../common/entity/response.entity";
import { uniqueArray } from "../../../common/Util";
import { SiteEntity } from "../entity/site.entity";
import SiteService from "../service/site.service";

const {Option} = Select;

export const MultiSelectSite = (props: StringProp) => {
    const [arr, setArr] = useState<SiteEntity[]>([]);
    const {t, i18n} = useTranslation();

    const onChange = (value: Record<string, any>[]) => {
        props.onChange && props.onChange(uniqueArray(value).map((item) => {
            return item.value
        }).join(","));
    }

    const valueArr = useCallback(
        (): Record<string, any>[] => {
            const arrFlag: Record<string, any>[] = [];
            props.value?.split(",").forEach( row => {
                arr.forEach( item => {
                    if (item.id === Number(row) ){
                        arrFlag.push({value: item.id, label: i18n.language === "zh-CN" ? item.title : item.shopNumber});
                    }
                })
            })
            return arrFlag;
        },
        [arr, i18n.language, props.value],
    );


    const list = useCallback(
        async () => {
            const {data}:AxiosResponse<ResponseEntity> = await SiteService.all({level:props.level?props.level:2});
            setArr(data.data)
        },[props]
    )
    useEffect(() => {
        if (arr.length === 0){
            list();
        }
    }, [arr,list]);
    return (
        <Container>
            <Select
                mode="multiple"
                showSearch
                optionFilterProp={"children"}
                labelInValue
                placeholder={t("please.select") + t("site")}
                onChange={onChange}
                value={valueArr()}
                allowClear
            >
                {arr && arr.map((item, index:number) => {
                    return <Option value={item.id} key={index}>{i18n.language === "zh-CN" ? item.title : item.shopNumber}</Option>
                })};
            </Select>
        </Container>
    )
}

const Container = styled.div`
    min-width: 300px;
  width: 100%;
`