import styled from "@emotion/styled";
import { Button, Col, Input, message, Modal, Row, Select, Space, Table } from "antd";
import { AxiosResponse } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Util from "src/common/Util";
import { checkPermission } from "src/module/admin/service/permission.service";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
// import { SelectCarLogo } from "../../module/CarLicense/component/select.car.logo";
// import { SelectCarType } from "../../module/CarLicense/component/select.car.type";
// import { SelectLicenseType } from "../../module/CarLicense/component/select.license.type";
import { CarLicenseEntity } from "../../module/CarLicense/entity/carlicense.entity";
import { CarLicenseParam } from "../../module/CarLicense/params/carlicense.param";
import carLicenseService from "../../module/CarLicense/service/carLicense.service";
import carLicensetService from "../../module/CarLicense/service/carLicense.service";
const { Option } = Select;

export const CarLicense: FC = () => {
	const { confirm } = Modal;
	const { t } = useTranslation();
	const [dataList, setDataList] = useState<CarLicenseEntity[]>([]);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// 	total: 0,
	// });

	const [searchArr, setSearchArr] = useState<CarLicenseParam>({});

	const [searchObj, setSearchObj] = useState<CarLicenseParam>({ customPageSize: 15, customPageNum: 1 });

	const [total, setTotal] = useState<number>(0);

	const [loading, setLoading] = useState(true);

	const changeAttr = (type: string, e: any) => {
		setSearchArr({
			...searchArr,
			[type]: e,
		});
	};
	const search = () => {
		setSearchObj({
			...searchArr,
			customPageSize: 15,
			customPageNum: 1,
		});
	};

	const download = async () => {
		const res: AxiosResponse<BlobPart> = await carLicenseService.export(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "carLicenseList");
		message.success(t("export.success"));
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await carLicensetService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const init = useCallback(async () => {
		setLoading(true);
		const { data } = await carLicensetService.list({
			...searchObj,
		});
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
		setLoading(false);
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const columns = [
		{
			title: t("alias"),
			dataIndex: "alias",
		},
		{
			title: t("companyNum"),
			dataIndex: "companyNum",
		},
		{
			title: t("carOwners"),
			dataIndex: "name",
		},
		{
			title: t("mobile"),
			dataIndex: "mobile",
		},
		{
			title: t("licenseNumber"),
			dataIndex: "licenseNumber",
		},

		{
			title: t("bindItem"),
			render: (record: CarLicenseEntity) => {
				return record.licenseVipProductList?.map((item, index) => {
					return (
						<div key={item.id}>
							{index + 1}.{item.shopName + "-" + item.productName + "-" + item.price + "元"}{" "}
						</div>
					);
				});
			},
		},
		{
			title: t("licenseType"),
			dataIndex: "type",
		},
		{
			title: t("carLogo"),
			dataIndex: "carLogo",
		},
		{
			title: t("carType"),
			dataIndex: "carType",
			render: (text: keyof typeof carLicenseService.carType) => {
				return carLicenseService.carType[text];
			},
		},
		{
			title: t("plateColor"),
			dataIndex: "plateColor",
		},
		{
			title: t("defaultCar"),
			dataIndex: "defaultCar",
			render: (text: number) => {
				return text === 1 ? t("no") : t("yes");
			},
		},
		{
			title: t("vip"),
			dataIndex: "vip",
			render: (text: number) => {
				return text === 1 ? t("no") : t("yes");
			},
		},

		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(11) && (
							<Link to={"/customer/carlicense/" + record.id}>
								<Button type='primary'>{t("edit")}</Button>
							</Link>
						)}
						{checkPermission(12) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];
	return (
		<Container>
			<div className='headers'>
				<Row gutter={20}>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("alias")}：</div>
							<Input
								allowClear
								className='searchInput'
								onChange={(e) => {
									changeAttr("alias", e.target.value);
								}}
								placeholder={t("alias")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("companyNum")}：</div>
							<Input
								allowClear
								className='searchInput'
								onChange={(e) => {
									changeAttr("companyNum", e.target.value);
								}}
								placeholder={t("companyNum")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("licenseNumber")}：</div>
							<Input
								allowClear
								className='searchInput'
								onChange={(e) => {
									changeAttr("licenseNumber", e.target.value);
								}}
								placeholder={t("licenseNumber")}
							/>
						</div>
					</Col>
					{/* <Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("plateColor")}：</div>
							<Input
								allowClear
								className='searchInput'
								onChange={(e) => {
									changeAttr("plateColor", e.target.value);
								}}
								placeholder={t("plateColor")}
							/>
						</div>
					</Col> */}
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("mobile")}：</div>
							<Input
								allowClear
								className='searchInput'
								onChange={(e) => {
									changeAttr("mobile", e.target.value);
								}}
								placeholder={t("mobile")}
							/>
						</div>
					</Col>

					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("vip")}：</div>
							<Select
								placeholder={t("vip")}
								allowClear
								style={{ width: 180 }}
								onChange={(e: number) => {
									changeAttr("vip", e);
								}}
							>
								<Option value={1}>{t("no")}</Option>
								<Option value={2}>{t("yes")}</Option>
							</Select>
						</div>
					</Col>

					{/* <Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("carType")}：</div>
							<SelectCarType
								onChange={(e: number) => {
									changeAttr("carType", e);
								}}
							/>
						</div>
					</Col> */}

					{/* <Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("carLogo")}：</div>
							<SelectCarLogo
								onChange={(e: number) => {
									changeAttr("carLogo", e);
								}}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("licenseType")}：</div>
							<SelectLicenseType
								onChange={(e: number) => {
									changeAttr("type", e);
								}}
							/>
						</div>
					</Col> */}

					<Col span={8}>
						<Space>
							<Button type={"primary"} onClick={search}>
								{t("search")}
							</Button>
							{checkPermission(251) && (
								<Button type={"primary"} style={{ marginLeft: "10px" }} onClick={download}>
									{t("export")}
								</Button>
							)}
							{checkPermission(10) && (
								<Link to={"/customer/carlicense/add"}>
									<Button type={"primary"}>{t("add")}</Button>
								</Link>
							)}
						</Space>
					</Col>
				</Row>
			</div>
			<Table
				columns={columns}
				loading={loading}
				dataSource={dataList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setSearchObj({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;

	.headers {
		width: 100%;

		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			.searchItem {
				min-width: 150px;
				text-align: right;
			}
		}
	}
`;
