import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Table } from "antd";
import { t } from "i18next";
import { PaginationEntity } from "../../common/entity/pagination.entity";
import rolesService from "../../module/roles/service/role.service";
import { RoleEntity } from "src/module/admin/entity/role.entity";

export const RoleList: FC = () => {
	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
		customPageSize: 15,
		customPageNum: 1,
	});
	const [total, setTotal] = useState(0);

	const init = useCallback(async () => {
		const { data } = await rolesService.list({ ...paginationObj });
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [paginationObj]);

	useEffect(() => {
		init();
	}, [init]);

	const [dataList, setDataList] = useState<RoleEntity[]>([]);

	const columns = [
		{ title: t("id"), dataIndex: "id" },
		{ title: t("name"), dataIndex: "name" },
		{
			title: t("type"),
			dataIndex: "type",
			render: (text: any) => {
				return(text == 1 ? t("Express") : text == 2 ? t("superCenter") : t("super"))
				
			},
		},
	];

	return (
		<Container>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id}
				pagination={{
					pageSize: paginationObj.customPageSize,
					current: paginationObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setPaginationObj({
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.ant-form-item {
		margin-bottom: 15px;
	}
`;
