import { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import AdminService from "../service/admin.service";
import { AdminEntity } from "../entity/admin.entity";
import { NumberProp } from "../../../common/entity/props.entity";
import { t } from "i18next";

const { Option } = Select;

export const SelectAdmin = (props: NumberProp) => {
	const [dataArr, setDataArr] = useState<AdminEntity[]>([]);
	const [visibleArr, setVisibleArr] = useState<AdminEntity[]>([]);
	const [name, setName] = useState<string | undefined>(undefined);

	const init = useCallback(async () => {
		let listFlag = dataArr;
		if (!dataArr.length) {
			const res = await AdminService.allList({ name });
			setDataArr(res.data.data);
			listFlag = res.data.data;
		}
		let flag = false;
		listFlag.forEach((item: AdminEntity) => {
			if (item.id === props.value){
				flag = true;
			}
		});
		if (!flag && props.value) {
			const currentAdminInfoRes = await AdminService.detail(props.value);
			listFlag.push(currentAdminInfoRes.data.data);
		}
		if (name) {
			const list = listFlag.filter(item => {
				return item.name && item.name.includes(name);
			})
			setVisibleArr(list);
		}
		else setVisibleArr(listFlag);
	}, [dataArr, name, props.value]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<>
			<Select
				showSearch
				value={props.value}
				onChange={(item: any) => props.onChange && props.onChange(item ? item.value : undefined)}
				allowClear
				labelInValue
				filterOption={false}
				onSearch={setName}
				style={{ width: 200 }}
				placeholder={t("pleaseSelectAdmin")}
				onBlur={()=>{setName(undefined)}}
			>
				{visibleArr &&
					visibleArr.map((item) => {
						return (
							<Option value={item.id ?? 0} key={item.id}>
								{item.name}
							</Option>
						);
					})}
			</Select>
		</>
	);
};
