/*
 * @Author: Yujia fyujia0815@gmail.com
 * @Date: 2022-11-23 10:58:43
 * @LastEditors: Yujia fyujia0815@gmail.com
 * @LastEditTime: 2022-12-01 10:16:30
 * @FilePath: \data-center-ts\src\module\site\component\select.site.tsx
 */
import styled from "@emotion/styled";
import { Select } from "antd";
import { t } from "i18next";
import { Props } from "src/common/entity/props.entity";
const { Option } = Select;

export const SelectFaultType = (props: Props) => {
	const faultType: Record<number, string> = {
		1: "手工下架",
		2: "非营业时间",
		3: "维护",
		4: "低温保护",
		5: "网络异常",
		6: "市政施工",
	};

	return (
		<Container>
			<Select style={{ width: "200px" }} placeholder={t("please.select") + t("faultType")} onChange={(e:number)=>{props.onChange(e)}}>
				{Object.keys(faultType).map((item, index) => {
					return (
						<Option key={index} value={Number(item)}>
							{faultType[Number(item)]}
						</Option>
					);
				})}
			</Select>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
`;
