import { FC, useEffect, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Button, Divider, Form, Input, InputNumber, Select, message } from "antd";
import { t } from "i18next";
import { CustomerFeedbackEntity } from "src/module/customerFeedback/customer.feedback.entity";
import customerFeedbackService from "src/module/customerFeedback/customer.feedback.service";
import { useNavigate, useParams } from "react-router-dom";
import customerService from "src/module/customer/service/customer.service";
import orderService from "src/module/order/service/order.service";
import { SelectSite } from "src/module/site/component/select.site";

export const CustomerFeedbackDetail: FC = () => {
	const navigate = useNavigate();
	const { Option } = Select;
	const { TextArea } = Input;
	const params = useParams();
	const [info, setInfo] = useState<CustomerFeedbackEntity>({});
	const [orderList, setOrderList] = useState([]);

	const init = useCallback(async () => {
		if (params.id) {
			const { data } = await customerFeedbackService.detail(Number(params.id));
			if (data.code === 200) {
				const businessType = data.data.businessType || 0;
				setInfo({ ...data.data, businessType });
				const res = await customerService.list({ mobile: data.data.mobile });
				setInfo((previousInfo) => {
					const nickName = res.data.data.length ? res.data.data[0]?.nickName : void 0;
					return { ...previousInfo, nickName };
				});
			}
		}
	}, [params.id]);

	useEffect(() => {
		init();
	}, [init]);

	const changeMobile = async (tel: string) => {
		if (tel.length == 11) {
			const { data } = await customerService.list({ mobile: tel });
			console.log("客户", data.data);
			const nickName = data?.data[0]?.nickName;
			const userId = data?.data[0]?.id;
			console.log("userid", data?.data[0]?.id);

			setInfo({ ...info, mobile: tel, nickName: nickName ? nickName : "未注册用户", userId: userId ? userId : 0 });
			const res = await orderService.list({ mobile: tel });
			console.log("订单", res.data.data.page.list);
			setOrderList(res.data.data.page.list);
		} else {
			setInfo({ ...info, mobile: tel, nickName: "请输入11位手机号", orderNum: "" });
		}
	};

	const onFinish = async (isClose: boolean) => {
		if (!params.id) {
			const { data } = await customerFeedbackService.add({
				...info,
				close: Number(isClose),
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/customerFeedback");
			} else {
				message.error(data.msg);
			}
			return;
		}
		// const { data } = await customerFeedbackService.update({
		// 	...info,
		// 	id: Number(params.id),
		// });
		// if (data.code === 200) {
		// 	message.success(t("success"));
		// 	navigate("/customerFeedback");
		// } else {
		// 	message.error(data.msg);
		// }
	};

	const dealTicket = async (isClose: boolean) => {
		const { data } = await customerFeedbackService.handle({
			...info,
			id: Number(params.id),
			close: Number(isClose),
		});
		if (data.code === 200) {
			message.success(t("success"));
			navigate("/customerFeedback");
		} else {
			message.error(data.msg);
		}
	};

	return (
		<Container>
			<Form labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} style={{ padding: 20 }} className='myForm'>
				<Form.Item label={t("mobile")}>
					<InputNumber
						placeholder={t("mobile")}
						value={info?.mobile}
						controls={false}
						type='phone'
						style={{ width: "100%" }}
						maxLength={11}
						onChange={(e) => {
							changeMobile(e ? e.toString() : "");
						}}
					/>
				</Form.Item>

				<Form.Item label={t("userName")}>
					<Input value={info?.nickName} disabled />
				</Form.Item>

				<Form.Item label={t("userNickname")}>
					<Input
						placeholder={t("userNickname")}
						value={info?.name}
						onChange={(e) => {
							setInfo({ ...info, name: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("orderNum")}>
					<Select
						placeholder={t("orderNum")}
						value={info?.orderNum}
						onChange={(e) => {
							setInfo({ ...info, orderNum: e });
						}}
					>
						{orderList?.map((item: any, index) => {
							return (
								<Select.Option value={item.orderNum} key={index}>
									{item.orderNum}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>

				<Form.Item label={t("site.name")}>
					<SelectSite
						className='siteSelect'
						value={info?.shopId}
						level={1}
						onChange={(e: number) => {
							setInfo({ ...info, shopId: e });
						}}
					/>
				</Form.Item>

				<Form.Item label={t("businessType")} required>
					<Select
						placeholder={t("businessType")}
						value={info?.businessType}
						onChange={(e) => {
							setInfo({ ...info, businessType: e, question: "", type: void 0 });
						}}
					>
						{Object.keys(customerFeedbackService.businessType).map((item: any) => {
							return (
								<Option key={item} value={Number(item)} disabled={item === "0"}>
									{customerFeedbackService.businessType[item]}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t("consultType")} required>
					<Select
						placeholder={t("consultType")}
						value={info?.type}
						onChange={(e) => {
							setInfo({ ...info, type: e, question: "" });
						}}
					>
						{typeof info.businessType === "number" &&
							Object.keys(customerFeedbackService.questionType[info.businessType]).map((item: any) => {
								return (
									<Option key={item} value={Number(item) + 1}>
										{customerFeedbackService.questionType[info.businessType!][item]}
									</Option>
								);
							})}
					</Select>
				</Form.Item>

				<Form.Item label={t("concreteIssue")} required>
					<Select
						placeholder={t("concreteIssue")}
						value={info?.question}
						onChange={(e) => {
							setInfo({ ...info, question: e });
						}}
					>
						{info.businessType &&
							!!info.type &&
							customerFeedbackService.concreteIssue[info.businessType * 10 + info.type].map((item: string, index: number) => {
								return (
									<Select.Option value={item} key={index}>
										{item}
									</Select.Option>
								);
							})}
					</Select>
				</Form.Item>

				<Form.Item label={t("issueDescription")}>
					<TextArea
						placeholder={t("issueDescription")}
						value={info?.comments}
						onChange={(e) => {
							setInfo({ ...info, comments: e.target.value });
						}}
					/>
				</Form.Item>

				<Form.Item label={t("channel")} required>
					<Select
						placeholder={t("channel")}
						value={info?.channel}
						onChange={(e) => {
							setInfo({ ...info, channel: e });
						}}
					>
						{Object.keys(customerFeedbackService.channelType).map((item: any) => {
							return (
								<Option key={item} value={Number(item)}>
									{customerFeedbackService.channelType[item]}
								</Option>
							);
						})}
					</Select>
				</Form.Item>

				{!params.id && (
					<Form.Item wrapperCol={{ span: 12, offset: 4 }} className='buttonContainer'>
						<Button type='primary' onClick={() => onFinish(false)}>
							{t("submit")}
						</Button>

						<Button type='primary' className='warnButton' onClick={() => onFinish(true)}>
							{t("submit&close")}
						</Button>
					</Form.Item>
				)}

				<Form.Item wrapperCol={{ span: 12, offset: 4 }}></Form.Item>
				{!!params.id && <div className='handleCover'></div>}
			</Form>
			{!!params.id && <Divider>{t("handle")}</Divider>}
			{!!params.id && (
				<Form
					name='treatmentForm'
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 12 }}
					style={{ padding: 20 }}
					className='treatmentForm'
					initialValues={{
						...info,
					}}
				>
					<Form.Item label={t("treatment")} required>
						<Input value={info?.programme} onChange={(e) => setInfo({ ...info, programme: e.target.value })} />
					</Form.Item>

					<Form.Item label={t("treatmentRes")} required>
						<Select value={info?.status} onChange={(e) => setInfo({ ...info, status: e })}>
							{Object.keys(customerFeedbackService.programmeList).map((item: any) => {
								return (
									<Select.Option key={item} value={Number(item)}>
										{customerFeedbackService.programmeList[item]}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>

					<Form.Item label={t("remark")}>
						<TextArea value={info?.remark} onChange={(e) => setInfo({ ...info, remark: e.target.value })} />
					</Form.Item>

					<Form.Item wrapperCol={{ span: 12, offset: 4 }} className='buttonContainer'>
						<Button type='primary' onClick={() => dealTicket(false)}>
							{t("submit")}
						</Button>
						<Button type='primary' className='warnButton' onClick={() => dealTicket(true)}>
							{t("submit&close")}
						</Button>
					</Form.Item>
				</Form>
			)}
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	.myForm {
		position: relative;
		.handleCover {
			position: absolute;
			width: 100%;
			top: 0;
			right: 0;
			height: 100%;
			cursor: not-allowed;
		}
	}

	.siteSelect {
		max-width: none !important;
	}
	.buttonContainer {
		padding-top: 30px;
		.warnButton {
			margin-left: 20px;
			background: orange;
			border-color: orange;
		}
	}
`;
