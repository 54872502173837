import styled from "@emotion/styled";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { PropsEntity } from "../../../common/entity/props.entity";
import couponTypeService from "../service/coupon.type.service";

const { Option } = Select;

export const SelectCouponType = (props: PropsEntity) => {
	const { t } = useTranslation();

	const handleChange = (e: number) => {
		props.onChange && props.onChange(e);
		props.entityChange && props.entityChange({ id: e, title: couponTypeService.typeArray[e] });
	};

	return (
		<Container>
			
				<Select
				style={{width:"100%"}}
					value={props.value ? Number(props.value) : undefined}
					placeholder={t("chooseType")}
					allowClear
					onChange={(e: number) => handleChange(e)}
				>
					{Object.keys(couponTypeService.typeArray).map((item) => {
						const id = Number(item);
						return (
							<Option key={"type" + item} value={id}>
								{t(couponTypeService.typeArray[id])}
							</Option>
						);
					})}
				</Select>
			
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	

	

	
`;
