import { PlusOutlined, SearchOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, InputNumber, message, Modal, Popover, Space, Spin, Table, Tag } from "antd";
import { AxiosResponse } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Util from "src/common/Util";
import { SelectPromoter } from "src/module/promoterAudit/components/SelectPromoter";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { ResponseEntity } from "../../common/entity/response.entity";
import { checkPermission } from "../../module/admin/service/permission.service";
import { SelectCouponType } from "../../module/coupon/component/SelectCouponType";
import { CouponEntity } from "../../module/coupon/entity/coupon.entity";
import { CouponParam } from "../../module/coupon/param/coupon.param";
import couponService from "../../module/coupon/service/coupon.service";
import { SelectSite } from "../../module/site/component/select.site";
import moment, { Moment } from "moment";

const { confirm } = Modal;
export const CouponList: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [searchArr, setSearchArr] = useState<CouponParam>({ type: 1 });
	const [searchObj, setSearchObj] = useState<CouponParam>({ type: 1, customPageSize: 15, customPageNum: 1 });
	const [couponList, setcouponList] = useState<CouponEntity[]>([]);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// 	total: 0,
	// });
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState<number>(0);

	const [visible, setVisible] = useState(false);
	const [modalInfo, setModalInfo] = useState<CouponEntity>({});
	const [num, setNum] = useState<number>();

	const [selectCouponId, setSelectCouponId] = useState<number>(0);
	const [expirationTime, setExpirationTime] = useState<Moment>(moment());

	const init = useCallback(async () => {
		const { data } = await couponService.list({ ...searchObj });
		if (data.code === 200) {
			setcouponList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const search = () => {
		setSearchObj({ ...searchArr, customPageSize: 15, customPageNum: 1 });
	};

	const deleteCoupon = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data }: AxiosResponse<ResponseEntity> = await couponService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const changeAttr = (type: string, e: any) => {
		setSearchArr({
			...searchArr,
			[type]: e,
		});
	};
	const siteChange = (e: number) => {
		setSearchArr({
			...searchArr,
			shopId: e,
		});
	};

	const changeStatus = async (id: number) => {
		const { data } = await couponService.changeCouponStatus(id);
		if (data.code == 200) {
			message.success(data.msg);
			init();
		}
	};
	const createAppletQRCode = async (id: number) => {
		const { data } = await couponService.createAppletQRCode(id);
		Util.processBlobImg(data);
	};

	const createDynamicQRCode = async (param: string | undefined) => {
		const { data } = await couponService.createDynamicAppletQRCode(param);
		Util.processBlobImg(data);
	};

	const batchCreateComb = async (param: number | undefined) => {
		const { data } = await couponService.batchCreateAppletQRCode(param);
		if (data.code == 200) {
			message.success("批量生成中,请稍后查看优惠券二维码券包列表");
			init();
		}
	};

	const handleOk = async (param: CouponEntity) => {
		const { data } = await couponService.batchCreateSubCoupons(param);
		if (data.code === 200) {
			message.success(data.msg);
			setVisible(false);
			init();
		} else {
			message.error(data.msg);
		}
	};
	const handleCancel = () => {
		setVisible(false);
	};

	const batchDownLordComb = async (param: number | undefined) => {
		setLoading(true);
		const { data } = await couponService.batchDownLordAppletQRCode(param);
		const blob = new Blob([data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.zipDownload(blobUrl);
		setLoading(false);
	};

	const batchCreateCertificate = async (param: number) => {
		const { data } = await couponService.detail(param);
		setVisible(true);
		setModalInfo(data.data);
	};

	const batchExportCoupon = async () => {
		setLoading(true);
		setSearchObj({ ...searchArr });
		const res: AxiosResponse<BlobPart> = await couponService.export({ ...searchArr });
		setLoading(false);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "coupon");
		message.success(t("export.success"));
	};

	const ExportSonCoupon = async () => {
		const res: AxiosResponse<BlobPart> = await couponService.export({ parentId: searchObj.parentId, type: 2 });
		setLoading(false);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "coupon");
		message.success(t("export.success"));
	};

	const confirmChangeExpirationTime = async () => {
		const { data } = await couponService.changeExpirationTime(selectCouponId, expirationTime.format("YYYY-MM-DD 23:59:59"));
		if (data.code === 200) {
			setSelectCouponId(0);
			setExpirationTime(moment());
			init();
			return;
		}
		message.error(data.msg);
	};

	const columns = [
		{
			title: t("id"),
			dataIndex: "id",
		},
		{
			title: t("title"),
			dataIndex: "title",
			render: (_text: string, record: CouponEntity) => {
				return (
					<div>
						<Tag color={"blue"}>{record.type === 1 ? t("masterCoupon") : t("subCoupon")}</Tag>
						{_text}
					</div>
				);
			},
		},
		{
			title: t("couponCode"),
			dataIndex: "code",
		},

		{
			title: t("shopName"),
			render: (_text: string, record: CouponEntity) => {
				return (
					<Popover content={record.shopName}>
						<Tag color={"blue"}>{t("shopName")}</Tag>
					</Popover>
				);
			},
		},
		{
			title: t("applicableProduct"),
			render: (_text: string, record: CouponEntity) => {
				return (
					<Popover content={record.productName}>
						<Tag color={"blue"}>{t("productName")}</Tag>
					</Popover>
				);
			},
		},
		{
			width: 130,
			title: t("expirationTime"),
			render: (record: CouponEntity) => {
				return record.effectiveWay == 1 ? record.expirationTime : record.effectiveDay + "天";
			},
		},
		{
			title: t("limitNum"),
			dataIndex: "limitNum",
		},
		{
			title: t("remainingNum"),
			dataIndex: "remainingNum",
		},
		{
			title: t("grantNum"),
			dataIndex: "grantNum",
		},

		{
			title: t("worth"),
			dataIndex: "worth",
			render: (text: number) => {
				return text! && text + "元";
			},
		},

		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? <Tag color={"green"}>{t("onSale")}</Tag> : <Tag color={"red"}>{t("off")}</Tag>;
			},
		},
		{
			title: t("action"),
			render: (_text: string, record: CouponEntity) => {
				return (
					<div>
						<div className='Line'>
							<Space>
								{checkPermission(73) && (
									<Button
										type='default'
										onClick={() => {
											navigate(`/coupon/coupon-add/${record?.id}`);
										}}
									>
										{t("edit")}
									</Button>
								)}

								<Button
									type='default'
									onClick={() => {
										message.success(`/pages/index/index?scene=code%3D${record.code}`);
									}}
								>
									{t("getUrl")}
								</Button>

								{record.status === 1 && checkPermission(75) && (
									<Button
										type='primary'
										danger
										onClick={() => {
											changeStatus(Number(record.id));
										}}
									>
										{t("ban")}
									</Button>
								)}
								{record.status === 2 && checkPermission(75) && (
									<Button
										type='primary'
										onClick={() => {
											changeStatus(Number(record.id));
										}}
									>
										{t("enable")}
									</Button>
								)}
								{checkPermission(115) && (
									<Button
										type='primary'
										danger
										onClick={() => {
											record.id && deleteCoupon(record.id);
										}}
									>
										{t("delete")}
									</Button>
								)}
							</Space>
						</div>
						<div className='Line'>
							<Space>
								{checkPermission(74) && (
									<Button
										type='default'
										onClick={() => {
											createAppletQRCode(Number(record.id));
										}}
									>
										{t("appletQcCode")}
									</Button>
								)}

								{checkPermission(146) && (
									<Button
										type='default'
										onClick={() => {
											createDynamicQRCode(record.code);
										}}
									>
										{t("dynamicAppletQcCode")}
									</Button>
								)}
							</Space>
						</div>

						<div className='Line'>
							<Space>
								{record.type === 1 && (
									<Button
										type='default'
										onClick={() => {
											batchCreateCertificate(Number(record.id));
										}}
									>
										{t("batchCreateCertificate")}
									</Button>
								)}

								<Button
									type='default'
									onClick={() => {
										setSelectCouponId(Number(record.id));
									}}
								>
									修改过期时间
								</Button>

								{record.type === 1 && (
									<Button
										type='default'
										onClick={() => {
											setSearchObj({ type: 2, parentId: record.id });
										}}
									>
										{t("viewSubCoupons")}
									</Button>
								)}

								<Button
									type='primary'
									onClick={() => {
										navigate(`/coupon/coupon-add/${record.id}/1`);
									}}
								>
									{t("copy")}
								</Button>
							</Space>
						</div>

						<div className='Line'>
							<Space>
								{record.type === 1 && (
									<Button
										type='default'
										onClick={() => {
											batchCreateComb(record.id);
										}}
									>
										{t("batchCreateComb")}
									</Button>
								)}

								{record.type === 1 && record.batchStatus === 2 && (
									<Spin spinning={loading}>
										<Button
											type='default'
											onClick={() => {
												batchDownLordComb(record.id);
											}}
										>
											{t("batchDownLoadComb")}
										</Button>
									</Spin>
								)}
							</Space>
						</div>
					</div>
				);
			},
		},
	];
	return (
		<Container>
			<div style={{ display: searchObj.type === 1 ? "block" : "none" }}>
				<Space>
					<Space>
						{t("couponName")}
						<Input placeholder={t("enterCouponName")} allowClear onChange={(e) => changeAttr("title", e.target.value)} />
					</Space>
					<Space>
						{t("promoterName")}
						<SelectPromoter onChange={(e: number) => changeAttr("promoterId", e)} />
					</Space>
					<Space>
						{t("couponType")}
						<SelectCouponType value={searchArr.couponType} onChange={(e: number) => changeAttr("couponType", e)} />
					</Space>

					<Space>
						{t("applySite")}
						<SelectSite onChange={siteChange} />
					</Space>
					<Space>
						{t("couponCode")}
						<Input placeholder={t("enterCouponCode")} allowClear onChange={(e) => changeAttr("code", e.target.value)} />
					</Space>
				</Space>
				<div style={{ marginTop: "20px" }}>
					<Space>
						<Button type={"primary"} icon={<SearchOutlined />} onClick={search}>
							{t("search")}
						</Button>
						{checkPermission(73) && (
							<Button type={"primary"} icon={<PlusOutlined />} onClick={() => navigate("/coupon/coupon-add")}>
								{t("add")}
							</Button>
						)}
						<Button type={"primary"} icon={<VerticalAlignBottomOutlined />} onClick={batchExportCoupon} loading={loading}>
							{t("export")}
						</Button>
					</Space>
				</div>
			</div>

			{
				<div style={{ display: searchObj.type === 2 ? "block" : "none" }}>
					<Space>
						<Button type={"primary"} onClick={() => setSearchObj({ type: 1, ...searchArr, code: "" })}>
							{t("backList")}
						</Button>

						<Space>
							{t("couponCode")}
							<Input placeholder={t("enterCouponCode")} allowClear onChange={(e) => changeAttr("code", e.target.value)} />
						</Space>

						<Button
							type={"primary"}
							icon={<SearchOutlined />}
							onClick={() => {
								setSearchObj({ ...searchObj, code: searchArr.code });
							}}
						>
							{t("search")}
						</Button>

						<Button type={"primary"} icon={<VerticalAlignBottomOutlined />} onClick={ExportSonCoupon} loading={loading}>
							{t("export")}
						</Button>
					</Space>
				</div>
			}

			<Table
				columns={columns}
				dataSource={couponList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setSearchObj({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
				}}
			/>

			<Modal width={"30%"} title='修改优惠券过期时间' visible={!!selectCouponId} onOk={confirmChangeExpirationTime} onCancel={() => setSelectCouponId(0)}>
				<DatePicker style={{ width: "100%" }} value={expirationTime} onChange={(val) => setExpirationTime(val as Moment)}></DatePicker>
			</Modal>

			<Modal
				width={"30%"}
				title={t("batchCreateCertificate")}
				visible={visible}
				onOk={() => {
					handleOk({ code: modalInfo.code, number: num });
				}}
				onCancel={handleCancel}
			>
				<Form style={{ padding: 20 }}>
					<Form.Item label={t("couponName")}>{modalInfo.title}</Form.Item>
					<Form.Item label={t("couponType")}>{modalInfo.type}</Form.Item>
					<Form.Item label={t("shopName")}>{modalInfo.shopName}</Form.Item>
					<Form.Item label={t("applicableProduct")}>{modalInfo.productName}</Form.Item>
					<Form.Item label={t("createNum")}>
						<InputNumber
							onChange={(e: number | null) => {
								setNum(e || 0);
							}}
							style={{ width: 100 }}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	.el-radio__original {
		display: none !important; /* 隐藏原生 radio 输入，但仍然允许交互 */
	}
	width: 100%;
	padding: 10px;

	.headers {
		width: 100%;

		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			.searchItem {
				min-width: 150px;
				text-align: right;
			}
		}
	}

	.Line {
		margin-bottom: 10px;
	}
`;
