import { useTranslation } from "react-i18next";

import en, { Locale } from "../locales/en-US";

const useI18n = () => {
	const { t } = useTranslation();

	const handler = {
		keys: [],
		get(target: object, key: keyof object): string | object {
			if (key in target) {
				this.keys.push(key);
				if (typeof target[key] === "string") {
					const res = t(this.keys.join("."));
					this.keys = [];
					return res;
				}

				return new Proxy(target[key], handler);
			}
			return key;
		},
		set() {
			throw new Error("i18n代理对象不可修改");
		},
	};

	const proxy = new Proxy<Locale>(en, handler);

	return proxy;
};

export default useI18n;
