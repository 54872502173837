import { http } from "../../../common/http";
import { BillParam } from "../params/bill.param";

class BillService {
	list = async (param: BillParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/financeLog",
			method: "GET",
			params: param,
		});
	};

	totalFee = async (params: BillParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/financeLog/totalFee",
			method: "GET",
			params,
		});
	};

	BillExport = async (param: BillParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/financeLog/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};
}
export default new BillService();
