import styled from "@emotion/styled";
import { Button, Col, DatePicker, Input, message, Modal, Popover, Row, Table, Tag } from "antd";
import { AxiosResponse } from "axios";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { ResponseEntity } from "../../common/entity/response.entity";
import Util from "../../common/Util";
import { checkPermission } from "../../module/admin/service/permission.service";
import { MembershipCardRecordEntity } from "../../module/membershipCard/entity/membership.card.record.entity";
import { MembershipCardRecordParam } from "../../module/membershipCard/params/membership.card.record.param";
import MonthCardRecordsService from "../../module/membershipCard/service/membership.card.record.service";
import { SelectSite } from "../../module/site/component/select.site";
import { SelectMemberShipPayStatus } from "src/module/membershipCard/component/SelectMemberShipPayStatus";
import { SelectCardBuyPayWay } from "src/module/membershipCard/component/SelectMemberShipPayWay";

const { confirm } = Modal;
const { RangePicker } = DatePicker;

export const MembershipCardRecord: FC = () => {
	const { t } = useTranslation();
	const [searchArr, setSearchArr] = useState<MembershipCardRecordParam>({
		startTime: moment().format("YYYY-MM-DD"),
		endTime: moment().format("YYYY-MM-DD"),
	});
	const [searchObj, setSearchObj] = useState<MembershipCardRecordParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});
	const [recordList, setRecordList] = useState([] as MembershipCardRecordEntity[]);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// 	total: 0,
	// });
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState(true);
	const changeAttr = (type: string, e: any) => {
		setSearchArr({
			...searchArr,
			[type]: e,
		});
	};
	const onDateChange = (date: any, dateString: any) => {
		setSearchArr({
			...searchArr,
			startTime: dateString[0],
			endTime: dateString[1],
		});
	};
	const download = async () => {
		const res: AxiosResponse<BlobPart> = await MonthCardRecordsService.exportMonthlyCard({
			...searchArr,
			startTime: searchArr.startTime + " 00:00:00",
			endTime: searchArr.endTime + " 23:59:59",
		});
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "month-card-records");
		message.success(t("export.success"));
	};
	const siteChange = (type: string) => {
		return (e: number) => {
			setSearchArr({
				...searchArr,
				[type]: e,
			});
		};
	};
	const search = () => {
		setSearchObj({
			...searchArr,
			startTime: searchArr.startTime + " 00:00:00",
			endTime: searchArr.endTime + " 23:59:59",
			customPageSize: 15,
			customPageNum: 1,
		});
	};
	const refundClick = (orderNum: string) => {
		confirm({
			title: "确认退款?",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data }: AxiosResponse<ResponseEntity> = await MonthCardRecordsService.refundCard({ orderNum });
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};
	const init = useCallback(async () => {
		setLoading(true);
		const { data } = await MonthCardRecordsService.getList({
			...searchObj,
		
		});
		if (data.code === 200) {
			setRecordList(data.data);
			setTotal(data.total);
		}
		setLoading(false);
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const columns = [
		{
			width: 180,
			title: t("orderNum"),
			dataIndex: "orderNum",
			render: (text: string, record: MembershipCardRecordEntity) => {
				return (
					<Popover content={record.shopName}>
						<div>{text}</div>
					</Popover>
				);
			},
		},
		{
			width: 130,
			title: t("membersCardName"),
			dataIndex: "title",
			render: (text: string, record: MembershipCardRecordEntity) => {
				return (
					<Popover content={record.productName}>
						<div>{text}</div>
					</Popover>
				);
			},
		},
		{
			width: 130,
			title: t("customer-info"),
			dataIndex: "nickName",
			render: (text: string, record: MembershipCardRecordEntity) => {
				return (
					<div>
						{text}
						<br />
						{record.mobile}
						<br />
						{record.licenseNumber}
					</div>
				);
			},
		},
		{
			title: t("money"),
			dataIndex: "currentPayWayFee",
		},
		{
			title: t("createdTime"),
			dataIndex: "createdTime",
		},
		{
			title: t("payWay"),
			dataIndex: "payWayStr",
		},
		{
			title: t("payStatus"),
			dataIndex: "payStatusStr",
		},
		{
			width: 130,
			title: t("applySite"),
			dataIndex: "shopName",
			render: (text: string) => {
				return (
					<Popover content={text}>
						<Tag color='blue'>{t("applySite")}</Tag>
					</Popover>
				);
			},
		},

		{
			width: 130,
			title: t("saleSite"),
			dataIndex: "saleShop",
		},

		{
			title: t("action"),
			render: (text: string, record: any) => {
				return (
					<div>
						{record.payStatus !== 17 && record.payStatus !== 15 && checkPermission(365) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									refundClick(record.orderNum);
								}}
							>
								{t("refund")}
							</Button>
						)}
					</div>
				);
			},
		},
	];
	return (
		<Container>
			<div className='headers'>
				<Row gutter={20}>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("membersCardName")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("title", e.target.value);
								}}
								placeholder={t("membersCardName")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("orderNum")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("orderNum", e.target.value);
								}}
								placeholder={t("orderNum")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("productName")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("productName", e.target.value);
								}}
								placeholder={t("productName")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("mobile")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("mobile", e.target.value);
								}}
								placeholder={t("mobile")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("nickName")}：</div>
							<Input
								onChange={(e) => {
									changeAttr("nickName", e.target.value);
								}}
								className='searchInput'
								placeholder={t("nickName")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("period")}：</div>
							<RangePicker
								defaultValue={[
									moment(searchArr.startTime && searchArr.startTime.toString()),
									moment(searchArr.endTime && searchArr.endTime.toString()),
								]}
								showTime={false}
								onChange={onDateChange}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("shopName")}：</div>
							<SelectSite onChange={siteChange("shopId")} />
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("saleSite")}：</div>
							<SelectSite onChange={siteChange("saleShopId")} />
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("payStatus")}：</div>
							<SelectMemberShipPayStatus
								onChange={(e: number) => {
									changeAttr("payStatus", e);
								}}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("payWay")}：</div>
							<SelectCardBuyPayWay onChange={(e: number) => changeAttr("payWay", e)} />
						</div>
					</Col>
					<Col span={8}>
						<Button type={"primary"} onClick={search}>
							{t("search")}
						</Button>
						<Button type={"primary"} style={{ marginLeft: "10px" }} onClick={download}>
							{t("export")}
						</Button>
					</Col>
				</Row>
			</div>
			<Table
				columns={columns}
				loading={loading}
				dataSource={recordList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setSearchObj({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
						startTime: searchArr.startTime + " 00:00:00",
						endTime: searchArr.endTime + " 23:59:59",
					});
					
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;

	.headers {
		width: 100%;

		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			.searchItem {
				min-width: 120px;
				text-align: right;
			}
		}
	}
`;
