import { FC, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Input, message, Row, Select, Space, Table } from "antd";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import moment from "moment";
import withdrawalAuditService from "../../module/withdrawalAudit/service/withdrawal.audit.service";
import { checkPermission } from "src/module/admin/service/permission.service";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const WithdrawalAuditList: FC = () => {
	const { t } = useTranslation();

	const [searchArr, setSearchArr] = useState({});
	const [searchObj, setSearchObj] = useState({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState(0);
	const [dataList, setDataList] = useState();

	const passAudit = async (id: number) => {
		const { data } = await  withdrawalAuditService.pass(id);
		if (data.code === 200) {
			message.success(t("success"));
			init();
		}
	};

	const refuseAudit = async (id: number) => {
		const { data } = await  withdrawalAuditService.refuse(id);
		if (data.code === 200) {
			message.success(t("success"));
			init();
		}
	};

	const changeDate = (date: any, dateString: any) => {
		setSearchArr({
			...searchArr,
			startTime: dateString[0] ? dateString[0] + " 00:00:00" : undefined,
			endTime: dateString[1] ? dateString[1] + " 23:59:59" : undefined,
		});
	};

	const init = useCallback(async () => {
		const { data } = await withdrawalAuditService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const changeSearchArr = (type: any, e: any) => {
		setSearchArr({ ...searchArr, [type]: e });
	};

	const handleSearch = () => {
		setSearchObj({ ...searchObj, ...searchArr,customPageSize: 15,
			customPageNum: 1, });
	};

	const columns = [
		{ title: t("userMobile"), dataIndex: "mobile" },
		{ title: t("promoterName"), dataIndex: "promoterName" },
		{ title: t("promoterCompanyName"), dataIndex: "promoterCompanyName" },
		{ title: t("drawAmount"), dataIndex: "drawAmount" },
		{
			title: t("auditStatus"),
			dataIndex: "drawState",
			render: (text: number) => {
				return text === 0 ? t("pendingAudit") : text === 1 ? t("passAudit") : t("refuseAudit");
			},
		},
		{ title: t("promoteApplyTime"), dataIndex: "createdTime" },
		{ title: t("promotePassTime"), dataIndex: "approvalTime" },
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(372)&& record.drawState === 0 && (
							<>
								<Button
									type='primary'
									onClick={() => {
										passAudit(record.id);
									}}
								>
									{t("passAudit")}
								</Button>
								<Button
									type='primary'
									danger
									onClick={() => {
										refuseAudit(record.id);
									}}
								>
									{t("refuseAudit")}
								</Button>
							</>
						)}
						{checkPermission(372)&& record.drawState === 1 && <Button type='primary'>{t("passAlready")}</Button>}
						{checkPermission(372)&&record.drawState === 2&& (
							<Button type='primary' danger>
								{t("refuseAlready")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Row gutter={20}>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("mobile")}：</div>
						<Input
							placeholder={t("mobile")}
							onChange={(e) => {
								changeSearchArr("mobile", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("promoterName")}：</div>
						<Input
							placeholder={t("promoterName")}
							onChange={(e) => {
								changeSearchArr("nickName", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("promoterCompanyName")}：</div>
						<Input
							placeholder={t("promoterCompanyName")}
							onChange={(e) => {
								changeSearchArr("promoterCompanyName", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("auditStatus")}：</div>
						<Select
							style={{ width: 150 }}
							placeholder={t("auditStatus")}
							allowClear
							onChange={(e) => {
								changeSearchArr("drawState", e);
							}}
						>
							<Option value={0}>{t("pendingAudit")}</Option>
							<Option value='1'>{t("passAudit")}</Option>
							<Option value='2'>{t("refuseAudit")}</Option>
						</Select>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("period")}：</div>
						<RangePicker defaultValue={[moment(), moment()]} onChange={changeDate} />
					</div>
				</Col>
				<Col span={8}>
					<Button type={"primary"} onClick={handleSearch}>
						{t("search")}
					</Button>
				</Col>
			</Row>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={dataList}
				rowKey={(record: any) => {
					return record.id;
				}}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
	.searchList {
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		.searchItem {
			min-width: 200px;
			text-align: right;
		}
	}
`;
