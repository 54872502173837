import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Props } from "../../../common/entity/props.entity";
import { SelectSite } from "../../site/component/select.site";
import { ProductEntity } from "../entity/product.entity";
import productService from "../service/product.service";
import { SingleSelectProduct } from "./single.select.product";

interface ProductsProp extends Props {
	shopId?: string;
	className?: string;
	changeSite?: (siteId: string) => void
}

export const MultiSelectProductBySite = (props: ProductsProp) => {
	const [siteId, setSiteId] = useState(props.shopId);
	const [productId, setProductId] = useState<number>();
	const [productArr, setProductArr] = useState<ProductEntity[] | undefined>([]);
	const [siteIds, setSiteIds] = useState<string>("");
	const [productIds, setProductIds] = useState<string>("");

	const init = useCallback(async () => {
		if (props.value) {
			const { data } = await productService.all({ productIds: props.value });
			const siteIdArr: (number | undefined)[] = [];
			const productIdArr: (number | undefined)[] = [];
			data.data.forEach((row: ProductEntity) => {
				siteIdArr.push(row.shopId);
				productIdArr.push(row.id);
			});
			setSiteIds(siteIdArr.join(","));
			setProductIds(productIdArr.join(","));
			setProductArr(data.data);
		} else {
			setSiteId(undefined);
			setProductId(undefined);
			setSiteIds("");
			setProductIds("");
			setProductArr([]);
		}
	}, [props.value]);

	useEffect(() => {
		init();
	}, [init]);

	const addItem = () => {
		const arr = productIds.split(",").map((item) => Number(item));
		productId && arr.push(productId);
		const arrFlag: number[] = [];
		new Set(arr).forEach((item) => {
			if (arrFlag.indexOf(item) === -1 && item !== 0) {
				arrFlag.push(item);
			}
		});
		props.onChange && props.onChange(arrFlag.join(","));
	};

	const remove = (index: number) => {
		const arr1 = productIds.split(",").map((item) => Number(item));
		arr1.splice(index, 1);
		props.onChange && props.onChange(arr1.join(","));
	};

	const siteIdOnChange = (e: any) => {
		console.log("222",e)
		
		setSiteId(e);
		setProductId(undefined);
		props.changeSite && props.changeSite(e)
	};	

	return (
		<Container className={props.className}>
			<Space>
				<div style={{ width: 300 }}>
					<SelectSite shopId={props.shopId} selected={siteIds} value={siteId} onChange={siteIdOnChange}  />
				</div>
				<div style={{ width: 300 }}>
				<SingleSelectProduct siteId={siteId} value={productId} onChange={(e:number )=>{setProductId(e)}} />
				</div>
				<div className="logo">
					<PlusOutlined style={{ color: "#096dd9" }} onClick={addItem} />
				</div>
			</Space>
			<div className="productList">
				{productArr &&
					productArr.length > 0 &&
					productArr.map((item, index) => {
						return (
							<div key={index}>
								{item.shopName} - {item.name} <MinusOutlined style={{ color: "#f5222d" }} onClick={() => remove(index)} />
							</div>
						);
					})}
			</div>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
`;
