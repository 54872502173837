import { FC, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import productService from "src/module/product/service/product.service";
import { ProductEntity, skusEntity, skuTypesEntity } from "src/module/product/entity/product.entity";
import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import skuService from "src/module/sku/service/sku.service";
import { DeleteOutlined } from "@ant-design/icons";
import { UploadImg } from "src/common/components/UploadImg";

export const ProductSku: FC = () => {
	const { t } = useTranslation();
	const [productInfo, setProductInfo] = useState<ProductEntity>({});
	const [isSKUtypeOpen, setIsSKUtypeOpen] = useState(false);
	const [isSKUOpen, setIsSKUOpen] = useState(false);
	const [skuTypeInfo, setSkuTypeInfo] = useState<skuTypesEntity>({});
	const [skuInfo, setSkuInfo] = useState<skusEntity>({});

	const { confirm } = Modal;
	const params = useParams();

	const getProductInfo = useCallback(async () => {
		const { data } = await productService.detail(Number(params.id));
		if (data.code === 200) {
			setProductInfo(data.data);
		}
	}, [params.id]);

	useEffect(() => {
		getProductInfo();
	}, [getProductInfo]);

	const openSkuTypeModal = () => {
		setSkuTypeInfo({});
		setIsSKUtypeOpen(true);
	};

	const openSkuModal = (id: number) => {
		setSkuInfo({ typeId: id });
		setIsSKUOpen(true);
	};

	const handleOk = async () => {
		if (skuTypeInfo.id) {
			const { data } = await skuService.changeSkuType({ ...skuTypeInfo, productId: Number(params.id) });
			if (data.code === 200) {
				message.success("修改成功");
			}
		} else {
			const { data } = await skuService.addSkuType({ ...skuTypeInfo, productId: Number(params.id) });
			if (data.code === 200) {
				message.success("添加成功");
			}
		}
		getProductInfo();
		setIsSKUtypeOpen(false);
	};
	const handleCancel = () => {
		setIsSKUtypeOpen(false);
	};

	const handleSkuOk = async () => {
		if (skuInfo.id!) {
			const { data } = await skuService.changeSku(skuInfo);
			if (data.code === 200) {
				message.success("修改成功");
			}
		} else {
			const { data } = await skuService.addSku(skuInfo);
			if (data.code === 200) {
				message.success("添加成功");
			}
		}
		getProductInfo();
		setIsSKUOpen(false);
	};

	const handleSkuCancel = () => {
		setIsSKUOpen(false);
	};

	const deleteType = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await skuService.deleteSkuType(id);
				if (data.code === 200) {
					message.success(data.msg);
					getProductInfo();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const deleteSku = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await skuService.deleteSku(id);
				if (data.code === 200) {
					message.success(data.msg);
					getProductInfo();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	return (
		<Container>
			<div className='info'>
				<div className='productTitle'>{t("productName")}:</div>
				<div className='content'>{productInfo.name}</div> &nbsp;&nbsp;
				<div className='title'>{t("Price")}:</div>
				<div className='content'>{productInfo.price}元</div>
			</div>
			{productInfo.skuTypes! &&
				productInfo.skuTypes.map((item: skuTypesEntity, index: number) => {
					return (
						<div className='skuType' key={index}>
							<div className='group'>
								<div className='icon'>SKU组{index + 1}:</div>
								<div
									className='item'
									onClick={() => {
										setIsSKUtypeOpen(true);
										setSkuTypeInfo(item);
									}}
								>
									<div className='typeHead'>
										<div className='title'>
											<Input value={item.title}></Input>
										</div>
									</div>

									<div className='typeHead'>
										<div className='sort'>{t("sort")}:</div>
										<div>
											<Input value={item.sort}></Input>
										</div>
									</div>
								</div>

								<div>
									<Button
										onClick={() => {
											deleteType(Number(item.id));
										}}
										danger
										style={{ color: "red" }}
										size={"small"}
									>
										{t("deleteGroup")}
									</Button>
								</div>
							</div>
							<div>
								{item.skus &&
									item.skus.map((item1: skusEntity) => {
										return (
											<div key={item1.id} className='sku'>
												<div
													className='skuItem'
													onClick={() => {
														setIsSKUOpen(true), setSkuInfo(item1);
													}}
												>
													<div className='typeHead'>
														<div className='img'>
															
															<Input className="title" value={item1.title}></Input>
															&nbsp;&nbsp;
															<div>
																<img src={item1.selectedImg} height='32' alt='' style={{ border: "1px solid #e1e1e1" }} />
															</div>
														</div>
													</div>

													<div className='typeHead'>
														<div className='sort'>{t("sort")}:</div>
														<div>
															<Input value={item1.sort}></Input>
														</div>
													</div>

													<div className='typeHead'>
														<div className='sort'>{t("price")}:</div>
														<div>
															<Input value={item1.price}></Input>
														</div>
													</div>
												</div>
												<div className="deleteIcon">
													<DeleteOutlined
														onClick={() => item1.id && deleteSku(item1.id)}
														style={{ fontSize: "18px", color: "red" }}
													/>
												</div>
											</div>
										);
									})}
							</div>

							<Button
								type='primary'
								size='small'
								onClick={() => {
									openSkuModal(Number(item.id));
								}}
							>
								添加SKU
							</Button>
						</div>
					);
				})}
			<Button type='primary' size='large' onClick={openSkuTypeModal}>
				添加SKU分组
			</Button>

			<Modal title='SKU分组管理' visible={isSKUtypeOpen} onOk={handleOk} onCancel={handleCancel}>
				<Form>
					<Form.Item label={t("title")}>
						<Input value={skuTypeInfo.title} onChange={(e) => setSkuTypeInfo({ ...skuTypeInfo, title: e.target.value })} />
					</Form.Item>
					<Form.Item label={t("sort")}>
						<InputNumber value={skuTypeInfo.sort} onChange={(e) => setSkuTypeInfo({ ...skuTypeInfo, sort: Number(e) })} />
					</Form.Item>
				</Form>
			</Modal>

			<Modal title='SKU管理' visible={isSKUOpen} onOk={handleSkuOk} onCancel={handleSkuCancel}>
				<Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					<Form.Item label={t("unSelectedImage")}>
						<UploadImg
							size={"210*138"}
							value={skuInfo?.img}
							onChange={(e: string) => {
								setSkuInfo({ ...skuInfo, img: e });
							}}
						/>
					</Form.Item>

					<Form.Item label={t("selectedImg")}>
						<UploadImg
							size={"210*138"}
							value={skuInfo?.selectedImg}
							onChange={(e: string) => {
								setSkuInfo({ ...skuInfo, selectedImg: e });
							}}
						/>
					</Form.Item>
					<Form.Item label={t("title")}>
						<Input value={skuInfo.title} onChange={(e) => setSkuInfo({ ...skuInfo, title: e.target.value })} />
					</Form.Item>
					<Form.Item label={t("sort")}>
						<InputNumber value={skuInfo.sort} onChange={(e) => setSkuInfo({ ...skuInfo, sort: Number(e) })} />
					</Form.Item>
					<Form.Item label={t("price")}>
						<InputNumber value={skuInfo.price} onChange={(e) => setSkuInfo({ ...skuInfo, price: Number(e) })} />
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
	.info {
		font-size: 20px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		.productTitle {
			font-weight: 550;
		}
		.content {
			color: gray;
		}
	}

	.skuType {
		background-color: #f9f9f9;
		padding: 20px;
		margin-bottom: 30px;
		font-size: 16px;
	

		.group {
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			.item {
				display: flex;
			}
		}

		.typeHead {
			display: flex;
			align-items: center;
			width: 400px;
			

			.title {
				width: 70%;
			}

			.img {
				display: flex;
				width: 100%;
			}
		}



		.sku {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		.skuItem {
			display: flex;
			margin-left: 100px;
		}
	}
	.icon {
		text-align: right;
		width: 80px;
		margin-right: 20px;
	}
	.sort {
		margin-right: 20px;
	}

	.deleteIcon{
		margin-left: -120px;
	}
	}

	
`;
