
import { http } from "../../../common/http";
import { EquipmentsEntity } from "../entity/equipments.entity";
import { EquipmentsParam } from "../param/equipments.param";

class EquipmentsService {
	machineStatus = {
		1: "机器故障",
		2: "机器故障",
		3: "低温保护",
		5: "设备就绪",
		10: "就绪中",
		11: "停止",
		12: "后退",
		13: "前进",
		20: "停车就位，仅支持手动",
		21: "停车就位，仅支持APP",
		22: "停车就位",
		23: "停车就位",
		26: "开始洗车",
		40: "喷洒泡沫",
		50: "轮刷",
		51: "轮毂清洗",
		60: "打蜡/抛光",
		70: "风干",
		81: "洗车结束",
		82: "洗车结束",
		83: "洗车结束",
		102: "设备被手动从CP0移除",
		103: "请等待，用于takt设备",
	};

	errorCode = {
		0: "一切正常",
		20001: "E01 - SR大角作動",
		20002: "E02 - 顶刷过电流",
		20003: "E03 - 车高异常",
		20004: "E04 - 滑行限位开关异常",
		20005: "E05 - 水压不足",
		20006: "E06 - 車幅安全动作",
		20007: "E07 - 側面安全动作",
		20008: "E08 - 侧刷限位开关异常",
		20009: "E09 - 顶刷限位开关异常",
		20010: "E10 - 顶部吹风及限位开关异常",
		20011: "E11 - 顶部吹风机安全环动作",
		20012: "E12 - 走行脉冲异常",
		20014: "E14 - 升降变频器报警",
		20015: "E15 - 走行和旋转变频器报警",
		20021: "E21 - 裙刷热继启动",
		20023: "E23 - 顶吹编码器异常",
		20024: "E24 - 顶刷升降不良",
		20026: "E26 - 侧刷打开不良",
		20027: "E27 - 裙刷限位不良",
		20028: "E28 - 顶部吹风升降不良",
		20031: "E31 - 停车定位光电检测异常",
		20033: "E33 - 非常停止",
		20035: "E35 - 风机热继动作",
		20037: "E37 - 侧刷热继动作",
		20040: "E40 - 车辆驶入洗车机内",
		20041: "E41 - 车型感应器异常",
		20042: "E42 - 底部光电异常",
		20045: "E45 - 顶刷升降带防断传感器动作",
		20048: "E48 - 定位置外",
		2: "预警 ",
		3: "顶部吹干预警",
		4: "第一定位预警",
		5: "轮刷预警",
		6: "药剂量过低预警",
		7: "水泵过载预警",
		13: "维护周期已过预警",
		26: "机器无动作",
		27: "顶刷无动作",
		28: "左侧刷无动作",
		29: "右侧刷无动作",
		30: "侧刷倾斜",
		31: "顶刷松动",
		32: "顶部吹干无动作",
		33: "顶部吹干触发碰撞 ",
		34: "轮刷缺失延伸",
		35: "电机过载",
		37: "水压过低/水泵超载",
		38: "左侧刷信号提示",
		39: "右侧刷信号提示",
		40: "顶刷信号提示",
		41: "车辆超高 ",
		42: "气压过低",
		55: "程序故障",
		56: "连接错误",
		81: "机器待复位",
		82: "刷子和吹干设备待复位",
		86: "洗车停止",
		90: "程序暂停",
		91: "服务暂停",
		92: "程序暂停",
		93: "程序被清除",
		94: "洗车停止",
		95: "管道充填",
		96: "低温保护",
		100: "选择程序，车辆就位",
		114: "程序执行中",
		117: "充气中",
		118: "等待中",
	};

	List = async (params: EquipmentsParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/equipment",
			method: "GET",
			params,
		});
	};

	add = async (params: EquipmentsParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/equipment",
			method: "POST",
			data: params,
		});
	};

	update = async (params: EquipmentsEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/equipment/" + params.id,
			method: "PUT",
			data: params,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/equipment/" + id,
			method: "GET",
			data: id,
		});
	};

	washDReset = async (data: EquipmentsEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/equipment/" + data.id + "/restoration/" + data.type,
			method: "PUT",
			data,
		});
	};

	zhuMReset = async (id: number | undefined) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/equipment/" + id + "/restoration",
			method: "PUT",
			data: id,
		});
	};

	shutDown = async (id: number | undefined) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/equipment/" + id + "/shutDown",
			method: "PUT",
			data: id,
		});
	};

	cancelOrder = async (id: number | undefined) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/equipment/" + id + "/cancel",
			method: "PUT",
			data: id,
		});
	};


	reSetCommand= async (data:any) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/equipment/start-washing",
			method: "PUT",
			data,
		});
	};


}
export default new EquipmentsService();
