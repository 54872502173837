import { http } from "../../../common/http";
import { LoginActivityListEntity } from "../entity/loginActivityListEntity";
import { LoginActivityListParam } from "../param/loginActivityListParam";

class LoginActivityListService {
	list = async (param: LoginActivityListParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/login-activity",
			method: "GET",
			params: param,
		});
	};

	changeStatus = async (data: LoginActivityListEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/login-activity/up-down/" + data.id,
			method: "PUT",
			data,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/login-activity/" + id,
			method: "DELETE",
			data: id,
		});
	};

	export = async (param: LoginActivityListParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/login-activity/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};

	add = async (data: LoginActivityListEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/login-activity",
			method: "POST",
			data,
		});
	};


	update = async (data: LoginActivityListEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/login-activity/"+data.id,
			method: "PUT",
			data,
		});
	};


	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/login-activity/"+id,
			method: "GET",
			data:id
		});
	};





}

export default new LoginActivityListService();
