import styled from "@emotion/styled";
import { Select } from "antd";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { getInitialProps, useTranslation } from "react-i18next";
import statisticsService from "../service/statistics.service";
const { Option } = Select;

interface Props {
	onChange?: any;
	provinceId?: number;
	cityId?: number;
	districtId?: number;
	labelInValue?: boolean;
	//显示省市区名称的适合用，一般不用（dashBoard 中省市区的标签）
	changeLabel?: any;
	labelName?: { provinceName?: string | undefined; cityName?: string | undefined; districtName?: string | undefined };
	//
}

// interface cityLabelObj {
// 	provinceName?: string | undefined;
// 	cityName?: string | undefined;
// 	districtName?: string | undefined;
// }
interface cityObj {
	provinceId?: number;
	cityId?: number;
	districtId?: number;
}

export const SelectCity = (props: Props) => {
	const { t } = useTranslation();
	// const [hasLabel, setHasLabel] = useState({});
	// 省市区id
	const [searchObj, setSearchObj] = useState<cityObj>({ provinceId: props.provinceId, cityId: props.cityId, districtId: props.districtId });
	// 省
	const [provinceList, setProvinceList] = useState([]);
	// 市
	const [cityList, setCityList] = useState([]);
	// 区
	const [districtList, setDistrictList] = useState([]);

	const [cityNameObj, setCityNameObj] = useState({
		provinceName: props.labelName?.provinceName,
		cityName: props.labelName?.cityName,
		districtName: props.labelName?.districtName,
	});

	const getProvinceList = useCallback(async () => {
		statisticsService.cityList({ areaLevel: 1 }).then((res) => {
			if (res.data.code === 200) {
				setProvinceList(res.data.data);
			}
		});
	}, []);

	useEffect(() => {
		if (props.provinceId) {
			statisticsService.cityListAll({ parentId: props.provinceId, areaLevel: 2 }).then((res) => {
				if (res.data.code === 200) {
					setCityList(res.data.data);
				}
			});
		}
		if (props.cityId) {
			statisticsService.cityListAll({ parentId: props.cityId, areaLevel: 3 }).then((res) => {
				if (res.data.code === 200) {
					setDistrictList(res.data.data);
				}
			});
		}
	}, [props.cityId, props.provinceId]);

	const handleModalInputChange = useCallback(
		async (type: string, e: any) => {
			if (props) {
				setSearchObj({
					provinceId: props.provinceId,
					cityId: props.cityId,
					districtId: props.districtId,
				});
			}
			if (type === "provinceId") {
				// statisticsService.cityList({ parentId: e, areaLevel: 2 }).then((res) => {
				// 	if (res.data.code === 200) {
				// 		setCityList(res.data.data);
				// 	}
				// });
				// setCityList([]);
				const objCity = {
					...searchObj,
					cityId: undefined,
					districtId: undefined,
					[type]: e,
				};
				setSearchObj(objCity);
				props.onChange(objCity);

				// 需要在页面中（非选择框）显示省份的文字的时候用下面的代码，一般不用
				const provinceLabel: any = provinceList.filter((item: any) => {
					return e == item.id;
				});

				setCityNameObj({ provinceName: provinceLabel[0]?.fullName, cityName: void 0, districtName: void 0 });
				console.log("省份变化", { provinceName: provinceLabel[0]?.fullName, cityName: void 0, districtName: void 0 });
				props.changeLabel! && props.changeLabel({ provinceName: provinceLabel[0]?.fullName, cityName: void 0, districtName: void 0 });

				//
			}

			if (type === "cityId") {
				// statisticsService.cityList({ parentId: e, areaLevel: 3 }).then((res) => {
				// 	if (res.data.code === 200) {
				// 		setDistrictList(res.data.data);
				// 	}
				// });

				const objCity = {
					...searchObj,
					districtId: undefined,
					[type]: e,
				};
				setSearchObj(objCity);
				props.onChange(objCity);

				// 需要在页面中（非选择框）显示市级的文字的时候用下面的代码，一般不用
				const cityLabel: any = cityList.filter((item: any) => {
					return e == item.id;
				});

				setCityNameObj({ ...cityNameObj, cityName: cityLabel[0]?.fullName, districtName: void 0 });
				console.log("城市变化", { ...cityNameObj, cityName: cityLabel[0]?.fullName, districtName: void 0 });
				props.changeLabel! && props.changeLabel({ ...cityNameObj, cityName: cityLabel[0]?.fullName, districtName: void 0 });

				//
			}

			if (type === "districtId") {
				const objCity = {
					...searchObj,
					[type]: e,
				};
				setSearchObj(objCity);
				props.onChange(objCity);

				// 需要在页面中（非选择框）显示区级的文字的时候用下面的代码，一般不用
				const districtLabel: any = districtList.filter((item: any) => {
					return e == item.id;
				});

				setCityNameObj({ ...cityNameObj, districtName: districtLabel[0]?.fullName });
				console.log("区域变化", { ...cityNameObj, districtName: districtLabel[0]?.fullName });
				props.changeLabel! && props.changeLabel({ ...cityNameObj, districtName: districtLabel[0]?.fullName });

				//
			}
		},
		[cityList, cityNameObj, districtList, props, provinceList, searchObj]
	);
	useLayoutEffect(() => {
		getProvinceList();
	}, [getProvinceList]);

	return (
		<Container>
			<div className={props.labelInValue ? "custom" : ""}>
				<Select
					allowClear
					value={searchObj.provinceId}
					placeholder={t("pleaseChooseProvince")}
					style={{ width: 124 }}
					onChange={(e) => handleModalInputChange("provinceId", e)}
				>
					{provinceList.map((item: any) => {
						return (
							<Option key={item.id} value={item.id}>
								{getInitialProps().initialLanguage === "zh-CN" ? item.fullName : item.enFullName}
							</Option>
						);
					})}
				</Select>
				<Select
					allowClear
					value={searchObj.cityId}
					style={{ width: 124, marginLeft: 12 }}
					placeholder={t("pleaseChooseCity")}
					onChange={(e) => handleModalInputChange("cityId", e)}
				>
					{cityList.map((item: any) => {
						return (
							<Option key={item.id} value={item.id}>
								{getInitialProps().initialLanguage === "zh-CN" ? item.fullName : item.enFullName}
							</Option>
						);
					})}
				</Select>
				<Select
					allowClear
					value={searchObj.districtId}
					style={{ width: 124, marginLeft: 12 }}
					placeholder={t("pleaseChooseArea")}
					onChange={(e) => handleModalInputChange("districtId", e)}
				>
					{districtList.map((item: any) => {
						return (
							<Option key={item.id} value={item.id}>
								{getInitialProps().initialLanguage === "zh-CN" ? item.fullName : item.enFullName}
							</Option>
						);
					})}
				</Select>
			</div>
		</Container>
	);
};
const Container = styled.div`
	.ant-select-selection-item::first {
		color: #777777;
	}
	.custom {
		display: flex;
		justify-content: space-between;
		.ant-select {
			width: 32% !important;
		}
	}
`;
