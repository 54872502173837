import styled from "@emotion/styled";
import { Button, Col, DatePicker, Input, message, Modal, Popover, Row, Space, Table, Tag } from "antd";
import { AxiosResponse } from "axios";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { ResponseEntity } from "../../common/entity/response.entity";
import { dateFormat } from "../../common/Util";
import { checkPermission } from "../../module/admin/service/permission.service";
import { MembershipCardEntity } from "../../module/membershipCard/entity/membership.card.entity";
import { MembershipCardParam } from "../../module/membershipCard/params/membership.card.param";
import membershipCardService from "../../module/membershipCard/service/membership.card.service";
import { SelectSite } from "../../module/site/component/select.site";

const { confirm } = Modal;
const { RangePicker } = DatePicker;

export const MembershipCardList: FC = () => {
	// 定义翻译相关变量，使用方法: t('翻译文件中对应的key'),翻译文件目录: src/common/locales
	const { t } = useTranslation();
	// 定义url跳转相关变量，使用方法：navigate('要跳转的地址')
	const navigate = useNavigate();

	// 定义数据相关参数，具体说明： const [数据存储变量, 修改数据的方法] = useState<数据类型>(初始值)
	const [dataList, setDataList] = useState<MembershipCardEntity[]>([]);
	// 定义分页相关参数，具体说明： const [数据存储变量, 修改数据的方法] = useState<数据类型>(初始值)
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// 	total: 0,
	// });

	// 定义筛选项相关参数，具体说明： const [筛选数据存储变量, 修改数据的方法] = useState<数据类型>(初始值)
	const [searchArr, setSearchArr] = useState<MembershipCardParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
	});
	/**
	 * 定义筛选项提交相关参数
	 * 具体说明： const [筛选数据存储变量, 修改数据的方法] = useState<数据类型>(初始值)
	 * 点击筛选后，将searchArr的数据set到searchObj中进行提交
	 */
	const [searchObj, setSearchObj] = useState<MembershipCardParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});
	// 定义数据数量，用于分页，数据查询后进行set
	const [total, setTotal] = useState<number>(0);
	// 是否显示加载中，数据查询时为true，返回后set为 false
	const [loading, setLoading] = useState(true);

	// 定义公用onChange方法，筛选项数据发生变化时，写入到searchArr中
	const changeAttr = (type: string, e: any) => {
		setSearchArr({
			...searchArr,
			[type]: e,
		});
	};

	// 定义日期onChange方法，筛选项数据发生变化时，写入到searchArr中
	const onDateChange = (date: any, dateString: any) => {
		setSearchArr({
			...searchArr,
			startTime: dateString[0],
			endTime: dateString[1],
		});
	};

	// 定义查询方法，将筛选项数据写入查询对象中
	const search = () => {
		setSearchObj({
			...searchArr,
			startTime: searchArr.startTime ? moment(searchArr.startTime).format(dateFormat) + " 00:00:00" : undefined,
			endTime: searchArr.endTime ? moment(searchArr.endTime).format(dateFormat) + " 23:59:59" : undefined,
			customPageSize: 15,
			customPageNum: 1,
		});
	};

	// 初始化数据
	const init = useCallback(async () => {
		setLoading(true);
		const { data } = await membershipCardService.list({
			...searchObj,
		});
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
		setLoading(false);
	}, [searchObj]);

	// 页面监听方法
	useEffect(() => {
		init();
	}, [init]);

	// 定义删除方法
	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data }: AxiosResponse<ResponseEntity> = await membershipCardService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	// 表格标题及数据展示定义，title为表头，dataIndex为api相应参数对应的entity里面的数据字段
	const columns = [
		{
			width: 180,
			title: t("id"),
			dataIndex: "id",
		},
		{
			width: 180,
			title: t("title"),
			dataIndex: "title",
		},
		{
			width: 180,
			title: t("productName"),
			dataIndex: "productName",
			render: (text: string, record: MembershipCardEntity) => {
				return (
					<Popover content={record.shopName}>
						<Tag>{text}</Tag>
					</Popover>
				);
			},
		},
		{
			width: 130,
			title: t("cardType"),
			dataIndex: "cardType",
			render: (text: number) => {
				return <div>{t(membershipCardService.cardType[text])}</div>;
			},
		},

		{
			title: t("distributionAmount"),
			dataIndex: "grantNum",
		},

		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? <Tag color='blue'>{t("onSale")}</Tag> : <Tag color='pink'>{t("off")}</Tag>;
			},
		},
		{
			title: t("availablePeriod"),
			width: 250,
			render: (record: MembershipCardParam) => {
				return (
					<div className="period" >
						<div>{record.startTime}</div>
						<div>{"~"}</div>
						<div>{record.expirationTime}</div>
					</div>
				);
			},
		},
		{
			title: t("action"),
			render: (text: string, record: any) => {
				return (
					<Space>
						{checkPermission(278) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/activity/membership-card/${record.id}`);
								}}
							>
								{t("edit")}
							</Button>
						)}
						{checkPermission(279) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];
	return (
		<Container>
			<div className='headers'>
				<Row gutter={20}>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("title")}：</div>
							<Input className='searchInput' onChange={(e) => changeAttr("title", e.target.value)} placeholder={t("title")} />
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("period")}：</div>
							<RangePicker
								defaultValue={[
									moment(searchArr.startTime && searchArr.startTime.toString()),
									moment(searchArr.endTime && searchArr.endTime.toString()),
								]}
								showTime={false}
								onChange={onDateChange}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("site")}：</div>
							<SelectSite onChange={(e: number) => changeAttr("shopId", e)} />
						</div>
					</Col>
					<Col span={8}>
						<Space>
							<Button type={"primary"} onClick={search}>
								{t("search")}
							</Button>
							{checkPermission(277) && (
								<Link to={"/activity/membership-card/0"}>
									<Button type={"primary"}>{t("add")}</Button>
								</Link>
							)}
						</Space>
					</Col>
				</Row>
			</div>
			<Table
				columns={columns}
				loading={loading}
				dataSource={dataList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setSearchObj({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;
	.period{
		width:150px;
		display:flex;
		flex-direction:column;
		align-items: center;
		
		
	}

	.headers {
		width: 100%;

		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			.searchItem {
				min-width: 150px;
				text-align: right;
			}
		}
	}
`;
