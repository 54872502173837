import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { FC, useCallback, useEffect, useState } from "react";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import meiTuanService from "../../module/tools/service/meituan.service";

export const MtPage: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(true);
	const [url, setUrl] = useState<string>("");
	const [authCode, setAuthCode] = useState<undefined | string>(void 0);

	// 初始化
	useEffect(() => {
		let timeout: null | NodeJS.Timeout = setTimeout(() => {
			setUrl("https://e.dianping.com/dz-open/merchant/auth?app_key=" + "9273638a2c9e02c7" + "&state=" + location.pathname);
			setLoading(false);
			timeout && clearTimeout(timeout);
			timeout = null;
		}, 500);
		return () => {
			setLoading(true);
			setUrl("");
			setAuthCode(void 0);
		};
	}, [location.pathname]);

	// 订阅消息
	const subscriptionMessage = useCallback(() => {
		window.addEventListener("message", (e) => {
			// session Code
			if (!e.data?.message || e.data?.message !== "open_sdk_auth_success") {
				return;
			}
			// 验证路径  只能在当前页面拿到的auth_code才有效 不然无效
			if (e.data?.state !== "/tools/MtPage") {
				message.error("非法获取！");
				return;
			}
			setAuthCode(e.data.auth_code);
		});
	}, []);

	useEffect(() => {
		subscriptionMessage();
	}, [subscriptionMessage]);

	// 拿到code 发送给后端
	const sendAuthCodeToBackEnd = useCallback(async () => {
		if (!authCode) return;
		await meiTuanService.sendAuthCode(authCode);
		navigate("/tools/MtShopPage");
	}, [authCode, navigate]);

	useEffect(() => {
		sendAuthCodeToBackEnd();
	}, [sendAuthCodeToBackEnd]);
	return (
		<Container>
			{loading && <LoadingOutlined />}
			<iframe style={{ opacity: loading ? 0 : 1 }} src={url}></iframe>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	iframe {
		width: 100%;
		height: 100%;
	}
`;
