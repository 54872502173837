import styled from "@emotion/styled";
import { Button, Input, Form, Select, Modal, FormInstance } from "antd";
import { t } from "i18next";
import React, { useEffect, useState, useCallback } from "react";
import { PropsEntity } from "src/common/entity/props.entity";
import { SingleSelectCouponByType } from "./SingleSelectCouponByType";
import { PackageActivityCouponEntity } from "../entity/package.activity.entity";
import PackageActivityCouponService from "../service/package.activity.service";
const { Option } = Select;
interface PackageCouponPropsEntity extends PropsEntity {
	id?: number;
	form: FormInstance<any>;
	commitPointers: (arg: [number, number?, number?][]) => void;
}
/**
 * 关于此组件删除的部分说明
 * 由于各种问题 antd值得绑定 等等 指针问题 常规删除数组某个下标的元素
 * 指针还是不变  eg: [{},{},{}] Form.Item 绑定的值为 name={'xxx', index, 'xxx'}
 * 删除数组的第二个元素   指针的值还是 1 , 2 而不是 1 , 3
 *
 * 所以使用 '假删' 解决  放置一个deleteFlag 当它为true时  页面不展示 最终数据过滤此值
 *
 * 做一个二位数组记录删除元素的指针
 *
 */
export const PackageActivityCoupon = (props: PackageCouponPropsEntity) => {
	const { commitPointers } = props;
	const [dataList, setDataList] = useState<PackageActivityCouponEntity[]>([]);
	// const [handleArr, setHandleArr] = useState<number>(0)
	const initEvent = {
		addClass: void 0,
		deleteClass: void 0,
		addCombo: void 0,
		deleteCombo: void 0,
		addCoupon: void 0,
		deleteCoupon: void 0,
	};
	const [recordDeletePointer, setRecordDeletePointer] = useState<[number, number?, number?][]>([]);
	const deleteMsg: Partial<Record<keyof typeof initEvent, string>> = {
		deleteClass: "确定要删除此分类吗？",
		deleteCombo: "确定要删除此选项吗？",
		deleteCoupon: "确定要删除此优惠券吗？",
	};
	const init = useCallback(async () => {
		if (typeof props.id === "number") {
			const { data } = await PackageActivityCouponService.list(props.id);
			if (data.code === 200) {
				setDataList(data.data);
				props.form.setFieldsValue({
					...props.form.getFieldsValue(),
					...{
						activityPackageCategoriesCopy: data.data,
					},
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id]);

	useEffect(() => {
		init();
	}, [init]);

	const finishCb = useCallback(() => {
		props.onChange(dataList);
	}, [dataList, props]);

	useEffect(() => {
		finishCb();
	}, [finishCb]);

	useEffect(() => {
		commitPointers(recordDeletePointer);
	}, [recordDeletePointer, commitPointers]);

	const clickEvent: (type: keyof typeof initEvent) => (index: any) => void = (type) => {
		const eventObj = {
			addClass,
			deleteClass,
			addCombo,
			deleteCombo,
			addCoupon,
			deleteCoupon,
		};
		return (index) => {
			// type deleteEvents = Pick<typeof initEvent, 'deleteClass' | 'deleteCombo' | 'deleteCoupon'>
			// type deleteTypes = keyof deleteEvents
			if (type === "deleteClass" || type === "deleteCombo" || type === "deleteCoupon") {
				Modal.confirm({
					title: deleteMsg[type],
					okText: "确定",
					cancelText: "取消",
					onOk() {
						eventObj[type](index);
					},
					onCancel() {
						console.log("cancel");
					},
				});
				return;
			}
			eventObj[type](index);
		};
	};

	const addClass = () => {
		setDataList([
			...dataList,
			{
				deleteId: "class_" + new Date().getTime(),
				title: "",
				sort: 0,
				activityId: props.id,
				activityPackageCombos: [
					{
						extraMoney: 0,
						extraMoneyType: 0,
						deleteId: "combo_" + new Date().getTime(),
						activityPackageCoupons: [
							{
								deleteId: "coupon_" + new Date().getTime(),
								couponType: void 0,
								couponNum: 0,
								couponId: void 0,
								title: "",
							},
						],
					},
				],
			},
		]);
	};

	const addCombo = (index: number) => {
		const tempArr = [...dataList];
		const arr3 = [
			...dataList[index].activityPackageCombos!,
			{
				deleteId: "combo_" + new Date().getTime(),
				extraMoney: 0,
				extraMoneyType: 0,
				activityPackageCoupons: [
					{
						deleteId: "coupon_" + new Date().getTime(),
						couponType: void 0,
						couponNum: 0,
						couponId: void 0,
						title: "",
					},
				],
			},
		];
		tempArr[index].activityPackageCombos = arr3;
		setDataList(tempArr);
	};

	const addCoupon = (index: number[]) => {
		const tempArr = [...dataList];
		const tempOptionArr = [...tempArr[index[0]].activityPackageCombos!];
		const tempCouponArr = [
			...tempOptionArr[index[1]].activityPackageCoupons!,
			{
				deleteId: "coupon_" + new Date().getTime(),
				couponType: void 0,
				couponNum: 0,
				couponId: void 0,
				title: "",
			},
		];
		tempArr[index[0]]!.activityPackageCombos![index[1]].activityPackageCoupons = tempCouponArr;
		setDataList(tempArr);
	};

	const deleteClass = (index: number) => {
		// 假删
		const arr = [...dataList];
		arr[index].deleteFlag = true;
		// arr.splice(index, 1);
		// console.log(index, arr, "???/////");
		setRecordDeletePointer([...recordDeletePointer, [index, void 0, void 0]]);
		setDataList(arr);
	};

	const deleteCombo = (index: [number, number]) => {
		const tempArr = [...dataList];
		const tempOptionArr = [...tempArr[index[0]].activityPackageCombos!];
		tempOptionArr[index[1]].deleteFlag = true;
		tempArr[index[0]].activityPackageCombos = tempOptionArr;
		setRecordDeletePointer([...recordDeletePointer, [index[0], index[1], void 0]]);
		setDataList(tempArr);
	};

	const deleteCoupon = (index: [number, number, number]) => {
		console.log(index);
		const tempArr = [...dataList];
		const tempOptionArr = [...tempArr[index[0]].activityPackageCombos!];
		const tempCouponArr = [...tempOptionArr[index[1]].activityPackageCoupons!];
		tempCouponArr[index[2]].deleteFlag = true;
		tempArr[index[0]].activityPackageCombos![index[1]].activityPackageCoupons = tempCouponArr;
		setRecordDeletePointer([...recordDeletePointer, [index[0], index[1], index[2]]]);
		setDataList(tempArr);
	};

	return (
		<Container>
			{dataList.map((item: PackageActivityCouponEntity, index) => {
				return (
					<div key={"class" + index + "_" + item.deleteId} className='list' style={{ display: item.deleteFlag ? "none" : "block" }}>
						<div className='head'>
							<div style={{ display: "flex" }}>
								<Form.Item
									label={t("classification") + (index + 1)}
									name={[`activityPackageCategoriesCopy`, index, "title"]}
									key={item.deleteId}
								>
									<Input />
								</Form.Item>
								<Form.Item label={t("sort")} style={{ marginLeft: "20px" }} name={[`activityPackageCategoriesCopy`, index, "sort"]}>
									<Input />
								</Form.Item>
								<Form.Item style={{ marginLeft: "20px" }}>
									<Button type='primary' onClick={() => clickEvent("deleteClass")(index)}>
										{t("deleteClassification")}
									</Button>
								</Form.Item>
							</div>

							{item.activityPackageCombos?.map((data, optionIndex) => {
								return (
									<div key={"options_" + optionIndex} className='body' style={{ display: data.deleteFlag ? "none" : "block" }}>
										<div style={{ marginBottom: "20px", display: "flex" }}>
											<Form.Item label={t("option") + (optionIndex + 1)}></Form.Item>
											<Form.Item
												label={t("sort")}
												name={[`activityPackageCategoriesCopy`, index, "activityPackageCombos", optionIndex, "sort"]}
											>
												<Input />
											</Form.Item>
											<Form.Item style={{ marginLeft: "20px" }}>
												<Button type='primary' onClick={() => clickEvent("deleteCombo")([index, optionIndex])}>
													{t("delete")}
												</Button>
											</Form.Item>
										</div>
										{data.activityPackageCoupons?.map((couponItem, couponIndex) => {
											return (
												<div
													key={"coupon_" + couponIndex}
													className='coupon'
													style={{ display: couponItem.deleteFlag ? "none" : "block" }}
												>
													<SingleSelectCouponByType
														couponType={couponItem.couponType}
														couponId={couponItem.couponId}
														couponNum={couponItem.couponNum}
														couponIndexArr={[index, optionIndex, couponIndex]}
														deleteCoupon={() => clickEvent("deleteCoupon")([index, optionIndex, couponIndex])}
													/>
												</div>
											);
										})}
										<Button type='primary' style={{ marginBottom: 20 }} onClick={() => clickEvent("addCoupon")([index, optionIndex])}>
											{t("addCoupon")}
										</Button>
										<div>
											<div style={{ display: "flex" }}>
												<Form.Item
													label={t("金额调整")}
													name={[`activityPackageCategoriesCopy`, index, "activityPackageCombos", optionIndex, "extraMoneyType"]}
												>
													<Select style={{ width: "120px" }}>
														<Option value={0}>{t("originalPrice")}</Option>
														<Option value={1}>{t("increasePrice")}</Option>
														<Option value={2}>{t("reducePrice")}</Option>
													</Select>
												</Form.Item>
												<Form.Item name={[`activityPackageCategoriesCopy`, index, "activityPackageCombos", optionIndex, "extraMoney"]}>
													<Input style={{ width: "120px", marginLeft: "20px" }} />
												</Form.Item>
												&nbsp; &nbsp;
												<Form.Item
													label={t("绩效调整")}
													name={[
														`activityPackageCategoriesCopy`,
														index,
														"activityPackageCombos",
														optionIndex,
														"performanceExtraMoneyType",
													]}
												>
													<Select style={{ width: "120px" }}>
														<Option value={0}>{t("originalPrice")}</Option>
														<Option value={1}>{t("increasePrice")}</Option>
														<Option value={2}>{t("reducePrice")}</Option>
													</Select>
												</Form.Item>
												<Form.Item
													name={[
														`activityPackageCategoriesCopy`,
														index,
														"activityPackageCombos",
														optionIndex,
														"performanceExtraMoney",
													]}
												>
													<Input style={{ width: "120px", marginLeft: "20px" }} />
												</Form.Item>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<Button style={{ marginTop: "20px" }} type='primary' onClick={() => clickEvent("addCombo")(index)}>
							{t("addOption")}
						</Button>
					</div>
				);
			})}
			<Form.Item>
				<Button style={{ width: "800px", marginTop: "20px", height: "40px" }} type='primary' onClick={clickEvent("addClass")}>
					{t("增加分类")}
				</Button>
			</Form.Item>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;

	.list {
		border: 2px dashed gray;
		margin-top: 20px;
		width: 800px;
		padding: 20px;

		.body {
			border: 2px solid gray;
			margin-top: 20px;
			padding: 15px;
			.coupon {
				display: flex;
			}
		}
	}
`;
