import styled from "@emotion/styled";
import { Tabs } from "antd";
import { t } from "i18next";
import { FC } from "react";
import { RegistrationActivityList } from "./RegistrationActivityList";
import { RegistrationStaffList } from "./RegistrationStaffList";

export const RegistrationActivity: FC = () => {
	return (
		<Container>
			<Tabs defaultActiveKey='1'>
				<Tabs.TabPane tab={t("registrationActivity")} key='1'>
					<RegistrationActivityList />
				</Tabs.TabPane>

				<Tabs.TabPane tab={t("registrationStaff")} key='2'>
					<RegistrationStaffList />
				</Tabs.TabPane>
			</Tabs>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
