import { http } from "src/common/http";
import { UpdateProductParam } from "./updateProductParam";
import { UpdateProductEntity } from "./updateProductEntity";


class UpdateProductService {

	list = async (param: UpdateProductParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/product-relation",
			method: "GET",
			params: param,
		});
	};

	add = async (data: UpdateProductEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/product-relation",
			method: "POST",
			data,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/product-relation/" + id,
			method: "GET",
			data: id,
		});
	};

	update = async (data: UpdateProductEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/product-relation/" + data.id,
			method: "PUT",
			data,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/product-relation/" + id,
			method: "DELETE",
			data: id,
		});
	};
}
export default new UpdateProductService();
