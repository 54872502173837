import { FC, useState } from "react";
import styled from "@emotion/styled";
import maintenanceRecordsService from "src/module/maintenanceRecords/maintenanceRecordsService";
import { Button, DatePicker, Form, Input, message } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { MaintenanceRecordsEntity } from "src/module/maintenanceRecords/maintenanceRecordsEntity";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
// import { MaintenanceRecordsEntity } from "src/module/maintenanceRecords/maintenanceRecordsEntity";
// import { CheckOutlined } from "@ant-design/icons";
// import { DatePicker, Form, Input } from "antd";
// import moment from "moment";

export const MaintenanceAdd: FC = () => {
	const navigate = useNavigate();
	interface MaintenanceValueInterface {
		title: string;
		value: string;
	}
	const params = useParams();
	const data = maintenanceRecordsService.MaintenanceData;
	const [ids, setIds] = useState<string[]>([]);
	const [values, setValues] = useState<MaintenanceValueInterface[]>([]);
	const [info, setInfo] = useState<MaintenanceRecordsEntity>({shopId:Number(params.id)});

	const clickItem = (uniItemId: string, itemUniTile: string) => {
		const hasId = new Set(ids).has(uniItemId);
		if (hasId) {
			setIds(ids.filter((id) => id !== uniItemId));
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [_, itemTitle] = itemUniTile.split("_");
			const newValues = values.map((value) => {
				const hasThisTile = value.value.split(",").includes(itemTitle);
				if (hasThisTile) {
					return {
						...value,
						value: value.value
							.split(",")
							.filter((title) => title !== itemTitle)
							.join(","),
					};
				}
				return value;
			});
			setValues(newValues);
			console.log("newValues", newValues);
			return;
		}
		// set会重新渲染页面
		setIds([...ids, uniItemId]);
		const [firstTile, secondTitle] = itemUniTile.split("_");
		let newValues = [...values];
		const hasTitleObj = values.filter((value) => value.title === firstTile);
		if (!hasTitleObj.length) {
			newValues = [
				...newValues,
				{
					title: firstTile,
					value: secondTitle,
				},
			];
		} else {
			const [obj] = hasTitleObj;
			newValues = [
				...values.filter((value) => value.title !== firstTile),
				{
					title: firstTile,
					value: [...obj.value.split(","), secondTitle].join(","),
				},
			];
		}
		setValues(newValues);
	};

	const handleOk = async () => {
		console.log({...info, project: JSON.stringify(values)});	
		const { data } = await maintenanceRecordsService.add({ ...info, project: JSON.stringify(values) });	
		if (data.code === 200) {
			message.success(t("success"));
			navigate(`/site/maintenance/${params.id}`);
		}
	};

	return (
		<Container>
			{data &&
				data.map((item: any, index: number) => {
					return (
						<div className='container' key={index}>
							<div className='head'>
								<div className='icon'></div>
								<div className='title'> {item.title}</div>
							</div>
							<div className='valueContainer'>
								{item.children.split(",").map((child: string, itemIndex: number) => {
									const itemUniId = index + "_" + itemIndex;
									const itemUniTile = item.title + "_" + child;
									const isChecked = ids.includes(itemUniId);

									return (
										<div
											className={isChecked ? "value checked" : "value"}
											key={itemIndex}
											onClick={() => clickItem(itemUniId, itemUniTile)}
										>
											<div>{child}</div>
											{isChecked && <CheckOutlined />}
										</div>
									);
								})}
							</div>
						</div>
					);
				})}

			<Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} label='维保备注'>
				<Input.TextArea
					onChange={(e) => {
						setInfo({ ...info, comments: e.target.value });
					}}
				/>
			</Form.Item>

			<Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} label='下次维保截止时间'>
				<DatePicker
					showTime
					onChange={(date, dateString) => {
						setInfo({ ...info, nextTime: dateString });
					}}
				/>
			</Form.Item>

			<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
				<Button
					type={"primary"}
					onClick={() => {
						handleOk()
					}}
				>
					确定提交
				</Button>
			</Form.Item>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.container {
		margin: 20px auto 20px 100px;
		.head {
			display: flex;
			align-items: center;
			.icon {
				margin-right: 5px;
				width: 3px;
				height: 18px;
				background: #1b78f2;
			}
			.title {
				font-size: 16px;
				font-weight: 500;
				color: #333333;
			}
		}
		.valueContainer {
			display: flex;
			flex-wrap: wrap;
			width: 80%;
			.value {
				margin-top: 5px;
				padding: 0 10px;
				width: 30%;
				height: 48px;
				border-radius: 4px;
				background: #f2f2f6;
				margin-right: 5px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #777777;
			}
			.checked {
				background: #1b78f2;
				color: #ffffff;
			}
		}

		.empty {
			width: 100px;
			height: 100px;
		}
	}
`;
