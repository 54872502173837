import { DatePicker } from "antd";
import moment from "moment";
import { PropsEntity } from "../entity/props.entity";
import { dateFormat } from "../Util";

export const DatePeriod = (props: PropsEntity) => {

    const onChange = (e: any, str:string[]) => {
        props.onChange(str);
    }

    return (
      <DatePicker.RangePicker
          value={[props?.value[0] ? moment(props?.value[0], dateFormat) : moment(new Date(), dateFormat), props?.value[1] ? moment(props?.value[1], dateFormat) : moment(new Date(), dateFormat)]}
          onChange={onChange}
      />
    )
}