import { http } from "../../../common/http";
import { ActivityEntity } from "../entity/activity.entity";
import { ActivityParam } from "../param/activity.param";

class ActivityService {
	list = async (param: ActivityParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/activities",
			method: "GET",
			params: param,
		});
	};

	add = async (param: ActivityEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/activities",
			method: "POST",
			data: param,
		});
	};

	changeStatus = async (param: ActivityEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/activities/shelf",
			method: "PUT",
			data: param,
		});
	};

	update = async (param: ActivityEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/activities/" + param.id,
			method: "PUT",
			data: param,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/activities/" + id,
			method: "GET",
			data: id,
		});
	};
}

export default new ActivityService();
