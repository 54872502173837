import styled from "@emotion/styled";
import { Table } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import meiTuanService from "../../module/tools/service/meituan.service";
import { useTranslation } from "react-i18next";

export const MtShopPage: FC = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(true);
	const [list, setList] = useState<MeituanShop[]>([]);

	const init = useCallback(async () => {
		const res = await meiTuanService.meiTuanShopList();
		setLoading(false);
		setList(res.data);
		// console.log(res);
	}, []);

	// 初始化
	useEffect(() => {
		init();
	}, [init]);

	const columns = [
		{
			title: "UUID",
			dataIndex: "open_shop_uuid",
		},
		{
			title: t("branchName"),
			dataIndex: "branchname",
		},
		{
			title: t("address"),
			dataIndex: "shopaddress",
		},
		{
			title: t("siteCity"),
			dataIndex: "cityname",
		},
		{
			title: t("shopName"),
			dataIndex: "shopname",
		},
	];

	return (
		<Container>
			<Table loading={loading} dataSource={list} columns={columns} rowKey={(record) => record.open_shop_uuid}></Table>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;
`;
