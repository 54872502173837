import { SearchOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Input, InputNumber, message, Modal, Space, Table, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CouponReceivedEntity } from "src/module/coupon/entity/coupon.received.entity ";
import couponReceivedService from "src/module/coupon/service/coupon.received.service";
import { PaginationEntity } from "../../common/entity/pagination.entity";
import { CouponEntity } from "../../module/coupon/entity/coupon.entity";
import { CouponParam } from "../../module/coupon/param/coupon.param";
import couponService from "../../module/coupon/service/coupon.service";

export const GiveAwayCoupon: FC = () => {
	const { t } = useTranslation();

	const [searchArr, setSearchArr] = useState<CouponParam>({});
	const [searchObj, setSearchObj] = useState<CouponParam>({ type: 1, status: 1 ,customPageSize: 15,
		customPageNum: 1,});
	const [maxNum, setMaxNum] = useState(0);
	const [giveNum, setGiveNum] = useState(1);
	const [idArr, setIdArr] = useState(0);
	const [mobileNum, setMobileNum] = useState("");
	const [couponList, setcouponList] = useState<CouponEntity[]>([]);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [userPaginationObj, setUserPaginationObj] = useState<PaginationEntity>({
		customPageSize: 15,
		customPageNum: 1,
	});
	const [total, setTotal] = useState<number>(0);
	const [userTotal, setUserTotal] = useState<number>(0);
	const [loading, setLoading] = useState(true);
	const [selectionType] = useState<"checkbox" | "radio">("radio");
	const rowSelection = {
		type: selectionType,
		onChange: (selectedRowKeys: any, selectedRows: any) => {
			// console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
			setMaxNum(selectedRows[0].limitNum);
			setIdArr(selectedRows[0].id);
		},
	};

	const init = useCallback(async () => {
		setLoading(true);
		const { data } = await couponService.list({  ...searchObj });
		if (data.code === 200) {
			setcouponList(data.data.list);
			setTotal(data.data.total);
		}
		setLoading(false);
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const search = () => {
		setSearchObj({ ...searchObj, ...searchArr,customPageSize: 15,
			customPageNum: 1, });
	};

	const changeAttr = (type: string, e: any) => {
		setSearchArr({
			...searchArr,
			[type]: e,
		});
	};

	const columns = [
		{
			title: t("title"),
			dataIndex: "title",
		},

		{
			title: "赠送数量",
			dataIndex: "grantNum",
		},
		{
			title: "每人限领数量",
			dataIndex: "limitNum",
		},
		{
			title: "剩余数量",
			dataIndex: "remainingNum",
		},
		{
			title: "有效期",
			render: (record: any) => {
				return <div>{record.effectiveDay ? "有效期：" + record.effectiveDay + "天" : "有效期至 " + record.expirationTime}</div>;
			},
		},
		{
			title: "站点名称",
			render: (record: any) => {
				return (
					<Tooltip placement='topLeft' title={<span>{record.shopName}</span>}>
						<div style={{ width: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{record.shopName}</div>
					</Tooltip>
				);
			},
		},
		{
			title: "商品名称",
			render: (record: any) => {
				return (
					<div style={{ width: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
						<Tooltip placement='topLeft' title={<span>{record.productName}</span>}>
							<span>{record.productName}</span>
						</Tooltip>
					</div>
				);
			},
		},
	];

	const [owerList, setowernList] = useState<CouponReceivedEntity[]>([]);
	const owerColumns = [
		{
			title: "车主名称",
			dataIndex: "nickName",
			width: 120,
		},
		{
			title: "手机号",
			dataIndex: "mobile",
			width: 120,
		},
		{
			title: "领取数量",
			dataIndex: "num",
			width: 120,
		},
	];

	const searchUser = useCallback(async () => {
		setLoading(true);
		if (!mobileNum) {
			setowernList([]);
		}
		if (mobileNum) {
			const { data } = await couponReceivedService.list({ ...userPaginationObj, couponId: idArr, mobiles: mobileNum });
			if (data.code === 200) {
				setowernList(data.data.list);
				setUserTotal(data.data.total);
			}
		}

		setLoading(false);
	}, [idArr, mobileNum, userPaginationObj]);

	useEffect(() => {
		searchUser();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userPaginationObj]);

	const [userRowKeys, setUserRowKeys] = useState<React.Key[]>([]);
	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setUserRowKeys(newSelectedRowKeys);
	};

	const userSelection = {
		userRowKeys,
		onChange: onSelectChange,
	};

	const submit = () => {
		const param = { couponId: idArr, grantNum: giveNum, userId: userRowKeys.join(",") };
		Modal.confirm({
			title: "确定赠送",
			okText: "确定",
			cancelText: "取消",
			onOk() {
				couponReceivedService.update(param).then((res) => {
					if (res.data.code === 200) message.success("赠送成功");
				});
			},
			onCancel() {
				console.log("cancel");
			},
		});
	};

	return (
		<Container>
			<div>
				<Space>
					<Space>
						{t("couponName")}
						<Input placeholder={t("enterCouponName")} allowClear onChange={(e) => changeAttr("title", e.target.value)} />
					</Space>

					<Space>
						<Button type={"primary"} icon={<SearchOutlined />} onClick={search}>
							{t("search")}
						</Button>
					</Space>
				</Space>
			</div>
			<Table
				rowSelection={rowSelection}
				columns={columns}
				loading={loading}
				dataSource={couponList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setSearchObj({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
					
				}}
			/>

			<div>
				设置发放数量：
				<InputNumber
					min={1}
					max={maxNum}
					style={{ width: "300px" }}
					placeholder={"发放数量"}
					onChange={(e) => {
						setGiveNum(e || 0);
					}}
				/>
			</div>

			<div className='title'>选择车主：</div>
			<div className='block'>
				<TextArea
					rows={4}
					placeholder={"车主手机号请用逗号拼接，最多50位"}
					onChange={(e) => {
						setMobileNum(e.target.value);
					}}
				/>
				<Button type={"primary"} style={{ marginLeft: "20px" }} onClick={searchUser}>
					{t("search")}
				</Button>
			</div>
			<Table
				pagination={{
					pageSize: userPaginationObj.customPageSize,
					current: userPaginationObj.customPageNum,
					total: userTotal,
					showTotal: () => {
						return (
							<div>
								{t("total")} {userTotal}
							</div>
						);
					},
				}}
				// onChange={async(pagination) => {
				// 	setUserPaginationObj({
				// 		...userPaginationObj,
				// 		customPageNum: pagination.current ?? 1,
				// 		customPageSize: pagination.pageSize ?? 15,
				// 	});
				// 	const res: any = await couponReceivedService.list({
				// 		customPageNum: pagination.current,
				// 		customPageSize: pagination.pageSize,
				// 		couponId: idArr,
				// 		mobiles: mobileNum,
				// 	});
				// 	setowernList(res.data.data.list);
				// 	setUserTotal(res.data.data.total);
				// }}

				onChange={(pagination) => {
					setUserPaginationObj({
						...userPaginationObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
				columns={owerColumns}
				dataSource={owerList}
				rowSelection={userSelection}
				rowKey={(record) => record.userId + ""}
			/>

			<Button type={"primary"} onClick={submit}>
				赠送
			</Button>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.headers {
		width: 100%;

		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			.searchItem {
				min-width: 150px;
				text-align: right;
			}
		}
	}

	.title {
		font-size: 20px;
		font-weight: bold;
		margin: 15px 0;
	}
	.block {
		display: flex;
		align-items: center;
	}
`;
