import { IDomEditor, IEditorConfig } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-react";
import { useEffect, useState } from "react";
import { Props } from "../entity/props.entity";

type InsertFnType = (url: string, alt: string, href: string) => void;

const MyEditor = (props: Props) => {
    const [editor, setEditor] = useState<IDomEditor | null>(null) // 存储 editor 实例
    const [html, setHtml] = useState(props.value) // 编辑器内容

    const toolbarConfig = { }
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: '请输入内容...',
        MENU_CONF: {}
    }

    editorConfig.MENU_CONF = {}
    editorConfig.MENU_CONF['uploadImage'] = {
        // form-data fieldName ，默认值 'wangeditor-uploaded-image'
        fieldName: 'file',
    
        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 1 * 1024 * 1024, // 1M
    
        // 最多可上传几个文件，默认为 100
        maxNumberOfFiles: 10,
    
        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ['image/*'],
    
        // 将 meta 拼接到 url 参数中，默认 false
        metaWithUrl: false,
    
        // 自定义增加 http  header
        headers: {
            // Accept: 'text/x-json',
            Authorization: localStorage.getItem("token")
        },
    
        // 跨域是否传递 cookie ，默认为 false
        withCredentials: true,
    
        // 超时时间，默认为 10 秒
        timeout: 5 * 1000, // 5 秒

        server: process.env.REACT_APP_UPLOAD_FILE_IMG+'/file',

        customInsert(res: any, insertFn: InsertFnType){
            insertFn(res.data.url, "", "")
        }
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    return (
        <>
            <div style={{ width: '750px', border: '1px solid #ccc', zIndex: 100}}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => {setHtml(editor.getHtml()); props.onChange && props.onChange(editor.getHtml())}}
                    mode="default"
                />
            </div>
        </>
    )
}

export default MyEditor
