import { CouponParam } from "../param/coupon.param";
import { http } from "../../../common/http";
import { CouponEntity } from "../entity/coupon.entity";
import { MeituanCouponEntity } from "../entity/meituan.coupon.entity ";
import { MeituanCouponParam } from "../param/meituan.coupon.param ";

class CouponService {
	changeCouponStatus = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon/status/" + id,
			method: "PUT",
		});
	};
	noAuth = async (params: CouponParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + `/coupon/noAuth`,
			params,
		});
	};
	list = async (param: CouponParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon",
			method: "GET",
			params: param,
		});
	};
	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/coupon/${id}`,
			method: "GET",
		});
	};
	addOrUpdate = async (data: CouponEntity) => {
		if (data.id) {
			return http({
				url: `${process.env.REACT_APP_ADMIN_API}/coupon/${data.id}`,
				method: "PUT",
				data,
			});
		} else {
			return http({
				url: process.env.REACT_APP_ADMIN_API + "/coupon",
				method: "POST",
				data,
			});
		}
	};
	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon/" + id,
			method: "DELETE",
		});
	};

	createAppletQRCode = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon/createAppletQRCode/" + id,
			method: "GET",
			responseType: "blob",
		});
	};

	createDynamicAppletQRCode = async (data: string | undefined) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon/createDynamicAppletQRCode",
			method: "POST",
			params: { code: data },
			responseType: "blob",
		});
	};

	batchCreateAppletQRCode = async (params: number | undefined) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon/batchCreateAppletQRCode",
			method: "POST",
			params: { parentCouponId: params },
		});
	};

	batchDownLordAppletQRCode = async (id: number | undefined) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon/batchDownloadAppletQRCode/" + id,
			method: "GET",
			responseType: "blob",
		});
	};

	batchCreateSubCoupons = async (params: CouponEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon/batch",
			method: "POST",
			params,
		});
	};

	export = async (param: CouponParam) => {
		return http({
			method: "GET",
			url: `${process.env.REACT_APP_ADMIN_API}/coupon/export`,
			responseType: "blob",
			params: param,
		});
	};

	meituanAddOrUpdate = async (data: MeituanCouponEntity) => {
		if (data.id) {
			return http({
				url: `${process.env.REACT_APP_ADMIN_API}/meituan-deal-coupon/${data.id}`,
				method: "PUT",
				data,
			});
		} else {
			return http({
				url: process.env.REACT_APP_ADMIN_API + "/meituan-deal-coupon",
				method: "POST",
				data,
			});
		}
	};

	meiTuanList = async (param: MeituanCouponParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/meituan-deal-coupon",
			method: "GET",
			params: param,
		});
	};

	syncMeituan = async (uuid: string) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/meituan/sync/" + uuid,
			method: "POST",
			data: uuid,
		});
	};

	newSyncMeituan = () => {
		return http({
			url: process.env.REACT_APP_GO_API + "/api/v1/admin/meituan/products",
			method: "GET",
		});
	};
	shopAuthorization = () => {
		return http({
			url: process.env.REACT_APP_GO_API + "/api/v1/admin/meituan/BatchAuthorizePush",
			method: "GET",
		});
	};
	singleShopAuthorization = () => {
		return http({
			url: process.env.REACT_APP_GO_API + "/api/v1/admin/meituan/auth-url",
			method: "GET",
		});
	};

	changeExpirationTime = async (id: number, expirationTime: string) => {
		return http({
			url: process.env.REACT_APP_GO_API + "/api/v1/admin/coupon/expiration/" + id,
			method: "PUT",
			data: { expirationTime },
		});
	};
}
export default new CouponService();
