import React, { FC, useEffect, useState } from "react";

import EChartsReact from "echarts-for-react";

interface Props {
    title: string,

}

const BarRank: FC<Props> = ({ title }) => {
    const [data, setData] = useState<number[]>([]);

    useEffect(() => {
        if (data.length === 0) {
            init()
        }
    })

    const init = () => {
        const data: number[] = [];
        for (let i = 0; i < 10; ++i) {
            data.push(Math.round(Math.random() * 200));
        }
        setData(data)
    }

    const getOption = () => {
        return {
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: ['00023-TC-BGCNPC', '00001-TC-TianJin', '00001-TC-TianJin', 'D00001-TC-TianJin', '00001-TC-TianJin', '00001-TC-TianJin', '00001-TC-TianJin', '00001-TC-TianJin', '00001-TC-TianJin', '00001-TC-TianJin'],
                inverse: true,
                animationDuration: 300,
                animationDurationUpdate: 300,
                max: 9
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            series: [{
                realtimeSort: true,
                name: title,
                type: 'bar',
                data: data,
                label: {
                    show: true,
                    position: 'right',
                    valueAnimation: true
                }
            }],
            legend: {
                show: true
            },
            animationDuration: 0,
            animationDurationUpdate: 3000,
            animationEasing: 'linear',
            animationEasingUpdate: 'linear'
        }
    }

    return (
        <EChartsReact option={getOption()} theme={"dark"} style={{ height: '100%' }} />
    )
}
export default BarRank;