import { http } from "../../common/http";
import { LoginActivityParam } from "./login.activity.params";

class LoginActivityService {
	list = async (param: LoginActivityParam) => {
		return http({ method: "GET", url: process.env.REACT_APP_ADMIN_API + `/loginActivityRecord`, params: param });
	};

    exportLoginActivity = async(param:LoginActivityParam)=>{
        return http({
			url: process.env.REACT_APP_ADMIN_API + "/loginActivityRecord/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};

}
export default new LoginActivityService();
