import { http } from "../../../common/http";
import { AdminParams } from "../params/admin.params";
import { AdminEntity } from "../entity/admin.entity";

class AdminService {
	typeArr: Record<number, string> = {
		1: "admin.type.express",
		2: "admin.type.beauty",
		3: "admin.type.headquarters",
		9: "admin.type.super",
	};

	async login(data: object) {
		return http({
			method: "POST",
			url: process.env.REACT_APP_ADMIN_API + "/login",
			data: data,
		});
	}

	list = async (params: AdminParams = {}) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/admin",
			method: "GET",
			params,
		});
	};
	allList = async (params: AdminParams = {}) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/admin/all",
			method: "GET",
			params,
		});
	};
	noAuth = async (params: AdminParams = {}) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/admin/noAuth",
			method: "GET",
			params,
		});
	};
	update = async (params: AdminEntity = {}) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/admin/" + params.id,
			method: "PUT",
			data: params,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/admin/" + id,
			method: "DELETE",
		});
	};

	updatePassWord = async (params: any) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/admin/password/" + params.id,
			method: "PUT",
			data: params,
		});
	};

	resetPassWord = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/admin/password-reset/" +id,
			method: "PUT",
			data: id,
		});
	};

	updateWorkStatus = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/admin/admin/" + id + "/work_status",
			method: "PUT",
		});
	};

	add = async (params: AdminEntity = {}) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/admin",
			method: "POST",
			data: params,
		});
	};

	async currentDetail() {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + "/getRouters",
		});
	}

	detail = async (id: number) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + "/admin/" + id,
		});
	};

	all = async () => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + "/admin",
		});
	};

	logOut() {
		localStorage.removeItem("adminInfo");
		localStorage.removeItem("token");
		window.location.href = "/login";
	}

	current = async () => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + "/admin/current ",
		});
	};
}

export default new AdminService();

export const currentInfo = (): AdminEntity => {
	const adminInfo = localStorage.getItem("adminInfo") ?? "";
	if (!adminInfo) {
		window.location.href = "/login";
		return { name: "" };
	}
	return JSON.parse(adminInfo);
};
