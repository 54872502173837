import { http } from "../../../common/http";
import { CompanyEntity } from "../entity/company.entity";

class CompanyService {
	all = async () => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + `/company/all`,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/company/` + id,
			method: "DELETE",
		});
	};

	update = async (data: CompanyEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/company/${data.id}`,
			method: "PUT",
			data,
		});
	};
}
export default new CompanyService();
