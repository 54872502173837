import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { InputNumber, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
import { CouponEntity } from "../entity/coupon.entity";
import CouponService from "../service/coupon.service";
import CouponTypeService from "../service/coupon.type.service";
import { SelectCouponType } from "./SelectCouponType";

const { Option } = Select;

interface CouponPropEntity extends Props {
	IsQuantity?: boolean;

	//组件中有数量的时候（会员卡配置中增加子卡数量）
	withIsQuantityValue?: CouponEntity[];
}

export const MultiSelectCouponByType = (props: CouponPropEntity) => {
	const [typeInfo, setTypeInfo] = useState<Record<string, any>>();
	const [couponId, setCouponId] = useState<string>();
	const [couponArr, setCouponArr] = useState<CouponEntity[]>([]);
	const [couponTitle, setCouponTitle] = useState<string>("");
	const [itemArr, setItemArr] = useState<CouponEntity[] | undefined>([]);
	const [idArr, setIdArr] = useState<(number | undefined)[]>([]);
	const [couponQuantity, setCouponQuantity] = useState<number>(1);
	const { t } = useTranslation();

	const choosedCouponList = useCallback(async () => {
		if (props.value && !props.IsQuantity) {
			const { data } = await CouponService.noAuth({ couponIds: props.value });
			setItemArr(data.data.list);
			console.log("优惠券", data.data.list);
		}
		if (props.IsQuantity) {
			setItemArr(props.withIsQuantityValue);
			console.log("sss", props.withIsQuantityValue);
		}
	}, [props.IsQuantity, props.value, props.withIsQuantityValue]);

	useEffect(() => {
		choosedCouponList();
	}, [choosedCouponList]);

	const couponList = useCallback(async () => {
		if (!typeInfo) {
			return;
		}
		const { data } = await CouponService.noAuth({ title: couponTitle, couponType: typeInfo.id, type: 1, isDisplay: 1 });
		setCouponArr(data.data.list);
	}, [typeInfo, couponTitle]);

	useEffect(() => {
		couponList();
	}, [couponList]);

	props.entityChange;

	const addItem = () => {
		const arr: CouponEntity[] = itemArr ? itemArr : [];
		console.log("开始的", arr);
		const couponInfo = couponId ? JSON.parse(couponId) : { id: "", title: "", quantity: couponQuantity };
		// const couponInfo = couponId ? JSON.parse(couponId) : { couponId: "", title: "", quantity: couponQuantity };

		// 去重，防止一样的优惠券被添加
		const newArr = itemArr?.map((item) => {
			return props.IsQuantity?Number(item.couponId): Number(item.id);
		});
		console.log(newArr);
		if (newArr && newArr!.indexOf(Number(couponInfo.id)) > -1) {
			return;
		}
		//
		couponArr.forEach((item: CouponEntity) => {
			if (item.id === couponInfo.id) {
				arr.push({ ...item, quantity: couponQuantity });
			}
		});
		setItemArr(arr);
		console.log("最后的", arr);

		// Get all ids
		const idsArr = arr.map((row) => {
			return row.id;
		});
		setIdArr(idsArr);
		console.log("333", idsArr, arr);

		//组件中有数量的时候（会员卡配置中增加子卡数量）
		const intArr = arr?.map((item) => {
			return { couponId: item.id, ...item, id: undefined };
		});

		// props.onChange && props.onChange(idsArr.join(","));
		props.onChange && props.onChange(idsArr.join(","));
		props.IsQuantity && props.entityChange && props.entityChange(intArr);
	};

	const remove = (index: number) => {
		const arr: CouponEntity[] = itemArr ? itemArr.concat() : [];
		arr.splice(index, 1);
		setItemArr(arr);

		// Get all ids
		const idsArr = arr.map((row) => {
			return row.id;
		});
		setIdArr(idsArr);
		props.onChange && props.onChange(idsArr.join(","));
		props.IsQuantity && props.entityChange && props.entityChange(arr);
	};

	const handleTypeChange = (e: Record<string, any>) => {
		setTypeInfo(e);
		setCouponId(undefined);
	};

	return (
		<Container>
			<div className={"select"}>
				<div className={"left"}>
					<SelectCouponType value={typeInfo?.id} entityChange={(e: Record<string, any>) => handleTypeChange(e)} />
				</div>
				&nbsp;&nbsp;
				<div className={"right"}>
					<Select value={couponId} placeholder={t("chooseCoupon")} allowClear onChange={setCouponId} showSearch onSearch={setCouponTitle}>
						{couponArr &&
							couponArr.map((item: CouponEntity) => {
								return (
									<Option
										key={"coupon" + item.id}
										value={JSON.stringify({
											id: item.id,
											title: item.title,
										})}
										style={{ color: idArr.indexOf(item.id) > -1 && "#1f6eb9" }}
									>
										{item.title}
									</Option>
								);
							})}
					</Select>
				</div>
				&nbsp;&nbsp;&nbsp;&nbsp;
				{props.IsQuantity && (
					<div className={"icon"}>
						<InputNumber
							defaultValue={couponQuantity}
							min={1}
							style={{ width: "100%" }}
							placeholder={t("enterQuantity")}
							onChange={(e) => {
								setCouponQuantity(e || 0);
							}}
						/>
					</div>
				)}
				&nbsp;&nbsp;&nbsp;&nbsp;
				<div className={"icon"}>
					<PlusOutlined style={{ color: "#096dd9" }} onClick={addItem} />
				</div>
			</div>
			<div>
				{itemArr &&
					itemArr?.map((item, index) => {
						return (
							<div key={item.id}>
								{props.IsQuantity &&
									item.couponType &&
									t(CouponTypeService.typeArray[Number(item.couponType)]) + "-" + item.title + "-" + Number(item.quantity) + "-" + "张"}
								{!props.IsQuantity && item.couponType && t(CouponTypeService.typeArray[item.couponType]) + "-" + item.title}
								<MinusOutlined style={{ color: "#f5222d" }} onClick={() => remove(index)} />
								{/* {!props.IsQuantity && item.couponType && t(CouponTypeService.typeArray[item.couponType])} - {item.title} */}
							</div>
						);
					})}
			</div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;

	.select {
		display: flex;
		justify-content: left;

		.left {
			width: 140px;
		}

		.right {
			width: 300px;
		}

		.icon {
			line-height: 32px;
			font-size: 18px;
			cursor: pointer;
		}
	}
`;
