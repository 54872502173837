import { FC, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Modal, Table, message } from "antd";
import { useNavigate } from "react-router-dom";
import couponService from "src/module/coupon/service/coupon.service";
import { MeituanCouponEntity, MeituanDealCardEntity, MeituanDealCouponEntity } from "src/module/coupon/entity/meituan.coupon.entity ";
import couponTypeService from "src/module/coupon/service/coupon.type.service";
import { SelectUUid } from "src/module/coupon/component/SelectUUid";
import { checkPermission } from "src/module/admin/service/permission.service";
import membershipCardService from "src/module/membershipCard/service/membership.card.service";
import { PaginationEntity } from "src/common/entity/pagination.entity";

export const ThirdCoupon: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [couponList, setcouponList] = useState<MeituanCouponEntity[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
		customPageSize: 15,
		customPageNum: 1,
		total: 0,
	});
	const [total, setTotal] = useState<number>(0);
	const init = useCallback(async () => {
		const { data } = await couponService.meiTuanList({ ...paginationObj });
		setcouponList(data.data.list);
		setLoading(false);
		setTotal(data.data.total);
	}, [paginationObj]);

	useEffect(() => {
		init();
	}, [init]);

	// const [visible, setVisible] = useState(false);
	// const [uuidNumber, setUuidNumber] = useState("");

	const handleOk = async () => {
		setLoading(true);
		const { data } = await couponService.newSyncMeituan();
		if (data.code === 200) {
			message.success(data.msg);
			// setVisible(false);
			init();
		}
	};

	const shopAuthorization = async () => {
		setLoading(true);
		const { data } = await couponService.shopAuthorization();
		if (data.code === 200) {
			message.success(data.msg);
			// setVisible(false);
			init();
		}
	};

	const singleShopAuthorization = async () => {
		setLoading(true);
		const { data } = await couponService.singleShopAuthorization();
		if (data.code === 200) {
			// message.success(data.msg);
			// setVisible(false);
			init();
			window.open(data.data, "_blank");
		}
	};

	const columns = [
		{
			title: t("dealGroupId"),
			dataIndex: "dealGroupId",
		},
		{
			title: t("comboId"),
			dataIndex: "dealId",
		},
		{
			title: t("shopName"),
			dataIndex: "shopName",
			width: "200px",
		},

		{
			title: t("packageName"),
			dataIndex: "title",
		},

		{
			title: t("packagePrice"),
			dataIndex: "price",
		},

		{
			title: t("groupCouponServiceTime"),

			render: (record: MeituanCouponEntity) => {
				return (
					<div>
						<div style={{ width: "80%", textAlign: "left" }}>{record.receiptBeginDate}</div>
						<div style={{ width: "80%", textAlign: "left" }}>~</div>
						<div style={{ width: "80%", textAlign: "left" }}>{record.receiptEndDate}</div>
					</div>
				);
			},
		},

		{
			title: t("relatedCouponInfo"),
			width: "200px",
			render: (record: MeituanCouponEntity) => {
				return record.meituanDealCouponList?.map((item: MeituanDealCouponEntity, index) => {
					return (
						<div key={index}>
							{index + 1 + "." + t(couponTypeService.typeArray[item.couponType!]) + "-" + item.couponName + "-" + item.couponNum + "张"}
						</div>
					);
				});
			},
		},

		{
			title: t("relatedCardInfo"),
			width: "200px",
			render: (record: MeituanCouponEntity) => {
				return record.meituanDealCardList?.map((item: MeituanDealCardEntity, index) => {
					return (
						<div key={index}>
							{index + 1 + "." + t(membershipCardService.cardType[item.cardType!]) + "-" + item.cardName + "-" + item.cardNum + "张"}
						</div>
					);
				});
			},
		},

		{
			title: t("action"),
			render: (record: MeituanCouponEntity) => {
				return (
					<div>
						<Button
							type='primary'
							style={{ marginRight: "20px" }}
							onClick={() => {
								navigate(`/coupon/third-coupon-add/${record.id}`);
							}}
						>
							{t("modify")}
						</Button>
					</div>
				);
			},
		},
	];

	return (
		<Container>
			{loading && (
				<div className='loadingContainer'>
					<div className='spinner'>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			)}
			{checkPermission(51501) && (
				<>
					<Button className='btn' type={"primary"} onClick={handleOk}>
						{t("syncMeituan")}
					</Button>
					<Button className='btn' type={"primary"} onClick={shopAuthorization}>
						门店批量授权
					</Button>
					<Button className='btn' type={"primary"} onClick={singleShopAuthorization}>
						门店授权
					</Button>
				</>
			)}

			<Table
				columns={columns}
				dataSource={couponList}
				rowKey={(record) => record.id + ""}
				pagination={{
					pageSize: paginationObj.customPageSize,
					current: paginationObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setPaginationObj({
						...paginationObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>

			{/* <Modal
				title='请选择门店'
				visible={visible}
				onOk={handleOk}
				onCancel={() => {
					setVisible(false);
				}}
			>
				<SelectUUid
					onChange={(e: any) => {
						setUuidNumber(e);
					}}
				/>
			</Modal> */}
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	position: relative;
	.loadingContainer {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		position: absolute;
		left: 0;
		top: 0;
		justify-content: center;
		z-index: 999;
		background: rgba(0, 0, 0, 0.8);
		cursor: wait;

		.spinner {
			--clr: rgb(0, 113, 128);
			--gap: 6px;
			/* gap between each circle */
			width: 100px;
			height: 100px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: var(--gap);
		}

		.spinner span {
			width: 20px;
			height: 20px;
			border-radius: 100%;
			background-color: var(--clr);
			opacity: 0;
		}

		.spinner span:nth-child(1) {
			animation: fade 1s ease-in-out infinite;
		}

		.spinner span:nth-child(2) {
			animation: fade 1s ease-in-out 0.33s infinite;
		}

		.spinner span:nth-child(3) {
			animation: fade 1s ease-in-out 0.66s infinite;
		}

		@keyframes fade {
			0%,
			100% {
				opacity: 1;
			}

			60% {
				opacity: 0;
			}
		}
	}
	.btn {
		margin: 20px;
	}
`;
