

export const payWayArr: Record<number, string> = {
	0: "unknown",
	10: "balance",
	30: "vipCard",
	50: "freeCoupon",
	54: "priceOffCoupon",
	56: "discountCoupon",
	60: "fixedPriceCoupon",
	70: "AliPay",
	80: "wechatPay",
	90: "Cash",
	100: "cooperator",
	110: "offlineWechat",
	120: "offlineAliPay",
	130: "thirdPartener",
	140: "partner",
	150:"pointsRedemption",
	160: "meiTuan",
	170:"douYin",
	180: "CCB",
	999: "VIP",
};

export const payWayColorArr: Record<number, string> = {
	0: "#4762FE",
	10: "#FEAC00",
	30: "#FF8B04",
	50: "#4762FE",
	54: "#4762FE",
	56: "#4762FE",
	60: "#4762FE",
	70: "#1677FE",
	80: "#07C160",
	90: "#4762FE",
	100: "#4762FE",
	110: "#4762FE",
	120: "#4762FE",
	130: "#1FA3F2",
	150:"#4762FE",
	160: "#4762FE",
	170:"#4762FE",
	180: "#4762FE",
	999: "#4762FE",
};

export const PayWayTagArr: Record<number, string> = {
	0: "unknown",
	10: "balance",
	30: "vipCard",
	50: "freeCoupon",
	54: "priceOffCoupon",
	// 56: "discountCoupon",
	// 60: "fixedPriceCoupon",
	70: "AliPay",
	80: "wechatPay",
	90: "Cash",
	100: "cooperator",
	110: "offlineWechat",
	120: "offlineAliPay",
	130: "thirdPartener",
	140: "partner",
	150:"pointsRedemption",
	160: "meiTuan",
	170:"douYin",
	180: "CCB",
	999: "VIP",
}
export const PayWayTagColorArr: Record<number, string> = {
	0: "#4762FE",
	10: "#FEAC00",
	30: "#FF8B04",
	50: "#4762FE",
	54: "#4762FE",
	56: "#4762FE",
	60: "#4762FE",
	70: "#1677FE",
	80: "#07C160",
	90: "#4762FE",
	100: "#4762FE",
	110: "#4762FE",
	120: "#4762FE",
	130: "#1FA3F2",
	150:"#4762FE",
	999: "#4762FE",
}



export const cardBuyPayWayArr: Record<number, string> = {
	0: "unknown",
	10: "balance",
	30: "vipCard",
	50: "freeCoupon",
	54: "priceOffCoupon",
	56: "discountCoupon",
	60: "fixedPriceCoupon",
	70: "AliPay",
	80: "wechatPay",
	90: "Cash",
	100: "cooperator",
	110: "offlineWechat",
	120: "offlineAliPay",
	130: "thirdPartener",
	140: "partner",
	150:"pointsRedemption",
	160: "meiTuan",
	170:"douYin",
	180:"CCB",
	999: "VIP",
};
