import { FC, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Form, Input, Modal, Select, Space, Table, message } from "antd";
import moment from "moment";
// import { PaginationEntity } from "src/common/entity/pagination.entity";
import { useNavigate } from "react-router-dom";
import { UpdateProductEntity } from "src/module/updateProduct/updateProductEntity";
import updateProductService from "src/module/updateProduct/updateProductService";
import { UpdateProductParam } from "src/module/updateProduct/updateProductParam";
import { checkPermission } from "src/module/admin/service/permission.service";
const { confirm } = Modal;
export const UpdateProduct: FC = () => {
	const { Option } = Select;
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const [dataList, setDataList] = useState<UpdateProductEntity[]>([]);
	const [total, setTotal] = useState(0);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [searchObj, setSearchObj] = useState<UpdateProductParam>({ customPageSize: 15, customPageNum: 1 });

	const init = useCallback(async () => {
		const { data } = await updateProductService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const onSearch = async (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			date: undefined,
			startTime: value.date && value.date[0] && value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.date && value.date[1] && value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageSize: 15,
			customPageNum: 1,
		});
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await updateProductService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const columns = [
		{
			title: t("beforeUpdateProduct"),
			render: (record: UpdateProductEntity) => {
				return record.productRelationDetails
					?.filter((item: any) => {
						return item.type === 0;
					})
					?.map((oldProductItem: any) => {
						return oldProductItem.skus?.map((skusItem: any, index: number) => {
							return <div key={index}>{`*${oldProductItem.productName}~${skusItem.title}`}</div>;
						});
					});
			},
		},
		{
			title: t("UpdateProduct"),
			render: (record: UpdateProductEntity) => {
				return record.productRelationDetails
					?.filter((item: any) => {
						return item.type === 1;
					})
					?.map((oldProductItem: any) => {
						return oldProductItem.skus?.map((skusItem: any, index: number) => {
							return <div key={index}>{`*${oldProductItem.productName}~${skusItem.title}`}</div>;
						});
					});
			},
		},
		{
			title: t("site.name"),
			render: (record: UpdateProductEntity) => {
				return <div>{record.productRelationDetails && record.productRelationDetails[0].shopName}</div>;
			},
		},
		{
			title: t("availablePeriod"),
			render: (record: UpdateProductEntity) => {
				return (
					<div>
						{record.startTime}~{record.endTime}
					</div>
				);
			},
		},
		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 0 ? t("onSale") : t("off");
			},
		},
		{
			title: t("action"),
			render: (record: UpdateProductEntity) => {
				return (
					<Space>
						{checkPermission(31103) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(Number(record.id));
								}}
							>
								{t("delete")}
							</Button>
						)}

						{checkPermission(31102) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/updateProduct/${record.id}`);
								}}
							>
								{t("edit")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onSearch} form={form} initialValues={{ date: [moment(), moment()] }}>
				<Form.Item label={t("upgradeProductName")} name='upgradeProductName'>
					<Input allowClear placeholder={t("upgradeProductName")} />
				</Form.Item>
				<Form.Item label={t("status")} name='status'>
					<Select style={{ width: "100px" }} placeholder={t("status")} allowClear>
						<Option value={0}>{t("onSale")}</Option>
						<Option value={1}>{t("off")}</Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("site.name")} name='shopName'>
					<Input allowClear placeholder={t("site.name")} />
				</Form.Item>

				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						{checkPermission(31102) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/updateProduct/add`);
								}}
							>
								{t("add")}
							</Button>
						)}
					</Space>
				</Form.Item>
			</Form>
			<Table
				style={{ marginTop: "30px" }}
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => Number(record.id)}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
