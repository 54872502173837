import styled from "@emotion/styled";
import { DatePicker, message } from "antd";
import { AxiosResponse } from "axios";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Util, { dateFormat } from "../../common/Util";
import { ReportParam } from "../../module/finance/entity/report.param";
import FinanceDataService from "../../module/finance/service/finance.data.service";
import { SelectSite } from "../../module/site/component/select.site";

export const ExpressSiteReport:FC = () => {
    const [param, setParam] = useState<ReportParam>({
        startTime: moment().year()+'-'+(moment().month()+1)+'-01 00:00:00',
        endTime: moment().format(dateFormat)+' 23:59:59',
    });
    const {t} = useTranslation();

    const download = async () => {
        const res:AxiosResponse<BlobPart> = await FinanceDataService.expressSiteReport(param);
        const blob = new Blob([res.data]);
        const blobUrl = window.URL.createObjectURL(blob);
        Util.download(blobUrl, 'express-site-report');
        message.success(t('export.success'));
    }

    const dateChange = (e: any) => {
        setParam({
            ...param,
            startTime: e[0].format(dateFormat)+' 00:00:00',
            endTime: e[1].format(dateFormat)+' 23:59:59',
        });
    }

    const siteChange = (e:number) => {
        setParam({
            ...param,
            shopId: e
        })
    }
    return (
        <Container>
            <div className={'search'}>
                <DatePicker.RangePicker defaultValue={[moment(param.startTime, dateFormat), moment(param.endTime, dateFormat)]} onChange={dateChange}/>
                <SelectSite onChange={siteChange}/>
                <button onClick={download}>{t("export")}</button>
            </div>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
	padding: 10px;

  .search {
    display: flex;
    justify-content: left;
  }
`