import styled from "@emotion/styled";
import { Select } from "antd";
import { t } from "i18next";
import { Props } from "../../../common/entity/props.entity";

export const SelectMemberShipPayStatus = (props: Props) => {
	return (
		<Container>
			<Select
			style={{width:"100%"}}
				placeholder={t("orderStatus")}
				onChange={(e: number) => {
					props.onChange && props.onChange(e);
				}}
				
				options={[
					{ value: 1, label: "待支付" },
					{ value: 5, label: "支付成功" },
					{ value: 15, label: "退款中" },
					{ value: 17, label: "退款成功" },
					
				]}
				allowClear
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
