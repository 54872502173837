import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, message, Modal, Popover, Row, Space, Table } from "antd";
import { AxiosResponse } from "axios";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkPermission } from "src/module/admin/service/permission.service";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { ResponseEntity } from "../../common/entity/response.entity";
import Util from "../../common/Util";
import { CustomerMembershipCardEntity } from "../../module/membershipCard/entity/customer.membership.card.entity";
import { CustomerMembershipCardParam } from "../../module/membershipCard/params/customer.membership.card.param";
import customerMembershipCardService from "../../module/membershipCard/service/customer.membership.card.service";
import CustomerMonthCardService from "../../module/membershipCard/service/customer.membership.card.service";
import MembershipCardService from "../../module/membershipCard/service/membership.card.service";
import { SelectSite } from "../../module/site/component/select.site";

const { confirm } = Modal;
const { RangePicker } = DatePicker;

export const CustomerMembershipCard: FC = () => {
	const { t } = useTranslation();
	const [searchArr, setSearchArr] = useState<CustomerMembershipCardParam>({
		startTime: moment().format("YYYY-MM-DD"),
		endTime: moment().format("YYYY-MM-DD"),
	});
	const [searchObj, setSearchObj] = useState<CustomerMembershipCardParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});
	const [cardList, setCardList] = useState([] as CustomerMembershipCardEntity[]);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// 	total: 0,
	// });
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState(true);

	const [visible, setVisible] = useState(false);

	const [info, setInfo] = useState<CustomerMembershipCardEntity>({});
	const [modalInfo, setModalInfo] = useState<CustomerMembershipCardEntity>({});

	const changeAttr = (type: string, e: any) => {
		setSearchArr({
			...searchArr,
			[type]: e,
		});
	};
	const onDateChange = (date: any, dateString: any) => {
		setSearchArr({
			...searchArr,
			startTime: dateString[0],
			endTime: dateString[1],
		});
	};
	const download = async () => {
		const res: AxiosResponse<BlobPart> = await CustomerMonthCardService.exportMonthlyCard({
			...searchArr,
			startTime: searchArr.startTime + " 00:00:00",
			endTime: searchArr.endTime + " 23:59:59",
		});
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "user-month-cards");
		message.success(t("export.success"));
	};
	const siteChange = (e: number) => {
		setSearchArr({
			...searchArr,
			shopId: e,
		});
	};
	const search = () => {
		setSearchObj({
			...searchArr,
			startTime: searchArr.startTime + " 00:00:00",
			endTime: searchArr.endTime + " 23:59:59",
			customPageSize: 15,
		customPageNum: 1,
		});
	};
	const init = useCallback(async () => {
		setLoading(true);
		const { data } = await CustomerMonthCardService.list({
			...searchObj,
			
		});
		if (data.code === 200) {
			setCardList(data.data);
			setTotal(data.total);
		}
		setLoading(false);
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const deleteCard = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data }: AxiosResponse<ResponseEntity> = await CustomerMonthCardService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const handleEdit = (data: CustomerMembershipCardEntity) => {
		setVisible(true);
		setInfo(data);
		setModalInfo({ ...info, id: data.id });
	};
	const handleCancel = () => {
		setVisible(false);
	};
	const handleOk = () => {
		customerMembershipCardService.updateExpiredTime(modalInfo).then((res) => {
			message.success(res.data.msg);
			setVisible(false);
			init();
		});
	};
	const columns = [
		{
			width: 180,
			title: t("productName"),
			dataIndex: "productName",
			render: (text: string, record: CustomerMembershipCardEntity) => {
				return (
					<Popover content={record.shopName}>
						<div>{text}</div>
					</Popover>
				);
			},
		},
		{
			width: 130,
			title: t("customer-info"),
			dataIndex: "nickName",
			render: (text: string, record: CustomerMembershipCardEntity) => {
				return (
					<div>
						{text}
						<br />
						{record.mobile}
						<br />
						{record.licenseNumber}
					</div>
				);
			},
		},
		{
			title: t("cardType"),
			dataIndex: "cardType",
			render: (text: number) => {
				return <div>{t(MembershipCardService.cardType[text])}</div>;
			},
		},
		{
			title: t("createdTime"),
			dataIndex: "createdTime",
		},
		{
			title: t("expirationDate"),
			dataIndex: "endTime",
		},
		{
			title: t("residueTimes"),
			dataIndex: "times",
		},
		{
			title: t("action"),
			render: (text: string, record: CustomerMembershipCardEntity) => {
				return (
					<Space>
						{checkPermission(8) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									deleteCard(Number(record.id));
								}}
							>
								{t("delete")}
							</Button>
						)}

						{checkPermission(7) && (
							<Button
								type='primary'
								onClick={() => {
									handleEdit(record);
								}}
							>
								{t("modifyExpiredTime")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];
	return (
		<Container>
			<div className='headers'>
				<Row gutter={20}>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("productName")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("productName", e.target.value);
								}}
								placeholder={t("productName")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("mobile")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("mobile", e.target.value);
								}}
								placeholder={t("mobile")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("nickName")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("nickName", e.target.value);
								}}
								placeholder={t("nickName")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("licenseNumber")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("licenseNumber", e.target.value);
								}}
								placeholder={t("licenseNumber")}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("period")}：</div>
							<RangePicker
								defaultValue={[
									moment(searchArr.startTime && searchArr.startTime.toString()),
									moment(searchArr.endTime && searchArr.endTime.toString()),
								]}
								showTime={false}
								onChange={onDateChange}
							/>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("site")}：</div>
							<SelectSite onChange={siteChange} />
						</div>
					</Col>
					


				
					<Col span={8}>
						<Button type={"primary"} onClick={search}>
							{t("search")}
						</Button>
						{checkPermission(259) && (
							<Button type={"primary"} style={{ marginLeft: "10px" }} onClick={download}>
								{t("export")}
							</Button>
						)}
					</Col>
				</Row>
			</div>
			<Table
				columns={columns}
				loading={loading}
				dataSource={cardList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setSearchObj({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
					// setPaginationObj({
					// 	...paginationObj,
					// 	customPageNum: pagination.current ?? 1,
					// 	customPageSize: pagination.pageSize ?? 15,
					// });
				}}
			/>
			<Modal destroyOnClose={true} width={"20%"} title={t("modifyExpiredTime")} visible={visible} onOk={handleOk} onCancel={handleCancel}>
				<Form>
					<Form.Item label={t("expirationDate")}>
						<DatePicker
							defaultValue={moment(info.endTime, "YYYY-MM-DD")}
							onChange={(date: any, dateString: any) => {
								setModalInfo({ ...info, endTime: dateString + " 23:59:59" });
								console.log(dateString + " 23:59:59");
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;

	.headers {
		width: 100%;

		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			.searchItem {
				min-width: 150px;
				text-align: right;
			}
		}
	}
`;
