import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { InputNumber, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
import { CouponEntity } from "../entity/coupon.entity";
import CouponService from "../service/coupon.service";
// import CouponTypeService from "../service/coupon.type.service";
import { SelectCouponType } from "./SelectCouponType";
import couponTypeService from "../service/coupon.type.service";
const { Option } = Select;
interface meituanDealCouponList {
	couponId?: string;
	couponName?: string;
	couponNum?: number;
	couponType?: number;
	id?: number;
}

export const SelectBandCoupon = (props: Props) => {
	const [typeInfo, setTypeInfo] = useState<Record<string, any>>();
	const [couponId, setCouponId] = useState<string>();
	const [couponArr, setCouponArr] = useState<CouponEntity[]>([]);
	const [couponTitle, setCouponTitle] = useState<string>("");
	const [couponName, setCouponName] = useState<string>();

	const [couponNum, setCouponNum] = useState<number>();
	const [itemArr, setItemArr] = useState<meituanDealCouponList[] | undefined>([]);
	const [idArr, setIdArr] = useState<(number | undefined)[]>([]);
	const { t } = useTranslation();

	const choosedCouponList = useCallback(async () => {
		if (props.value) {
			setItemArr(props.value);
			console.log(props.value);
		}
	}, [props.value]);

	useEffect(() => {
		choosedCouponList();
	}, [choosedCouponList]);

	const couponList = useCallback(async () => {
		if (!typeInfo) {
			return;
		}
		const { data } = await CouponService.noAuth({ title: couponTitle, couponType: typeInfo.id, type: 1, isDisplay: 1 });
		setCouponArr(data.data.list);
	}, [typeInfo, couponTitle]);

	useEffect(() => {
		couponList();
	}, [couponList]);

	const addItem = () => {
		const arr: meituanDealCouponList[] = itemArr ? itemArr : [];
		const couponInfo = { couponId: couponId, couponType: Number(typeInfo?.id), couponName: couponName, couponNum: Number(couponNum) };
		arr?.push(couponInfo);
		const newArr = arr.concat();
		setItemArr(newArr);
		props.onChange && props.onChange(newArr);
	};

	const remove = (index: number) => {
		const arr: meituanDealCouponList[] = itemArr ? itemArr.concat() : [];
		arr.splice(index, 1);
		setItemArr(arr);

		// Get all ids
		const idsArr = arr.map((row) => {
			return row.id;
		});
		setIdArr(idsArr);
		props.onChange && props.onChange(arr);
	};

	const handleTypeChange = (e: Record<string, any>) => {
		console.log(e);

		setTypeInfo(e);
		setCouponId(undefined);
	};

	return (
		<Container>
			<div className={"select"}>
				<div className={"left"}>
					<SelectCouponType value={typeInfo?.id} entityChange={(e: Record<string, any>) => handleTypeChange(e)} />
				</div>
				&nbsp;&nbsp;
				<div className={"right"}>
					<Select
						labelInValue
						placeholder={t("chooseCoupon")}
						allowClear
						onChange={(e: { value?: string; label?: string }) => {
							setCouponId(e.value || undefined);
							setCouponName(e.label);
							console.log(e);
						}}
						showSearch
						optionFilterProp={"children"}
						onSearch={(e) => {
							setCouponTitle(e);
						}}
					>
						{couponArr &&
							couponArr.map((item: CouponEntity) => {
								return (
									<Option key={item.id} style={{ color: idArr.indexOf(item.id) > -1 && "#1f6eb9" }}>
										{item.title}
									</Option>
								);
							})}
					</Select>
				</div>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<div className='num'>
					<div>数量:</div>
					&nbsp;&nbsp;
					<div>
						<InputNumber
							onChange={(e) => {
								setCouponNum(Number(e));
							}}
						/>
					</div>
				</div>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<div className={"icon"}>
					<PlusOutlined style={{ color: "#096dd9" }} onClick={addItem} />
				</div>
			</div>
			<div>
				{itemArr?.length!==0 &&
					itemArr?.map((item, index) => {
						return (
							<div key={index}>
								{item.couponType && t(couponTypeService.typeArray[item.couponType])}- {item.couponName} - {item.couponNum}
								<MinusOutlined style={{ color: "#f5222d" }} onClick={() => remove(index)} />
							</div>
						);
					})}
			</div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;

	.select {
		display: flex;
		justify-content: left;

		.left {
			width: 140px;
		}

		.right {
			width: 300px;
		}
		.num {
			min-width: 150px;
			display: flex;
			align-items: center;
		}

		.icon {
			line-height: 32px;
			font-size: 18px;
			cursor: pointer;
		}
	}
`;
