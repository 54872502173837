import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, message, Modal, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { PaginationEntity } from "../../common/entity/pagination.entity";
import { useNavigate, useParams } from "react-router-dom";
import { MaintenanceRecordsParam } from "src/module/maintenanceRecords/maintenanceRecordsParam";
import maintenanceRecordsService from "src/module/maintenanceRecords/maintenanceRecordsService";
import { MaintenanceRecordsEntity } from "src/module/maintenanceRecords/maintenanceRecordsEntity";
const { RangePicker } = DatePicker;

export const MaintenanceRecords: FC = () => {
	const navigate = useNavigate();
	const { confirm } = Modal;
	const params = useParams();
	const { t } = useTranslation();
	const [dataList, setDataList] = useState([]);
	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
		customPageSize: 15,
		customPageNum: 1,
	});
	const [total, setTotal] = useState(0);

	const [searchObj, setSearchObj] = useState<MaintenanceRecordsParam>({
		shopId: Number(params.id),
	});

	const [searchDate, setSearchDate] = useState({
		startTime: "",
		endTime: "",
	});

	const init = useCallback(async () => {
		const { data } = await maintenanceRecordsService.list({ ...searchObj, ...paginationObj });
		if (data.code === 200) {
			setDataList(data.data.records);
			setTotal(data.data.total);
		}

		
	}, [paginationObj, searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await maintenanceRecordsService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};



	const columns = [
		{ title: t("site.name"), dataIndex: "shopTitle" },
		{ title: t("maintenanceTime"), dataIndex: "createdTime" },

		{
			title: t("action"),
			render: (record: MaintenanceRecordsEntity) => {
				return (
					<Space>
						<Button
							type='primary'
							onClick={() => {
								handleDelete(Number(record.id));
							}}
						>
							{t("delete")}
						</Button>
						<Button
							type='primary'
							onClick={() => {
								navigate(
									`/site/maintenance/detail/${record.id}`
								);
							}}
						>
							{t("detail")}
						</Button>
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline'>
				<Form.Item label={t("period")}>
					<RangePicker
						onChange={(time, timeStr) => {
							setSearchDate({ startTime: timeStr[0] + " 00:00:00", endTime: timeStr[1] + " 23:59:59" });
						}}
					/>
				</Form.Item>

				<Form.Item>
					<Button
						type='primary'
						onClick={() => {
							setSearchObj({ ...searchObj, ...searchDate });
						}}
					>
						{t("search")}
					</Button>
				</Form.Item>
				<Form.Item>
					<Button
						type='primary'
						onClick={() => {
							navigate(
								`/site/maintenance/${params.id}/add`
							);
						}}
					>
						{t("add")}
					</Button>
				</Form.Item>
			</Form>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={dataList}
				rowKey={(record: any) => {
					return record.id;
				}}
				pagination={{
					pageSize: paginationObj.customPageSize,
					current: paginationObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setPaginationObj({
						...paginationObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
