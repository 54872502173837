import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, message, Modal, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { SelectSite } from "../../module/site/component/select.site";
import evaluationService from "../../module/evaluation/evaluation.service";
import { EvaluationEntity } from "../../module/evaluation/evaluation.entity";
import { EvaluationParam } from "../../module/evaluation/evaluation.param";
import Util from "../../common/Util";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { checkPermission } from "../../module/admin/service/permission.service";
import { SelectAdminName } from "src/module/admin/components/SelectAdminName";
import moment from "moment";

export const Evaluation: FC = () => {
	const { t, i18n } = useTranslation();
	const [dataList, setDataList] = useState([]);
	const { TextArea } = Input;
	const [isShow, setIsShow] = useState(false);
	const [info, setInfo] = useState<EvaluationEntity>({});

	const [searchObj, setSearchObj] = useState<EvaluationParam>({ customPageNum: 1, customPageSize: 15 });
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({ customPageNum: 1, customPageSize: 15 });
	const [total, setTotal] = useState(0);
	const search = (value: any) => {
		const newValue = !value.duration?.length
			? value
			: { ...value, startTime: value.duration[0]?.format("YYYY-MM-DD HH:mm:ss"), endTime: value.duration[1]?.format("YYYY-MM-DD HH:mm:ss") };
		delete newValue.duration;
		setSearchObj({ ...newValue, customPageNum: 1, customPageSize: 15 });
	};

	const init = useCallback(async () => {
		const { data } = await evaluationService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const update = (record: any) => {
		setIsShow(true);
		setInfo(record);
	};
	const handleCancel = () => {
		setIsShow(false);
	};
	const handleOk = async () => {
		const { data } = await evaluationService.update(info);
		if (data.code === 200) {
			message.success(data.msg);
			init();
		}
		setIsShow(false);
	};

	const downLoad = async () => {
		const { data } = await evaluationService.export(searchObj);
		const blob = new Blob([data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "evaluationList");
		message.success(t("export.success"));
	};

	const columns = [
		{
			title: t("orderNum"),
			dataIndex: "orderNum",
		},
		{
			title: t("site.name"),
			dataIndex: i18n.language === "zh-CN" ? "shopName" : "shopNumber",
		},
		{
			title: t("constructionName"),
			dataIndex: "constructionName",
		},
		{
			title: t("nickName"),
			dataIndex: "nickName",
		},
		{
			title: t("mobile"),
			dataIndex: "mobile",
		},
		{
			title: t("orderRate"),
			dataIndex: "orderRate",
		},
		{
			title: t("rateRemark"),
			dataIndex: "rateRemark",
		},
		{
			title: t("rateScore"),
			dataIndex: "rateScore",
		},
		{
			title: t("rateTime"),
			dataIndex: "rateTime",
		},
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Button
						type='primary'
						onClick={() => {
							update(record);
						}}
					>
						{t("rateRemark")}
					</Button>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' style={{ marginBottom: 20 }} onFinish={search}>
				<Form.Item label={t("orderNum")} name='orderNum'>
					<Input placeholder={t("orderNum")} allowClear></Input>
				</Form.Item>

				<Form.Item label={t("constructionName")} name='constructionName'>
					<SelectAdminName></SelectAdminName>
				</Form.Item>
				<Form.Item label={t("site")} name='shopId'>
					<SelectSite />
				</Form.Item>
				<Form.Item label="评价日期" name='duration'>
					<DatePicker.RangePicker
						showTime={{
							defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
						}}
					/>
				</Form.Item>
				<Form.Item>
					<Space>
						<Button htmlType='submit' type='primary'>
							{t("search")}
						</Button>
						{checkPermission(258) && (
							<Button type='primary' onClick={downLoad}>
								{t("export")}
							</Button>
						)}
					</Space>
				</Form.Item>
			</Form>

			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record: any) => record.id + ""}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({ ...searchObj, customPageNum: pagination.current, customPageSize: pagination.pageSize });
				}}
			/>
			<Modal width={"50%"} title={t("Evaluation Processing Results Remarks")} visible={isShow} onOk={handleOk} onCancel={handleCancel}>
				<TextArea
					value={info.rateRemark}
					rows={4}
					onChange={(e) => {
						setInfo({ ...info, rateRemark: e.target.value });
					}}
				/>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
