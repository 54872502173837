import en_US from "./en-US.json";

const EN_US = {
	...en_US,
	test: "test",
	subscriptionConfig:"SubscriptionConfig",
	enterQuantity:"Quantity",
	basicOption:"BasicOption"
	
};

export type Locale = typeof EN_US;
export default EN_US;
