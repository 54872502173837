import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, message, Modal, Space } from "antd";
import { useTranslation } from "react-i18next";
import fullGiftService from "../../module/fullGift/service/fullGift.service";
import { FullGiftEntity } from "../../module/fullGift/entity/fullGift.entity";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import { PaginationEntity } from "../../common/entity/pagination.entity";
import { checkPermission } from "src/module/admin/service/permission.service";

export const FullGiftList: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [dataList, setDataList] = useState<FullGiftEntity[]>([]);
	const { confirm } = Modal;

	const [total, setTotal] = useState(0);

	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
		customPageSize: 15,
		customPageNum: 1,
	});

	const init = useCallback(async () => {
		const { data } = await fullGiftService.list({});

		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, []);

	useEffect(() => {
		init();
	}, [init]);

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await fullGiftService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	return (
		<Container>
			{checkPermission(293) && (
				<Button
					type='primary'
					onClick={() => {
						navigate("/activity/fullGift/add");
					}}
				>
					{t("addActivity")}
				</Button>
			)}

			{dataList.map((item: FullGiftEntity, index) => {
				return (
					<div className='list' key={index}>
						<div className='left'>
							<h3 style={{ fontWeight: "bold" }}>
								{t("activitySite")}：{item.shopTitles}
							</h3>
							<div>
								{t("satisfyAmount")}：{item.amount}
							</div>
							<div>
								{t("statisticsDays")}：{item.days}
							</div>
							<div>
								{t("availablePeriod")}：{item.start}
								{" -- "}
								{item.end}
							</div>
						</div>
						<div className='mid'>
							<div style={{ fontWeight: "bold" }}>{t("giftCoupons")}：</div>
							<div>
								{item.couponTitles?.split(",").map((data, index) => {
									return (
										<div key={index}>
											{index + 1}、{data}
										</div>
									);
								})}
							</div>
						</div>
						<div className='right'>
							<div>
								<Space>
									{checkPermission(294) && (
										<Button
											type='primary'
											onClick={() => {
												navigate("/activity/fullGift/" + item.id);
											}}
										>
											{t("edit")}
										</Button>
									)}

									<Button
										type='primary'
										danger
										onClick={() => {
											handleDelete(Number(item.id));
										}}
									>
										{t("delete")}
									</Button>
								</Space>
							</div>
						</div>
					</div>
				);
			})}

			<div className='page'>
				<Pagination
					current={paginationObj.customPageNum}
					pageSize={paginationObj.customPageSize}
					total={total}
					showTotal={() => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					}}
					onChange={(page, pageSize) => {
						setPaginationObj({
							customPageSize: pageSize,
							customPageNum: page,
						});
					}}
				/>
			</div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.list {
		display: flex;
		align-items: center;
		padding: 20px;
		border: 2px solid gray;
		border-radius: 20px;
		margin: 10px auto;
		color: gray;

		.left {
			width: 40%;
		}
		.mid {
			width: 40%;
		}
		.right {
			width: 20%;
		}
	}

	.page {
		text-align: right;
	}
`;
