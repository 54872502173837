import { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import AdminService from "../service/admin.service";
import { AllUserEntity } from "../entity/admin.entity";
import { NumberProp } from "../../../common/entity/props.entity";
import { t } from "i18next";

const { Option } = Select;

export const SelectAllUser = (props: NumberProp) => {
	const [dataArr, setDataArr] = useState<AllUserEntity[]>([]);
	// const [name, setName] = useState<string | undefined>(undefined);

	const init = useCallback(async () => {
		const res = await AdminService.noAuth();
		const listFlag = res.data.data;
		let flag = false;
		listFlag.forEach((item: AllUserEntity) => {
			if (item.id === props.value){
				flag = true;
			}
		});
		if (!flag && props.value) {
			const currentAdminInfoRes = await AdminService.detail(props.value);
			listFlag.push(currentAdminInfoRes.data.data);
		}
		setDataArr(res.data.data);
	}, [ props.value]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<>
			<Select
				showSearch
				value={props.value}
				onChange={(item: any) => props.onChange && props.onChange(item ? item.value : undefined)}
				allowClear
				labelInValue
				// filterOption={false}
				// onSearch={(val) =>setName(val)}
				style={{ width: 200 }}
				optionFilterProp={"children"}
				placeholder={t("pleaseSelectAdmin")}
			>
				{dataArr &&
					dataArr.map((item) => {
						return (
							<Option value={item.id ?? 0} key={item.id}>
								{item.name}
							</Option>
						);
					})}
			</Select>
		</>
	);
};
