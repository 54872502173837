import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import userCouponService from "../../module/userCoupon/service/user.coupon.service";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { UserCouponParam } from "../../module/userCoupon/param/user.coupon.param";
import { SelectSite } from "../../module/site/component/select.site";
import moment from "moment";
import { UserCouponEntity } from "../../module/userCoupon/entity/user.coupon.entity";
import Util from "../../common/Util";
import { checkPermission } from "src/module/admin/service/permission.service";
import { SelectRechargeActivity } from "src/module/activity/component/selectRechargeActivity";

export const UserCoupon: FC = () => {
	const { confirm } = Modal;
	const { RangePicker } = DatePicker;
	const { t } = useTranslation();
	const [dataList, setDataList] = useState([]);
	const [total, setTotal] = useState(0);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });

	const [visible, setVisible] = useState(false);
	const [modalInfo, setModalInfo] = useState<UserCouponEntity>({});

	const [info, setInfo] = useState<UserCouponParam>({
		startReceiveTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endReceiveTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	const init = useCallback(async () => {
		const { data } = await userCouponService.list({ ...info});
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [info]);

	useEffect(() => {
		init();
	}, [init]);

	const onFinish = (value: any) => {
		setInfo({
			...value,
			receiveTime: undefined,
			startReceiveTime: value.receiveTime && value.receiveTime[0].format("YYYY-MM-DD") + " 00:00:00",
			endReceiveTime: value.receiveTime && value.receiveTime[1].format("YYYY-MM-DD") + " 23:59:59",
			useTime: undefined,
			startUserTime: value.useTime && value.useTime[0].format("YYYY-MM-DD") + " 00:00:00",
			endUserTime: value.useTime && value.useTime[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageSize: 15,
			customPageNum: 1,
		});
	};

	const handleDelete = (id: any) => {
		confirm({
			title: t("confirmOperation"),
			okType: "danger",
			okText: "Yes",
			cancelText: "No",
			onOk: async () => {
				const { data } = await userCouponService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const downLoard = async () => {
		const { data } = await userCouponService.export(info);
		const blob = new Blob([data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "UserCouponList");
		message.success(t("export.success"));
	};

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const batchDelete = (selectedRowKeys: React.Key[]) => {
		confirm({
			title: t("confirmOperation"),
			okType: "danger",
			okText: "Yes",
			cancelText: "No",
			onOk: async () => {
				const param = selectedRowKeys.join();
				const { data } = await userCouponService.batchDelete({ userCouponIds: param });
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const handleEdit = (data: UserCouponEntity) => {
		setModalInfo(data);
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
	};
	const handleOk = () => {
		userCouponService.changeExpirationTime(modalInfo).then((res) => {
			message.success(res.data.msg);
			setVisible(false);
			init();
		});
	};

	const columns = [
		{ title: t("couponName"), dataIndex: "title" },
		{ title: t("userNickname"), dataIndex: "nickName" },
		{ title: t("userMobile"), dataIndex: "mobile" },
		{ title: t("getSource"), dataIndex: "getSourceStr" },
		{
			title: t("meituanCouponCode"),
			dataIndex: "meituanCouponCode",
		},
		{ title: t("receiveTime"), dataIndex: "receiveTime" },
		{ title: t("useTime"), dataIndex: "useTime" },
		{ title: t("expirationTime"), dataIndex: "expirationTime" },
		{
			title: t("action"),
			render: (record: UserCouponEntity) => {
				return (
					<Space>
						{checkPermission(306) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
						{checkPermission(306) && (
							<Button
								type='primary'
								onClick={() => {
									handleEdit(record);
								}}
							>
								{t("modifyExpiredTime")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onFinish} initialValues={{ receiveTime: [moment(), moment()] }}>
				<Row gutter={30}>
					<Col span={8}>
						<Form.Item label={t("couponName")} name='title'>
							<Input placeholder={t("couponName")} allowClear />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("mobile")} name='mobile'>
							<Input placeholder={t("mobile")} allowClear />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("shopName")} name='shopId'>
							<SelectSite />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("meituanCouponCode")} name='meituanCouponCode'>
							<Input placeholder={t("meituanCouponCode")} allowClear />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label={t("packageName")} name='packageId'>
							<SelectRechargeActivity type={6}/>
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label={t("receiveTime")} name='receiveTime'>
							<RangePicker />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("useTime")} name='useTime'>
							<RangePicker />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item>
							<Space>
								<Button type='primary' htmlType='submit'>
									{t("search")}
								</Button>
								<Button type='primary' onClick={downLoard}>
									{t("export")}
								</Button>
							</Space>
						</Form.Item>
					</Col>

					<Col span={8}>
						{checkPermission(306) && (
							<Button
								type='primary'
								onClick={() => {
									batchDelete(selectedRowKeys);
								}}
							>
								{t("batchDelete")}
							</Button>
						)}
					</Col>
				</Row>
			</Form>
			<Table
				rowSelection={rowSelection}
				style={{ marginTop: 5 }}
				columns={columns}
				dataSource={dataList}
				rowKey={(record: any) => {
					return record.id;
				}}
				pagination={{
					pageSize: info.customPageSize,
					current: info.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setInfo({
						...info,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>

			<Modal destroyOnClose={true} width={"20%"} title={t("modifyExpiredTime")} visible={visible} onOk={handleOk} onCancel={handleCancel}>
				<Form>
					<Form.Item label={t("expirationDate")}>
						<DatePicker
							defaultValue={moment(modalInfo.expirationTime, "YYYY-MM-DD")}
							onChange={(date: any, dateString: any) => {
								setModalInfo({ ...modalInfo, expirationTime: dateString + " 23:59:59" });
								console.log(dateString + " 23:59:59");
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.ant-row {
		margin-bottom: 20px;
	}
`;
