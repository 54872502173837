import { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { PropsEntity } from "../../../../common/entity/props.entity";
import statisticsService from "../../../../module/overview/service/statistics.service";
import { PieComponents } from "../../../../module/overview/components/pie";
import { Space } from "antd";

export const CouponStatistics = (props: PropsEntity) => {
	const [info, setInfo] = useState<Record<string, number>>({});
	const [pieData, setPieData] = useState([
		{
			type: "剩余数量",
			value: 0,
		},
		{
			type: "使用数量",
			value: 0,
		},
		{
			type: "领取数量",
			value: 0,
		},
	]);

	const { t } = useTranslation();

	const init = useCallback(async () => {
		const { data } = await statisticsService.queryCouponStatistics(props.value);
		setInfo(data.data);
		setPieData([
			{
				type: t("remaining"),
				value: data.data.couponRemainingCount,
			},
			{
				type: t("used"),
				value: data.data.couponUseCount,
			},
			{
				type: t("Received"),
				value: data.data.couponSendCount,
			},
		]);
	}, [props.value, t]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<Container>
			<h2>{t("coupon")}</h2>
			<div className='area'>
				<div className='pie'>
					<PieComponents list={pieData} />
				</div>
				<div>
					<div><Space>
						<div className="bgType" style={{backgroundColor: '#28D88A'}} />
						{t("remaining")}
						{info.couponRemainingCount}
					</Space>
					</div>
					<div><Space>
						{t("totalRemaining")}
						{info.totalCouponRemainingCount}
					</Space>
					</div>
				</div>
			</div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;

	h2 {
		padding-left: 10rem;
	}

	.area {
		display: flex;
		justify-content: space-around;

		.pie {
			width: 150rem;
		}
	}

	.bgType {
		width: 13rem;
		height: 13rem;
		background: #2264f6;
		border-radius: 2rem;
		margin-right: 5rem;
	}
`;
