import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import rateGiftService from "../../module/rate.gift/service/rate.gift.service";
import { CouponsEntity, RateGiftEntity } from "../../module/rate.gift/entity/rate.gift.entity";
import { useNavigate } from "react-router-dom";
import { checkPermission } from "src/module/admin/service/permission.service";
import { PaginationEntity } from "src/common/entity/pagination.entity";

export const RateGift: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [total, setTotal] = useState(0);
	const [dataList, setDataList] = useState<RateGiftEntity[]>([]);
	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
		customPageSize: 15,
		customPageNum: 1,
	});

	const init = useCallback(async () => {
		const { data } = await rateGiftService.list(paginationObj);
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [paginationObj]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<Container>
			{checkPermission(282) && (
				<Button
					type='primary'
					onClick={() => {
						navigate("/activity/rate-gift/add");
					}}
				>
					{t("addActivity")}
				</Button>
			)}

			{dataList.map((item) => {
				return (
					<div key={item.id} className='list'>
						<div className='title'>
							<h2>{item.title}</h2>
							<p className='content'>
								<span>{t("couponProduct")}：</span>
								{item.shopName}
								{" => "}
								{item.productName}
							</p>
							<p>
								<span>{t("availablePeriod")}：</span>
								{item.startTime} {" -- "}
								{item.endTime}
							</p>
						</div>

						<div className='coupon'>
							<h3>{t("choosedCoupon")}</h3>
							{item.coupons?.map((data: CouponsEntity, index) => {
								return (
									<ul key={data.id}>
										<li>
											{index + 1} {" . "}
											{data.title}( {data.shopName})
										</li>
									</ul>
								);
							})}
						</div>
						{checkPermission(283) && (
							<div className='edit'>
								<div>
									<Button
										type='primary'
										onClick={() => {
											navigate("/activity/rate-gift/" + item.id);
										}}
									>
										{t("edit")}
									</Button>
								</div>
							</div>
						)}
					</div>
				);
			})}
			<div className='page'>
				<Pagination
					current={paginationObj.customPageNum}
					pageSize={paginationObj.customPageSize}
					total={total}
					showTotal={() => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					}}
					onChange={(page, pageSize) => {
						setPaginationObj({
							customPageSize: pageSize,
							customPageNum: page,
						});
					}}
				/>
			</div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.list {
		border: 2px solid gray;
		margin: 20px 0px 20px 0px;
		border-radius: 20px;
		padding: 20px;
		color: gray;
		display: flex;
		align-items: center;

		.title {
			width: 40%;

			.content {
				margin-bottom: 10px;
			}
		}

		.coupon {
			width: 40%;
		}

		.edit {
			width: 20%;
			text-align: center;
		}
	}

	h2 {
		color: #0202a0;
		font-weight: bold;
	}

	span {
		font-weight: bold;
	}

	li {
		list-style-type: none;
	}
	.page {
		text-align: right;
	}
`;
