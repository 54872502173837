import { FC, useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled";
import { Button, Space, Table, Modal, message, Form, DatePicker, InputNumber } from "antd";
import { t } from "i18next";
import { AxiosResponse } from "axios";
import planService from "src/module/plan/service/plan.service";
import { ResponseEntity } from "src/common/entity/response.entity";
import { PlanEntity } from "src/module/plan/entity/plan.entity";
import moment from "moment";
import { PlanParam } from "src/module/plan/param/plan.param";
// import { PaginationEntity } from "src/common/entity/pagination.entity";
import { checkPermission } from "src/module/admin/service/permission.service";

const { confirm } = Modal;
const { RangePicker } = DatePicker;

export const MonthlyPlan: FC = () => {
	const [dataList, setDataList] = useState([]);
	const [visible, setVisible] = useState(false);
	const [searchInfo, setSerachInfo] = useState<PlanParam>({});
	const [searchObj, setSerachObj] = useState<PlanParam>({ customPageSize: 15, customPageNum: 1 });
	const [info, setInfo] = useState<PlanEntity>({});
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState(0);
	const init = useCallback(async () => {
		const { data } = await planService.list(searchObj);
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const changeSearchDate = (date: any, dateString: any) => {
		console.log(date, dateString);
		setSerachInfo({
			...searchInfo,
			startTime: dateString && dateString[0] && dateString[0] + "-01 00:00:00",
			endTime: dateString && dateString[0] && dateString[1] + "-01 00:00:00",
		});
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data }: AxiosResponse<ResponseEntity> = await planService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const handleEdit = (record: PlanEntity) => {
		setVisible(true);
		setInfo(record);
	};

	const onSearch = () => {
		setSerachObj({ ...searchInfo, customPageSize: 15, customPageNum: 1 });
	};

	const columns = [
		{
			title: t("planDate"),
			render: (record: PlanEntity) => {
				return record.year + "-" + record.month;
			},
		},
		{
			title: t("monthlyPlanRevenue"),
			dataIndex: "monthlyRevenue",
		},
		{
			title: t("monthlyPlanCarwashDiscount"),
			render: (record: PlanEntity) => {
				return record.monthlyWashDiscountRate + "%";
			},
		},
		{
			title: t("monthlyPlanOtherDiscount"),
			render: (record: PlanEntity) => {
				return record.monthlyOtherDiscountRate + "%";
			},
		},
		{
			title: t("createdTime"),
			dataIndex: "createdTime",
		},
		{
			title: t("action"),
			render: (text: string, record: any) => {
				return (
					<Space>
						{checkPermission(60902) && (
							<Button
								type='primary'
								onClick={() => {
									handleEdit(record);
								}}
							>
								{t("edit")}
							</Button>
						)}

						{checkPermission(60903) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	const addPlan = () => {
		setInfo({});
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const handleOk = async () => {
		if (!info.id) {
			const { data } = await planService.add({ ...info });
			if (data.code === 200) {
				message.success(data.msg);
				setVisible(false);
				init();
			}
		} else {
			const { data } = await planService.update({ ...info });
			if (data.code === 200) {
				message.success(data.msg);
				setVisible(false);
				init();
			}
		}
	};

	const chooseDate = (date: any, dateString: string) => {
		const time = dateString.split("-");
		console.log(time[0]);
		setInfo({ ...info, year: time[0], month: time[1] });
	};

	return (
		<Container>
			<Space style={{ marginBottom: 20 }}>
				<RangePicker picker='month' onChange={changeSearchDate} />
				<Button type='primary' onClick={onSearch}>
					{t("search")}
				</Button>
				{checkPermission(60902) && (
					<Button type='primary' onClick={addPlan}>
						{t("add")}
					</Button>
				)}
			</Space>

			<Table
				dataSource={dataList}
				columns={columns}
				rowKey={(record) => record.id + ""}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSerachObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
			<Modal width={"40%"} title={t("edit")} visible={visible} onOk={handleOk} onCancel={handleCancel}>
				<Form labelCol={{ span: 10 }} wrapperCol={{ span: 12 }}>
					<Form.Item label={t("planDate")}>
						<DatePicker
							style={{ width: 200 }}
							value={info.id ? moment(info.year + "/" + info.month, "YYYY-MM") : undefined}
							onChange={chooseDate}
							picker='month'
						/>
					</Form.Item>
					<Form.Item label={t("monthlyPlanRevenue")}>
						<InputNumber
							style={{ width: 200 }}
							placeholder='请输入月实收计划'
							value={info.monthlyRevenue}
							onChange={(e) => {
								setInfo({ ...info, monthlyRevenue: e });
							}}
						/>
					</Form.Item>

					<Form.Item label={t("monthlyPlanCarwashDiscount")}>
						<InputNumber
							style={{ width: 200 }}
							placeholder='请输入月洗车计划折扣率'
							formatter={(value) => `${value}%`}
							value={info.monthlyWashDiscountRate}
							onChange={(e) => {
								setInfo({ ...info, monthlyWashDiscountRate: e });
							}}
						/>
					</Form.Item>

					<Form.Item label={t("monthlyPlanOtherDiscount")}>
						<InputNumber
							style={{ width: 200 }}
							formatter={(value) => `${value}%`}
							placeholder='请输入月其他计划折扣率'
							value={info.monthlyOtherDiscountRate}
							onChange={(e) => {
								setInfo({ ...info, monthlyOtherDiscountRate: e });
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
