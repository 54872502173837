import styled from "@emotion/styled";
import { Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Props } from "../../../common/entity/props.entity";
import { SelectSite } from "../../site/component/select.site";

import productService from "../service/product.service";
import { SingleSelectProduct } from "./single.select.product";

export const SingleSelectProductBySite = (props: Props) => {
	const [siteId, setSiteId] = useState<number | undefined>();
	const [productId, setProductId] = useState<number>();

	const init = useCallback(async () => {
		if (props.value) {
			const { data } = await productService.list({ productId: props.value });
			console.log(data.data);

			setSiteId(data.data.list[0].shopId);
			setProductId(data.data.list[0].id);
		} else {
			setSiteId(undefined);
			setProductId(undefined);
		}
	}, [props.value]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<Container>
			<Space>
				<div style={{ width: 300 }}>
					<SelectSite
						selected={siteId}
						value={siteId}
						onChange={(e: number) => {
							setSiteId(e), setProductId(undefined);
						}}
					/>
				</div>
				<SingleSelectProduct
					siteId={siteId}
					value={productId}
					onChange={(e: number) => {
						props.onChange(e);
					}}
				/>
			</Space>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
`;
