import styled from "@emotion/styled";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
interface Props {
	list?: any;
	color?: string;
	nav?: string;
}
export const Bar = (props: Props) => {
	const [list, setList] = useState([]);
	useEffect(() => {
		setList(props.list);
	}, [props]);
	return (
		<Container>
			<div className='ranking'>
				<div className='nav'>{props.nav}</div>
				{list.map((item: any, index: number) => {
					return (
						<div className='ProgressList' key={index}>
							<div className='ProgressList-left'>
								<Tooltip placement='top' title={item.name}>
									<div className='text'>{item.name}</div>
								</Tooltip>
							</div>
							<div className='ProgressList-center'>
								<div className='line' style={{ width: item.percent + "%", background: props.color }}>
									<div className='ProgressList-right'>{item.count}</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	.ranking {
		width: 100%;
		height: 100%;
		font-family: PingFangSC-Semibold, PingFang SC, serif;
		.nav {
			font-size: 13rem;
			font-weight: 600;
			color: #333333;
			margin-bottom: 8rem;
		}
		.ProgressList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 9rem;
			.ProgressList-left {
				width: 75rem;
				font-size: 8rem;
				font-weight: 400;
				color: #333333;
				.text {
					width: 85rem;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			.ProgressList-center {
				width: 247rem;
				.line {
					width: 100%;
					height: 6rem;
					background: linear-gradient(270deg, #90ebff 0%, #00a4ff 100%);
					border-radius: 4rem;
					position: relative;
					.ProgressList-right {
						font-size: 9rem;
						font-weight: 400;
						color: #aaaaaa;
						position: absolute;
						right: -27rem;
						top: -5rem;
					}
				}
			}
		}
	}
`;
