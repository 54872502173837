import { http } from "../../../common/http";
import { InvoiceParams } from "../params/invoice.params";

class InvoiceService {
	list = async (param: InvoiceParams) => {
		return http({ method: "GET", url: process.env.REACT_APP_ADMIN_API + `/invoice`, params: param });
	};

	detail = async (id: number) => {
		return http({ method: "GET", url: process.env.REACT_APP_ADMIN_API + `/invoice/` + id });
	};

	updateStatus = async (id: number | undefined, data: any) => {
		return http({ method: "PUT", url: process.env.REACT_APP_ADMIN_API + `/invoice/status/` + id, data });
	};
}

export default new InvoiceService();
