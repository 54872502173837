import {FC, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, DatePicker, Form, Input, message} from "antd";
import {useForm} from "antd/es/form/Form";
import styled from "@emotion/styled";
import {UploadImg} from "../../../common/components/UploadImg";
import {useTranslation} from "react-i18next";
import {MultiSelectComboProductBySite} from "../../../module/product/component/multi.select.comboProduct.by.shop";
import {IndexPopupEntity} from "../../../module/indexPopup/entity/indexPopupEntity";
import {useParams} from "react-router-dom";
import moment from "moment";
import indexPopupService from "../../../module/indexPopup/service/indexPopupService";

export const IndexPopupAdd: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [ form ] = useForm();
    const { id } = useParams();

    const [info, setInfo] = useState<IndexPopupEntity>({
        id: 0,
        startTime: '',
        endTime: '',
        title: '',
        image: '',
        products: [],
        sort: 0,
    });

    const init = useCallback(async () => {
        const paramId = Number(id);
        if (paramId > 0) {
            const { data } = await indexPopupService.detail(paramId);
            console.log(data);
            setInfo(data.data);
            form.setFieldsValue({
                ...data.data,
                time: [moment(data.data.startTime), moment(data.data.endTime)],
                title: data.data.title,
            });
        }
    }, [id, form]);
    useEffect(() => {
        init();
    }, [init]);

    const onFinish = async (value: any) => {
        const params: Omit<IndexPopupEntity, 'id'> = {
            image: value.image || 'https://splashprod.oss-cn-shanghai.aliyuncs.com/img/new-mini-customer/0418_test.png?x-oss-process=image/format,webp&Version=0.0.5',
            startTime: moment(value.time[0]).format('YYYY-MM-DD 00:00:00'),
            endTime: moment(value.time[1]).format('YYYY-MM-DD 23:59:59'),
            sort: Number(value.sort),
            title: value.title,
            products: value.products,
        }
        console.log(params);
        if (!id) {
            const { data } = await indexPopupService.add(params);
            if (data.code === 200) {
                message.success(t("success"));
                navigate("/activity/indexPopup");
            } else message.error(data.msg);
        } else {
            const paramId = Number(id);
            const { data } = await indexPopupService.update(paramId, params);
            if (data.code === 200) {
                message.success(t("success"));
                navigate("/activity/indexPopup");
            } else message.error(data.msg);
        }
    }

    return (
        <Container>
            <Form labelCol={{span: 4}} wrapperCol={{span: 12}} style={{padding: 20}} onFinish={onFinish} form={form}>
                <Form.Item label={t("image")} name='image'>
                    <UploadImg size="810*1068" />
                </Form.Item>
                <Form.Item label={t("title")} name='title'>
                    <Input placeholder={t("title")}/>
                </Form.Item>
                <Form.Item label={t("availablePeriod")} name="time">
                    <DatePicker.RangePicker/>
                </Form.Item>
                <Form.Item label={t("sort")} name='sort'>
                    <Input placeholder={t("sort")}/>
                </Form.Item>
                <Form.Item label={t("product")} name='products'>
                    <MultiSelectComboProductBySite value={info?.products} />
                </Form.Item>
                <div>
                    <Button type={"primary"} htmlType='submit'>
                        {t("submit")}
                    </Button>
                </div>
            </Form>
        </Container>
    );
}

const Container = styled.div`
  width: 100%;
  padding: 20px;
`;