import { http } from "src/common/http";
import { DouyinParam } from "./douyin.param ";
import { DouyinEntity } from "./douyin.coupon.entity ";


class DouyinService {
	list = async (param: DouyinParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/douyin",
			method: "GET",
			params: param,
		});

		
	};

	

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/douyin/" + id,
			method: "GET",
			data: id,
		});
	};




	douyinAddOrUpdate=async (data: DouyinEntity) => {
		if (data.id) {
			return http({
				url: `${process.env.REACT_APP_ADMIN_API}/douyin/${data.id}`,
				method: "PUT",
				data,
			});
		} else {
			return http({
				url: process.env.REACT_APP_ADMIN_API + "/douyin",
				method: "POST",
				data,
			});
		}
	};


	delete=async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/douyin/" + id,
			method: "DELETE",
			data:id
		});
	};
}
export default new DouyinService();
