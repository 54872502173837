import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, InputNumber, message, Modal, Space } from "antd";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { SelectAllUser } from "src/module/admin/components/SelectAllUser";
import { PerformanceEntity } from "../../../module/performance/performance.entity";
import performanceService from "../../../module/performance/service/performance.service";

interface ConstructionPerformanceEntity {
	orderNum?: string;
	visible?: boolean;
	onCancel?: any;
	changeStatus?: any;
	totalMoney?: number;
	flag?: boolean;
}

export const ConstructionPerformanceMadal = (props: ConstructionPerformanceEntity) => {
	const [dataList, setDataList] = useState<PerformanceEntity[]>([]);
	const [addItem, setAddItem] = useState<PerformanceEntity>({});
	const [allMoney, setAllMoney] = useState<number>(0);

	const init = useCallback(async () => {
		if (!props.visible) return;
		if (!props.orderNum) return;
		const { data } = await performanceService.list({ serviceType: 2, orderNum: props.orderNum });
		if (data.code === 200) {
			setDataList(data.data);
			if (!isNaN(Number(props.totalMoney))) {
				setAllMoney(Number(props.totalMoney));
			}
			// if (data.data.length !== 0) {
			// 	setAllMoney(data.data[0].constructionMoney)
			// }else{setAllMoney(0)}
		}
	}, [props.orderNum, props.totalMoney, props.visible]);

	useEffect(() => {
		init();
	}, [init]);

	const handleOk = async () => {
		const sumMoney = dataList.reduce((a, b) => {
			return a + b.money!;
		}, 0);
		if (sumMoney > allMoney) {
			message.error("超过施工绩效总额");
		} else {
			if (props.flag) {
				const arr: number[] = [];
				dataList.forEach((a) => {
					arr.push(a.userId!);
				});
				props.changeStatus && props.changeStatus(arr.toString(), JSON.stringify(dataList));
				props.onCancel();
			}
			const { data } = await performanceService.updatePerformance({
				serviceType: 2,
				adminPerformanceManage: JSON.stringify(dataList),
				orderNum: props.orderNum,
			});
			if (data.code === 200) {
				message.success("修改施工绩效成功");
				props.onCancel();
				init();
			} else {
				message.error(data.msg);
			}
		}
	};

	const fixPerformance = async () => {
		const { data } = await performanceService.fixPerformance();
		if (data.code === 200) {
			message.success("更新成功");
			props.onCancel();
			init();
		} else {
			message.error(data.msg);
		}
	};

	const handleClose = () => {
		props.onCancel();
		setAddItem({});
		init();
	};

	const addPerformance = () => {
		const { userId, money } = addItem;
		if (userId !== void 0 && money !== void 0) {
			const newArr = [...dataList, addItem];
			setDataList(newArr);
			console.log(newArr);
			setAddItem({});
		} else {
			message.error("请输入完整内容");
		}
	};

	const remove = (index: number) => {
		dataList.splice(index, 1);
		setDataList(dataList.concat());
		console.log(dataList);
	};

	// 修改的时候
	const changeInfo = (index: number, type: string, e: any) => {
		const newArr = dataList.map((item, key) => {
			if (index == key) {
				return { ...item, [type]: e };
			} else {
				return item;
			}
		});
		setDataList(newArr);
	};

	return (
		<Container>
			<Modal title='施工绩效' visible={props.visible} onCancel={handleClose} onOk={handleOk}>
				<div className='setMargin'>施工人员</div>
				<Space style={{ marginTop: "20px", marginBottom: "20px" }}>
					<SelectAllUser
						value={addItem.userId}
						onChange={(e: number) => {
							setAddItem({ ...addItem, userId: e });
						}}
					/>
					<InputNumber
						value={addItem.money}
						min={0}
						onChange={(e) => {
							setAddItem({ ...addItem, money: Number(e) });
						}}
					/>
					<PlusOutlined style={{ color: "#096dd9" }} onClick={addPerformance} />
				</Space>
				<div>已选择的施工人员</div>
				<div>
					{dataList.map((item, index) => {
						return (
							<div key={index}>
								<Space style={{ marginTop: "5px", marginBottom: "5px" }}>
									<SelectAllUser
										value={item.userId}
										onChange={(e: number) => {
											changeInfo(index, "userId", e);
										}}
									/>
									<InputNumber
										placeholder={t("money")}
										value={item.money}
										onChange={(e: number | null) => {
											changeInfo(index, "money", e || 0);
										}}
									/>
									<MinusOutlined style={{ color: "#f5222d" }} onClick={() => remove(index)} />
								</Space>
							</div>
						);
					})}
				</div>
				<div style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
					<p>施工管理绩效：{allMoney}元</p>
					<Button type='link' onClick={fixPerformance}>
						绩效不对？点击更新
					</Button>
				</div>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
