type descriptionInterface = Record<optionsType["type"] | myProps["type"], string>;
import { TFunction } from "react-i18next";

type t = TFunction<"translation", undefined>;

const getDescriptionText: (arg: t) => descriptionInterface = (t) => {
	const getHtmlTemplate: (arg: string[]) => string = (arr) => {
		let html = "";
		arr.forEach((item) => {
			html += `
                <p><b style="color: #40a9ff"><i>${t(item)}</i></b> : ${t(item + "Desc")};</p> 
            `;
		});
		return html;
	};
	return {
		ecoSales: getHtmlTemplate(["Volume_1", "Revenues_1", "Outstanding_1", "surplusValue", "MTDsurplusValue"]),
		// EcoSales: t("waitingDev"),
		fleets: t("waitingDev"),
		package: getHtmlTemplate(["Volume_1", "Revenues_1", "Outstanding_1", "surplusValue", "MTDsurplusValue"]),
		recharge: getHtmlTemplate(["Volume", "Revenues", "OutStanding", "prepaidMTD"]),
		siteStatus: getHtmlTemplate(["operating", "maintain", "construction"]),
		newSite: getHtmlTemplate(["open", "closed"]),
		orderNumber: getHtmlTemplate(["allVol","price_2", "freeCoupons","prepaid","comboDis", "new","otherCou","otherDis"]),
		quantity: "",
		revenues: getHtmlTemplate(["totalNet", "totalGross", "totalDis"]),
		"month&YearRevenues": getHtmlTemplate(["totalNetMTD", "totalNetPlanMTD", "totalNetYTD", "totalNetPlanYTD"]),
		monthDisCount: getHtmlTemplate(["MTDPriceDis", "PlanedPriceDis", "MTDOtherPriceDis", "PlanedOtherPriceDis"]),
		performanceVol: getHtmlTemplate([
			"averageDailyVolumes",
			"superCenterOdNum",
			"ExpressOdNum",
			"DetailingOdNum",
			"breakEvenVolumes",
			"superCenterOdQuantity",
			"ExpressOdQuantity",
			"DetailingOdQuantity",
		]),
		performanceRevenues: getHtmlTemplate([
			"averageTransactionValue",
			"Tunnel",
			"ExpressRev",
			"DetailingRev",
			"BasicInteriorRev"
		]),
		monthCard: getHtmlTemplate(["cardPercentage","active", "gained", "washVolumes","cardATV","washFrequency"]),
		waitingDev: t("waitingDev"),
		volumes: getHtmlTemplate([
			"TotalVolume",
			"ExteriorVol",
			"DetailingVol",
			"BasicInteriorVol",
			"MaintenanceVol",
			"ExteriorIncome",
			"DetailingIncome",
			"BasicInteriorIncome",
			"MaintenanceIncome",
			
		]),
		customerTimes: getHtmlTemplate(["Retention","AveFrequency","ExterioFrequency","DetailingFrequency"]),
		customerAnalysis: getHtmlTemplate([
			"allRegistrants",
			"inactiveRegistrants",
			"activeRegistrants",
			"todayRegistrants",
			"registrantsConversion",
			"InactiveUsers",
			"activeUsers",
			"todayUsers",
			"usersConversion",
			"inactiveCustomer",
			"activeCustomer",
			"todayCustomer",
			"totalPeople",
			
		]),
		orderTrend: t("orderTrend"),
		dailyCarWash:getHtmlTemplate(["averageDailyVol","aveDailyStatistics"]),
		dailyRevenue: t("aveDailyRevenue"),
		exteriorRanking: t("expressWashOrderRanking"),
		interiorRanking: t("interiorCleanOrderRanking"),
		detailingRanking: t("detailingOrderRanking"),
	};
};

export default getDescriptionText;
