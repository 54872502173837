import {http} from "../../../common/http";
import {searchObj} from "../entity/statistics.entity";

interface cityObj {
    fullName?: string,
    parentId?: number,
    areaLevel?: number
}

class StatisticsServe {

    cityList = async (param: cityObj) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + '/area/shop',
            method: 'GET',
            params: param,
        })
    }
    cityListAll = async (param: cityObj) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + '/area/all',
            method: 'GET',
            params: param,
        })
    }
    goodsTypeList = async () => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + '/productType/all',
            method: 'GET',
        })
    }
    // 门店状态接口
    getShopStatus = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/querySiteStatusStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 日最高订单量
    getMaxOrder = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryMaxOrderStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 日均洗车辆
    getDayCarWash = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryDateOrderStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 外洗订单排名
    getWashout = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryWashRankStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 内清订单排名
    getQinDynasty = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryCleanRankStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 美容订单排名
    getBeautyRanked = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryBeautyRankStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 新用户
    getNewsUser = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryUserStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 用券用户量
    getCouponUser = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryUseCouponUserStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 洗车频次
    getWashingFrequency = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryOrderFrequencyStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 订单数据
    getOrders = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryOrderPolymerizeStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 收入结构
    getIncomeStructure = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryIncomeStatistics',
            method: 'GET',
            params: param,
        })
    }
    // 订单量及客单价统计
    getOrderCountAndATV = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/simple-order-statistics',
            method: 'GET',
            params: param,
        })
    }
    // 优惠券统计
    queryCouponStatistics = async (param: searchObj) => {
        return http({
            url: process.env.REACT_APP_DATA_API + '/dataBoard/queryCouponStatistics',
            method: 'GET',
            params: param,
        })
    }
}

export default new StatisticsServe();