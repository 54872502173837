import { http } from "../../../common/http";
import { RateGiftEntity } from "../entity/rate.gift.entity";
import { RateGiftParam } from "../param/rate.gift.param";

class RateGiftService {
	list = async (param: RateGiftParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order-rate-gift",
			method: "GET",
			params: param,
		});
	};

	update = async (data: RateGiftEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order-rate-gift/"+data.id,
			method: "PUT",
			data,
		});
	};

	detail=async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order-rate-gift/"+id,
			method: "GET",
			data:id,
		});
	};

	add=async (data: RateGiftEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order-rate-gift",
			method: "POST",
			data,
		});
	};




}
export default new RateGiftService();
