import { PlusCircleOutlined, RestOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { Props } from "../../../common/entity/props.entity";
import { SelectSite } from "../../site/component/select.site";
import { SelectSku } from "./select.sku";
import { SingleSelectProduct } from "./single.select.product";
interface ProductsProp extends Props {
	shopId?: string;
	className?: string;
	changeSite?: (siteId: string) => void;
}

interface comProductEntity {
	shopName?: string;
	shopTitle?: string;
	shopId?: number;
	productName?: string;
	productId?: number;
	skuName?: string;
	skuStr?: string;
	skuIds?: number;
}

export const MultiSelectComboProductBySite = (props: ProductsProp) => {
	const [comboProduct, setComboProduct] = useState<comProductEntity>({});
	const [comboProductArr, setComboProductArr] = useState<comProductEntity[]>([]);
	const [siteId, setSiteId] = useState(undefined);
	const [productId, setProductId] = useState(undefined);
	const [selectedSite, setSelectedSite] = useState("");
	const [selectedProduct, setSelectedProduct] = useState("");
	const [selectedSku, setSelectedSku] = useState("");
                                                        
	useEffect(() => {
		console.log("value", props.value);

		const arr = props.value?.map((item: any) => {
			// 增加或者减少sku选项的时候,props.onchange(value),
			// 其中value的结构与页面刚加载时候得到的value结构不一样了,所以要进行一下判断，有skus时要先处理
			if (item.skus) {
				const skuNameArr = item.skus?.map((skuItem: any) => {
					return skuItem.typeTitle + ":" + skuItem.title;
				});
				return {
					productId: item.productId,
					skuIds: item.skuIds,
					shopName: item.shopName,
					productName: item.productName,
					skuName: skuNameArr?.join(","),
					shopId: item.shopId,
				};
			} else {
				return item;
			}
		});
		console.log("arr", arr);

		setComboProductArr(arr);

		const selectedSiteArr: number[] = [];
		const selectedProductArr: number[] = [];
		const selectedSkuArr: number[] = [];

		arr?.map((item: any) => {
			selectedSiteArr.push(item.shopId);
			selectedProductArr.push(item.productId);
			selectedSkuArr.push(item.skuIds);
		});
		setSelectedSite(selectedSiteArr.toString());
		setSelectedProduct(selectedProductArr.toString());
		setSelectedSku(selectedSkuArr.toString());
	}, [props.value]);

	const changeSite = (siteInfo: any) => {
		setSiteId(siteInfo?.value);
		setComboProduct({ ...comboProduct, shopName: siteInfo?.label, shopId: siteInfo?.value });
	};

	const changeProduct = (productInfo: any) => {
		setProductId(productInfo?.value);
		setComboProduct({ ...comboProduct, productName: productInfo?.label, productId: productInfo?.value });
	};

	const changeSku = (skuInfo: any) => {
		// console.log("123", skuInfo);

		const skuTitleArr = skuInfo.map((item: any) => {
			return item.label;
		});

		const skuTitles = skuTitleArr.join(",");

		const skuIdArr = skuInfo.map((item: any) => {
			return item.value;
		});
		const skuIds = skuIdArr.join(",");

		setComboProduct({ ...comboProduct, skuName: skuTitles, skuIds: skuIds });
	};
	const addItem = () => {
		const newArr = comboProductArr?.map((item) => {
			return Number(item.productId);
		});

		if (newArr?.indexOf(Number(productId)) > -1) {
			return;
		} else {
			//新创建的时候 comboProductArr还不存在，要考虑不存在的状况，不然报错
			const skusArr = comboProductArr ? [...comboProductArr, comboProduct] : [comboProduct];
			// console.log("skusArr", skusArr);
			setComboProductArr(skusArr);
			props.onChange && props.onChange(skusArr);

			// 方法二：
			//  不用props..entityChange,只获取增加或者修改时候所需要的 [{ productId: item.productId, skuIds: item.skuIds }];
			// 把得到的数据传到页面中处理就可以
			// const arr = skusArr.map((item: any) => {
			// 	return { productId: item.productId, skuIds: item.skuIds };
			// });
			// props.entityChange && props.entityChange(arr);
		}
	};

	const remove = (index: number) => {
		comboProductArr.splice(index, 1);
		const skusArr = comboProductArr;
		setComboProductArr([...comboProductArr]);
		props.onChange && props.onChange(skusArr);

		// const arr = [...comboProductArr].map((item: any) => {
		// 	return { productId: item.productId, skuIds: item.skuIds, productName: item.productName };
		// });

		// props.entityChange && props.entityChange(arr);
	};

	return (
		<Container className={props.className}>
			<div className='select'>
				<div className='selectItem'>
					<SelectSite
						selected={selectedSite}
						labelInValue={true}
						onChange={(e: any) => {
							changeSite(e);
						}}
					/>
				</div>
				&nbsp;
				<div className='selectItem'>
					<SingleSelectProduct
						selected={selectedProduct}
						labelInValue={true}
						siteId={siteId}
						onChange={(e: any) => {
							changeProduct(e);
						}}
					/>
				</div>
				&nbsp;
				<div className='selectItem_sku'>
					<SelectSku
						selected={selectedSku}
						labelInValue={true}
						productId={productId}
						onChange={(e: any) => {
							changeSku(e);
						}}
					/>
				</div>
				<div className='selectItem_icon'>
					<PlusCircleOutlined onClick={addItem} style={{ color: "#096dd9", fontSize: "25px" }} />
				</div>
			</div>

			{comboProductArr &&
				comboProductArr.map((item: comProductEntity, index: number) => {
					return (
						<div key={index} style={{ marginTop: "3px" }}>
							商品{index + 1}:{item.shopName || item.shopTitle}—{item.productName}—{item.skuName || item.skuStr}
							<RestOutlined style={{ marginLeft: "5px", color: "#f5222d" }} onClick={() => remove(index)} />
						</div>
					);
				})}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	.select {
		display: flex;
		align-items: center;
		.selectItem {
			flex: 2;
		}
		.selectItem_sku {
			flex: 5;
		}
		.selectItem_icon {
			flex: 1;
			text-align: right;
		}
	}
`;
