import {FC, useCallback, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {Button, Form, Input, Select, Space, Table, Tag} from "antd";
import {t} from "i18next";


import earlyBirdService from "src/module/earlyBird/service/earlyBirdService";
import Popover from "antd/es/popover";
import {EarlyBirdParam} from "../../../module/earlyBird/param/earlyBirdParam";
import {EarlyBirdEntity} from "../../../module/earlyBird/entity/earlyBirdEntity";

export const EarlyBirdList: FC = () => {
    const {Option} = Select;
    const [total, setTotal] = useState<number>(0);
    const [searchObj, setSearchObj] = useState<EarlyBirdParam>({customPageSize: 15, customPageNum: 1});
    const [dataList, setDataList] = useState<EarlyBirdEntity[]>([]);
    const onfinish = (value: any) => {
        setSearchObj({...searchObj, ...value, customPageSize: 15, customPageNum: 1});
    };
    const init = useCallback(async () => {
        const {data} = await earlyBirdService.list({...searchObj});
        if (data.code === 200) {
            setDataList(data.data.records);
            setTotal(data.data.total);
        }
    }, [searchObj]);

    useEffect(() => {
        init();
    }, [init]);


    const columns = [
        {
            width: 220,
            title: t("nickName"),
            dataIndex: "nickName",
        },
        {
            width: 220,
            title: t("mobile"),
            dataIndex: "mobile",
        },
        {
            width: 220,
            title: t("verifyStatus"),
            render: (record: any) => {
                return record.status ? (
                    <Popover content={t("verificated")}>
                        <Tag color='green'>{t("verificated")}</Tag>
                    </Popover>
                ) : (
                    <Popover content={t("unVerificated")}>
                        <Tag color='blue'>{t("unVerificated")}</Tag>
                    </Popover>
                )
            },
        },
        {
            width: 220,
            title: t("codeCoupon"),
            dataIndex: "code",
        },
        {
            title: t("verificationTime"),
            render: (record: any) => {
                console.log(record)
                return record.completeTime ? (
                    <Space>{record.completeTime}</Space>
                ) : (
                    <Space>{t("noMessage")}</Space>
                )
            },
        },
    ];

    return (
        <Container>
            <Form layout='inline' onFinish={onfinish}>
                <Form.Item label={t("mobile")} name='title'>
                    <Input placeholder={t("mobile")} allowClear/>
                </Form.Item>
                <Form.Item label={t("verifyStatus")} name='status'>
                    <Select placeholder={t("verifyStatus")} allowClear>
                        <Option value={1}>{t("verificated")}</Option>
                        <Option value={0}>{t("unVerificated")}</Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type='primary' htmlType='submit'>
                            {t("search")}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Table
                columns={columns}
                dataSource={dataList}
                rowKey={(record) => record.code}
                style={{marginTop: 20}}
                pagination={{
                    pageSize: searchObj.customPageSize,
                    current: searchObj.customPageNum,
                    total: total,
                    showTotal: () => {
                        return (
                            <div>
                                {t("total")} {total}
                            </div>
                        );
                    },
                }}
                onChange={(pagination) => {
                    setSearchObj({
                        ...searchObj,
                        customPageNum: pagination.current ?? 1,
                        customPageSize: pagination.pageSize ?? 15,
                    });
                }}
            />
        </Container>
    );
};
const Container = styled.div`
  width: 100%;
  padding: 20px;
`;
