import { http } from "../../../common/http";
import { PlanEntity } from "../entity/plan.entity";
import { PlanParam } from "../param/plan.param";

class PlanService {
	list = async (params:PlanParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + `/plan`,
			params,
		});
	};

	delete=async (id:number) => {
		return http({
			method: "DELETE",
			url: process.env.REACT_APP_ADMIN_API + "/plan/"+ id,
			data:id
		});
	};


	update=async (data:PlanEntity) => {
		return http({
			method: "PUT",
			url: process.env.REACT_APP_ADMIN_API + "/plan/"+ data.id,
			data
		});
	};

	add=async (data:PlanEntity) => {
		return http({
			method: "POST",
			url: process.env.REACT_APP_ADMIN_API + "/plan",
			data
		});
	};


	
}
export default new PlanService();
