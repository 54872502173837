import styled from "@emotion/styled";
import { Select } from "antd";
import { t } from "i18next";
import { useEffect, useState, useCallback } from "react";
import { ActivityEntity } from "../entity/activity.entity";
import { ActivityParam } from "../param/activity.param";
import activityService from "../service/activity.service";
import { PropsEntity } from "../../../common/entity/props.entity";
const { Option } = Select;

interface ActivityPropsEntity extends PropsEntity {
	type?: number;
}
export const SelectRechargeActivity = (props: ActivityPropsEntity) => {
	const [dataList, setDataList] = useState<ActivityEntity[]>([]);
	const [info, setInfo] = useState<ActivityParam>({ type: props.type });

	const init = useCallback(async () => {
		const { data } = await activityService.list(info);
		if (data.code === 200) {
			setDataList(data.data.list);
		}

		let flag = false;
		const list = data.data.list;
		list.forEach((item: ActivityEntity) => {
			if (item.id === props.value) {
				flag = true;
			}
		});

		if (!flag && props.value) {
			const { data } = await activityService.detail(props.value);

			setDataList([...list, data.data]);
		}
	}, [info, props.value]);

	useEffect(() => {
		init();
	}, [init]);

	const onSearch = (e: any) => {
		setInfo({ ...info, title: e });
	};

	return (
		<Container>
			<Select
				showSearch
				style={{ width: "100%" }}
				value={props.value}
				onChange={props.onChange}
				allowClear
				placeholder={t("activityName")}
				optionFilterProp={"children"}
				onSearch={onSearch}
			>
				{dataList.map((item, index) => {
					return (
						<Option key={index} value={item.id}>
							{item.title}
						</Option>
					);
				})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
