import { PaginationEntity } from "../../../common/entity/pagination.entity";
import {http} from "../../../common/http";
import {ProductTypeListEntity} from "../entity/product.type.entity";
class ProductTypeService {

    list = async (param:PaginationEntity) => {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/productType`,
            params: param,
        })
    }
    listAll = async () => {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/productType/all`,
        })
    }
    detail = async (id: number) => {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/productType/`+id,
        })
    }
    add = async (param:ProductTypeListEntity) => {
        return http({
            method: 'POST',
            url: `${process.env.REACT_APP_ADMIN_API}/productType`,
            data: param,
        })
    }

    update = async (param:ProductTypeListEntity) => {
        return http({
            method: 'PUT',
            url: `${process.env.REACT_APP_ADMIN_API}/productType/`+param.id,
            data: param,
        })
    }

    delete = async (id:number) => {
        return http({
            method: 'DELETE',
            url: `${process.env.REACT_APP_ADMIN_API}/productType/`+id,
        })
    }

    export = async () => {
        return http({
            method: 'GET',
            url: `${process.env.REACT_APP_ADMIN_API}/productType/export`,
            responseType: 'blob',
        })
    }
}
export default new ProductTypeService();