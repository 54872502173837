import { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import AdminService from "../service/admin.service";
import { AdminEntity } from "../entity/admin.entity";
import { NumberProp } from "../../../common/entity/props.entity";
import { t } from "i18next";

const { Option } = Select;

export const SelectAdminName = (props: NumberProp) => {
	const [dataArr, setDataArr] = useState<AdminEntity[]>([]);
	const [name, setName] = useState<string | undefined>(undefined);

	const init = useCallback(async () => {
		const res = await AdminService.list({ name });
		if (res.data.code === 200) setDataArr(res.data.data);
	}, [name]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<>
			<Select
				showSearch
				value={props.value}
				onChange={(e) => {
					props.onChange && props.onChange(e);
					
				}}
				onSearch={setName}
				onBlur={()=>{setName(undefined)}}
				allowClear
				style={{ width: 200 }}
				placeholder={t("pleaseSelectAdmin")}
			>
				{dataArr &&
					dataArr.map((item) => {
						return (
							<Option value={item.name} key={item.id}>
								{item.name}
							</Option>
						);
					})}
			</Select>
		</>
	);
};
