import { http } from "src/common/http";
import { CarBrandParam } from "./carBrand.param";
import { CarBrandEntity } from "./carBrand.entity";


class CarBrandService {
    list = async (param:CarBrandParam) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/carBrand',
            method: 'GET',
            params: param,
        })
    }

    detail = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+`/carBrand/${id}`,
            method: 'GET'
        })
    }
    
    addOrUpdate = async (data: CarBrandEntity) => {
        if (data.id) {
            return http({
                url: process.env.REACT_APP_ADMIN_API+`/carBrand/${data.id}`,
                method: 'PUT',
                data
            })
        }else{
            return http({
                url: process.env.REACT_APP_ADMIN_API+`/carBrand`,
                method: 'POST',
                data
            })
        }
    }
    delete = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/carBrand/'+id,
            method: 'DELETE'
        })
    }


}


			
export default new CarBrandService();