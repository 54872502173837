import { useCallback, useState, useEffect } from "react";
import { Select } from "antd";
import { RoleEntity } from "../entity/role.entity";
import RoleService from "../service/role.service";
import { Props } from "../../../common/entity/props.entity";
import { t } from "i18next";

const { Option } = Select;

export const MultiSelectRole = (props: Props) => {
	const [dataArr, setDataArr] = useState<RoleEntity[]>([]);

	const init = useCallback(async () => {
		const res = await RoleService.all();
		setDataArr(res.data.data);
	}, []);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<>
			<Select
				value={props.value?.split(",").map((item: string) => Number(item))}
				onChange={(e: number[]) =>{props.onChange && props.onChange(e.join(","))}
                }
				allowClear
				mode='multiple'
				placeholder={t("selectRoles")}
			>
				{dataArr &&
					dataArr.map((item) => {
						return (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						);
					})}
			</Select>
		</>
	);
};
