import { http } from "../../../common/http";
import { PointParam } from "../param/point.param";
class PointService{

    detailList = async (param:PointParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/points-record",
			method: "GET",
			params: param,
		});
	};

	exchangeList = async (param:PointParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/points-order",
			method: "GET",
			params: param,
		});
	};

	updateStatus=async (data:string) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/points-order/"+ data,
			method: "GET",
			data,
		});
	};



    }


export default new PointService()