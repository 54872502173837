import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Button, Col, DatePicker, Input, message, Modal, Row, Space, Table } from "antd";
import { SelectSite } from "../../module/site/component/select.site";
import packagePurchaseService from "../../module/packagePurchase/packagePurchase.service";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import moment from "moment";
import { SingleSelectPayWay } from "../../module/finance/component/SingleSelectPayWay";
import { AxiosResponse } from "axios";
import Util from "../../common/Util";
import { SelectPayStatus } from "../../module/finance/component/SelectPayStatus";
import { checkPermission } from "src/module/admin/service/permission.service";
import { SalePerformanceMadal } from "src/module/performance/module/SalePerformance";
const { RangePicker } = DatePicker;

export const PackagePurchase: FC = () => {
	const { confirm } = Modal;
	const { t } = useTranslation();
	const [dataList, setDataList] = useState();
	const [searchArr, setSearchArr] = useState({});
	const [searchObj, setSearchObj] = useState({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageNum: 1,
		customPageSize: 15,
	});
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageNum: 1,
	// 	customPageSize: 15,
	// });
	const [total, setTotal] = useState(0);

	const [numInfo, setNumInfo] = useState("");

	const changeArr = (type: any, e: any) => {
		setSearchArr({ ...searchArr, [type]: e });
	};

	const handleSearch = () => {
		setSearchObj({ ...searchObj, ...searchArr, customPageNum: 1, customPageSize: 15 });
	};
	const changeDate = (date: any, dateString: any) => {
		if (date) {
			setSearchArr({ ...searchArr, startTime: dateString[0] + " 00:00:00", endTime: dateString[1] + " 23:59:59" });
		} else {
			setSearchArr({ ...searchArr, startTime: undefined, endTime: undefined });
		}
	};

	const handleChangeStatus = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await packagePurchaseService.changeRefundStatus(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const handleRefund = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await packagePurchaseService.refund(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const downLoad = async () => {
		const res: AxiosResponse<BlobPart> = await packagePurchaseService.downLoad(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "recommendActivity");
		message.success(t("export.success"));
	};

	const [visible, setVisible] = useState(false);
	const [saleMoney, setSaleMoney] = useState(0);

	const columns = [
		{ title: t("packageName"), dataIndex: "activityName" },
		{ title: t("packageCouponName"), dataIndex: "packageCouponName" },
		{ title: t("saleShop"), dataIndex: "saleShopTitle" },
		{ title: t("userNickname"), dataIndex: "nickName" },
		{ title: t("mobile"), dataIndex: "mobile" },
		{ title: t("orderNum"), dataIndex: "orderNum" },
		{ title: t("payWay"), dataIndex: "payWayStr" },
		{ title: t("payStatus"), dataIndex: "payStatusStr" },
		{ title: t("createdTime"), dataIndex: "createdTime" },
		{ title: t("packageFee"), dataIndex: "currentPayWayFee" },
		{
			title: t("action"),
			render: (_: any, record: any) => {
				return (
					<div className='action'>
						<Space>
							{checkPermission(352) && (
								<Button
									type='primary'
									onClick={() => {
										getPerformance(record.orderNum, record.saleMoney);
									}}
								>
									{t("salesPerformance")}
								</Button>
							)}
							{checkPermission(20403) && record.payStatusStr === "支付成功" && (
								<Space>
									<Button
										type='primary'
										danger
										onClick={() => {
											handleChangeStatus(record.id);
										}}
									>
										{t("manuallyRefunded")}
									</Button>
									<Button
										type='primary'
										danger
										onClick={() => {
											handleRefund(record.id);
										}}
									>
										{t("refund")}
									</Button>
								</Space>
							)}
						</Space>
					</div>
				);
			},
		},
	];

	const init = useCallback(async () => {
		const { data } = await packagePurchaseService.list({  ...searchObj });
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [ searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const getPerformance = async (orderNum: string, saleMoney: number) => {
		setSaleMoney(saleMoney);
		setNumInfo(orderNum);
		setVisible(true);
	};

	return (
		<Container>
			<Row gutter={30}>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchTitle'>{t("packageName")} :</div>
						<Input
							allowClear
							placeholder={t("packageName")}
							onChange={(e) => {
								changeArr("activityName", e.target.value);
							}}
						/>
					</div>
				</Col>

				<Col span={8}>
					<div className='searchList'>
						<div className='searchTitle'> {t("userNickname")} :</div>
						<Input
							allowClear
							placeholder={t("userNickname")}
							onChange={(e) => {
								changeArr("nickName", e.target.value);
							}}
						/>
					</div>
				</Col>

				<Col span={8}>
					<div className='searchList'>
						<div className='searchTitle'>{t("mobile")} :</div>
						<Input
							allowClear
							placeholder={t("mobile")}
							onChange={(e) => {
								changeArr("mobile", e.target.value);
							}}
						/>
					</div>
				</Col>

				<Col span={8}>
					<div className='searchList'>
						<div className='searchTitle'>{t("orderNum")} :</div>
						<Input
							allowClear
							placeholder={t("orderNum")}
							onChange={(e) => {
								changeArr("orderNum", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchTitle'>{t("saleShop")} :</div>
						<SelectSite
							onChange={(e: number) => {
								changeArr("saleShopId", e);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchTitle'>{t("site")} :</div>
						<SelectSite
							onChange={(e: number) => {
								changeArr("shopId", e);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchTitle'>{t("payWay")} :</div>
						<SingleSelectPayWay
							onChange={(e: number) => {
								changeArr("payWay", e);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchTitle'>{t("payStatus")} :</div>
						<SelectPayStatus
							onChange={(e: number) => {
								changeArr("payStatus", e);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchTitle'>{t("createdTime")} :</div>
						<RangePicker defaultValue={[moment(), moment()]} onChange={changeDate} />
					</div>
				</Col>
				<Col span={8}>
					<Space>
						<Button type='primary' onClick={handleSearch}>
							{t("search")}
						</Button>
						{checkPermission(262) && (
							<Button type='primary' onClick={downLoad}>
								{t("export")}
							</Button>
						)}
					</Space>
				</Col>
			</Row>
			<Table
				rowKey={(record) => record.id + ""}
				columns={columns}
				dataSource={dataList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>

			<SalePerformanceMadal
				orderNum={numInfo}
				visible={visible}
				saleMoney={saleMoney}
				onCancel={() => {
					setVisible(false);
				}}
			></SalePerformanceMadal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
	.action {
		text-align: center;
	}
	.searchList {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		.searchTitle {
			min-width: 100px;
			text-align: right;
		}
	}
`;
