export const dateFormat = "YYYY-MM-DD";

class Util {
	download(blobUrl: string, title: string) {
		const a = document.createElement("a");
		a.style.display = "none";
		a.download = title + ".xlsx";
		a.href = blobUrl;
		a.click();
	}

	processBlobImg(fileStream: any) {
		const blob = new Blob([fileStream]);
		const blobUrl = window.URL.createObjectURL(blob);
		this.downloadImg(blobUrl);
	}

	downloadImg(blobUrl: string) {
		const a = document.createElement("a");
		a.style.display = "none";
		a.download = "appletImg.png";
		a.href = blobUrl;
		a.click();
	}

	zipDownload(blobUrl: string) {
		const a = document.createElement("a");
		a.style.display = "none";
		a.download = "<二维码包>.zip";
		a.href = blobUrl;
		a.click();
	}
}

export default new Util();

export const uniqueArray = (arr: any[]) => {
	return Array.from(new Set(arr));
};
// 保留1位小数
export const floatFormat = (src: number, pos: number) => {
	return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos);
};
export const setRem = () => {
	const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
	const htmlDom: any = document.querySelector("html");
	// 判断pc浏览器是否缩放，若返回1则为默认无缩放，如果大于1则是放大，否则缩小
	const ratio = window.devicePixelRatio;
	if (ratio !== 1) {
		htmlDom.style.fontSize = 1.2 + "px";
	} else {
		htmlDom.style.fontSize = htmlWidth <= 1400 ? "1px" : htmlWidth / 1400 + "px";
	}
};

export const styleCalc = (num: number) => {
	const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
	return htmlWidth <= 1366 ? num : (htmlWidth / 1366) * num;
};

export const formatNumToLocaleString = (num: number | "-" | "N/A") => {
	if (num === "-") return "N/A";
	if (num === "N/A") return "N/A";
	if (typeof num !== "number" || !num) {
		return 0;
	}

	const str = num.toLocaleString("en-US");
	if (num > 10_000) {
		const [res] = str.split(".");
		return res;
	}
	return str;
};

export const dayDifference = (startDay: string, endDay: string) => {
	const start = Date.parse(startDay);
	const end = Date.parse(endDay);
	return (end - start) / (1 * 24 * 60 * 60 * 1000);
};

export const includes = (arr1: number[], arr2: number[]) => {
	// if (arr1.length === 0) return fl;
	let flag = false;
	arr2.forEach((val) => {
		if (arr1.indexOf(val) > -1) {
			flag = true;
		}
	});
	// console.log(arr1, arr2)
	return flag;
};
