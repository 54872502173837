import styled from "@emotion/styled";
import { FC, useCallback, useEffect, useState } from "react";
import From from "./components/otherComponents/newForm";
import { Charts } from "./components/charts";
import { formValue } from "src/module/dashboard/entity/form.entity";
import moment from "moment";
import scaleService from "../../../module/dashboard/service/ScaleService";
import usePrevious from "src/common/hooks/usePrevious";
// import { usePrevious } from "@uiw/react-baidu-map";

// type uniKeys<T> = { [key in myProps["type"]]: T };

export const Dashboard: FC = () => {
	const [filterValue, setFilterValue] = useState<formValue>({
		startTime: moment(new Date()).format("yyyy-MM-DD 00:00:00"),
		endTime: moment(new Date()).format("yyyy-MM-DD 23:59:59"),
	});
	const previousFilter = usePrevious<formValue | undefined>(filterValue);
	const [filterTypesArr, setFilterTypesArr] = useState<number[]>([]);
	// const [resetFlag, setRestFlag] = useState<boolean>(false);
	// const [filterValObj, setFilterValObj] = useState<Partial<uniKeys<formValue>>>({});
	// const latFilterVal = usePrevious(filterValue);
	const [radio, setRadio] = useState<{
		widthRadio: number;
		heightRadio: number;
	}>({
		widthRadio: 1,
		heightRadio: 1,
	});

	const isToday = (date: string) => {
		return moment(date).isSame(moment(), "day");
	};

	const changeFormVal = (val: formValue) => {
		// if (resetFlag) {
		// 	setFilterTypesArr([6]);
		// } else {
		setFilterTypesArr([]);
		// }
		setFilterValue(val);
	};

	const resetFilter = () => {
		setFilterTypesArr([]);
		// setRestFlag(true);
	};

	const onResize = useCallback(() => {
		const { clientWidth: realWidth, clientHeight: realHeight } = document.documentElement;
		const widthRadio = realWidth / 1920;
		const heightRadio = realHeight / 2171;
		setRadio({
			widthRadio,
			heightRadio,
		});
		scaleService.setWidthRadio(widthRadio);
	}, []);

	useEffect(() => {
		onResize();
		window.addEventListener("resize", onResize);
	}, [onResize]);

	useEffect(() => {
		const Arr = [];
		if (!previousFilter) return;
		if (filterValue.provinceId || filterValue.cityId || filterValue.districtId) {
			// 筛选省市区
			Arr.push(1);
		}
		if (filterValue.shopTypes) {
			// 筛选门店类型
			Arr.push(2);
		}
		if (filterValue.shopIds) {
			// 筛选门店
			Arr.push(3);
		}
		if (filterValue.productTypeIds) {
			// 筛选服务类型
			Arr.push(4);
		}

		// 筛选时间不是今天的日期统一认为时间区间发生改变
		if (!isToday(filterValue.startTime!) || !isToday(filterValue.endTime!)) {
			Arr.push(5);
		}

		console.log(Arr, "?????");
		setFilterTypesArr(Arr);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterValue]);

	return (
		<Container>
			<div className='formContainer container'>
				<From onChange={changeFormVal} onReset={resetFilter}></From>
			</div>
			<div className='statusContainer container'>
				<div className='newRevenues'>
					<Charts type='revenues' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				</div>
				<div className='newVolumes'>
					<Charts type='volumes' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				</div>
			</div>
			<div className='orderNumContainer container'>
				<div className='orderItem orderItem1'>
					<div className='first-line'>
						<div className='dailyLeft'>
							<Charts type='dailyCarWash' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
						</div>
					</div>
					<div className='second-line'>
						<div className='revenues_top'>
							<Charts type='newSite' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
						</div>
						<div className='revenues_bot'>
							<Charts type='siteStatus' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
						</div>
					</div>
				</div>

				<div className='orderItem orderItem1 orderItem2'>
					<div className='first-line newPlanContainer'>
						<div className='dailyLeft'>
							<Charts type='month&YearRevenues' filterVal={filterValue} filterTypes={filterTypesArr} options={radio}></Charts>
						</div>
					</div>

					<div className='second-line performance'>
						<div className='orderItem performanceContainer'>
							<Charts type='performanceVol' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
						</div>
						<div className='orderItem performanceContainer orderItem3'>
							<Charts type='performanceRevenues' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
						</div>
					</div>
				</div>

				{/* <div className=' orderItem orderItem2'>
					<div className='orderItem  newPlanContainer'>
						<Charts type='month&YearRevenues' filterVal={filterValue} isFetch options={radio}></Charts>
					</div>
					<div className='orderItem'>
						<Charts type='performanceVol' filterVal={filterValue} isFetch={includes(filterTypesArr, [1, 6])}></Charts>
					</div>
					<div className='orderItem' style={{ marginRight: 0 }}>
						<Charts type='performanceRevenues' filterVal={filterValue} isFetch={includes(filterTypesArr, [1])}></Charts>
					</div>
				</div> */}
			</div>
			<div className='monthCard container'>
				<div className='monthCardItem'>
					<Charts type='monthCard' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				</div>
				<div className='recharge monthCardContainer'>
					<div className='part_1 part'>
						<Charts type='recharge' options={{ type: "recharge" }} filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
					</div>
					<div className='part_2 part'>
						<Charts type='recharge' options={{ type: "package" }} filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
					</div>
				</div>
				<div className='otherRevenue monthCardContainer' style={{ marginRight: 0 }}>
					<div className='part_1 part'>
						<Charts type='waitingDev' options={{ type: "fleets" }} filterVal={filterValue}></Charts>
					</div>
					<div className='part_2 part'>
						<Charts type='orderNumber' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
					</div>
				</div>
			</div>
			<div className='userContainer container'>
				<div className='userLeft'>
					<Charts type='customerTimes' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				</div>
				<div className='userRight'>
					<Charts type='customerAnalysis' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				</div>
			</div>
			<div className='barContainer container'>
				<div className='barLeft'>
					<Charts type='orderTrend' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				</div>
				<div className='barRight'>
					{/* <Charts type='waitingDev' options={{ type: "EcoSales" }} filterVal={filterValue} filterTypes={filterTypesArr}></Charts> */}
					<Charts type='ecoSales' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				
				</div>
			</div>
			<div className='rankContainer container'>
				<div className='rankingChart'>
					<Charts type='exteriorRanking' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				</div>
				<div className='rankingChart'>
					<Charts type='interiorRanking' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				</div>
				<div className='rankingChart'>
					<Charts type='detailingRanking' filterVal={filterValue} filterTypes={filterTypesArr}></Charts>
				</div>
			</div>
		</Container>
	);
};

// 哪一块是独立的模块就将它设为 position: relative

const Container = styled.div`
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	background: #f7f9ff;
	.container {
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		margin-bottom: 16px;
		width: 100%;
	}
	.formContainer {
		min-height: 10vh;
		box-sizing: border-box;
		background: #fff;
		padding: 16px 20px;
		padding-bottom: 0;
	}
	.statusContainer {
		height: 12vmax;
		display: flex;
		justify-content: space-between;
		.newRevenues {
			flex: 1;
			background: #fff;
			position: relative;
			border-radius: 8px;
			/* width: 25%; */
			height: 100%;
			margin-right: 16px;
		}

		.newVolumes {
			flex: 2;
			border-radius: 8px;
			background: #fff;
			overflow: hidden;
			position: relative;
		}
	}

	.orderNumContainer {
		height: 35vmax;
		display: flex;
		justify-content: space-between;
		.orderItem {
			position: relative;
			background: #fff;
			margin-right: 16px;
			height: 100%;
			width: 30%;
			border-radius: 8px;
			overflow: hidden;
		}
		.newPlanContainer {
			border-radius: 8px;
			background: #fff;
			overflow: hidden;
			position: relative;
			margin-bottom: 16px;
		}
		.performanceContainer {
			width: 50%;
		}
		.orderItem1 {
			width: 38.5%;
			background: rgba(0, 0, 0, 0);
			display: flex;
			flex-direction: column;
			.first-line {
				height: 40%;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 16px;
				.dailyLeft {
					position: relative;
					width: 100%;
					height: 100%;

					border-radius: 8px;
				}
				.dailyRight {
					position: relative;
					width: 48.5%;
					height: 90%;
					background: #fff;
					margin-bottom: 16px;
					border-radius: 8px;
				}
			}
			.second-line {
				height: 60%;
				width: 100%;
				display: flex;
				flex-direction: column;
				/* justify-content: space-between; */
				.revenues_top {
					position: relative;
					width: 100%;
					height: 40%;
					background: #fff;
					margin-bottom: 16px;
					border-radius: 8px;
				}
				.revenues_bot {
					width: 100%;
					height: 60%;
					display: flex;
					justify-content: space-between;
					border-radius: 8px;
					background: #fff;
					position: relative;
				}
			}
			.performance {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
		}
		.orderItem2 {
			flex: 1;
			margin-right: 0px;
		}
		.orderItem3 {
			margin-right: 0px;
		}
	}
	.monthCard {
		height: 24vmax;
		display: flex;
		justify-content: space-between;
		.monthCardItem {
			width: 38.5%;
			height: 100%;
			margin-right: 16px;
			background: #fff;
			border-radius: 8px;
			position: relative;
		}
		.monthCardContainer {
			width: 30%;
			height: 100%;
			margin-right: 16px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.part {
				width: 100%;
				height: 48%;
				border-radius: 8px;
				background: #fff;
				position: relative;
			}
		}
	}
	.userContainer {
		height: 16vmax;
		display: flex;
		justify-content: space-between;
		.userLeft {
			width: 38.5%;
			height: 100%;
			position: relative;
			background: #fff;
			border-radius: 8px;
			margin-right: 16px;
		}
		.userRight {
			flex: 1;
			background: #fff;
			position: relative;
			border-radius: 8px;
			overflow: hidden;
		}
	}
	.barContainer {
		height: 24vmax;
		display: flex;
		justify-content: space-between;
		.barLeft {
			width: 73%;
			height: 100%;
			background: #fff;
			position: relative;
			border-radius: 8px;
			margin-right: 16px;
		}
		.barRight {
			flex: 1;
			background: #fff;
			position: relative;
			border-radius: 8px;
		}
	}
	.rankContainer {
		height: 24vmax;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.rankingChart {
			width: calc(100% / 3 - 32px / 3);
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto !important;
			background: #fff;
			position: relative;
			border-radius: 8px;
		}
	}
`;
