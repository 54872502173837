import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import maintenanceRecordsService from "src/module/maintenanceRecords/maintenanceRecordsService";
import { MaintenanceRecordsEntity } from "src/module/maintenanceRecords/maintenanceRecordsEntity";
import { CheckOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input } from "antd";
import moment from "moment";

export const MaintenanceDetail: FC = () => {
	const params = useParams();
	const [info, setInfo] = useState<MaintenanceRecordsEntity>({});

	const init = useCallback(async () => {
		const { data } = await maintenanceRecordsService.detail(Number(params.id));
		if (data.code === 200) {
			console.log(data.data);

			// const newProject = JSON.parse(data.data.project);
			setInfo({ ...data.data });
			console.log(moment(data.data?.nextTime, "YYYY-MM-DD HH:mm:ss"));
			
		}
	}, [params.id]);

	useEffect(() => {
		init();
	}, [init]);
	return (
		<Container>
			{info?.project &&
				JSON.parse(info.project).map((item: any, index: number) => {
					if (item.title == "下次维保截止时间" || item.title == "本次维保备注") return;
					return (
						<div className='container' key={index}>
							<div className='head'>
								<div className='icon'></div>
								<div className='title'> {item.title}</div>
							</div>
							<div className='valueContainer'>
								{item.value.split(",").map((valueItem: string, valueIndex: number) => {
									return (
										<div className='value' key={valueIndex}>
											<div className='inner'>{valueItem}</div>
											<CheckOutlined className='inner' />
										</div>
									);
								})}
							</div>
						</div>
					);
				})}

			<Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} label='维保备注'>
				<Input.TextArea value={info?.comments} />
			</Form.Item>
			
			<Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} label='下次维保截止时间'>
				<DatePicker showTime value={moment(info?.nextTime, "YYYY-MM-DD HH:mm:ss")}/>
			</Form.Item>

			<div className='empty'></div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.container {
		margin: 20px auto 20px 100px;
		.head {
			display: flex;
			align-items: center;
			.icon {
				margin-right: 5px;
				width: 3px;
				height: 18px;
				background: #1b78f2;
			}
			.title {
				font-size: 16px;
				font-weight: 500;
				color: #333333;
			}
		}
		.valueContainer {
			display: flex;
			.value {
				margin-top: 5px;
				padding: 0 10px;
				width: 30%;
				height: 48px;
				border-radius: 4px;
				background: #1b78f2;
				margin-right: 5px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.inner {
					color: #ffffff;
				}
			}
		}

		.empty {
			width: 100px;
			height: 100px;
		}
	}
`;
