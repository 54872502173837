import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, message, Radio, TimePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SelectSite } from "../../module/site/component/select.site";
import { UploadImg } from "../../common/components/UploadImg";
import productCombService from "../../module/product/service/product.comb.service";
import siteService from "src/module/site/service/site.service";
import { SiteEntity } from "src/module/site/entity/site.entity";
import { MultiSelectComboProductBySite } from "src/module/product/component/multi.select.comboProduct.by.shop";

export const ProductCombDetail: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const params = useParams();
	const [form] = useForm();
	const [sonId, setSonId] = useState<string | undefined>();
	sonId
	
	const changeSite = async (e: number) => {
		
		const { data } = await siteService.all({ parentId: e });
		const IdArr = data.data.map((item: SiteEntity) => {
			return item.id;
		});
		const IdArrs = IdArr.join(",");
		setSonId(IdArrs);
	};

	useEffect(() => {
		if (params.id) {
			productCombService.detail(Number(params.id)).then(async (res) => {
				if (res.data.code === 200) {
					const { data } = await siteService.all({ parentId: res.data.data.shopId });

					const IdArr = data.data.map((item: SiteEntity) => {
						return item.id;
					});
					const IdArrs = IdArr.join(",");
					setSonId(IdArrs);

					form.setFieldsValue({
						...res.data.data,
						time: [moment(res.data.data.businessHoursStart, "HH:mm:ss"), moment(res.data.data.businessHoursEnd, "HH:mm:ss")],
						date:res.data.data.beginDate?[moment(res.data.data.beginDate, "YYYY-MM-DD"), moment(res.data.data.endDate, "YYYY-MM-DD")]:undefined,
					});
				}
			});
		}
	}, [form, params.id]);

	const onFinish = async (value: any) => {
		const searchObj = {
			...value,
			date: undefined,
			time: undefined,
			businessHoursStart:value.time&& value.time[0]&&value.time[0].format("HH:mm:ss"),
			businessHoursEnd: value.time&& value.time[1]&&value.time[1].format("HH:mm:ss"),
			beginDate: value.date&& value.date[1]&&value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endDate:value.date&& value.date[1]&&value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			// comboProducts:comboProducts
		};

		if (!params.id) {
			const { data } = await productCombService.add(searchObj);
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/site/combo");
			} else {
				message.error(data.msg);
			}
		}
		if (params.id) {
			const { data } = await productCombService.update({ ...searchObj, id: Number(params.id) });
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/site/combo");
			} else {
				message.error(data.msg);
			}
		}
	};

	return (
		<Container>
			<Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onFinish} form={form}>
				<Form.Item label={t("image")} name='imageUrl' required>
					<UploadImg />
				</Form.Item>
				<Form.Item label={t("productCombName")} name='name' required>
					<Input placeholder={t("productCombName")} />
				</Form.Item>
				<Form.Item label={t("salePrice")} name='currentPrice' required>
					<Input placeholder={t("salePrice")} />
				</Form.Item>
				<Form.Item label={t("saleMoney")} name='saleMoney' required>
					<Input placeholder={t("saleMoney")} />
				</Form.Item>
				<Form.Item label={t("constructionMoney")} name='constructionMoney' required>
					<Input placeholder={t("constructionMoney")} />
				</Form.Item>
				<Form.Item label={t("promoteMoney")} name='promoteMoney' required>
					<Input placeholder={t("promoteMoney")} />
				</Form.Item>
				<Form.Item label={t("SaleStatus")} name='status' required>
					<Radio.Group>
						<Radio value={1}>{t("onSale")}</Radio>
						<Radio value={2}>{t("off")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("displayStatus")} name='displayStatus' required>
					<Radio.Group>
						<Radio value={0}>{t("noDisplay")}</Radio>
						<Radio value={1}>{t("display")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("onSaletime")} name='time'>
					<TimePicker.RangePicker />
				</Form.Item>
				<Form.Item label={t("effectiveDate")} name='date'>
					<DatePicker.RangePicker />
				</Form.Item>
				<Form.Item label={t("site.name")} name='shopId' required>
					<SelectSite
						level={1}
						onChange={(e: number) => {
							changeSite(e);
						}}
					/>
				</Form.Item>
				<Form.Item label={t("productName")} name='comboProducts' required>
					<MultiSelectComboProductBySite />
				</Form.Item>

				<Form.Item label={t("sort")} name='sort'>
					<Input placeholder={t("sort")} />
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 4 }}>
					<Button type='primary' htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
