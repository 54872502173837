import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, message, Modal, Space, Table } from "antd";
import { AxiosResponse } from "axios";

import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PaginationEntity } from "../../common/entity/pagination.entity";
import { ResponseEntity } from "../../common/entity/response.entity";
import { checkPermission } from "../../module/admin/service/permission.service";
import { BannerEntity } from "../../module/tools/entity/banner.entity";
import bannerService from "../../module/tools/service/banner.service";

const { confirm } = Modal;
export const BannerList: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
		customPageSize: 15,
		customPageNum: 1,
		total: 0,
	});
	const [bannerList, setBannerList] = useState<BannerEntity[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState(true);
	const init = useCallback(async () => {


		
		setLoading(true);
		const { data } = await bannerService.list({ ...paginationObj });
		if (data.code === 200) {
			setBannerList(data.data);
			setTotal(data.total);
		}
		setLoading(false);
	}, [paginationObj]);

	useEffect(() => {
		init();
	}, [init]);

	const deleteBanner = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data }: AxiosResponse<ResponseEntity> = await bannerService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const columns = [
		{
			width: 180,
			title: t("imageUrl"),
			dataIndex: "imageUrl",
			key: "imageUrl",
			render: (text: string | undefined) => {
				return <img src={text} width='200' alt='' style={{ border: "1px solid #e1e1e1" }} />;
			},
		},
		{
			width: 180,
			title: t("imageOrder"),
			dataIndex: "imageOrder",
		},
		{
			title: t("appletLinkUrl"),
			dataIndex: "appletLinkUrl",
		},
		// {
		// 	width: 130,
		// 	title: t("isRecommend"),
		// 	dataIndex: "isRecommend",
		// },
		{
			title: t("action"),
			render: (text: string, record: BannerEntity) => {
				return (
					<Space>
						{checkPermission(100) && (
							<Button
								type='default'
								onClick={() => {
									navigate(`/banner/detail/${record.id}`);
								}}
							>
								{t("edit")}
							</Button>
						)}
						{checkPermission(101) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									deleteBanner(record.id ?? 0);
								}}
							>
								{t("delete")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];
	return (
		<Container>
			{checkPermission(99) && (
				<div>
					<Space>
						<Space>
							<Button type={"primary"} icon={<PlusOutlined />} onClick={() => navigate(`/banner/detail`)}>
								{t("newBanner")}
							</Button>
						</Space>
					</Space>
				</div>
			)}

			<Table
				columns={columns}
				loading={loading}
				dataSource={bannerList}
				pagination={{
					pageSize: paginationObj.customPageSize,
					current: paginationObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setPaginationObj({
						...paginationObj,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
					setPaginationObj({
						...paginationObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;
	.headers {
		width: 100%;
		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;
			.searchItem {
				min-width: 150px;
				text-align: right;
			}
		}
	}
`;
