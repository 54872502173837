import { FC, lazy, Suspense, useState } from "react";
import Loading from "./otherComponents/loading";
import Notification from "./otherComponents/notification";
import { formValue } from "src/module/dashboard/entity/form.entity";
import { useTranslation } from "react-i18next";
import getDescriptionText from "./toolTipsText";

// options里的type为 区分相同component不同业务用 不能乱用
interface Props extends myProps {
	filterVal: formValue;
	previousVal?: formValue;
	filterTypes?: number[];
	options?: {
		type?: optionsType["type"];
		[x: string]: any;
	};
}

// type descriptionType = {
// 	[key in optionsType["type"] | myProps["type"]]: string
// }

export const Charts: FC<Props> = (props) => {
	const AsyncComponent: React.LazyExoticComponent<React.ComponentType<chartsProps>> = lazy(() => import(`./charts/${props.type}`));
	const [loading, setLoading] = useState(true);
	const changeLoading = (isLoading: boolean) => {
		setLoading(isLoading);
	};
	const { t } = useTranslation();
	const descriptionTextObj = getDescriptionText(t);

	return (
		<Suspense fallback={<Loading isLoading></Loading>}>
			<Loading isLoading={loading}></Loading>
			<Notification isShow={!loading} type={descriptionTextObj[props.options?.type || props.type]}></Notification>
			<AsyncComponent onChange={changeLoading} {...props.options} filterVal={props.filterVal} filterTypes={props.filterTypes!}></AsyncComponent>
		</Suspense>
	);
};
