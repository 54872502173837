import { FC, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, Modal, Space, Table, message } from "antd";
import moment from "moment";
import { CustomerFeedbackEntity } from "src/module/customerFeedback/customer.feedback.entity";
// import { PaginationEntity } from "src/common/entity/pagination.entity";
import { useNavigate } from "react-router-dom";
import customerFeedbackService from "src/module/customerFeedback/customer.feedback.service";
import { checkPermission } from "src/module/admin/service/permission.service";
import Util from "src/common/Util";
import { SelectSite } from "src/module/site/component/select.site";

const { RangePicker } = DatePicker;
const { confirm } = Modal;
export const CustomerFeedback: FC = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { t, i18n } = useTranslation();
	const [dataList, setDataList] = useState<CustomerFeedbackEntity[]>([]);
	const [total, setTotal] = useState(0);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [searchObj, setSearchObj] = useState({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	const init = useCallback(async () => {
		const { data } = await customerFeedbackService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.records);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const onSearch = async (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			date: undefined,
			startTime: value.date && value.date[0] && value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.date && value.date[1] && value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageSize: 15,
			customPageNum: 1,
		});
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await customerFeedbackService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const exportList = async () => {
		const res = await customerFeedbackService.export({ ...searchObj });
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "customerFeedback");
		message.success(t("export.success"));
	};

	const columns = [
		{
			title: t("customer-info"),
			render: (record: CustomerFeedbackEntity) => {
				return (
					<div>
						<div> {record.name || record.nickName}</div>
						<div> {record.mobile}</div>
						<div> {record.licenseNumber}</div>
					</div>
				);
			},
		},
		{ title: t("orderNum"), dataIndex: "orderNum" },
		{
			title: t("consultType"),
			dataIndex: "type",
			render: (text: keyof typeof customerFeedbackService.questionType) => {
				return customerFeedbackService.questionType[text];
			},
		},
		{
			title: t("channel"),
			dataIndex: "channel",
			render: (text: keyof typeof customerFeedbackService.channelType) => {
				return customerFeedbackService.channelType[text];
			},
		},
		{ title: t("createdAdmin"), dataIndex: "createdName" },
		{ title: t("site.name"), dataIndex: i18n.language === "zh-CN" ? "shopTitle" : "shopNumber" },
		{ title: t("concreteIssue"), dataIndex: "question" },
		{ title: t("treatment"), dataIndex: "programme" },
		{
			title: t("closedTicket"),
			render: (record: any) => {
				return !record.close ? "否" : "是";
			},
		},
		{
			title: t("treatmentRes"),
			render: (record: any) => {
				const treatmentRes = record.status;
				return customerFeedbackService.programmeList[treatmentRes];
			},
		},
		{ title: t("createdTime"), dataIndex: "createdTime" },
		{ title: t("remark"), dataIndex: "comments" },
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(30803) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}

						{checkPermission(30802) && record.close !== 1 && (
							<Button
								type='primary'
								onClick={() => {
									window.open(`/customerFeedback/${record.id}`, "_blank");
								}}
							>
								{t("handle")}
							</Button>
						)}

						{/* {checkPermission(30802) && (
							<Button
								type='primary'
								onClick={() => {
									window.open(`/customerFeedback/${record.id}`, '_blank')
								}}
							>
								{t("edit")}
							</Button>
						)} */}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onSearch} form={form} initialValues={{ date: [moment(), moment()] }}>
				<Form.Item label={t("mobile")} name='mobile'>
					<Input allowClear />
				</Form.Item>
				{/* <Form.Item label={t("licenseNumber")} name='licenseNumber'>
					<Input allowClear />
				</Form.Item> */}
				<Form.Item label={t("site")} name='shopId'>
					<SelectSite level={1} />
				</Form.Item>
				<Form.Item label={t("orderNum")} name='orderNum'>
					<Input allowClear />
				</Form.Item>
				<Form.Item label={t("createdTime")} name='date'>
					<RangePicker allowClear />
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						{checkPermission(30802) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/customerFeedback/add`);
								}}
							>
								{t("add")}
							</Button>
						)}
						<Button
							type='primary'
							onClick={() => {
								// navigate(`/customerFeedback/add`);
								exportList();
							}}
						>
							{t("export")}
						</Button>
					</Space>
				</Form.Item>
			</Form>
			<Table
				style={{ marginTop: "30px" }}
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => Number(record.id)}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
