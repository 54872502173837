/*
 * @Author: Yujia fyujia0815@gmail.com
 * @Date: 2022-08-30 10:34:07
 * @LastEditors: Yujia fyujia0815@gmail.com
 * @LastEditTime: 2022-12-01 13:10:33
 * @FilePath: \data-center-ts\src\module\product\component\select.product.type.tsx
 */
import styled from "@emotion/styled";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
import { ProductTypeListEntity } from "../entity/product.type.entity";
import productTypeService from "../service/product.type.service";
import i18n from "src/common/config/i18n";
const { Option } = Select;

export const SelectProductType = (props: Props) => {
	const { t } = useTranslation();
	const [list, setList] = useState<ProductTypeListEntity[]>([]);

	useEffect(() => {
		productTypeService.listAll().then((res) => {
			if (res.data.code === 200) {
				setList(res.data.data);
			}
		});
	}, []);

	const onChange = (value: number) => {
		props.onChange(value);
	};

	return (
		<Container>
			<Select style={{ width: 150 }} allowClear placeholder={t("product.type")} onChange={onChange} value={props.value}>
				{list.map((item) => {
					return (
						<Option value={item.id} key={item.id}>
							{i18n.language === "zh-CN" ? item.title : item.enTitle}
						</Option>
					);
				})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
