import { http } from "../../../common/http";
import { CustomerParam } from "../params/customer.param";

class CustomerService {
	list = async (param: CustomerParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user",
			method: "GET",
			params: param,
		});
	};

	all = async (param: CustomerParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user/all",
			method: "GET",
			params: param,
		});
	};

	detail=async (id: number) => {
		console.log(id);
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user/"+id,
			method: "GET",
			data: id,
		});
	};

	disableEnable = async (param: CustomerParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/user/disable-enable/${param.id}`,
			method: "PUT",
			data: param,
		});
	};

	balanceChange = async (param: CustomerParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/user/balanceChange/${param.id}`,
			method: "PUT",
			data: param,
		});
	};

	exportCustomerList = async (param: CustomerParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};


	getToken = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user/token/"+id,
			method: "GET",
			data: id,
		});
	};
}

export default new CustomerService();
