import { FC, useEffect } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { MultiSelectSite } from "../../module/site/component/multi.select.site";
import fullGiftService from "../../module/fullGift/service/fullGift.service";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { MultiSelectCouponByType } from "../../module/coupon/component/MultiSelectCouponByType";

export const FullGiftDetail: FC = () => {
	const { t } = useTranslation();
	const { RangePicker } = DatePicker;
	const params = useParams();
	const [form] = useForm();
	const navigate = useNavigate();

	useEffect(() => {
		if (params.id)
			fullGiftService.detail(Number(params.id)).then((res) => {
				if (res.data.code === 200) {
					form.setFieldsValue({
						...res.data.data,
						time: [moment(res.data.data.start), moment(res.data.data.end)],
					});
				}
			});
	}, [form, params.id]);

	const onFinish = async (value: any) => {
		if (params.id) {
			const { data } = await fullGiftService.update({
				...value,
				time: undefined,
				start: value.time[0].format("YYYY-MM-DD"),
				end: value.time[1].format("YYYY-MM-DD"),
				id: Number(params.id),
			});
			
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/activity/fullGift");
			} else {
				message.error(data.msg);
			}
		} else {
			const { data } = await fullGiftService.add({
				...value,
				time: undefined,
				start: value.time[0].format("YYYY-MM-DD"),
				end: value.time[1].format("YYYY-MM-DD"),
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/activity/fullGift");
			} else {
				message.error(data.msg);
			}
		}
	};

	return (
		<Container>
			<Form labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} style={{ padding: 20 }} form={form} onFinish={onFinish}>
				<Form.Item label={t("activitySite")} name='shopIds'>
					<MultiSelectSite />
				</Form.Item>
				<Form.Item label={t("satisfyAmount")} name='amount'>
					<Input />
				</Form.Item>
				<Form.Item label={t("statisticsDays")} name='days'>
					<Input />
				</Form.Item>
				<Form.Item label={t("giftCoupons")} name='couponIds'>
					<MultiSelectCouponByType />
				</Form.Item>
				<Form.Item label={t("availablePeriod")} name='time'>
					<RangePicker />
				</Form.Item>
				<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
					<Button type='primary' htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
