import { Select } from "antd";
import { PropsEntity } from "../../../common/entity/props.entity";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const SelectCustomerStatus = (props: PropsEntity) => {
	const { t } = useTranslation();

	return (
		<>
			<Select value={props.value ? Number(props.value) : undefined} allowClear onChange={props.onChange} placeholder={t("selectCustomerStatus")}>
				<Option value='1'>{t("unlock")}</Option>
				<Option value='2'>{t("lock")}</Option>
			</Select>
		</>
	);
};
