import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, message, Radio } from "antd";

import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { UploadImg } from "../../../common/components/UploadImg";
import { MultiSelectSite } from "../../../module/site/component/multi.select.site";
import MyEditor from "../../../common/components/MyEditor";
import { MultiSelectCouponByType } from "src/module/coupon/component/MultiSelectCouponByType";
import loginActivityListService from "src/module/login.activity/service/loginActivityListService";
import couponService from "src/module/coupon/service/coupon.service";
import { CouponEntity } from "src/module/coupon/entity/coupon.entity";

export const LoginActivityListDetail: FC = () => {
	const [editorLoading, setEditorLoading] = useState<boolean>(false);
	const { TextArea } = Input;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const params = useParams();
	const [form] = useForm();
	const [couponArr, setCouponArr] = useState("");

	useEffect(() => {
		if (params.id){
			loginActivityListService.detail(Number(params.id)).then((res) => {
				if (res.data.code === 200) {
					form.setFieldsValue({
						...res.data.data,
						activityTime: [moment(res.data.data.beginDate), moment(res.data.data.endDate)],
						activityArea: [res.data.data.provinceId, res.data.data.cityId, res.data.data.districtId],
					});
					setEditorLoading(true);
				}
				
			});
		}else {
			setEditorLoading(true);
		}
			
	}, [form, params.id]);

	const onFinish = async (value: any) => {
		const { data } = await couponService.noAuth({ couponIds: value.couponIds });
		if (data.code === 200) {
			console.log(data.data.list);
			const Arr = data.data.list.map((item: CouponEntity) => {
				return item.title;
			});
			setCouponArr(Arr.join(","));
		}

		const param = {
			...value,
			activityTime: undefined,
			beginDate: value.activityTime[0].format("YYYY-MM-DD") + " 00:00:00",
			endDate: value.activityTime[1].format("YYYY-MM-DD") + " 23:59:59",
			provinceId: value.activityArea && value.activityArea[0],
			cityId: value.activityArea && value.activityArea[1],
			districtId: value.activityArea && value.activityArea[2],
			couponName: couponArr,
		};


		if (!params.id) {
			const { data } = await loginActivityListService.add(param);
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/activity/loginActivity");
			} else {
				message.error(data.msg);
			}
		} else {
			const { data } = await loginActivityListService.update({
				...param,
				id: Number(params.id),
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/activity/loginActivity");
			} else {
				message.error(data.msg);
			}
		}}

	
	return (
		<Container>
			<Form labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} style={{ padding: 20 }} onFinish={onFinish} form={form}>
				<Form.Item label={t("image")} name='imageUrl'>
					<UploadImg />
			
				</Form.Item>
				<Form.Item label={t("shopName")} name='shopId'>
					<MultiSelectSite />
				</Form.Item>
				<Form.Item label={t("activityName")} name='title'>
					<Input placeholder={t("activityName")} />
				</Form.Item>

				<Form.Item label={t("sort")} name='sort'>
					<Input placeholder={t("sort")} />
				</Form.Item>

				<Form.Item label={t("活动允许参加次数")} name='loginGrantNum'>
					<Input />
				</Form.Item>
				<Form.Item label={t("限制每人参加次数")} name='loginLimitNum'>
					<Input />
				</Form.Item>
				<Form.Item label={t("activityTime")} name='activityTime'>
					<DatePicker.RangePicker />
				</Form.Item>

				<Form.Item label={t("choosedCoupon")} name='couponIds'>
					<MultiSelectCouponByType />
				</Form.Item>

				<Form.Item label={t("status")} name='status'>
					<Radio.Group>
						<Radio value={1}>{t("onSale")}</Radio>
						<Radio value={2}>{t("off")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("isHomePageShow")} name='isHomePageShow'>
					<Radio.Group>
						<Radio value={0}>{t("no")}</Radio>
						<Radio value={1}>{t("yes")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("activityBriefly")} name='sketch'>
					<TextArea rows={4} placeholder={t("activityBriefly")} />
				</Form.Item>
				<Form.Item label={t("activityRule")} name='rule'>
				{editorLoading && <MyEditor />}
				</Form.Item>
				<Form.Item label={t("activityContent")} name='content'>
				{editorLoading && <MyEditor />}
				</Form.Item>
				<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
					<Button type='primary' htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
