import { FC, useLayoutEffect, useState } from "react";
import { Moment } from "moment";
import moment from "moment";
import { useForm } from "antd/es/form/Form";
import { SearchOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { SelectSite } from "src/module/site/component/select.site";
import { getInitialProps, useTranslation } from "react-i18next";
import { Form, Col, Row, Radio, DatePicker, Button, RadioChangeEvent, Input, Tag, Modal, Select } from "antd";
import { cityObj } from "src/module/overview/entity/statistics.entity";
// import { SelectCity } from "src/module/overview/components/select.city.all";
import { SelectCity } from "src/module/overview/components/select.city";
import statisticsService from "src/module/overview/service/statistics.service";
import { formValue } from "src/module/dashboard/entity/form.entity";
import i18n from "src/common/config/i18n";
import useI18n from "src/common/hooks/useI18n";

interface formValInterface {
	siteType?: string;
	site?: { label: string; value: number }[];
	// serviceType?: string;
	serviceType?: { label: string; value: number }[];
	siteCity?: cityObj;
	openDate?: Moment;
	timeRange?: [Moment, Moment];
	quickSelectTime?: string;
	serviceTypeLabel?: string;
	shopTypeIds?: string;
	shopTypes?: string;
	cityLabel?: { provinceName: ""; cityName: ""; districtName: "" };
}

interface formProps {
	onChange?: (obj: formValue) => void;
	onReset?: () => void;
}

interface cityLabelObj {
	provinceName?: string | undefined;
	cityName?: string | undefined;
	districtName?: string | undefined;
}

const NewForm: FC<formProps> = (props) => {
	const { t } = useTranslation();
	const chain = useI18n();
	const [form] = useForm();
	const [filterForm] = useForm();
	const formObj = {
		form,
		filterForm,
	};
	const siteTypeOptions = [
		{ label: t("express"), value: "1" },
		{ label: t("superCenter"), value: "2" },
	];
	const [cityObj, setCityObj] = useState<cityObj>({});
	const [cityNameObj, setCityNameObj] = useState<cityLabelObj>({});
	const [productTypeList, setProductTypeList] = useState<{ label: string; value: number }[]>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [finalFormVal, setFinalFormVal] = useState<formValInterface>({
		timeRange: [moment(new Date()), moment(new Date())],
		quickSelectTime: "today",
	});
	const initFormVal: formValInterface = {
		timeRange: [moment(new Date()), moment(new Date())],
		quickSelectTime: "today",
	};

	const timeSelectOptions = [
		{ label: t("today"), value: "today" },
		{ label: t("thisMonth"), value: "currentMonth" },
		{ label: t("thisQuarter"), value: "currentSeason" },
		{ label: t("thisYear"), value: "currentYear" },
	];

	const handleModalInputChange = (e: any) => {
		setCityObj(e);
	};
	useLayoutEffect(() => {
		listProductType();
	}, []);
	const listProductType = () => {
		statisticsService.goodsTypeList().then((res) => {
			if (res.data.code === 200) {
				const arr: { label: string; value: number }[] = [];
				res.data.data.forEach((item: { title: string; enTitle: string; id: number }) => {
					arr.push({
						label: i18n.language === "zh-CN" ? item.title : item.enTitle,
						value: item.id,
					});
				});
				setProductTypeList(arr);
			}
		});
	};
	const openModal = () => {
		setIsModalOpen(true);
		const obj = { ...finalFormVal, ...form.getFieldsValue() };
		filterForm.setFieldsValue(obj);
		setFinalFormVal({ ...obj });
	};
	const onFinish = (values: formValInterface) => {
		form.setFieldsValue(values);
		const searchObjNoUndefined = JSON.parse(JSON.stringify(values));
		// 此处json序列化反序列化直接筛选掉无效数据

		// const serviceTypeArr: string[] = [];
		// searchObjNoUndefined.serviceType &&
		// 	searchObjNoUndefined.serviceType.forEach((item: any) => {
		// 		serviceTypeArr.push(item.label);
		// 	});
		// searchObjNoUndefined.serviceTypeLabel = serviceTypeArr.join("、");
		setFinalFormVal({ ...searchObjNoUndefined, cityLabel: cityNameObj });
		setIsModalOpen(false);
	};
	const onSearch = () => {
		const { timeRange } = finalFormVal;
		const [startTime, endTime] = timeRange as [Moment, Moment];
		const searchObj = {
			...finalFormVal,
			...{
				startDate: moment(startTime)?.format("yyyy-MM-DD 00:00:00"),
				endDate: moment(endTime)?.format("yyyy-MM-DD 23:59:59"),
			},
		};
		// 通过json序列化反序列化过滤掉无效的值
		props.onChange &&
			props.onChange(
				JSON.parse(
					JSON.stringify({
						provinceId: searchObj.siteCity?.provinceId,
						cityId: searchObj.siteCity?.cityId,
						districtId: searchObj.siteCity?.districtId,
						startTime: searchObj.startDate,
						endTime: searchObj.endDate,
						productTypeIds: getValues(searchObj.serviceType),
						shopIds: getValues(searchObj.site),
						shopTypeIds: searchObj.siteType,
						shopTypes: searchObj.siteType,
					})
				)
			);
	};
	const getValues = (arr: any) => {
		if (!arr || !arr.length) {
			return void 0;
		}
		const newArr: number[] = [];
		arr.forEach((item: { value: number }) => {
			newArr.push(item.value);
		});
		return newArr.join(",");
	};
	const changeTimeRange = (type: keyof typeof formObj) => {
		const { setFieldsValue } = formObj[type];
		return (e: RadioChangeEvent) => {
			const { value } = e.target;
			const end = moment(new Date()); // 结束 today
			if (value === "today") {
				setFieldsValue({
					timeRange: [end, end],
				});
				setTimeRange([end, end]);
				return;
			}
			if (value === "currentMonth") {
				const start = moment().startOf("month"); // 开始
				setFieldsValue({
					timeRange: [start, end],
				});
				setTimeRange([start, end]);
				return;
			}
			if (value === "currentSeason") {
				const start = moment().startOf("quarter"); // 开始
				// const end = moment().endOf("quarter"); // 结束
				setFieldsValue({
					timeRange: [start, end],
				});
				setTimeRange([start, end]);
				return;
			}
			if (value === "currentYear") {
				const start = moment().year(moment().year()).startOf("year");
				// const end = moment().year(moment().year()).endOf("year");
				setFieldsValue({
					timeRange: [start, end],
				});
				setTimeRange([start, end]);
				return;
			}
		};
	};
	// const closeTag = (type: string) => {
	// 	return (e: React.MouseEvent<HTMLElement>) => {
	// 		e.preventDefault();
	// 		setFinalFormVal({
	// 			...finalFormVal,
	// 			...{
	// 				[type]: void 0,
	// 			},
	// 		});
	// 	};
	// };

	const closeTag = (type: "siteType" | "serviceType" | "site" | "cityLabel") => {
		return (e: React.MouseEvent<HTMLElement>, value: number | undefined) => {
			e.preventDefault();

			if (type !== "siteType" && type !== "cityLabel") {
				const remainingTagArr = finalFormVal[type]?.filter((item) => {
					return item.value !== value;
				});
				setFinalFormVal({
					...finalFormVal,
					...{
						[type]: remainingTagArr,
					},
				});
				return;
			}
			if (type == "cityLabel") {
				setCityNameObj({ provinceName: "", cityName: "", districtName: "" });
				setCityObj({});
				setFinalFormVal({
					...finalFormVal,
					...{
						[type]: void 0,
					},
					siteCity: undefined,
				});
				return;
			}
			setFinalFormVal({
				...finalFormVal,
				...{
					[type]: void 0,
				},
			});
		};
	};
	const changeRangeTime = (type: keyof typeof formObj) => {
		const { setFieldsValue } = formObj[type];
		return (val: any) => {
			if (type === "form") {
				setTimeRange(val);
			}
			setFieldsValue({
				quickSelectTime: void 0,
			});
		};
	};

	const setTimeRange = (val: [Moment, Moment]) => {
		setFinalFormVal({
			...finalFormVal,
			...{
				timeRange: val,
			},
		});
	};
	const resetFilter = () => {
		form.resetFields();
		filterForm.resetFields();
		setCityObj({});
		setCityNameObj({ provinceName: "", cityName: "", districtName: "" });
		setFinalFormVal(initFormVal);
		props.onReset && props.onReset();
	};

	return (
		<Container>
			<Form name='basic' initialValues={initFormVal} onFinish={onSearch} autoComplete='off' form={form}>
				<Row justify='start'>
					<Col span={4} style={{ display: "none" }}>
						<Form.Item name='orderNum' label={t("orderNum")}>
							<Input type='number' placeholder={t("pleaseInputOrderNum")}></Input>
						</Form.Item>
					</Col>
					<Col span={7}>
						<Form.Item name='timeRange' label={chain.orderPeriod}>
							<DatePicker.RangePicker onChange={changeRangeTime("form")}></DatePicker.RangePicker>
						</Form.Item>
					</Col>
					<Col span={getInitialProps().initialLanguage === "zh-CN" ? 6 : 10}>
						<Form.Item name='quickSelectTime'>
							<Radio.Group buttonStyle='solid' options={timeSelectOptions} optionType='button' onChange={changeTimeRange("form")}></Radio.Group>
						</Form.Item>
					</Col>
					<Col span={2}>
						<Form.Item>
							<Button type='primary' htmlType='submit' icon={<SearchOutlined />}>
								{t("search")}
							</Button>
						</Form.Item>
					</Col>
					<Col span={2} className='moreCol'>
						<div className='filterContainer' onClick={openModal}>
							<div className='filterIconContainer'>
								<img src='https://splashtest.oss-cn-shanghai.aliyuncs.com/img1/dataCenter/filter.svg' />
							</div>
							<p>{t("moreFilter")}</p>
						</div>
					</Col>
				</Row>
				<Row justify='start'>
					<Col span={24}>
						<Form.Item label={t("currentFilter")}>
							<div className='tagContainer'>
								<Tag
									closable
									style={{ display: finalFormVal.siteType ? "block" : "none" }}
									onClose={(e) => {
										closeTag("siteType")(e, undefined);
									}}
								>
									{t("siteType") + "："}
									<span>{finalFormVal.siteType === "1" ? t("express") : t("superCenter")}</span>
								</Tag>

								{/* <Tag closable style={{ display: finalFormVal.serviceType?.length ? "block" : "none" }} onClose={closeTag("serviceType")}>
									{t("serviceType") + "："}
									<span>{finalFormVal.serviceTypeLabel}</span>
								</Tag> */}

								{finalFormVal.serviceType &&
									finalFormVal.serviceType.map((item) => {
										return (
											<Tag
												closable
												key={item.value}
												onClose={(e) => {
													closeTag("serviceType")(e, item.value);
												}}
											>
												{t("serviceType") + "："}
												<span>{item.label}</span>
											</Tag>
										);
									})}

								{finalFormVal.site &&
									finalFormVal.site.map((item) => {
										return (
											<Tag
												closable
												key={item.value}
												onClose={(e) => {
													closeTag("site")(e, item.value);
												}}
											>
												{t("site") + "："}
												<span>{item.label}</span>
											</Tag>
										);
									})}

								{finalFormVal.cityLabel?.provinceName && (
									<Tag
										closable
										onClose={(e) => {
											closeTag("cityLabel")(e, undefined);
										}}
									>
										{t("siteCity")}：{finalFormVal.cityLabel.provinceName}
										{finalFormVal.cityLabel.cityName && finalFormVal.cityLabel.cityName}
										{finalFormVal.cityLabel.districtName && finalFormVal.cityLabel.districtName}
									</Tag>
								)}
							</div>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Modal title={t("FilterConditions")} visible={isModalOpen} width='45%' getContainer={false} onCancel={() => setIsModalOpen(false)} footer={[]}>
				<Form name='filterForm' initialValues={initFormVal} onFinish={onFinish} autoComplete='off' form={filterForm} layout='vertical'>
					<Form.Item name='orderNum' label={t("orderNum")} style={{ display: "none" }}>
						<Input type='number' placeholder={t("pleaseInputOrderNum")}></Input>
					</Form.Item>
					<Form.Item name='quickSelectTime' label={t("QuickTimeSelection")}>
						<Radio.Group buttonStyle='solid' options={timeSelectOptions} optionType='button' onChange={changeTimeRange("filterForm")}></Radio.Group>
					</Form.Item>
					<Form.Item name='timeRange' label={t("orderPeriod")}>
						<DatePicker.RangePicker onChange={changeRangeTime("filterForm")}></DatePicker.RangePicker>
					</Form.Item>
					<Form.Item name='siteType' label={t("siteType")}>
						<Select placeholder={t("pleaseChooseSiteType")} options={siteTypeOptions}></Select>
					</Form.Item>
					<Form.Item name='serviceType' label={t("serviceType")}>
						<Select placeholder={t("pleaseChooseServiceType")} options={productTypeList} mode='multiple' labelInValue></Select>
					</Form.Item>
					<Form.Item name='site' label={t("site")}>
						<SelectSite isMultiple></SelectSite>
					</Form.Item>
					<Form.Item name='siteCity' label={t("siteCity")}>
						<SelectCity
							changeLabel={(e: any) => {
								setCityNameObj(e);
							}}
							labelName={cityNameObj}
							labelInValue
							provinceId={cityObj.provinceId}
							cityId={cityObj.cityId}
							districtId={cityObj.districtId}
							onChange={handleModalInputChange}
						/>
					</Form.Item>
					<Form.Item className='btnFormItem'>
						<div></div>
						<div>
							<Button onClick={resetFilter} key='reset'>
								{t("reset")}
							</Button>
							<Button key='submit' type='primary' htmlType='submit'>
								{t("Yes")}
							</Button>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};

const Container = styled.div`
	.ant-col-6,
	.ant-col-7 {
		margin-right: 4vmax;
	}
	.ant-modal-header {
		border: none;
		.ant-modal-title {
			font-weight: 600;
			font-size: 18px;
		}
	}
	.ant-modal-footer {
		border: none;
	}
	.ant-modal-body {
		.btnFormItem {
			position: relative;
			top: 1vmax;
			.ant-form-item-control-input-content {
				display: flex;
				justify-content: space-between;
				.ant-btn-default {
					margin-right: 20px;
				}
			}
		}
		.ant-form-item-label {
			font-size: 14px;
			font-weight: 550;
		}
		.ant-radio-group {
			width: 100%;
		}
		.ant-radio-button-wrapper {
			width: 25%;
		}
	}

	.moreCol {
		display: flex;
		box-sizing: border-box;
		position: absolute;
		right: 30px;
		font-size: 12px;
		.filterContainer {
			display: flex;
			width: 100%;
			height: 30px;
			align-items: center;
			cursor: pointer;
			.filterIconContainer {
				width: 20px;
				height: 20px;
				margin-right: 5px;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.tagContainer {
		width: 100%;
		height: 100%;
		display: flex;
		.ant-tag {
			padding: 5px 10px;
			span {
				color: #2b64f4;
			}
			.anticon-close {
				color: #2b64f4;
			}
		}
	}

	.ant-picker {
		width: 100%;
	}

	.ant-radio-button-wrapper {
		min-width: 5vw;
		text-align: center;
		padding: 0 1vw;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		appearance: none;
		margin: 0;
	}
	/* 火狐 */
	input {
		-moz-appearance: textfield;
	}
`;

export default NewForm;
