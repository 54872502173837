import {FC, useCallback, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {Button, Col, DatePicker, Form, Image, Input, message, Row, Space, Table, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {SelectSite} from "../../module/site/component/select.site";
import {BillEntity} from "../../module/bill/entity/bill.entiyy";
import BillService from "../../module/bill/service/bill.service";
import {BillParam} from "../../module/bill/params/bill.param";
import moment from "moment";
import {AxiosResponse} from "axios";
import Util from "../../common/Util";
import {SingleSelectPayWay} from "../../module/finance/component/SingleSelectPayWay";
import {useForm} from "antd/es/form/Form";
// import { PaginationEntity } from "../../common/entity/pagination.entity";

// const { Option } = Select;

export const BillList: FC = () => {
    const {t} = useTranslation();
    const [searchForm] = useForm();

    const [searchArr, setSearchArr] = useState<BillParam>({});

    const [searchObj, setSearchObj] = useState<BillParam>({
        startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
        endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
        customPageSize: 15,
        customPageNum: 1,
    });
    const search = () => {
        setSearchObj({
            ...searchObj,
            ...searchArr,
            customPageSize: 15,
            customPageNum: 1,
        });
    };

    const changeArr = (type: string, e: any) => {
        setSearchArr({...searchArr, [type]: e});
    };

    const onDateChange = (dates: any, dateString: string[]) => {
        setSearchArr({ ...searchArr, startTime: dateString[0] && dateString[0] + " 00:00:00", endTime: dateString[1] && dateString[1] + " 23:59:59" });
    };

    const download = async () => {
        const res: AxiosResponse<BlobPart> = await BillService.BillExport({
            ...searchArr,
        });
        const blob = new Blob([res.data]);
        const blobUrl = window.URL.createObjectURL(blob);
        Util.download(blobUrl, "billList");
        message.success(t("export.success"));
    };
    const columns = [
        {
            title: t("orderNum"),
            dataIndex: "orderNum",
        },
        {
            title: t("fee"),
            dataIndex: "fee",
        },
        {
            title: t("site"),
            dataIndex: "shopName",
            render: (shopName: string) => (
                <Tooltip placement="topLeft" title={shopName}>
                    <div className="shopBox">{"适用站点"}</div>
                </Tooltip>
            ),
        },
        {
            title: t("payWay"),
            dataIndex: "payWayStr",
        },
        {
            title: t("createdTime"),
            dataIndex: "createdTime",
        },
    ];

    const [dataList, setDataList] = useState<BillEntity[]>([]);
    const [total, setTotal] = useState(0);
    const [totalFee, setTotalFee] = useState(0);

    const init = useCallback(async () => {
        const {data} = await BillService.list({...searchObj,});

        if (data.code === 200) {
            setDataList(data.data);
            setTotal(data.total);
        }
    }, [searchObj]);

    useEffect(() => {
        init();
    }, [init]);

    const init1 = useCallback(async () => {
        const {data} = await BillService.totalFee(searchObj);
        if (data.code === 200) {
            setTotalFee(data.data);
        }
    }, [searchObj]);

    useEffect(() => {
        init1();
    }, [init1]);

    return (
        <Container>
            <div className="dataCard_container">
                <div className="dataCard purple">
                    <div className="dataLabel">
                        <span>{t("totalFee")}</span>
                        <span className="light">{'(' + t("rmb") + ')'}</span>
                    </div>
                    <div className="dataValue">
                        <Image
                            className='icon'
                            src={"https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/pocket.png"}
                        />
                        {totalFee ?? 0}
                    </div>
                </div>
                <div className="dataCard blue">
                    <div className="dataLabel">
                        <span>{t("totalamount")}</span>
                    </div>
                    <div className="dataValue">
                        <Image
                            className='icon'
                            src={"https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/bill.png"}
                        />
                        {total ?? 0}
                    </div>
                </div>
            </div>

            <Form onFinish={search} form={searchForm} labelCol={{span: 6}}>
                <Row>
                    <Col span={4}>
                        <Form.Item label={t("orderNum")} name={"orderNum"}>
                            <Input placeholder={t("orderNum")} style={{width: "200px"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={t("payWay")} name={"payWay"}>
                            <SingleSelectPayWay onChange={(e: number) => {
                                changeArr("payWay", e);
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={t("site")} name={"shopId"}>
                            <div className="selectBox">
                                <SelectSite level={1} onChange={(e: number) => changeArr("shopId", e)}/>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={t("period")} name={"date"}>
                            <DatePicker.RangePicker
                                defaultValue={[moment(searchArr.startTime && searchArr.startTime.toString()),
                                    moment(searchArr.endTime && searchArr.endTime.toString()),]} showTime={false}
                                onChange={onDateChange}
                                style={{width: "240px"}} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item>
                            <Space>
                                <Button type={"primary"} htmlType={"submit"}>
                                    {t("search")}
                                </Button>
                                <Button type={"primary"} onClick={download}>
                                    {t("export")}
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Table
                rowKey={(_, index) => index + ""}
                columns={columns}
                dataSource={dataList}
                pagination={{
                    pageSize: searchObj.customPageSize,
                    current: searchObj.customPageNum,
                    total: total,
                    showTotal: () => {
                        return (
                            <div>
                                {t("total")} {total}
                            </div>
                        );
                    },
                }}
                onChange={(pagination) => {
                    setSearchObj({
                        ...searchObj,
                        customPageNum: pagination.current ?? 1,
                        customPageSize: pagination.pageSize ?? 15,
                    });
                }}
            />
        </Container>
    );
};
const Container = styled.div`
  width: 99%;
  padding: 10px;

  .header {
    padding-bottom: 20px;
    padding-left: 30px;
    font-size: 20px;
    color: blue;
  }

  .dataCard_container {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    .dataCard {
      width: 276px;
      height: 118px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 16px;
      margin-right: 20px;

      .dataLabel {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;

        .light {
          color: #b4b7bf;
          font-size: 12px;
          margin-left: 4px;
        }
      }

      .dataValue {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
        margin-top: 18px;

        .icon {
          height: 48px;
          width: 48px;
          margin-right: 8px;
        }
      }
    }

    .purple {
      background: #EAF0FE;
    }

    .blue {
      background: #E5F6FF;
    }
  }

  .selectBox {
    width: 200px;
  }

  .shopBox {
    width: 64px;
    height: 22px;
    background: #E6F7FF;
    border-radius: 2px;
    border: 1px solid #91D5FF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: rgba(9, 109, 217, 0.88);
    line-height: 17px;
  }


`;
