import styled from "@emotion/styled";
import { message } from "antd";
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import AdminService from "../../../module/admin/service/admin.service";

let num: number;
interface InnerClean {
	orderItemId: number;
	adminName: string;
	licenseNumber: string;
	orderNum: string;
	productName: string;
	skuStr: string;
}
export const InQinDynasty: FC = () => {
	const ws = useRef<WebSocket | null>(null);
	const [windowWidth, setWindowWidth] = useState(0);
	const [windowHeight, setWindowHeight] = useState(0);
	const [list, setList] = useState([] as InnerClean[]);
	const [surplusOrderVOList, setSurplusOrderVOList] = useState([] as InnerClean[]);
	const [scroll, setScroll] = useState(true);
	const [footerScroll, setFooterScroll] = useState(true);
	const speed = 30;
	const warper: any = useRef();
	const footerWarper: any = useRef();
	const childDom: any = useRef();
	const childDomTwo: any = useRef();
	const licenseList: any = useRef();
	const licenseListTwo: any = useRef();
	const init = useCallback(async () => {
		const width: number = window.innerWidth;
		const height: number = window.innerHeight;
		setWindowWidth(width);
		setWindowHeight(height);
	}, []);
	const WebSocketConnection = useCallback(async () => {
		const token: any = localStorage.getItem("token");
		const userInfo: any = localStorage.getItem("adminInfo");
		ws.current = new WebSocket(process.env.REACT_APP_INNERClEAN_WEBSOCKET_API + "/websocket/innerClean?account=" + JSON.parse(userInfo).account, [token]);
		ws.current.onmessage = (e) => {
			num = 0;
			const data = JSON.parse(e.data);
			if (!data.data) {
				setList([]);
				return;
			}

			// 0 - 9 是当前展示页面的订单list  10 - arr.length是排队订单
			// arr.length < 9 就是全部给list

			const arr = data.data.orderVOList;

			if (arr.length <= 9) {
				setList(arr);
				setSurplusOrderVOList([]);
			} else {
				setList(arr.slice(0, 9));
				setSurplusOrderVOList(arr.slice(10, arr.length));
			}
		};
		// 心跳机制
		ws.current.onopen = () => {
			setInterval(() => {
				ws.current?.send("1");
			}, 30000);
		};
		ws.current.onclose = () => {
			num++;
			if (num > 2) {
				message.error({
					content: "请重新登录打开当前页面!",
					duration: 0,
				});
				return;
			}
			setTimeout(() => {
				WebSocketConnection();
			}, 30000);
		};
		// 已关闭
		ws.current.onerror = () => {
			ws.current?.close();
		};
		return () => {
			ws.current?.close();
		};
	}, []);
	useLayoutEffect(() => {
		AdminService.currentDetail().then(() => {
			WebSocketConnection();
		});
		return () => {
			ws.current?.close();
		};
	}, [ws, WebSocketConnection]);
	useEffect(() => {
		init();
		// 中间自动滚动部分
		// 纵向滚动取消
		let timer: any;
		// 单独一个item高度是 100
		// warper.current.clientHeight 容器总高度
		// warper.current.clientHeight / 100
		// Math.floor(warper.current.clientHeight / 110) * 2 可放置最大的item
		// console.log(Math.floor(warper.current.clientHeight / 110) * 2); // 带上margin的10
		// const maxItemNum = 9;
		// console.log(childDom.current.clientHeight, warper.current.clientHeight)
		// if (list.length > 5 && childDom.current.clientHeight >= warper.current.clientHeight) {
		// const showLastHeight = warper.current.clientHeight - (maxItemNum / 2) * 110;
		// 如果最后一行展示区域大于 80%则也没必要滚动
		// if (list.length > 5 && list.length > maxItemNum && showLastHeight < 75) {
		// 	childDomTwo.current.innerHTML = childDom.current.innerHTML;
		// 	if (scroll) {
		// 		timer = setInterval(
		// 			() => (warper.current.scrollTop >= childDom.current.scrollHeight ? (warper.current.scrollTop = 0) : warper.current.scrollTop++),
		// 			speed
		// 		);
		// 	}
		// } else {
		// 	childDomTwo.current.innerHTML = "";
		// 	warper.current.scrollTop = 0;
		// 	clearTimeout(timer);
		// }
		// 底部横向滚动部分

		let licTime: any;
		if (surplusOrderVOList.length > 3 && licenseList.current.clientWidth >= footerWarper.current.clientWidth) {
			licenseListTwo.current.innerHTML = licenseList.current.innerHTML;
			if (footerScroll) {
				licTime = setInterval(
					() =>
						footerWarper.current.scrollLeft >= licenseList.current.scrollWidth
							? (footerWarper.current.scrollLeft = 0)
							: footerWarper.current.scrollLeft++,
					speed
				);
			}
		} else {
			licenseListTwo.current.innerHTML = "";
			footerWarper.current.scrollLeft = 0;
			clearTimeout(licTime);
		}
		return () => {
			clearTimeout(timer);
			clearTimeout(licTime);
		};
	}, [init, list, scroll, footerScroll, surplusOrderVOList]);
	const hoverHandler = (flag: any) => setScroll(flag);
	const hoverFooterHandler = (flag: any) => setFooterScroll(flag);
	const processSkuStr = (skuStr: string) => {
		console.log(skuStr)
		if (!skuStr) return '';
		const skuArr = JSON.parse(skuStr);
		if (skuArr.length) {
			const result = skuArr
				.reduce(
					(pre: string, next: {title: string}) => pre + '/' + next.title,
					'('
				)
				.replace('(/', '(');
			console.log(result + ')');
			return result + ')';
		}
	}

	return (
		<Container>
			<div className='InQinDynasty' style={{ width: windowWidth + "px", height: windowHeight + "px" }}>
				<div className='headers'>
					<div className='headers-title'>
						<div className='list'>
							<img
								className='img-License'
								src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/InQinDynasty/chepaihao.png'
								alt=''
							/>
							<span className='text'>车牌号</span>
						</div>

						<div className='list'>
							<img
								className='img-project'
								src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/InQinDynasty/xiangmu.png'
								alt=''
							/>
							<span className='text'>项目</span>
						</div>
					</div>
					<div className='headers-title'>
						<div className='list'>
							<img
								className='img-License'
								src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/InQinDynasty/chepaihao.png'
								alt=''
							/>
							<span className='text'>车牌号</span>
						</div>
						<div className='list'>
							<img
								className='img-project'
								src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/InQinDynasty/xiangmu.png'
								alt=''
							/>
							<span className='text'>项目</span>
						</div>
					</div>
					<div className='headers-title'>
						<div className='list'>
							<img
								className='img-License'
								src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/InQinDynasty/chepaihao.png'
								alt=''
							/>
							<span className='text'>车牌号</span>
						</div>
						<div className='list'>
							<img
								className='img-project'
								src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/InQinDynasty/xiangmu.png'
								alt=''
							/>
							<span className='text'>项目</span>
						</div>
					</div>
				</div>
				<div className='centers' style={{ height: windowHeight - 140 + "px" }} ref={warper}>
					<div className='child' ref={childDom} onMouseOver={() => hoverHandler(false)} onMouseLeave={() => hoverHandler(true)}>
						{list &&
							list.map((item, index) => {
								return (
									<div className='list' key={item.orderItemId}>
										<div className='item'>
											<div className='License'>
												<span className='count'>{index + 1}</span>
												<span className='text'>{item.licenseNumber}</span>
											</div>
											{/* <div className='personnel'>{item.adminName}</div> */}
											<div className='project'>
												<div>{item.productName}</div>
												<div className="small">{processSkuStr(item.skuStr)}</div>
											</div>
										</div>
									</div>
								);
							})}
					</div>
					<div className='child' ref={childDomTwo} onMouseOver={() => hoverHandler(false)} onMouseLeave={() => hoverHandler(true)}></div>
				</div>
				<div className='footers'>
					<div className='cardImg'>
						<img className='img' src='https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/InQinDynasty/che.png' alt='' />
						<span className='text'>等待中车辆:{surplusOrderVOList && surplusOrderVOList.length > 0 ? surplusOrderVOList.length : 0}</span>
					</div>
					<div className='license' style={{ width: windowWidth - 450 + "px" }} ref={footerWarper}>
						<div
							className='licenseList'
							ref={licenseList}
							style={{ minWidth: 120 * surplusOrderVOList.length + "px" }}
							onMouseOver={() => hoverFooterHandler(false)}
							onMouseLeave={() => hoverFooterHandler(true)}
						>
							{surplusOrderVOList &&
								surplusOrderVOList.map((item, index) => {
									return (
										<div className='licenseItem' key={index}>
											{item.licenseNumber}
										</div>
									);
								})}
						</div>
						<div
							className='licenseList'
							ref={licenseListTwo}
							style={{ minWidth: 120 * surplusOrderVOList.length + "px" }}
							onMouseOver={() => hoverFooterHandler(false)}
							onMouseLeave={() => hoverFooterHandler(true)}
						></div>
					</div>
				</div>
			</div>
		</Container>
	);
};
const Container = styled.div`
	.InQinDynasty {
		font-family: PingFangSC-Semibold, PingFang SC;
		background: #f5f5fc;
		.headers {
			width: 100%;
			height: 70px;
			background: #187fff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.headers-title {
				min-width: 30%;
				white-space: nowrap;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.list {
					width: 33%;
					display: flex;
					align-items: center;
					justify-content: center;
					.img-License {
						width: 46px;
						height: 34px;
						margin-right: 5px;
					}
					.img-project {
						width: 46px;
						height: 34px;
						position: relative;
						bottom: 5px;
						margin-right: 5px;
					}
					.text {
						font-size: 20px;
						font-weight: 600;
						color: #ffffff;
					}
				}
			}
		}
		.centers {
			width: 100%;
			padding-bottom: 100px;
			overflow-y: hidden;
			.child {
				width: 100%;
				height: auto;
				display: flex;
				justify-content: flex-start;
				align-content: flex-start;
				flex-wrap: wrap;
				.list {
					width: 33%;
					margin-top: 3vh;
					.item {
						width: 95%;
						height: 22vh;
						margin: 0 auto;
						background: #ffffff;
						border-radius: 4px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						.License {
							width: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							.count {
								width: 28px;
								height: 28px;
								background: #00b478;
								font-size: 16px;
								font-weight: 600;
								color: #ffffff;
								border-radius: 50%;
								text-align: center;
								line-height: 28px;
							}
							.text {
								font-size: 16px;
								font-weight: 600;
								color: #777777;
							}
						}

						.project {
							width: 50%;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							font-size: 16px;
							font-weight: 600;
							color: #00b478;
							
							.small {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
		.footers {
			width: 100%;
			height: 70px;
			background: #ffa100;
			.cardImg {
				position: fixed;
				bottom: -18px;
				left: 74px;
				.img {
					width: 162px;
					height: 90px;
				}
			}
			.text {
				font-size: 26px;
				font-weight: 600;
				color: #ffffff;
			}
			.license {
				height: 100%;
				margin-left: 430px;
				overflow: hidden;
				display: flex;
				align-items: center;
				.licenseList {
					height: 100%;
					display: flex;
					margin-right: 50px;
					align-items: center;
					flex-wrap: nowrap;
					margin-right: 15px;
					.licenseItem {
						min-width: 120px;
						height: 100%;
						white-space: nowrap;
						font-size: 20px;
						color: #ffffff;
						display: flex;
						align-items: center;
						padding-left: 10px;
						padding-right: 10px;
					}
				}
			}
		}
	}
`;
