import { http } from "../../../common/http";
import { FullGiftEntity } from "../entity/fullGift.entity";

class FullGiftService {
	list = async (params: any) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/consumption-above-activity",
			method: "GET",
			params,
		});
	};


	detail=async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/consumption-above-activity/"+id,
			method: "GET",
			data:id,
		});
	};

	add=async (data: FullGiftEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/consumption-above-activity/",
			method: "POST",
			data,
		});
	};

	update=async (data: FullGiftEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/consumption-above-activity/"+data.id,
			method: "PUT",
			data,
		});
	};

	delete=async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/consumption-above-activity/"+id,
			method: "DELETE",
			data:id
		});
	};


	

}
export default new FullGiftService();
