import {http} from "../../../common/http";
import {MembershipCardRecordParam} from "../params/membership.card.record.param";
interface refundCardParam {
    orderNum?:string
}
class MembershipCardRecordService {
    getList = async (param: MembershipCardRecordParam) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/card-buy-record',
            method: 'GET',
            params: param,
        })
    }
    refundCard = async (param: refundCardParam) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/pay/card-refund',
            method: 'POST',
            params: param,
        })
    }
    exportMonthlyCard = async (param: MembershipCardRecordParam) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/card-buy-record/export',
            method: 'GET',
            params: param,
            responseType: 'blob',
        })
    }
}
export default new MembershipCardRecordService();