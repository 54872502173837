import { http } from "../../../common/http";
import { IndexPopupEntity } from "../entity/indexPopupEntity";

class IndexPopupService {
    list = async () => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/homepage-popup",
            method: "GET",
        });
    };

    add = async (params: Omit<IndexPopupEntity, 'id'>) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/homepage-popup",
            method: "POST",
            data: params,
        });
    };
    detail = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + `/homepage-popup/${id}`,
            method: "GET",
        });
    }

    delete = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + `/homepage-popup/${id}`,
            method: "DELETE",
        });
    }

    update = async (id: number, params: Omit<IndexPopupEntity, 'id'>) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + `/homepage-popup/${id}`,
            method: "PUT",
            data: params,
        });
    }
}

export default new IndexPopupService();