export const ProductTypeArr: Record<number, string> = {
    1: "express",
    2: "interiorClean",
    3: "Detailing",
    4: "maintainance",
    5: "otherService",
    6: "spacy"
}

export const ProductTypeColorArr: Record<number, string> = {
    1: "#197EFF",
    2: "#00B478",
    3: "#FFA100",
    4: "#FE5989",
    5: "#FFA100",
    6: "#00B478"
}