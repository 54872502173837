import styled from "@emotion/styled";
import { Select } from "antd";
import { t } from "i18next";
import { Props } from "../../../common/entity/props.entity";
import { OrderStatusArr } from "../enum/order.status.enum";
const { Option } = Select;

export const MultiSelectOrderStatus = (props: Props) => {
	return (
		<Container>
			<Select
				placeholder={t("orderStatus")}
				mode='multiple'
				onChange={(e: number[]) => {
					props.onChange && props.onChange(e.toString());
				}}
				// value={
				// 	props.value
				// 		? props.value.split(",").map((item: string) => {
				// 				return Number(item);
				// 		  })
				// 		: undefined
				// }
				value={props.value ? props.value?.split(",") : undefined}
				// options={[
				// 	{ value: 1, label: "待支付" },
				// 	{ value: 3, label: "已取消" },
				// 	{ value: 5, label: "支付成功" },
				// 	{ value: 7, label: "施工中" },
				// 	{ value: 9, label: "施工完成" },
				// 	{ value: 11, label: "申请退款" },
				// 	{ value: 13, label: "拒绝退款" },
				// 	{ value: 15, label:  "退款中"},
				// 	{ value: 17, label: "退款成功" },
				// 	{ value: 19, label: "已评价" },
				// 	{ value: 20, label: "已过期" },
				// ]}
				allowClear
			>
				{Object.keys(OrderStatusArr).map((item) => {
					return (
						<Option value={item} key={item}>							
							{t(OrderStatusArr[Number(item)])}
						</Option>
					);
				})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
