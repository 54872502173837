import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, message, Modal, Select, Space, Table, Tag } from "antd";
import { t } from "i18next";

import { useNavigate } from "react-router-dom";
// import { PaginationEntity } from "src/common/entity/pagination.entity";
import { LoginActivityListEntity as RecommendActivityListEntity } from "src/module/login.activity/entity/loginActivityListEntity";
import { LoginActivityListParam as RecommendActivityListParam } from "src/module/login.activity/param/loginActivityListParam";
import { SelectSite } from "src/module/site/component/select.site";
import { AxiosResponse } from "axios";
import Util from "src/common/Util";
import recommendActivityListService from "src/module/recommend.activity/service/recommendActivityListService";
import { checkPermission } from "src/module/admin/service/permission.service";
import Popover from "antd/es/popover";

export const RecommendActivitylist: FC = () => {
	const { confirm } = Modal;
	const navigate = useNavigate();
	const { Option } = Select;
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState<number>(0);
	const [searchObj, setSearchObj] = useState<RecommendActivityListParam>({ customPageSize: 15, customPageNum: 1 });
	const [dataList, setDataList] = useState<RecommendActivityListEntity[]>([]);
	const onfinish = (value: any) => {
		setSearchObj({ ...searchObj, ...value, customPageSize: 15, customPageNum: 1 });
	};
	const init = useCallback(async () => {
		const { data } = await recommendActivityListService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const updateStatus = async (params: RecommendActivityListEntity) => {
		const { data } = await recommendActivityListService.changeStatus(params);
		if (data.code === 200) {
			message.success(t("success"));
			init();
		} else {
			message.error(data.msg);
		}
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await recommendActivityListService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const downLoad = async () => {
		const res: AxiosResponse<BlobPart> = await recommendActivityListService.export(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "recommendActivity");
		message.success(t("export.success"));
	};

	const columns = [
		{
			title: t("activityId"),
			dataIndex: "id",
		},
		{
			width: 180,
			title: t("activityName"),
			dataIndex: "title",
		},
		{
			title: t("site.name"),
			render: (record: any) => {
				return (
					<Popover content={record.shopName}>
						<Tag color='blue'>{t("shopName")}</Tag>
					</Popover>
				);
			},
		},

		{
			title: t("允许参加次数"),
			dataIndex: "recommendGrantNum",
		},
		{
			title: t("	剩余参加次数"),
			dataIndex: "recommendRemainingNum",
		},
		{
			title: t("	限制每人参加次数"),
			dataIndex: "recommendLimitNum",
		},
		{
			title: t("注册用户获取优惠券组合"),
			dataIndex: "registerNewGrantCouponName",
		},
		{
			title: t("推荐用户获取优惠券组合"),
			dataIndex: "registerOldGrantCouponName",
		},

		{
			title: t("首单注册用户获取优惠券组合"),
			dataIndex: "firstOrderNewGrantCouponName",
		},
		{
			title: t("首单推荐用户获取优惠券组合"),
			dataIndex: "firstOrderOldGrantCouponName",
		},
		{
			title: t("endTime"),
			dataIndex: "endDate",
		},
		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? t("onSale") : t("off");
			},
		},
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<div>
						<div style={{ marginTop: 10 }}>
							{record.status === 1 && (
								<Button
									type='primary'
									danger
									onClick={() => {
										updateStatus({ id: record.id, status: 2 });
									}}
								>
									{t("off")}
								</Button>
							)}
						</div>
						<div style={{ marginTop: 10 }}>
							{record.status === 2 && (
								<Button
									type='primary'
									onClick={() => {
										updateStatus({ id: record.id, status: 1 });
									}}
								>
									{t("onSale")}
								</Button>
							)}
						</div>

						{checkPermission(340) && (
							<div style={{ marginTop: 10 }}>
								<Button
									type='primary'
									onClick={() => {
										navigate("/activity/recommendActivity/" + record.id);
									}}
								>
									{t("edit")}
								</Button>
							</div>
						)}

						{checkPermission(342) && (
							<div style={{ marginTop: 10 }}>
								<Button
									type='primary'
									danger
									onClick={() => {
										handleDelete(record.id);
									}}
								>
									{t("delete")}
								</Button>
							</div>
						)}
					</div>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onfinish}>
				<Form.Item label={t("activityName")} name='title'>
					<Input placeholder={t("activityName")} allowClear />
				</Form.Item>
				<Form.Item label={t("activityStatus")} name='status'>
					<Select placeholder={t("activityStatus")} allowClear>
						<Option value={1}>{t("onSale")}</Option>
						<Option value={2}>{t("off")}</Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("shopName")} name='shopId'>
					<SelectSite />
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						{checkPermission(341) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/activity/recommendActivity/add");
								}}
							>
								{t("addActivity")}
							</Button>
						)}

						<Button type='primary' onClick={downLoad}>
							{t("export")}
						</Button>
					</Space>
				</Form.Item>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id + ""}
				style={{ marginTop: 20 }}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
