import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, Space, Table } from "antd";
import { t } from "i18next";
import moment from "moment";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import pointService from "../../module/point/service/point.service";
import { PointParam } from "../../module/point/param/point.param";
const { RangePicker } = DatePicker;

export const PointDetailList: FC = () => {

	const recordType = {
		1: "订单",
		2: "会员卡",
		3: "套餐",
		4: "兑换",
	};
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState(0);

	const [form] = Form.useForm();

	const [searchObj, setSearchObj] = useState<PointParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	const onSearch = async (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			date: undefined,
			startTime: value.date && value.date[0] && value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.date && value.date[1] && value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageSize: 15,
		customPageNum: 1,
		});
	};

	const init = useCallback(async () => {
		const { data } = await pointService.detailList({ ...searchObj});
		if (data.code === 200) {
			setDataList(data.data.records);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const [dataList, setDataList] = useState();

	const columns = [
		{ title: t("nickName"), dataIndex: "nickName" },
		{ title: t("mobile"), dataIndex: "mobile" },
		{ title: t("type"), dataIndex: "type" ,render: (text:keyof typeof recordType ) => {
			return recordType[text]
		},},
		{
			title: t("pointChanges"),
			dataIndex: "amount",
			render: (text: number) => {
				return text < 0 ? <div style={{ color: "red" }}>{text}</div> : <div style={{ color: "blue" }}>{"+"+text}</div>;
			},
		},
		{ title: t("orderNum"), dataIndex: "orderNum" },
		{ title: t("createdTime"), dataIndex: "createdTime" },
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onSearch} form={form} initialValues={{ date: [moment(), moment()] }}>
				<Form.Item label={t("nickName")} name='nickName'>
					<Input allowClear />
				</Form.Item>
				<Form.Item label={t("mobile")} name='mobile'>
					<Input allowClear />
				</Form.Item>
				<Form.Item label={t("orderNum")} name='orderNum'>
					<Input allowClear />
				</Form.Item>
				<Form.Item label={t("period")} name='date'>
					<RangePicker allowClear />
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
					</Space>
				</Form.Item>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.ant-form-item {
		margin-bottom: 15px;
	}
`;
