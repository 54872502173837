import { FC, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Form, Input, Modal, Space, Table, message } from "antd";
import { useNavigate } from "react-router-dom";
// import { PaginationEntity } from "src/common/entity/pagination.entity";
import { DouyinEntity } from "src/module/douyin/douyin.coupon.entity ";
import douyinService from "src/module/douyin/douyin.service";
import { DouyinParam } from "src/module/douyin/douyin.param ";
import { checkPermission } from "src/module/admin/service/permission.service";

export const Douyin: FC = () => {
	const { confirm } = Modal;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [douyinList, setdouyinList] = useState<DouyinEntity[]>([]);
	const [searchObj, setSearchObj] = useState<DouyinParam>({customPageSize: 15,
		customPageNum: 1,});
	

	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// 	total: 0,
	// });
	const [total, setTotal] = useState<number>(0);
	const init = useCallback(async () => {
		const { data } = await douyinService.list({ ...searchObj});
		console.log("33", data.data);
		setdouyinList(data.data);
		setTotal(data.total);
	}, [ searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const onfinish = (value: any) => {
		setSearchObj({ ...searchObj, ...value ,customPageSize: 15,
			customPageNum: 1,});
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await douyinService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const columns = [
		{
			title: t("douyinProductId"),
			dataIndex: "productId",
		},
		{
			title: t("douyinProductName"),
			dataIndex: "productName",
		},
		{
			title: t("couponName"),
			dataIndex: "couponTitle",
		},

		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(51603) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}

						{checkPermission(51602) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/activity/douyin/${record.id}`);
								}}
							>
								{t("edit")}
							</Button>)
						}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onfinish}>
				<Form.Item label={t("douyinProductId")} name='productId'>
					<Input placeholder={t("activityName")} allowClear />
				</Form.Item>
				<Form.Item label={t("douyinProductName")} name='productName'>
					<Input placeholder={t("douyinProductName")} allowClear />
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>

						<Button
							type='primary'
							onClick={() => {
								navigate("/activity/douyin/add");
							}}
						>
							{t("addActivity")}
						</Button>
					</Space>
				</Form.Item>
			</Form>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={douyinList}
				rowKey={(record) => record.id + ""}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
