import {FC, useCallback, useEffect, useState} from "react";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, message, Row, Space, Table } from "antd";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import groupMessageService from "src/module/groupMessage/service/groupMessageService";
import { GroupMessageEntity } from "../../module/groupMessage/entity/groupMessageEntity";

const { RangePicker } = DatePicker;
export const GroupMessage: FC = () => {
    const { t } = useTranslation();

    const [searchDate, setSearchDate] = useState({
        startTime: moment().add(-1, "days").format("YYYY-MM-DD") + " 00:00:00",
        endTime: moment().add(-1, "days").format("YYYY-MM-DD") + " 00:00:00",
    });
    const [dataList, setDataList] = useState<GroupMessageEntity[]>([]);

    const onDateChange = (date: any, dateString: any) => {
        setSearchDate({
            startTime: dateString[0] + " 00:00:00",
            endTime: dateString[1] + " 00:00:00",
        });
    };

    const calDisabledDate = (current: moment.Moment) => {
        return (
            current <= moment().add(-180, "days") ||
            current > moment().startOf("day")
        );
    };

    const calDateDiff = (startDate: string, endDate: string) => {
        return moment(endDate).diff(moment(startDate), "days");
    };

    const handleSearch = useCallback(async () => {
        if (!searchDate.startTime || !searchDate.endTime) return;
        const diff = calDateDiff(searchDate.startTime, searchDate.endTime);
        if (diff > 31) {
            message.warning("时间跨度不能超过30天!");
            setDataList([]);
            return;
        }
        const { access_token } = await groupMessageService.getToken();
        const { items } = await groupMessageService.fetchMessage(access_token, {
            day_begin_time: Number(moment(searchDate.startTime).format("X")),
            day_end_time: Number(moment(searchDate.endTime).format("X")),
            order_by: 2,
        });
        setDataList(
            items.map((item: { owner: string; data: GroupMessageEntity[] }, index: number) => {
                return {
                    id: index,
                    owner: item.owner,
                    ...item.data,
                };
            })
        );
    }, [searchDate.endTime, searchDate.startTime]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    const columns = [
        {
            title: "群主ID",
            dataIndex: "owner",
        },
        {
            title: "新增客户群数量",
            dataIndex: "new_chat_cnt",
        },
        {
            title: "客户群总数量",
            dataIndex: "chat_total",
        },
        {
            title: "有发过消息的客户群数量",
            dataIndex: "chat_has_msg",
        },
        {
            title: "客户群新增群人数",
            dataIndex: "new_member_cnt",
        },
        {
            title: "截至当天客户群总人数",
            dataIndex: "member_total",
        },
        {
            title: "有发过消息的群成员数",
            dataIndex: "member_has_msg",
        },
        {
            title: "客户群消息总数",
            dataIndex: "msg_total",
        },
    ];

    return (
        <Container>
            <div className="header">
                <Row gutter={20}>
                    <Col span={6}>
                        <div className="searchList">
                            <div className="searchItem">
                                {t("effectiveDate")}：
                            </div>
                            <RangePicker
                                allowClear
                                defaultValue={[
                                    moment().add(-1, "days"),
                                    moment().add(-1, "days"),
                                ]}
                                onChange={onDateChange}
                                disabledDate={calDisabledDate}
                            />
                            <Button
                                htmlType="submit"
                                type="primary"
                                onClick={handleSearch}
                                className="searchBtn"
                            >
                                {t("search")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <Table
                columns={columns}
                dataSource={dataList}
                rowKey={(record: any) => record.id + ""}
                style={{ paddingBottom: 20, boxSizing: "border-box" }}
                pagination={false}
            />
            <div className="scrollBot"></div>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    padding: 10px;

    .header {
        width: 100%;

        .searchList {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .searchItem {
                margin-left: 10px;
                text-align: right;
            }

            .searchBtn {
                margin-left: 30px;
            }
        }
    }

    .scrollBot {
        width: 100%;
        height: 250px;
    }
`;
