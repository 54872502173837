import { http } from "../../../common/http";
import { ThirdOrderParam } from "../param/third.order.param";

class ThirdOrderService {

	list = async (param: ThirdOrderParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/thirdOrder",
			method: "GET",
			params: param,
		});
	};

    delete=async (id:number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/thirdOrder/"+id,
			method: "DELETE",
			data:id
		});
	};

    export = async (param:ThirdOrderParam ) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/thirdOrder/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};
}
export default new ThirdOrderService();
