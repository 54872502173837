import styled from "@emotion/styled";
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Tag,
	Image,
	Radio,
	Table,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiSelectAdmin } from "src/module/admin/components/MultiSelectAdmin";
import { formatTime } from "../../common/timeFormatting";
import { MultiSelectPayWay } from "../../module/finance/component/MultiSelectPayWay";
import { MultiSelectOrderStatus } from "../../module/order/compent/MultiSelectOrderStatus";
import { SelectDiscountType } from "../../module/order/compent/select.discount.type";
import { OrderEntity } from "../../module/order/entity/order.entity";
import { OrderStatusArr, OrderStatusColorArr } from "../../module/order/enum/order.status.enum";
import { OrderParam } from "../../module/order/param/order.param";
import orderService from "../../module/order/service/order.service";
import { ConstructionPerformanceMadal } from "../../module/performance/module/Constructionperformance";
import { SalePerformanceMadal } from "../../module/performance/module/SalePerformance";
import { MultiSelectProductType } from "../../module/product/component/multi.select.product.type";
import { ProductTypeColorArr } from "../../module/product/enum/product.type.enum";
import { SelectSite } from "../../module/site/component/select.site";
import performanceService from "../../module/performance/service/performance.service";
import { checkPermission } from "src/module/admin/service/permission.service";
import { SelectPromoter } from "src/module/promoterAudit/components/SelectPromoter";
import { PayWayTagArr, PayWayTagColorArr } from "src/module/finance/enum/pay.way.enum copy";
import { disCountArr } from "src/module/finance/enum/discount.way.enum";
import couponTypeService from "src/module/coupon/service/coupon.type.service";
import equipmentsService from "src/module/equipments/service/equipments.service";
import Util from "src/common/Util";
import qualityCheckService from "src/module/qualityCheck/quality.check.service";
import { QualityCheckEntity } from "src/module/qualityCheck/quality.check.entity";
import { PaginationEntity } from "src/common/entity/pagination.entity";
const { confirm } = Modal;
const defaultSearchParam: OrderParam = {
	startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
	endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
	customPageNum: 1,
	customPageSize: 15,
};

export const OrderList: FC = () => {
	const [data, setData] = useState<OrderEntity[]>([]);
	const [orderSumData, setOrderSumData] = useState({
		allOrderCount: 0,
		allOrderCouponFee: 0.0,
		allOrderFee: 0.0,
		allOrderPayFee: 0.0,
		allOrderSettleFee: 0.0,
	});
	const [searchObj, setSearchObj] = useState<OrderParam>(defaultSearchParam);
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(0);
	const [qualityTotal, setQualityTotal] = useState(0);

	const [adminVisible, setAdminVisible] = useState(false);
	const [multiSettleVisible, setMultiSettleVisible] = useState(false);
	const [saleVisible, setSaleVisible] = useState(false);
	const [conVisible, setConVisible] = useState(false);
	const [remarkVisible, setRemarkVisible] = useState(false);
	const [settleVisible, setSettleVisible] = useState(false);
	const [checkVisible, setCheckVisible] = useState(false);
	const [orderInfo, setOrderInfo] = useState<OrderEntity>();
	const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
	const [indeterminate, setIndeterminate] = useState(true);
	const [checkAll, setCheckAll] = useState(false);
	const [settleDiscount, setSettleDiscount] = useState<number>(100);
	const [totalMoney, setTotalMoney] = useState<number>(0);
	const [stateFlag, setStateFlag] = useState(false);
	const [qualityInfo, setQualityInfo] = useState({});
	// const [qualityId, setQualityId] = useState();
	const [qualityVisible, setQualityVisible] = useState(false);
	const [qualityPaginationObj, setQualityPaginationObj] = useState<PaginationEntity>({ customPageNum: 1, customPageSize: 15 });

	const [qualityArr, setQualityArr] = useState<QualityCheckEntity[]>([]);
	const [orderItemShopId, setOrderItemShopId] = useState<number>(0);

	const [searchForm] = useForm();

	const [settleForm] = useForm();

	const [allSettleFlag, setAllSettleFlag] = useState(false);
    const { t, i18n } = useTranslation();



	useEffect(() => {
		searchForm.setFieldsValue({
			date: [moment(defaultSearchParam.startTime), moment(defaultSearchParam.endTime)],
		});
	}, [searchForm]);

	const init = useCallback(() => {
		setLoading(true);
		setData([]);
		setTotal(0);
		setCheckedList([]);
		setCheckAll(false);
		setOrderInfo({});
		orderService.list(searchObj).then((res) => {
			setData(res.data.data.page.list);
			setTotal(res.data.data.page.total);
			setOrderSumData(res.data.data.orderSumData);
			setLoading(false);
		});
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const setQualityList = async (id: number) => {
		const { data } = await qualityCheckService.list({ ...qualityPaginationObj, itemId: id });
		if (data.code === 200) {
			setQualityArr(data.data.records);
			setQualityTotal(data.data.total);
			console.log(data.data.total);
		}
	};

	const columns = [
		{
			title: t("qualityResult"),
			dataIndex: "result",
			render: (text: number) => {
				return <div style={{ color: text === 0 ? "#FF4D4F" : "#52C41A" }}>{text === 0 ? "不通过" : "通过"}</div>;
			},
		},
		{
			title: t("qualityTime"),
			dataIndex: "createdTime",
		},
		{
			title: t("qualityAdmin"),
			dataIndex: "createdName",
		},
		{
			title: t("remark"),
			dataIndex: "comments",
		},
	];

	const onSearchFinish = (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			date: undefined,
			startTime: value.date && formatTime(value.date[0]),
			endTime: value.date && formatTime(value.date[1]),
			settleDate: undefined,
			settleStartTime: value.settleDate && formatTime(value.settleDate[0]),
			settleEndTime: value.settleDate && formatTime(value.settleDate[1]),
			refundTime: undefined,
			refundStartTime: value.refundTime && formatTime(value.refundTime[0]),
			refundEndTime: value.refundTime && formatTime(value.refundTime[1]),
			customPageNum: 1,
			customPageSize: 15,
		});

		searchForm.setFieldsValue(value);
	};

	const settle = (orderInfo: OrderEntity) => {
		setOrderInfo(orderInfo);
		setSettleVisible(true);
		settleForm.setFieldsValue({
			payFee: orderInfo.payFee,
		});
	};

	const handleDelete = (id: any) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await orderService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const onSettleFinish = async () => {
		const { data } = await orderService.settle({ ids: orderInfo?.id, settleFee: orderInfo?.settleFee ?? 0, settleRemark: orderInfo?.settleRemark ?? "" });
		if (data.code == 200) {
			message.success("更新结算金额成功");
		} else {
			message.error(data.msg);
		}
		setSettleVisible(false);
		init();
	};

	const onMultiSettleFinish = async () => {
		// 如果点击了全部结算 则代表批量处理该筛选条件下所有订单
		let params = {};
		if (allSettleFlag) {
			confirm({
				title: "你现在操作是当前筛选条件下，所有的订单，请再次确认",
				okText: "Yes",
				okType: "danger",
				cancelText: "No",
				onOk: () => {
					params = {
						...searchObj,
						ids: '',
						discount: settleDiscount,
						settleRemark: orderInfo?.settleRemark ?? ''
					}
					Reflect.deleteProperty(params,"customPageNum");
					Reflect.deleteProperty(params,"customPageSize");
					requestSettle(params)
				},
				onCancel() {
					return;
				},
			})
		}
		else if (checkedList.length === 0){
				message.error("请选择订单");
				return;
		} else {
			params = {
				ids: checkedList.join(","),
				discount: settleDiscount,
				settleRemark: orderInfo?.settleRemark ?? ''
			}
			requestSettle(params);
		}

	};

	const requestSettle = async (params: any) => {
		const { data } = await orderService.settle(JSON.parse(JSON.stringify(params)));
		if (data.code == 200) {
			message.success("更新结算金额成功");
		} else {
			message.error(data.msg);
		}
		setMultiSettleVisible(false);
		init();
	}

	const onChange = (list: CheckboxValueType[]) => {
		setCheckedList(list);
		setIndeterminate(!!list.length && list.length < data.length);
		setCheckAll(list.length === data.length);
	};

	const onCheckAllChange = (e: CheckboxChangeEvent) => {
		setCheckedList(e.target.checked ? data.map((item) => item.id ?? 0) : []);
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	};

	const exportList = async () => {
		const date1 = new Date(searchObj.startTime!);
		const date2 = new Date(searchObj.endTime!);
		const timestamp1 = date1.getTime();
		const timestamp2 = date2.getTime();
		const daysDifference = (timestamp2 - timestamp1) / (1000 * 60 * 60 * 24);
		if (daysDifference > 181 || !searchObj.startTime) {
			message.error("订单筛选区间不可为空或超出六个月");
			setData([]);
			return;
		}
		const { data } = await orderService.export(searchObj);
		const blob = new Blob([data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "orderList");
		message.success(t("export.success"));
	};

	const exportSku = async () => {
		const date1 = new Date(searchObj.startTime!);
		const date2 = new Date(searchObj.endTime!);
		const timestamp1 = date1.getTime();
		const timestamp2 = date2.getTime();
		const daysDifference = (timestamp2 - timestamp1) / (1000 * 60 * 60 * 24);
		if (daysDifference > 181 || !searchObj.startTime) {
			message.error("订单筛选区间不可为空或超出六个月");
			setData([]);
			return;
		}
		const { data } = await orderService.exportSku(searchObj);
		const blob = new Blob([data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "skuList");
		message.success(t("export.success"));
	};

	const updateStatus = async (param: any) => {
		const { data } = await orderService.updateStatus(param);
		if (data.code === 200) {
			message.success(t("success"));
			init();
		} else {
			message.error(data.msg);
		}
	};

	const updateItemsStatus = async (param: any) => {
		const { data } = await orderService.updateItemsStatus(param);
		if (data.code === 200) {
			message.success(t("success"));
			setConstructionUser({});
			init();
		} else {
			message.error(data.msg);
		}
	};

	const reSetCommand = async (param: any) => {
		const { data } = await equipmentsService.reSetCommand(param);
		if (data.code === 200) {
			message.success(t("success"));
		} else {
			message.error(data.msg);
		}
	};

	const [numInfo, setNumInfo] = useState<string | undefined>();
	const [remarkInfo, setRemarkInfo] = useState<{ id: number; remark: string }>({ id: 0, remark: "" });
	const [constructionUser, setConstructionUser] = useState<any>({});
	const [itemId, setItemId] = useState();

	const handleCheckOk = async () => {
		setCheckVisible(false);
		const { data } = await qualityCheckService.add({ ...qualityInfo, itemId: itemId });
		if (data.code === 200) {
			message.success(t("success"));
			init();
			setQualityArr([]);
			setQualityInfo({});
		}
	};

	const handleCheckCancel = async () => {
		setCheckVisible(false);
	};

	const handleQualityListCancel = async () => {
		setQualityVisible(false);
	};

	const handleMarkOk = async () => {
		setRemarkVisible(false);
		const { data } = await orderService.updateRemark(remarkInfo);
		if (data.code === 200) {
			message.success(t("success"));
			init();
		}
	};

	const handleMarkCancel = () => {
		setRemarkVisible(false);
	};

	const handleAdminOk = () => {
		setAdminVisible(false);
		updateItemsStatus(constructionUser);
	};

	const handleAdminCancel = () => {
		setAdminVisible(false);
		setConstructionUser({});
	};

	const handleConstruction = async (orderNum: string, totalMoney?: number, shopId?: number) => {
		shopId ? setOrderItemShopId(shopId) : setOrderItemShopId(0);
		const { data } = await performanceService.list({ orderNum, serviceType: 2 });
		if (data.data.length > 0) {
			setConVisible(true);
			setTotalMoney(totalMoney!);
			setStateFlag(true);
		} else {
			setAdminVisible(true);
		}
	};

	const handleConAction = async (orderNum?: string, totalMoney?: number) => {
		// const { data } = await performanceService.list({ orderNum, serviceType: 2 });
		// if (data.code == 200) {
		setConVisible(true);
		setStateFlag(false);
		setTotalMoney(totalMoney!);
		// }
	};

	const handleSaleAction = async (orderNum?: string, saleMoney?: number) => {
		const { data } = await performanceService.list({ orderNum, serviceType: 1 });
		if (data.code == 200) {
			setSaleVisible(true);
			setTotalMoney(saleMoney!);
		}
	};

	// 根据skuStr字段拼接商品的sku
	const getSku = useCallback((item: { skuStr: string }) => {
		if (item.skuStr) {
			const skus = JSON.parse(item.skuStr);
			const skuTitle = "（" + skus.reduce((pre: string, next: { title: string }) => pre + "/" + next.title, "") + "）";
			return skuTitle.replace("（/", "（");
		}
		return "";
	}, []);

	// 如果选择的语言是中文则拼接中文店名
	const getShopName = useCallback(
		(row: OrderEntity) => {
			const language = i18n.language;
			if (language === "zh-CN") return (row.shopNumber || "") + " " + (row.shopTitle || "");
			return row.shopNumber || "";
		},
		[i18n.language]
	);
	return (
        <Container>
            <Form
                onFinish={onSearchFinish}
                form={searchForm}
                labelCol={{ span: 8 }}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item label={t("orderNum")} name={"orderNum"}>
                            <Input placeholder={t("orderNum")} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t("orderType")} name={"orderType"}>
                            <MultiSelectProductType />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t("discountType")}
                            name={"discountTypeNumber"}
                        >
                            <SelectDiscountType />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t("orderStatus")}
                            name={"orderStatus"}
                        >
                            <MultiSelectOrderStatus />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t("createdTime")} name={"date"}>
                            <DatePicker.RangePicker showTime />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t("refundTime")} name={"refundTime"}>
                            <DatePicker.RangePicker showTime />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t("site.name")} name={"shopId"}>
                            <SelectSite />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t("mobile")} name={"mobile"}>
                            <Input placeholder={t("mobile")} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t("licenseNumber")}
                            name={"licenseNumber"}
                        >
                            <Input placeholder={t("licenseNumber")} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t("payWay")} name={"payWay"}>
                            <MultiSelectPayWay />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t("hasSettled")} name={"settled"}>
                            <Select
                                placeholder={t("hasSettled")}
                                options={[
                                    { value: 0, label: t("no") },
                                    { value: 1, label: t("yes") },
                                ]}
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t("settleDate")} name={"settleDate"}>
                            <DatePicker.RangePicker
                                showTime={{
                                    defaultValue: [
                                        moment("00:00:00", "HH:mm:ss"),
                                        moment("23:59:59", "HH:mm:ss"),
                                    ],
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label={t("promoterName")}
                            name={"promoterId"}
                        >
                            <SelectPromoter />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t("productName")}
                            name={"productName"}
                        >
                            <Input placeholder={t("productName")} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={t("couponName")} name={"couponName"}>
                            <Input placeholder={t("couponName")} />
                        </Form.Item>
                    </Col>
                    <Col span={8}></Col>
                    <Col span={8}>
                        <Form.Item>
                            <Space>
                                <Button type={"primary"} htmlType={"submit"}>
                                    {t("search")}
                                </Button>
                                {checkPermission(350) && (
                                    <Button
                                        type={"primary"}
                                        onClick={exportList}
                                    >
                                        {t("export")}
                                    </Button>
                                )}
                                <Button type={"primary"} onClick={exportSku}>
                                    {t("exportSku")}
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="list">
                {loading && (
                    <div className="spin">
                        <Spin size={"large"} />
                    </div>
                )}

                <Space className="multiSettle">
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                    />
                    <Button
                        type={"primary"}
                        size={"small"}
                        onClick={() => {
							setAllSettleFlag(false);
                            setMultiSettleVisible(true);
                        }}
                    >
                        {t("batchSettle")}
                    </Button>
					<Button
						type={"primary"}
						size={"small"}
						onClick={() => {
							setAllSettleFlag(true);
							setMultiSettleVisible(true);
						}}
					>
						{t("allSettle")}
					</Button>
                    <div>
                        {t("totalOrder")}:{" "}
                        {Number(
                            orderSumData.allOrderCount ?? 0,
                        ).toLocaleString()}
                    </div>
                    <div>
                        {t("totalPrice")}:{" "}
                        {Number(orderSumData.allOrderFee ?? 0).toLocaleString()}
                    </div>
                    <div>
                        {t("couponFee")}:{" "}
                        {Number(
                            orderSumData.allOrderCouponFee ?? 0,
                        ).toLocaleString()}
                    </div>
                    <div>
                        {t("totalRevenue")}:{" "}
                        {Number(
                            orderSumData.allOrderPayFee ?? 0,
                        ).toLocaleString()}
                    </div>
                    <div>
                        {t("totalSettled")}:{" "}
                        {Number(
                            orderSumData.allOrderSettleFee ?? 0,
                        ).toLocaleString()}
                    </div>
                </Space>
                <Checkbox.Group value={checkedList} onChange={onChange}>
                    {data &&
                        data.map((row, index) => {
                            return (
                                <div className="item" key={index}>
                                    <div className="main_container">
                                        <Space className={"header"}>
                                            <Checkbox
                                                value={row.id}
                                                checked={true}
                                            />
                                            <div
                                                className={"shopName"}
                                                style={{
                                                    color:
                                                        [3, 17, 20].indexOf(
                                                            row.orderStatus ??
                                                                0,
                                                        ) > -1
                                                            ? "#AAAAAA"
                                                            : "#376EB3",
                                                }}
                                            >
                                                {getShopName(row)}
                                            </div>
                                            <div className="orderNum">
                                                {t("orderNum")}:{" "}
                                                <span>{row.orderNum}</span>
                                            </div>
                                            <div className={"shopStatus"}>
                                                <Tag
                                                    color={
                                                        OrderStatusColorArr[
                                                            row.orderStatus ?? 0
                                                        ]
                                                    }
                                                >
                                                    {row.orderStatus &&
                                                        t(
                                                            OrderStatusArr[
                                                                row.orderStatus
                                                            ],
                                                        )}
                                                </Tag>
                                                <Tag
                                                    color={
                                                        PayWayTagColorArr[
                                                            row.payWayCode ?? 0
                                                        ] || "#4762fe"
                                                    }
                                                >
                                                    {row.payWayCode
                                                        ? t(
                                                              PayWayTagArr[
                                                                  row.payWayCode
                                                              ],
                                                          )
                                                        : t("unknown")}
                                                </Tag>
                                            </div>
                                        </Space>
                                        <div className={"Introduction"}>
                                            <div className={"list"}>
                                                <div className="listItem">
                                                    <Image
                                                        className="icon"
                                                        src={
                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/nickname.png"
                                                        }
                                                    />
                                                    <span>
                                                        {row.nickName ||
                                                            "暂无信息"}
                                                    </span>
                                                </div>
                                                <div className="listItem">
                                                    <Image
                                                        className="icon"
                                                        src={
                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/license.png"
                                                        }
                                                    />
                                                    <span>
                                                        {row.licenseNumber ||
                                                            "暂无信息"}
                                                    </span>
                                                </div>
                                                <div className="listItem">
                                                    <Image
                                                        className="icon"
                                                        src={
                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/phone.png"
                                                        }
                                                    />
                                                    <span>
                                                        {row.mobile ||
                                                            "暂无信息"}
                                                    </span>
                                                </div>
                                                <div className="listItem">
                                                    <Image
                                                        className="icon"
                                                        src={
                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/salesman.png"
                                                        }
                                                    />
                                                    <span>
                                                        {row.saleUserName ||
                                                            "暂无信息"}
                                                    </span>
                                                </div>
                                                <div className="listItem">
                                                    <Image
                                                        className="icon"
                                                        src={
                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/constructor.png"
                                                        }
                                                    />
                                                    <span>
                                                        {row.adminNameList ||
                                                            "暂无信息"}
                                                    </span>
                                                </div>
                                                <div className="listItem">
                                                    <Image
                                                        className="icon"
                                                        src={
                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/discount.png"
                                                        }
                                                    />
                                                    <span>
                                                        {t(
                                                            disCountArr[
                                                                Number(
                                                                    row.discountType,
                                                                )
                                                            ],
                                                        ) || "暂无信息"}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={"list"}>
                                                <div className="listItem">
                                                    <Image
                                                        className="icon"
                                                        src={
                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/time.png"
                                                        }
                                                    />
                                                    <span>
                                                        {"创建: " +
                                                            (row.createdTime ||
                                                                "暂无信息")}
                                                    </span>
                                                </div>
                                                <div className="listItem">
                                                    <Image
                                                        className="icon"
                                                        src={
                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/time.png"
                                                        }
                                                    />
                                                    <span>
                                                        {"支付: " +
                                                            (row.payTime ||
                                                                "暂无信息")}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"borderline"}></div>
                                    {row.orderItemInfo &&
                                        JSON.parse(row.orderItemInfo).map(
                                            (item: any, indexs: number) => {
                                                return (
                                                    <div
                                                        className={
                                                            "content_container"
                                                        }
                                                        key={indexs}
                                                    >
                                                        <div
                                                            className={
                                                                "shopList"
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    "itemTop"
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        "name"
                                                                    }
                                                                >
                                                                    {item.productName +
                                                                        getSku(
                                                                            item,
                                                                        )}
                                                                    <Tag
                                                                        className={
                                                                            "tag"
                                                                        }
                                                                        color={
                                                                            ProductTypeColorArr[
                                                                                item.productTypeId ??
                                                                                    0
                                                                            ]
                                                                        }
                                                                    >
                                                                        {
                                                                            item.productTypeTitle
                                                                        }
                                                                    </Tag>
                                                                </div>
                                                                <div
                                                                    className={
                                                                        "money"
                                                                    }
                                                                >
                                                                    <span
                                                                        className={
                                                                            "total"
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "total",
                                                                        )}
                                                                        :{" "}
                                                                    </span>
                                                                    <span
                                                                        className={
                                                                            "price"
                                                                        }
                                                                    >
                                                                        ￥
                                                                        {
                                                                            item.totalFee
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        className={
                                                                            "preferential"
                                                                        }
                                                                    >
                                                                        -
                                                                        {t(
                                                                            "discount",
                                                                        )}
                                                                        :{" "}
                                                                    </span>
                                                                    <span
                                                                        className={
                                                                            "price"
                                                                        }
                                                                    >
                                                                        ￥
                                                                        {
                                                                            item.couponFee
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        className={
                                                                            "realPay"
                                                                        }
                                                                    >
                                                                        =
                                                                        {t(
                                                                            "netTotal",
                                                                        )}
                                                                        :{" "}
                                                                    </span>
                                                                    <span
                                                                        className={
                                                                            "price"
                                                                        }
                                                                    >
                                                                        ￥
                                                                        {(
                                                                            item.totalFee -
                                                                            item.couponFee
                                                                        ).toFixed(
                                                                            2,
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                {item.cardName && (
                                                                    <Tag color="red">
                                                                        {" "}
                                                                        {
                                                                            item.cardName
                                                                        }
                                                                    </Tag>
                                                                )}
                                                                {item.couponType ===
                                                                    50 && (
                                                                    <div className="coupon">
                                                                        {
                                                                            item.couponName
                                                                        }{" "}
                                                                        <Tag color="red">
                                                                            {" "}
                                                                            {t(
                                                                                couponTypeService
                                                                                    .typeArray[50],
                                                                            )}
                                                                        </Tag>{" "}
                                                                    </div>
                                                                )}
                                                                {item.couponType ===
                                                                    54 && (
                                                                    <div>
                                                                        {
                                                                            item.couponName
                                                                        }{" "}
                                                                        <Tag color="red">
                                                                            {" "}
                                                                            {t(
                                                                                couponTypeService
                                                                                    .typeArray[54],
                                                                            )}
                                                                        </Tag>
                                                                        (满
                                                                        {
                                                                            item.couponMaxMoney
                                                                        }
                                                                        减
                                                                        {
                                                                            item.couponDeductMoney
                                                                        }
                                                                        )
                                                                    </div>
                                                                )}
                                                                {item.couponType ===
                                                                    56 && (
                                                                    <div>
                                                                        {
                                                                            item.couponName
                                                                        }{" "}
                                                                        <Tag color="red">
                                                                            {" "}
                                                                            {t(
                                                                                couponTypeService
                                                                                    .typeArray[56],
                                                                            )}
                                                                        </Tag>
                                                                        (
                                                                        {item.couponDiscount *
                                                                            10}
                                                                        折)
                                                                    </div>
                                                                )}

                                                                {item.couponType ===
                                                                    60 && (
                                                                    <div>
                                                                        {
                                                                            item.couponName
                                                                        }{" "}
                                                                        <Tag color="red">
                                                                            {" "}
                                                                            {t(
                                                                                couponTypeService
                                                                                    .typeArray[60],
                                                                            )}
                                                                        </Tag>
                                                                        {
                                                                            item.couponDeductMoney
                                                                        }
                                                                        元
                                                                    </div>
                                                                )}

                                                                {item.couponType ===
                                                                    64 && (
                                                                    <div>
                                                                        {
                                                                            item.couponName
                                                                        }{" "}
                                                                        <Tag color="red">
                                                                            {" "}
                                                                            {t(
                                                                                couponTypeService
                                                                                    .typeArray[64],
                                                                            )}
                                                                        </Tag>
                                                                    </div>
                                                                )}

                                                                <div
                                                                    className={
                                                                        "btn"
                                                                    }
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        onClick={() => {
                                                                            setRemarkVisible(
                                                                                true,
                                                                            ),
                                                                                setRemarkInfo(
                                                                                    {
                                                                                        id: item.id,
                                                                                        remark: item.remark,
                                                                                    },
                                                                                );
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            "changeRemark",
                                                                        )}
                                                                    </Button>

                                                                    {item.productTypeId !==
                                                                        1 &&
                                                                        item.orderItemStatus ==
                                                                            9 && (
                                                                            <Button
                                                                                type="primary"
                                                                                onClick={() => {
                                                                                    setItemId(
                                                                                        item.id,
                                                                                    );
                                                                                    setCheckVisible(
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {t(
                                                                                    "qualityTesting",
                                                                                )}
                                                                            </Button>
                                                                        )}

                                                                    {item.orderItemStatus ==
                                                                        5 && (
                                                                        <Space>
                                                                            {checkPermission(
                                                                                40102,
                                                                            ) && (
                                                                                <Button
                                                                                    type="primary"
                                                                                    onClick={() => {
                                                                                        updateItemsStatus(
                                                                                            {
                                                                                                productTypeId:
                                                                                                    item.productTypeId,
                                                                                                id: item.id,
                                                                                                status: 7,
                                                                                                orderNum:
                                                                                                    item.orderNum,
                                                                                            },
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        "goToConstruction",
                                                                                    )}
                                                                                </Button>
                                                                            )}
                                                                            {item.productTypeId ===
                                                                                1 &&
                                                                                checkPermission(
                                                                                    40103,
                                                                                ) && (
                                                                                    <Button
                                                                                        type="primary"
                                                                                        onClick={() => {
                                                                                            reSetCommand(
                                                                                                {
                                                                                                    orderNum:
                                                                                                        item.orderNum,
                                                                                                },
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {t(
                                                                                            "重发指令",
                                                                                        )}
                                                                                    </Button>
                                                                                )}
                                                                        </Space>
                                                                    )}

                                                                    {item.orderItemStatus ==
                                                                        7 && (
                                                                        <Space>
                                                                            {checkPermission(
                                                                                40102,
                                                                            ) && (
                                                                                <Button
                                                                                    type="primary"
                                                                                    onClick={() => {
                                                                                        handleConstruction(
                                                                                            item.orderNum,
                                                                                            row.constructionMoney,
                                                                                            item.shopId,
                                                                                        );
                                                                                        setConstructionUser(
                                                                                            {
                                                                                                // ...constructionUser,
                                                                                                productTypeId:
                                                                                                    item.productTypeId,
                                                                                                id: item.id,
                                                                                                status: 9,
                                                                                                orderNum:
                                                                                                    item.orderNum,
                                                                                            },
                                                                                        );
                                                                                        setNumInfo(
                                                                                            item.orderNum,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {t(
                                                                                        "finishConstruction",
                                                                                    )}
                                                                                </Button>
                                                                            )}
                                                                            {item.productTypeId ===
                                                                                1 &&
                                                                                checkPermission(
                                                                                    40103,
                                                                                ) && (
                                                                                    <Button
                                                                                        type="primary"
                                                                                        onClick={() => {
                                                                                            reSetCommand(
                                                                                                {
                                                                                                    orderNum:
                                                                                                        item.orderNum,
                                                                                                },
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {t(
                                                                                            "重发指令",
                                                                                        )}
                                                                                    </Button>
                                                                                )}
                                                                        </Space>
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className={
                                                                        "operation"
                                                                    }
                                                                ></div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    "itemBottom"
                                                                }
                                                            >
                                                                <div className="itemBox">
                                                                    <Image
                                                                        className="icon"
                                                                        src={
                                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/time.png"
                                                                        }
                                                                    />
                                                                    <span>
                                                                        {t(
                                                                            "constructStart",
                                                                        )}
                                                                        :{" "}
                                                                        {item.startDatetime ||
                                                                            "暂无信息"}
                                                                    </span>
                                                                </div>
                                                                <div className="itemBox">
                                                                    <Image
                                                                        className="icon"
                                                                        src={
                                                                            "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/time.png"
                                                                        }
                                                                    />
                                                                    <span>
                                                                        {t(
                                                                            "constructEnd",
                                                                        )}
                                                                        :{" "}
                                                                        {item.endDatetime ||
                                                                            "暂无信息"}
                                                                    </span>
                                                                </div>
                                                                <div className="itemBox">
                                                                    <span>
                                                                        {t(
                                                                            "remark",
                                                                        )}
                                                                        :{" "}
                                                                        {item.remark ||
                                                                            "暂无信息"}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            {item.productTypeId !==
                                                                1 &&
                                                                item.orderItemStatus ==
                                                                    9 &&
                                                                item.orderQualityCreatedTime! && (
                                                                    <div
                                                                        className={
                                                                            "itemBottom"
                                                                        }
                                                                    >
                                                                        <div className="itemBox">
                                                                            <Image
                                                                                className="icon"
                                                                                src={
                                                                                    "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/qualityResult.png"
                                                                                }
                                                                            />
                                                                            <span>
                                                                                {t(
                                                                                    "qualityResult",
                                                                                )}
                                                                                :{" "}
                                                                                {item.orderQualityResult ===
                                                                                0
                                                                                    ? "不通过"
                                                                                    : "通过"}
                                                                            </span>
                                                                        </div>
                                                                        <div className="itemBox">
                                                                            <Image
                                                                                className="icon"
                                                                                src={
                                                                                    "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/time.png"
                                                                                }
                                                                            />
                                                                            <span>
                                                                                {t(
                                                                                    "qualityTime",
                                                                                )}
                                                                                :{" "}
                                                                                {
                                                                                    item.orderQualityCreatedTime
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className="itemBox">
                                                                            <span>
                                                                                {t(
                                                                                    "qualityAdmin",
                                                                                )}
                                                                                :{" "}
                                                                                {
                                                                                    item.orderQualityCreatedName
                                                                                }
                                                                            </span>
                                                                        </div>

                                                                        <div className="itemBox">
                                                                            <span>
                                                                                {t(
                                                                                    "qualityRemark",
                                                                                )}
                                                                                :{" "}
                                                                                {
                                                                                    item.orderQualityComments
                                                                                }
                                                                            </span>
                                                                        </div>

                                                                        <div
                                                                            className="itemBox"
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => {
                                                                                setQualityList(
                                                                                    item.id,
                                                                                );
                                                                                setQualityVisible(
                                                                                    true,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color: "#1B78F2",
                                                                                }}
                                                                            >
                                                                                {t(
                                                                                    "qualityRecord",
                                                                                ) +
                                                                                    ">"}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        )}
                                    <div className={"borderline"}></div>
                                    <div className="footer_container">
                                        <div className={"footer"}>
                                            <div className={"itemBottom"}>
                                                {/* {row.refundApplyTime != null && (
												<span>
													{t("refundApplication")}: {row.refundApplyTime}
												</span>
											)}
											{row.refundTime != null && (
												<span>
													{t("refundAt")}: {row.refundTime}
												</span>
											)} */}
                                                {[11, 13, 15, 17].indexOf(
                                                    row.orderStatus ?? 1,
                                                ) > -1 && (
                                                    <div>
                                                        <span>
                                                            <Image
                                                                className="icon"
                                                                src={
                                                                    "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/time.png"
                                                                }
                                                            />
                                                            {t("applyRefund")}:{" "}
                                                            {
                                                                row.refundApplyTime
                                                            }
                                                        </span>
                                                        <span>
                                                            <Image
                                                                className="icon"
                                                                src={
                                                                    "https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/DataCenter/time.png"
                                                                }
                                                            />
                                                            {t("refundedTime")}:{" "}
                                                            {row.refundTime}
                                                        </span>
                                                        <span>
                                                            {t(
                                                                "refundedReason",
                                                            )}
                                                            : {row.refundReason}
                                                        </span>
                                                    </div>
                                                )}

                                                <div>
                                                    <span>
                                                        {t("orderRate")}:{" "}
                                                        {row.orderRate ||
                                                            "暂无信息"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"footerMoney"}>
                                            <div className="footerItem">
                                                <span className={"total"}>
                                                    {t("total")}:
                                                </span>
                                                <span className={"price"}>
                                                    ￥{row.totalFee}
                                                </span>
                                                <span
                                                    className={"preferential"}
                                                >
                                                    {" "}
                                                    - {t("discount")}:
                                                </span>
                                                <span className={"price"}>
                                                    ￥{row.couponFee}
                                                </span>
                                                <span className={"realPay"}>
                                                    {" "}
                                                    = {t("netTotal")}:
                                                </span>
                                                <span className={"price"}>
                                                    ￥{row.payFee}
                                                </span>
                                            </div>
                                            <div className="footerItem">
                                                <span className={"realPay"}>
                                                    {t("settleRevenue")}:{" "}
                                                </span>
                                                <span className={"price"}>
                                                    {row.settleFee}
                                                </span>
                                            </div>
                                            <div className="footerItem">
                                                <span
                                                    className={
                                                        "realPay greyText"
                                                    }
                                                >
                                                    {t("settleRemark")}:{" "}
                                                </span>
                                                <span className={"greyText"}>
                                                    {row.settleRemark ||
                                                        "暂无信息"}
                                                </span>
                                            </div>
                                            <div className="footerItem">
                                                <span
                                                    className={
                                                        "realPay greyText"
                                                    }
                                                >
                                                    {t("settleDatetime")}:{" "}
                                                </span>
                                                <span className={"greyText"}>
                                                    {row.settleTime ||
                                                        "暂无信息"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"footerOperation"}>
                                            <Space>
                                                {/* {row.orderStatus === 3 && (
												<Button onClick={() => settle(row)} type={"primary"}>
													结算
												</Button>
											)} */}

                                                {[
                                                    5, 7, 9, 11, 13, 15, 19,
                                                ].includes(
                                                    Number(row.orderStatus),
                                                ) && (
                                                    <Button
                                                        onClick={() =>
                                                            settle(row)
                                                        }
                                                        type={"primary"}
                                                    >
                                                        结算
                                                    </Button>
                                                )}
                                                {checkPermission(317) &&
                                                    Number(row.orderStatus) >=
                                                        9 &&
                                                    row.orderStatus !== 17 && (
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                handleConAction(
                                                                    row.orderNum,
                                                                    row.constructionMoney,
                                                                ),
                                                                    setNumInfo(
                                                                        row.orderNum,
                                                                    );
                                                            }}
                                                        >
                                                            {t(
                                                                "constructionManagement",
                                                            )}
                                                        </Button>
                                                    )}
                                                {checkPermission(351) &&
                                                    Number(row.orderStatus) >=
                                                        9 &&
                                                    row.orderStatus !== 17 && (
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                handleSaleAction(
                                                                    row.orderNum,
                                                                    row.saleMoney,
                                                                ),
                                                                    setNumInfo(
                                                                        row.orderNum,
                                                                    ),
                                                                    setSaleVisible(
                                                                        true,
                                                                    );
                                                            }}
                                                        >
                                                            {t(
                                                                "salesManagement",
                                                            )}
                                                        </Button>
                                                    )}
                                                {checkPermission(297) &&
                                                    row.orderStatus === 11 && (
                                                        <Button
                                                            onClick={() => {
                                                                updateStatus({
                                                                    orderNum:
                                                                        row.orderNum,
                                                                    orderId:
                                                                        row.id,
                                                                    orderStatus: 15,
                                                                });
                                                            }}
                                                            type={"primary"}
                                                        >
                                                            {t("passAudit")}
                                                        </Button>
                                                    )}
                                                {checkPermission(298) &&
                                                    (row.orderStatus === 11 ||
                                                        row.orderStatus ===
                                                            15) && (
                                                        <Button
                                                            onClick={() => {
                                                                updateStatus({
                                                                    orderId:
                                                                        row.id,
                                                                    orderStatus: 13,
                                                                });
                                                            }}
                                                            type={"primary"}
                                                        >
                                                            {t("refuseRefund")}
                                                        </Button>
                                                    )}
                                                {checkPermission(299) &&
                                                    row.orderStatus === 15 && (
                                                        <Button
                                                            onClick={() => {
                                                                updateStatus({
                                                                    orderNum:
                                                                        row.orderNum,
                                                                    orderId:
                                                                        row.id,
                                                                    orderStatus: 17,
                                                                });
                                                            }}
                                                            type={"primary"}
                                                        >
                                                            {t("agreeRefund")}
                                                        </Button>
                                                    )}
                                                {checkPermission(300) && (
                                                    <Button
                                                        onClick={() => {
                                                            handleDelete(
                                                                row.id,
                                                            );
                                                        }}
                                                        type={"primary"}
                                                        danger
                                                    >
                                                        {t("delete")}
                                                    </Button>
                                                )}
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </Checkbox.Group>
            </div>
            <Pagination
                total={total}
                current={searchObj.customPageNum}
                showTotal={(total) => `${t("total")} ${total} ${t("items")}`}
                defaultPageSize={15}
                defaultCurrent={1}
                onChange={(page, pageSize) =>
                    setSearchObj({
                        ...searchObj,
                        customPageNum: page,
                        customPageSize: pageSize,
                    })
                }
            />
            <Modal
                visible={settleVisible}
                width="60%"
                title="结算"
                onOk={onSettleFinish}
                onCancel={() => setSettleVisible(false)}
            >
                <Form labelCol={{ span: 8 }} form={settleForm}>
                    <Form.Item label={t("orderNum")}>
                        {orderInfo?.orderNum}
                    </Form.Item>
                    <Form.Item label={t("settleRevenue")}>
                        <InputNumber
                            value={orderInfo?.settleFee ?? orderInfo?.payFee}
                            onChange={(e: any) =>
                                setOrderInfo({ ...orderInfo, settleFee: e })
                            }
                        />
                    </Form.Item>
                    <Form.Item label={t("settleRemark")}>
                        <TextArea
                            value={orderInfo?.settleRemark}
                            onChange={(e: any) => {
                                setOrderInfo({
                                    ...orderInfo,
                                    settleRemark: e.target.value,
                                });
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={multiSettleVisible}
                width="60%"
                title="批量结算"
                onOk={onMultiSettleFinish}
                onCancel={() => setMultiSettleVisible(false)}
            >
                <Form labelCol={{ span: 8 }} form={settleForm}>
                    {!allSettleFlag && (
                        <Form.Item label={t("id")}>
                            {checkedList.join(",")}
                        </Form.Item>
                    )}
                    {allSettleFlag && (
                        <Form.Item label="结算订单">
                            该筛选条件下所有订单
                        </Form.Item>
                    )}
                    <Form.Item label={t("settleDiscount")}>
                        <InputNumber
                            value={settleDiscount}
                            onChange={(e) => setSettleDiscount(e || 0)}
                        />
                        %
                    </Form.Item>
                    <Form.Item label={t("settleRemark")}>
                        <TextArea
                            value={orderInfo?.settleRemark}
                            onChange={(e: any) => {
                                setOrderInfo({ settleRemark: e.target.value });
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <SalePerformanceMadal
                visible={saleVisible}
                orderNum={numInfo}
                saleMoney={totalMoney}
                onCancel={() => {
                    setSaleVisible(false);
                }}
            />
            <ConstructionPerformanceMadal
                visible={conVisible}
                orderNum={numInfo}
                totalMoney={totalMoney}
                flag={stateFlag}
                onCancel={() => {
                    setConVisible(false);
                    setNumInfo("");
                }}
                // 更新订单状态后 施工人员消失
                changeStatus={(ids: any, jsonStr: string) => {
                    updateItemsStatus({
                        ...constructionUser,
                        constructionUserId: ids,
                        adminPerformanceManage: jsonStr,
                    });
                    // init();
                }}
            />
            {/* <ConstructionPerformanceMadal
				visible={conItemVisible}
				orderNum={numInfo}
				onCancel={() => {
					setConItemVisible(false);
					setNumInfo('')
				}}
				changeStatus={() => {
					updateItemsStatus(constructionUser);
				}}
			/> */}

            <Modal
                title={t("changeRemark")}
                visible={remarkVisible}
                onOk={handleMarkOk}
                onCancel={handleMarkCancel}
            >
                {
                    <div>
                        <div>{t("changeRemark")}</div>
                        <Input
                            value={remarkInfo.remark}
                            onChange={(e) => {
                                setRemarkInfo({
                                    ...remarkInfo!,
                                    remark: e.target.value!,
                                });
                            }}
                        />
                    </div>
                }
            </Modal>

            <Modal
                title="选择施工人员"
                visible={adminVisible}
                onOk={handleAdminOk}
                onCancel={handleAdminCancel}
            >
                <MultiSelectAdmin
                    shopId={orderItemShopId}
                    value={constructionUser?.constructionUserId}
                    onChange={(e: any) => {
                        setConstructionUser({
                            ...constructionUser,
                            constructionUserId: e,
                        });
                    }}
                />
            </Modal>

            <Modal
                title="质检信息"
                visible={checkVisible}
                onOk={handleCheckOk}
                onCancel={handleCheckCancel}
                destroyOnClose={true}
            >
                <Form labelCol={{ span: 8 }}>
                    <Form.Item label={t("qualityResult")}>
                        <Radio.Group
                            onChange={(e) => {
                                setQualityInfo({
                                    ...qualityInfo,
                                    result: e.target.value,
                                });
                            }}
                        >
                            <Radio value={1}>{t("pass")}</Radio>
                            <Radio value={0}>{t("unPass")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label={t("remarkInfo")}>
                        <TextArea
                            onChange={(e) => {
                                setQualityInfo({
                                    ...qualityInfo,
                                    comments: e.target.value,
                                });
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="质检列表"
                visible={qualityVisible}
                footer={null}
                width={"50%"}
                onCancel={handleQualityListCancel}
            >
                <Table
                    columns={columns}
                    dataSource={qualityArr}
                    rowKey={(record: any) => record.id + ""}
                    pagination={{
                        pageSize: qualityPaginationObj.customPageSize,
                        current: qualityPaginationObj.customPageNum,
                        total: qualityTotal,
                        showTotal: () => {
                            return (
                                <div>
                                    {t("total")} {qualityTotal}
                                </div>
                            );
                        },
                    }}
                    onChange={(pagination) => {
                        setQualityPaginationObj({
                            ...qualityPaginationObj,
                            customPageNum: pagination.current,
                            customPageSize: pagination.pageSize,
                        });
                    }}
                ></Table>
            </Modal>
        </Container>
    );
};

const Container = styled.div`
	width: 100%;
	padding: 10px;

	.searchArea {
		display: flex;
		justify-content: left;
		flex-wrap: wrap;
	}

	.list {
		display: flex;
		flex-direction: column;
		width: 100%;

		.multiSettle {
			margin-bottom: 10px;
			padding-bottom: 5px;
			border-bottom: 1px dashed #ccc;
		}

		.spin {
			text-align: center;
		}

		.item,
		.itemCancel {
			display: flex;
			flex-direction: column;
			border: 1px solid #d9d9d9;
			border-radius: 10px;
			margin-bottom: 10px;
			background: #f4f5fb;
			.main_container {
				width: 100%;
				box-sizing: border-box;
				padding: 20px;
				display: flex;
				flex-direction: column;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;

				.header {
					width: 100%;
					display: flex;
					align-items: center;
					.shopName {
						font-size: 20px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						color: #376eb3;
					}
					.orderNum {
						margin-left: 20px;
						font-size: 16px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						color: #333333;
						line-height: 22px;
					}
					.shopStatus {
						.shopStatusSpan {
							padding: 3px 5px 3px 5px;
							background: rgba(23, 127, 255, 0.06);
							border-radius: 4px;
							border: 1px solid #376eb3;
							font-size: 12px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #376eb3;
							margin-left: 10px;
						}
					}
				}

				.Introduction {
					width: 100%;
					display: flex;
					flex-direction: column;
					box-sizing: border-box;
					.list {
						box-sizing: border-box;
						display: flex;
						flex-direction: row;
						height: 30px;
						width: 100%;
						margin: 5px 0;
						align-items: center;
						.listItem {
							display: inline-flex;
							align-items: center;
							margin-right: 60px;
							font-size: 16px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
							line-height: 22px;

							.icon {
								width: 24px;
								height: 24px;
							}

							span {
								margin: 2px 0 0 4px;
							}
						}
					}
				}
			}
			.borderline {
				width: 100%;
				height: 1px;
				background: #cccccc;
			}
			.content_container {
				width: 100%;
				background: #fff;
				padding: 20px;
				display: flex;
				align-items: center;

				.shopList {
					.itemTop {
						width: 100%;
						display: flex;
						align-items: center;
						.name {
							min-width: 300px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #376eb3;

							.tag {
								margin-left: 5px;
							}
						}
						.money {
							min-width: 300px;
							margin-left: 80px;
							font-size: 14px;
							font-family: PingFangSC-Semibold, PingFang SC;
							font-weight: 600;
							color: #333333;
							.price {
								color: #ff4d4f;
							}
						}

						.coupon {
							margin-left: 100px;
						}

						.btn {
							margin-left: 100px;

							Button {
								margin-right: 20px;
							}
						}
						.operation {
							margin-left: 20px;
							display: flex;
							align-items: center;
							.btn {
								width: 74px;
								height: 30px;
								background: #206eb8;
								border-radius: 4px;
								font-size: 12px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #ffffff;
								margin-left: 15px;
								text-align: center;
								line-height: 30px;
								cursor: pointer;
							}
						}
					}
					.itemBottom {
						margin-top: 15px;
						display: flex;
						flex-direction: row;
						align-items: center;

						.itemBox {
							display: inline-flex;
							flex-direction: row;
							filter: grayscale(80%);
							margin-right: 60px;

							.icon {
								width: 20px;
								height: 20px;
							}

							span {
								margin: 2px 0 0 4px;
							}
						}
						span {
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #777777;
							margin-right: 20px;
						}
					}
				}
			}
			.footer_container {
				width: 100%;
				background: #fff;
				padding: 20px;
				display: flex;
				flex-direction: column;
				align-items: center;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;

				.footer {
					width: 100%;
					display: flex;
					align-items: center;
					.itemBottom {
						span {
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #777777;
							margin-right: 20px;
						}
					}
				}
				.footerMoney {
					width: 100%;
					margin: 10px 0;
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size: 14px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #333333;

					.footerItem {
						display: inline-block;
						margin-right: 40px;

						.price {
							color: #ff4d4f;
						}

						.greyText {
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #777777;
						}
					}
				}
				.footerOperation {
					width: 100%;
					display: flex;
					align-items: center;
					.btn {
						width: 74px;
						height: 30px;
						background: #206eb8;
						border-radius: 4px;
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #ffffff;
						text-align: center;
						line-height: 30px;
						margin-right: 20px;
						cursor: pointer;
					}
				}
			}
		}
	}
`;
