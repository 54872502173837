import { http } from "../../common/http"
import { PackagePurchaseParam } from "./packagePurchase.param"

class PackagePurchaseService{
    
    list=async(param:PackagePurchaseParam)=>{
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/activityPackageBuyRecord",
			method: "GET",
			params: param,
        })
    }


    downLoad=async(param:PackagePurchaseParam)=>{
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/activityPackageBuyRecord/export",
            method: "GET",
            params: param,
            responseType: "blob",
        });
    };

    changeRefundStatus=async(id:number)=>{
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/activityPackageBuyRecord/statusChange/refund/"+id,
            method: "POST",
            data: id,
            
        });
    };

    refund=async(id:number)=>{
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/activityPackageBuyRecord/refund/"+id,
            method: "POST",
            data: id,           
        });
    };


}

export default  new PackagePurchaseService()