import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Space, Table, Tag } from "antd";
import { t } from "i18next";
import moment from "moment";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import pointService from "../../module/point/service/point.service";
import { PointParam } from "../../module/point/param/point.param";
const { RangePicker } = DatePicker;
const { Option } = Select;

export const PointExchangeList: FC = () => {
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState(0);

	const [form] = Form.useForm();

	const [searchObj, setSearchObj] = useState<PointParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	const onSearch = async (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			date: undefined,
			startTime: value.date && value.date[0] && value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.date && value.date[1] && value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageSize: 15,
		customPageNum: 1,
		});
	};

	const init = useCallback(async () => {
		const { data } = await pointService.exchangeList({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.records);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const [dataList, setDataList] = useState();

	const changeStatus = async (orderNum: string) => {
		const { data } = await pointService.updateStatus(orderNum);
		if (data.code === 200) {
			message.success(t("success"));
			init();
		}
	};

	const columns = [
		{ title: t("nickName"), dataIndex: "nickName" },
		{ title: t("mobile"), dataIndex: "mobile" },
		{ title: t("orderNum"), dataIndex: "orderNum" },
		{ title: t("productName"), dataIndex: "productName" },
		{ title: t("pointsNumber"), dataIndex: "pointsNumber" },
		{ title: t("createdTime"), dataIndex: "createdTime" },
		{
			title: t("orderStatus"),
			dataIndex: "orderStatus",
			render: (text: number) => {
				return text === 1 ? <Tag color={"blue"}>{t("exchanged")}</Tag> : <Tag color={"green"}>{t("verificated")}</Tag>;
			},
		},
		{ title: t("verificationTime"), dataIndex: "completeTime" },
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<div>
						{record.orderStatus === 1 && (
							<Button
								type='primary'
								onClick={() => {
									changeStatus(record.orderNum);
								}}
							>
								{t("confirmVerification")}
							</Button>
						)}
					</div>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onSearch} form={form} initialValues={{ date: [moment(), moment()] }}>
				<Row gutter={20}>
					<Col span={8}>
						<Form.Item label={t("nickName")} name='nickName'>
							<Input allowClear />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("mobile")} name='mobile'>
							<Input allowClear/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("orderNum")} name='orderNum'>
							<Input allowClear/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("period")} name='date'>
							<RangePicker allowClear/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("orderStatus")} name='orderStatus'>
							<Select allowClear>
								<Option value={1}> {t("exchanged")}</Option>
								<Option value={2}> {t("verificated")}</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={4} offset={1}>
						<Form.Item>
							<Space>
								<Button type='primary' htmlType='submit'>
									{t("search")}
								</Button>
							</Space>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(_, index) => index + ""}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.ant-form-item {
		margin-bottom: 15px;
	}

	.ant-form-item-label {
		min-width: 100px;
	}
`;
