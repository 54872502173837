import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { FC, useEffect, useState } from "react";

interface props {
	isLoading: boolean;
}

const Loading: FC<props> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	useEffect(() => {
		setLoading(props.isLoading);
	}, [props.isLoading]);
	return (
		<Container style={{ display: loading ? "flex" : "none" }}>
			<div className='loading'>
				<LoadingOutlined color='#1890ff' className='icon' />
				loading...
			</div>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 800;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #1890ff;
	font-size: 1vmax;
	.loading {
		display: flex;
		flex-direction: column;
		.icon {
			margin-bottom: 1vmax;
			font-size: 1.5vmax;
		}
	}
`;

export default Loading;
