import styled from "@emotion/styled";
import { Button, Form, Input, message, Select, Space, Table } from "antd";
import { t } from "i18next";
import { FC, useCallback, useEffect, useState } from "react";
import { ActivityEntity } from "../../../module/activity/entity/activity.entity";
import { ActivityParam } from "../../../module/activity/param/activity.param";
import activityService from "../../../module/activity/service/activity.service";
// import { PaginationEntity } from "../../../common/entity/pagination.entity";
import { useNavigate } from "react-router-dom";
import { checkPermission } from "src/module/admin/service/permission.service";
const { Option } = Select;

export const PropagateActivityList: FC = () => {
	

	const navigate = useNavigate();
	const [searchObj, setSearchObj] = useState<ActivityParam>({ type: 5,customPageSize: 15,
		customPageNum: 1, });
	const [dataList, setDataList] = useState<ActivityEntity[]>([]);
	const [total, setTotal] = useState<number>(0);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });

	const onfinish = (value: any) => {
		setSearchObj({ ...searchObj, ...value,customPageSize: 15,
			customPageNum: 1, });
	};

	const init = useCallback(async () => {
		const { data } = await activityService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
			
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const changeStatus = async (id: number, status: number) => {
		const { data } = await activityService.changeStatus({ id, status });
		if (data.code === 200) {
			message.success(t("success"));
			init();
		} else {
			message.error(data.msg);
		}
	};
	const columns = [
		{
			title: t("activityId"),
			dataIndex: "id",
		},
		{
			title: t("activityName"),
			dataIndex: "title",
		},

		{
			title: t("startTime"),
			dataIndex: "beginDate",
		},
		{
			title: t("endTime"),
			dataIndex: "endDate",
		},
		{
			title: t("sort"),
			dataIndex: "sort",
		},
		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? t("onSale") : t("off");
			},
		},
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(66) && record.status === 1 && (
							<Button
								type='primary'
								danger
								onClick={() => {
									changeStatus(record.id, 2);
								}}
							>
								{t("off")}
							</Button>
						)}
						{checkPermission(65) && record.status === 2 && (
							<Button
								type='primary'
								onClick={() => {
									changeStatus(record.id, 1);
								}}
							>
								{t("onSale")}
							</Button>
						)}
						{checkPermission(64) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/activity/propagate/" + record.id);
								}}
							>
								{t("edit")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onfinish}>
				<Form.Item label={t("activityName")} name='title'>
					<Input placeholder={t("activityName")} allowClear />
				</Form.Item>
				<Form.Item label={t("activityStatus")} name='status'>
					<Select placeholder={t("activityStatus")} allowClear>
						<Option value={1}>{t("onSale")}</Option>
						<Option value={2}>{t("off")}</Option>
					</Select>
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						{checkPermission(63) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/activity/propagate/add");
								}}
							>
								{t("addActivity")}
							</Button>
						)}
					</Space>
				</Form.Item>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id + ""}
				style={{ marginTop: 20 }}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
