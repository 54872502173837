import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Space, Table, Modal, message } from "antd";
import { useTranslation } from "react-i18next";
import { PaginationEntity } from "../../common/entity/pagination.entity";
import { useNavigate } from "react-router-dom";
import { QuestionEntity } from "src/module/question/entity/question.entity";
import questionService from "src/module/question/service/question.service";
import { checkPermission } from "src/module/admin/service/permission.service";
const { confirm } = Modal;

export const Question: FC = () => {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const [list, setList] = useState();
	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({ customPageNum: 1, customPageSize: 15 });
	const [total, setTotal] = useState(0);

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await questionService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const updateStatus = async (param: QuestionEntity) => {
		const { data } = await questionService.update(param);
		if (data.code === 200) {
			message.success("success");
			init();
		}
	};

	const columns = [
		{ title: t("title"), dataIndex: "title" },
		{ title: t("sort"), dataIndex: "sort" },

		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? t("onSale") : t("off");
			},
		},
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{record.status === 1 && (
							<Button
								type='primary'
								danger
								onClick={() => {
									updateStatus({ id: record.id, status: 2 });
								}}
							>
								{t("off")}
							</Button>
						)}
						{record.status === 2 && (
							<Button
								type='primary'
								onClick={() => {
									updateStatus({ id: record.id, status: 1 });
								}}
							>
								{t("onSale")}
							</Button>
						)}
						{checkPermission(358) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}

						{checkPermission(356) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/question/${record.id}`);
								}}
							>
								{t("edit")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	const init = useCallback(async () => {
		const { data } = await questionService.list({ ...paginationObj });
		if (data.code === 200) {
			setList(data.data.records);
			setTotal(data.data.total);
		}
	}, [paginationObj]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<Container>
			{checkPermission(357) && (
				<Button type='primary' onClick={() => navigate(`/question/add`)}>
					{t("add")}
				</Button>
			)}

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={list}
				rowKey={(record) => {
					return record.id;
				}}
				pagination={{
					current: paginationObj.customPageNum,
					pageSize: paginationObj.customPageSize,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")}
								{total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setPaginationObj({ ...paginationObj, customPageNum: pagination.current ?? 1, customPageSize: pagination.pageSize ?? 15 });
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
