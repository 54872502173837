import { FC, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, InputNumber, Select, message } from "antd";
import { SelectBandCoupon } from "src/module/coupon/component/SelectBandCoupon";
import { useNavigate, useParams } from "react-router-dom";
import couponService from "src/module/coupon/service/coupon.service";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { SelectMembershipCardByType } from "src/module/membershipCard/component/SelectMembershipCardByType";

export const ThirdCouponAdd: FC = () => {
	const [form] = useForm();
	const navigate = useNavigate();
	const { id } = useParams();
	const { t } = useTranslation();

	const init = useCallback(async () => {
		console.log(id);
		
		if (id) {
			const { data } = await couponService.meiTuanList({ id: Number(id) });

			if (data.code === 200) {
				const info = data.data.list[0];
				form.setFieldsValue({
					...info,
					groupTime: [moment(info.beginDate), moment(info.endDate)],
					serviceTime: [moment(info.receiptBeginDate), moment(info.receiptEndDate)],
				});
			}
		}
	}, [form, id]);

	useEffect(() => {
		init();
	}, [init]);

	const onFinish = async (value: any) => {
		console.log(value);

		const info = {
			...value,
			groupTime: undefined,
			serviceTime: undefined,
			beginDate: value.groupTime[0].format("YYYY-MM-DD HH:mm:ss"),
			endDate: value.groupTime[1].format("YYYY-MM-DD HH:mm:ss"),
			receiptBeginDate: value.serviceTime[0].format("YYYY-MM-DD HH:mm:ss"),
			receiptEndDate: value.serviceTime[1].format("YYYY-MM-DD HH:mm:ss"),
		};

		const { data } = await couponService.meituanAddOrUpdate({ id: Number(id), ...info });
		if (data.code === 200) {
			message.success(t("success"));
			navigate("/coupon/thirdCoupon");
		} else {

			message.error(data.msg);
		}
	};

	return (
		<Container>
			<Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }} onFinish={onFinish} form={form}>
				<Form.Item label={t("dealGroupId")} name='dealGroupId'>
					<Input disabled/>
				</Form.Item>
				<Form.Item label={t("comboId")} name='dealId'>
					<Input disabled />
				</Form.Item>
				<Form.Item label={t("美团openShopUuid")} name='meituanOpenShopUuid' >
					<Input disabled/>
				</Form.Item>
				
				<Form.Item label={t("packageName")} name='title'>
					<Input />
				</Form.Item>
				<Form.Item label={t("packagePrice")} name='price'>
					<InputNumber />
				</Form.Item>
				<Form.Item label={t("groupType")} name='dealType'>
					<Select>
						<Select.Option value='1'>团购套餐</Select.Option>
						<Select.Option value='2'>代金券</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("bandCoupon")} name='meituanDealCouponList'>
					<SelectBandCoupon />
				</Form.Item>
				<Form.Item label={t("bandMembershipCard")} name='meituanDealCardList'>
					<SelectMembershipCardByType />
				</Form.Item>
				<Form.Item label={t("saleStatus")} name='saleStatus'>
					<Select>
						<Select.Option value={1}>未开始售卖</Select.Option>
						<Select.Option value={2}>售卖中</Select.Option>
						<Select.Option value={3}>售卖结束</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("groupTime")} name='groupTime'>
					<DatePicker.RangePicker showTime />
				</Form.Item>
				<Form.Item label={t("groupCouponServiceTime")} name='serviceTime'>
					<DatePicker.RangePicker showTime />
				</Form.Item>
				<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
					<Button type='primary' htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
