import { LockOutlined, UserOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Input, message } from "antd";
import { AxiosResponse } from "axios";
import React, { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ResponseEntity } from "../../common/entity/response.entity";
import AdminService from "../../module/admin/service/admin.service";

const Login: FC = () => {
	const [account, setAccount] = useState("");
	const [password, setPassword] = useState("");
	const { t } = useTranslation();

	const navigate = useNavigate();

	const handleChangeAccount = (e: ChangeEvent<HTMLInputElement>) => {
		setAccount(e.target.value);
	};

	const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value);
	};

	const handleSubmit = async () => {
		if (account.trim() === "") {
			message.error(t("account.not.null"));
			return false;
		}
		if (password.trim() === "") {
			message.error(t("password.not.null"));
			return false;
		}
		const { data }: AxiosResponse<ResponseEntity> = await AdminService.login({ account: account, password: password });
		localStorage.setItem("token", data.data.token);

		// const res: AxiosResponse<ResponseEntity> = await AdminService.currentDetail();
		// localStorage.setItem("adminInfo", JSON.stringify(res.data.data));

			const res: AxiosResponse<ResponseEntity> = await AdminService.current();
		localStorage.setItem("adminInfo", JSON.stringify(res.data.data));
		navigate("/");
	};

	return (
		<Container>
			<div className='loginWrap'>
				<div className='innerWrap'>
					<h3>{t("app-name")}</h3>
					<div className='inputWrap'>
						<Input size='large' maxLength={16} placeholder={t("account.placeholder")} prefix={<UserOutlined />} onChange={handleChangeAccount} />
						<Input
							size='large'
							maxLength={16}
							type='password'
							placeholder={t("password.placeholder")}
							prefix={<LockOutlined />}
							onChange={handleChangePassword}
						/>
					</div>
					<Button type='primary' size='large' block onClick={handleSubmit}>
						{t("login")}
					</Button>
				</div>
			</div>
		</Container>
	);
};
export default Login;

const Container = styled.div`
	width: 100%;
	height: 100%;
	background: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/login_bg_technology.jpg") center no-repeat;
	background-size: auto 100%;
	position: relative;

	.innerWrap {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -120px 0 0 -200px;
		width: 400px;
		height: 240px;

		h3 {
			text-align: center;
			font-size: 30px;
			color: #fff;
		}

		.inputWrap {
			> * {
				margin-bottom: 20px;
			}
		}

		.ant-input-affix-wrapper {
			background: RGBA(255, 255, 255, 1);

			input,
			input:-webkit-autofill {
				background: none;
				color: #000;
				-webkit-box-shadow: 0 0 0 1000px white inset;
			}
		}

		::-webkit-input-placeholder {
			color: #6a6f7b;
		}
	}
`;
