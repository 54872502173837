import {Select} from "antd";
import {useCallback, useEffect, useState} from "react";
import CompanyService from "../service/company.service";
import {CompanyEntity} from "../entity/company.entity";
import {NumberProp} from "../../../common/entity/props.entity";


export const SingleSelectCompany = (props: NumberProp) => {
    const [arr, setArr] = useState<CompanyEntity[]>([]);

    const value = props.value ? Number(props.value) : undefined;

    const list = useCallback(
        async () => {
            const {data} = await CompanyService.all();
            setArr(data.data);
        },
        [],
    );

    useEffect(() => {
        list();
    }, [list]);

    return (
        <>
            <Select value={value} allowClear onChange={props.onChange} >
                {
                    arr.map( (row: CompanyEntity) => {
                        return <Select.Option key={row.id} value={Number(row.id)} >{row.name}</Select.Option>
                    })
                }
            </Select>
        </>
    )
}