import { FC, useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled";
import { Button, Space, Table, Modal, message, Form, Input } from "antd";
import { t } from "i18next";
import companyService from "../../module/company/service/company.service";
import { AxiosResponse } from "axios";
import { ResponseEntity } from "../../common/entity/response.entity";
import { CompanyEntity } from "../../module/company/entity/company.entity";
import { checkPermission } from "src/module/admin/service/permission.service";
const { confirm } = Modal;

export const Company: FC = () => {
	const [dataList, setDataList] = useState([]);
	const [visible, setVisible] = useState(false);

	const handleCancel = () => {
		setVisible(false);
	};

	const [companyInfo, setCompanyInfo] = useState<CompanyEntity>({});

	const update = (record: CompanyEntity) => {
		setVisible(true);
		setCompanyInfo(record);
	};

	const changeInfo = (type: string, e: any) => {
		setCompanyInfo({ ...companyInfo, [type]: e });
	};

	const handleOk = async () => {
		const { data } = await companyService.update(companyInfo);
		if (data.code === 200) {
			message.success(data.msg);
			init();
		}
		setVisible(false);
	};

	const init = useCallback(async () => {
		const { data } = await companyService.all();
		if (data.code === 200) {
			setDataList(data.data);
		}
	}, []);

	// 页面监听方法
	useEffect(() => {
		init();
	}, [init]);

	// 这个[]里面写啥

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data }: AxiosResponse<ResponseEntity> = await companyService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const columns = [
		{
			title: t("id"),
			dataIndex: "id",
		},

		{
			title: t("companyName"),
			dataIndex: "name",
		},
		{
			title: t("financeCode"),
			dataIndex: "financeCode",
		},
		{
			title: t("wxMerchantId"),
			dataIndex: "wxMerchantId",
		},
		{
			title: t("wxPayKey"),
			dataIndex: "wxPayKey",
		},

		{
			title: t("action"),
			render: (text: string, record: any) => {
				return (
					<Space>
						{checkPermission(304) && (
							<Button
								onClick={() => {
									update(record);
								}}
								type='primary'
							>
								{t("edit")}
							</Button>
						)}
						{checkPermission(305) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Table dataSource={dataList} columns={columns} rowKey={(record) => record.id + ""} />
			<Modal
				width={"50%"}
				title={t("edit")}
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
				// okButtonProps={{disabled: !checkPermission(323) && !checkPermission(321)}}
			>
				<Form layout='vertical'>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 100 }}>{t("companyName")}:</div>
							<div style={{ marginRight: 20, width: 400 }}>
								<Input value={companyInfo.name} onChange={(e) => changeInfo("name", e.target.value)} />
							</div>
						</div>
					</Form.Item>

					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 100 }}>{t("financeCode")}:</div>
							<div style={{ marginRight: 20, width: 400 }}>
								<Input
									value={companyInfo.financeCode}
									onChange={(e) => {
										changeInfo("financeCode", e.target.value);
									}}
								/>
							</div>
						</div>
					</Form.Item>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 100 }}>{t("wxMerchantId")}:</div>
							<div style={{ marginRight: 20, width: 400 }}>
								<Input
									value={companyInfo.wxMerchantId}
									onChange={(e) => {
										changeInfo("wxMerchantId", e.target.value);
									}}
								/>
							</div>
						</div>
					</Form.Item>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 100 }}>{t("wxPayKey")}:</div>
							<div style={{ marginRight: 20, width: 400 }}>
								<Input
									value={companyInfo.wxPayKey}
									onChange={(e) => {
										changeInfo("wxPayKey", e.target.value);
									}}
								/>
							</div>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
