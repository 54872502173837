import { http } from "src/common/http";
import { RepairFeedbackParam } from "./repair.feedback.param";
import { RepairFeedbackEntity } from "./repair.feedback.entity";

class RepairFeedbackService {
	type: Record<number, string> = {
		1: "机器故障",
		2: "水循环故障",
		3: "摄像头故障",
		4: "PLC",
		5: "场地",
		6: "其他",
	};

	faultType: any = {
		1: { 0: "机器故障", 1: "缺水", 2: "卡住", 3: "无雨蜡药剂", 4: "防撞杆故障", 5: "吹风故障", 6: "其他故障" },
		2: { 0: "水循环故障", 1: "不补水", 2: "水溢出", 3: "异味较严重", 4: "其他故障" },
		3: { 0: "摄像头故障", 1: "不自动识别", 2: "识别错误率高", 3: "镜头模糊", 4: "无法访问", 5: "自动触发识别", 6: "其他故障" },
		4: { 0: "PLC", 1: "无法连接", 2: "不抬杆", 3: "机器无反应", 4: "状态未归位", 5: "其他故障" },
		5: { 0: "场地", 1: "出入口挡住", 2: "地面损坏", 3: "广告牌损坏", 4: "其他问题" },
		6: { 0: "其他", 1: "其他故障" },
	};

	professionalType: Record<number, string> = {
		1: "侧刷模块",
		2: "顶刷模块",
		3: "轮刷模块",
		4: "顶吹风系统",
		5: "侧吹风系统",
		6: "防撞保护系统",
		7: "化学液供给系统",
		8: "压缩空气供给系统",
		9: "供水系统_离心泵至洗车设备",
		10: "供水系统_地坑至水桶",
		11: "行走系统",
		12: "电器模块_机身内部",
		13: "电器模块_电箱",
		14: "附件",
		15: "其他",
	};

	professionalFaultType: Record<number, string[]> = {
		1: ["毛刷组件", "行走电机", "行走电机主动轮", "行走电机导向轮", "旋转电机", "气缸", "减震块", "位置传感器"],
		2: ["毛刷组件", "行走电机", "行走电机导向轮", "皮带", "皮带轮", "旋转电机", "轨道", "位置传感器"],
		3: ["毛刷组件", "气缸", "旋转电机", "支架", "导向轮", "位置传感器"],
		4: ["风机框架", "安全环", "鼓风机", "升降电机", "升降电机皮带", "升降电机皮带轮", "导轨", "导轨导向轮", "位置传感器"],
		5: ["鼓风机", "风道"],
		6: ["防撞杆", "弹簧框架", "位置传感器"],
		7: ["计量泵", "管路"],
		8: ["空压机面板", "空压机机组", "空压机电磁阀", "空压机单向阀", "空压机排水阀", "压力表", "空气管路", "洗车设备内气阀", "洗车设备内电磁阀"],
		9: ["离心泵", "低温保护单向阀", "管路", "喷嘴", "电磁阀", "节流阀", "压力表", "位置传感器", "泡沫发生器"],
		10: [
			"地坑水泵",
			"地坑水泵浮球",
			"管路",
			"沉淀池",
			"沉淀池水泵",
			"沉淀池水泵浮球",
			"气动三通阀",
			"计量泵",
			"计量泵管道",
			"气体电磁阀",
			"流体电磁阀",
			"离心泵",
			"过滤罐",
			"清水桶",
			"清水桶浮球",
			"工艺桶",
			"工艺桶浮球",
			"排水泵",
			"电箱",
		],
		11: ["轨道", "行走电机", "行走轮", "支架", "从动轮", "位置传感器", "光栅传感器"],
		12: ["电源", "主板（K1)", "主板（K100)", "显示屏", "空气开关", "接触器", "变频器", "端子排", "开关电源（24V)", "位置传感器", "蜂鸣器", "线束"],
		13: [
			"电源",
			"主板（K6)",
			"主板（K7)",
			"主板（K8)",
			"PLC模块",
			"PLC编程方面的问题",
			"CPO操控面板",
			"washlink模块",
			"空气开关",
			"软起动器",
			"端子排",
			"继电器",
			"变压器（低压",
			"扬声器",
			"线束",
		],
		14: [
			"设别摄像头",
			"监控摄像头",
			"录像机",
			"监控/识别线路",
			"网络",
			"地面格栅",
			"地面限位杆",
			"停车到位挡块",
			"供水增压泵（加装）",
			"供电稳压器（加装）",
			"市政接入电源电箱",
			"道闸",
		],
		15: ["其他"],
	};

	// machineFaultType: Record<number, string> = {
	// 	1: "缺水",
	// 	2: "卡住",Z
	// 	3: "无雨蜡药剂",
	// 	4: "防撞杆故障",
	// 	5: "吹风故障",
	// 	6: "其他故障",
	// };
	// waterFaultType: Record<number, string> = {
	// 	1: "不补水",
	// 	2: "水溢出",
	// 	3: "异味较严重",
	// 	4: "其他故障",
	// };

	// CameraFaultType: Record<number, string> = {
	// 	1: "不自动识别",
	// 	2: "识别错误率高",
	// 	3: "镜头模糊",
	// 	4: "无法访问",
	// 	5: "自动触发识别",
	// 	6: "其他故障",
	// };

	// plcFaultType: Record<number, string> = {
	// 	1: "无法连接",
	// 	2: "不抬杆",
	// 	3: "机器无反应",
	// 	4: "状态未归位",
	// 	5: "其他故障",
	// };

	// placeFaultType: Record<number, string> = {
	// 	1: "出入口挡住",
	// 	2: "地面损坏",
	// 	3: "广告牌损坏",
	// 	4: "其他问题",
	// };s

	startWorking = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/repair-feedback/start/" + id,
			method: "PUT",
		});
	};

	list = async (param: RepairFeedbackParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/repair-feedback",
			method: "GET",
			params: param,
		});
	};

	add = async (data: RepairFeedbackEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/repair-feedback",
			method: "POST",
			data,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/repair-feedback/" + id,
			method: "GET",
			data: id,
		});
	};

	update = async (data: RepairFeedbackEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/repair-feedback/" + data.id,
			method: "PUT",
			data,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/repair-feedback/" + id,
			method: "DELETE",
			data: id,
		});
	};

	export = async (param: RepairFeedbackParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/repair-feedback/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};
}
export default new RepairFeedbackService();
