import { http } from "../../../common/http";
import { WithdrawalAuditParam } from "../param/withdrawal.audit.param";

class WithdrawalAuditService {
	list = async (params: WithdrawalAuditParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + "/promoter/draw-record",
			params,
		});
	};

	pass = async (id: number) => {
		return http({
			method: "POST",
			url: process.env.REACT_APP_ADMIN_API + "/promoter/draw/pass/"+id ,
			data:id
		});
	};


	refuse = async (id: number) => {
		return http({
			method: "POST",
			url: process.env.REACT_APP_ADMIN_API + "/promoter/draw/refuse/"+id ,
			data:id
		});
	};
}
export default new WithdrawalAuditService();
