import { useCallback, useEffect, useState } from "react";
import { Select } from "antd";

import { t } from "i18next";
import { Props } from "src/common/entity/props.entity";
import { MembershipCardEntity } from "../entity/membership.card.entity";
import membershipCardService from "../service/membership.card.service";

const { Option } = Select;

export const SelectMembershipCard = (props: Props) => {
	const [dataArr, setDataArr] = useState<MembershipCardEntity[]>([]);
	const [title, setTitle] = useState<string | undefined>(undefined);

	const init = useCallback(async () => {
		const res = await membershipCardService.list({ title });
		if (res.data.code === 200) setDataArr(res.data.data);

		let flag = false;
		const list = res.data.data;
		list.forEach((item: MembershipCardEntity) => {
			if (item.id === props.value) {
				flag = true;
			}
		});

		if (!flag && props.value) {
			const { data } = await membershipCardService.detail(props.value);
			setDataArr([...list, data.data]);
		}
	}, [props.value, title]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<>
			<Select
				showSearch
				value={props.value}
				onChange={(e) => {
					props.onChange && props.onChange(e);
				}}
				onSearch={setTitle}
				onBlur={() => {
					setTitle(undefined);
				}}
				allowClear
				style={{ width: 500 }}
				optionFilterProp={"children"}
				placeholder={t("pleaseSelectMembershipCard")}
			>
				{dataArr &&
					dataArr.map((item) => {
						return (
							<Option value={item.id} key={item.id}>
								{item.title}
							</Option>
						);
					})}
			</Select>
		</>
	);
};
