import { http } from "../../../common/http";
import { RegistrationParam } from "../param/registration.param";
class RegistrationService{

    list = async (param:RegistrationParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/activities/registrations",
			method: "GET",
			params: param,
		});
	};



    }


export default new RegistrationService()