import { FC } from "react";
import styled from "@emotion/styled";
import {  Tabs } from "antd";
import { t } from "i18next";


import { Coupon } from "./Coupon";
import { RegistrationStaff } from "./RegistrationStaff";
const { TabPane } = Tabs;
export const CouponActivity: FC = () => {
	

	return (
		<Container>
			<Tabs>
				<TabPane tab={t("couponActivity")} key='1'>
				<Coupon/>
				</TabPane>
				<TabPane tab={t("registrationStaff")} key='2'>
				<RegistrationStaff/>
				</TabPane>
			</Tabs>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding:20px
`;
