import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Col, Form, Input, message, Modal, Row, Select, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { checkPermission } from "src/module/admin/service/permission.service";
import pointProductService from "src/module/point/service/point.product.service";
import { SelectPointType } from "src/module/point/component/select.point.type";
import { SelectSite } from "src/module/site/component/select.site";
const { confirm } = Modal;
const { Option } = Select;

export const PointProduct: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [dataList, setDataList] = useState();
	const [searchObj, setsearchObj] = useState({customPageSize: 15,
		customPageNum: 1, });
	const onFinish = (value: any) => {
		setsearchObj({ ...value, customPageSize: 15, customPageNum: 1 });
	};

	const [total, setTotal] = useState(0);

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await pointProductService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const init = useCallback(async () => {
		const { data } = await pointProductService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.records);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const columns = [
		{
			title: t("image"),
			dataIndex: "thumb",
			render: (text: string | undefined) => {
				return <img src={text} width='100' alt='' style={{ border: "1px solid #e1e1e1" }} />;
			},
		},
		
		{
			title: t("productName"),
			dataIndex: "name",
		},
		{
			title: t("price"),
			dataIndex: "price",
		},
		{
			title: t("salesAmount"),
			dataIndex: "count",
		},
		{
			title: t("stockAmount"),
			dataIndex: "stockAmount",
		},
		{
			title: t("checkShop"),
			dataIndex: "shopTitle",
		},
		{
			title: t("point.type"),
			dataIndex: "typeTitle",
		},
		{
			title: t("createdTime"),
			dataIndex: "createdTime",
		},
		{
			title: t("usePointsNumber"),
			dataIndex: "pointsNumber",
		},

		{
			title: t("sort"),
			dataIndex: "sort",
		},

		{
			title: t("productNum"),
			dataIndex: "productNum",
		},
		{
			title: t("status"),
			dataIndex: "upDown",
			render: (text: number) => {
				return text === 1 ? t("onSale") : t("off");
			},
		},

		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(61202) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/point/pointProduct/${record.id}`);
								}}
							>
								{t("edit")}
							</Button>
						)}
						{checkPermission(61204) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}

						<Button
							type='primary'
							onClick={() => {
								navigate(`/point/pointProduct/${record.id}/1`);
							}}
						>
							{t("copy")}
						</Button>
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onFinish} style={{ marginBottom: 20 }}>
				<Row gutter={[20, 10]}>
					<Col span={8}>
						<Form.Item label={t("pointProductName")} name='name'>
							<Input style={{ width: 300 }} placeholder={t("pointProductName")} allowClear></Input>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("productNum")} name='productNum'>
							<Input style={{ width: 300 }} placeholder={t("productNum")} allowClear></Input>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("point.type")} name='typeId'>
							<SelectPointType />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label={t("checkShop")} name='shopId'>
							<SelectSite />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("status")} name='upDown'>
							<Select placeholder={t("status")} allowClear style={{ width: 300 }}>
								<Option value={1}>{t("onSale")}</Option>
								<Option value={2}>{t("off")}</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item>
							<Space>
								<Button type='primary' htmlType='submit'>
									{t("search")}
								</Button>

								{checkPermission(61202) && (
									<Link to='/point/pointProduct/add'>
										<Button type='primary'>{t("add")}</Button>
									</Link>
								)}
							</Space>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					
					setsearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}

			
	
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
