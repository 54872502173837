import { http } from "../../../common/http";
import { PointTypeEntity } from "../entity/point.type.entity";
import { PointTypeParam } from "../param/point.type.param";
class PointTypeService{

    list = async (param:PointTypeParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/points-product-type",
			method: "GET",
			params: param,
		});
	};

	add = async (data:PointTypeEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/points-product-type",
			method: "POST",
			data
		});
	};

	detail=async (id:number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API +"/points-product-type/"+ id,
			method: "GET",
			data:id
		});
	};

	update=async (data:PointTypeEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API +"/points-product-type/"+ data.id,
			method: "PUT",
			data,
		});
	};


	delete=async (id:number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API +"/points-product-type/"+ id,
			method: "DELETE",
			data:id
		});
	};




    }


export default new PointTypeService()