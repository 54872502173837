import { http } from "../../../common/http";
import { PerformanceEntity } from "../performance.entity";
import { PerformanceParam } from "../performance.params";

class PerformanceService {
	list = async (param: PerformanceParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/performance",
			method: "GET",
			params: param,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/performance/" + id,
			method: "DELETE",
		});
	};

	update = async (data: PerformanceEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/performance/${data.id}`,
			method: "PUT",
			data,
		});
	};
	updatePerformance = async (data: { [x: string]: any; serviceType: 1 | 2 }) => {
		return await http<undefined>({
			url: process.env.REACT_APP_ADMIN_API + (data.serviceType === 1 ? "/performance/order-sale" : `/performance/order-construction`),
			method: "PUT",
			data,
		});
	};

	updatePackageSale = async (data: PerformanceEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/performance/package-sale",
			method: "PUT",
			data,
		});
	};

	exportPerformance = async (param: PerformanceParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/performance/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};

	exportGroupPerformance = async (param: PerformanceParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/performance/exportGroup",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};

	detail = async (param: PerformanceParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/performance/order-info",
			method: "GET",
			params: param,
		});
	};

	orderMoney = async (params = {}) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/performance/order-money",
			method: "GET",
			params,
		});
	};
	fixPerformance = async () => {
		return http({
			url: process.env.REACT_APP_GO_API + "/api/v1/admin/order/update-construction-performance",
			method: "PUT",
		});
	};
}

export default new PerformanceService();
