import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, message, Modal, Select, Space, Table } from "antd";
import { t } from "i18next";

import { useNavigate } from "react-router-dom";
// import { PaginationEntity } from "src/common/entity/pagination.entity";
import { LoginActivityListEntity } from "src/module/login.activity/entity/loginActivityListEntity";
import { LoginActivityListParam } from "src/module/login.activity/param/loginActivityListParam";
import loginActivityListService from "src/module/login.activity/service/loginActivityListService";
import { SelectSite } from "src/module/site/component/select.site";
import { AxiosResponse } from "axios";
import Util from "src/common/Util";
import { checkPermission } from "src/module/admin/service/permission.service";

export const LoginActivityList: FC = () => {
	const { confirm } = Modal;
	const navigate = useNavigate();
	const { Option } = Select;
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState<number>(0);
	const [searchObj, setSearchObj] = useState<LoginActivityListParam>({customPageSize: 15,
		customPageNum: 1,});
	const [dataList, setDataList] = useState<LoginActivityListEntity[]>([]);
	const onfinish = (value: any) => {
		setSearchObj({ ...searchObj, ...value ,customPageSize: 15,
			customPageNum: 1});
	};
	const init = useCallback(async () => {
		const { data } = await loginActivityListService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const updateStatus = async (params: LoginActivityListEntity) => {
		const { data } = await loginActivityListService.changeStatus(params);
		if (data.code === 200) {
			message.success(t("success"));
			init();
		} else {
			message.error(data.msg);
		}
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await loginActivityListService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const downLoad = async () => {
		const res: AxiosResponse<BlobPart> = await loginActivityListService.export(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "recommendActivity");
		message.success(t("export.success"));
	};

	const columns = [
		{
			title: t("activityId"),
			dataIndex: "loginActivityId",
		},
		{
			title: t("activityName"),
			dataIndex: "title",
		},
		{
			title: t("site.name"),
			dataIndex: "shopName",
		},

		{
			title: t("CouponCombinations"),
			dataIndex: "couponName",
		},
		{
			title: t("	活动允许参加次数"),
			dataIndex: "loginGrantNum",
		},
		{
			title: t("	活动剩余参加次数"),
			dataIndex: "loginRemainingNum",
		},
		{
			title: t("限制每人参加次数"),
			dataIndex: "loginLimitNum",
		},
		{
			title: t("startTime"),
			dataIndex: "beginDate",
		},
		{
			title: t("endTime"),
			dataIndex: "endDate",
		},
		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? t("onSale") : t("off");
			},
		},
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{record.status === 1 && (
							<Button
								type='primary'
								danger
								onClick={() => {
									updateStatus({ id: record.id, status: 2 });
								}}
							>
								{t("off")}
							</Button>
						)}
						{record.status === 2 && (
							<Button
								type='primary'
								onClick={() => {
									updateStatus({ id: record.id, status: 1 });
								}}
							>
								{t("onSale")}
							</Button>
						)}
						{checkPermission(335) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/activity/loginActivity/ " + record.id);
								}}
							>
								{t("edit")}
							</Button>
						)}
						{checkPermission(337) && (
							<Button
								type='primary'
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onfinish}>
				<Form.Item label={t("activityName")} name='title'>
					<Input placeholder={t("activityName")} allowClear />
				</Form.Item>
				<Form.Item label={t("activityStatus")} name='status'>
					<Select placeholder={t("activityStatus")} allowClear>
						<Option value={1}>{t("onSale")}</Option>
						<Option value={2}>{t("off")}</Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("shopName")} name='shopId'>
					<SelectSite />
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						{checkPermission(336) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/activity/loginActivity/add");
								}}
							>
								{t("addActivity")}
							</Button>
						)}

						<Button type='primary' onClick={downLoad}>
							{t("export")}
						</Button>
					</Space>
				</Form.Item>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id + ""}
				style={{ marginTop: 20 }}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
