import {useCallback, useState, useEffect} from "react";
import {Select} from "antd";
import {RoleEntity} from "../entity/role.entity";
import RoleService from "../service/role.service";
import { Props } from "../../../common/entity/props.entity";

const {Option} = Select;

export const SelectRole = (props:Props) => {
    const [dataArr, setDataArr] = useState<RoleEntity[]>([]);

    const init = useCallback(
        async () => {
            const res = await RoleService.all();
            setDataArr(res.data.data);
        },
        [],
    );

    useEffect(() => {
        init();
    }, [init]);

    return (
        <>
            <Select value={props.value} onChange={props.onChange} allowClear>
                {
                    dataArr && dataArr.map((item) => {
                        return <Option key={item.id} value={item.id} >{item.name}</Option>
                    })
                }
            </Select>
        </>
    )
}