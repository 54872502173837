import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Radio, Row, Table, Select } from "antd";
import { AxiosResponse } from "axios";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import Util from "../../common/Util";
import { CustomerEntity } from "../../module/customer/entity/customer.entity";
import { CustomerParam } from "../../module/customer/params/customer.param";
import CustomerService from "../../module/customer/service/customer.service";
import { SelectCustomerStatus } from "../../module/customer/component/SelectCustomerStatus";
import { checkPermission } from "../../module/admin/service/permission.service";
import copy from "copy-to-clipboard";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface customer {
	id: number;
	status: number;
}

export const CustomerList: FC = () => {
	const { t } = useTranslation();
	const [searchArr, setSearchArr] = useState<CustomerParam>({
		startTime: moment().format("YYYY-MM-DD"),
		endTime: moment().format("YYYY-MM-DD"),
	});
	const [searchObj, setSearchObj] = useState<CustomerParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});
	const [customerList, setCustomerList] = useState<CustomerEntity[]>([]);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// 	total: 0,
	// });
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState(true);
	const changeAttr = (type: string, e: any) => {
		setSearchArr({
			...searchArr,
			[type]: e,
		});
	};
	const changeModal = (type: string, e: any) => {
		setModelParam({
			...modelParam,
			[type]: e,
		});
	};
	const onDateChange = (date: any, dateString: any) => {
		setSearchArr({
			...searchArr,
			startTime: dateString[0],
			endTime: dateString[1],
		});
	};
	const download = async () => {
		const res: AxiosResponse<BlobPart> = await CustomerService.exportCustomerList({
			...searchArr,
			startTime: searchArr.startTime + " 00:00:00",
			endTime: searchArr.endTime + " 23:59:59",
		});
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "customerList");
		message.success(t("export.success"));
	};
	const search = () => {
		setSearchObj({
			...searchArr,
			startTime: searchArr.startTime && searchArr.startTime + " 00:00:00",
			endTime: searchArr.endTime && searchArr.endTime + " 23:59:59",
			customPageSize: 15,
		customPageNum: 1,
			
		});
	};
	const disableEnable = (customer: customer) => {
		CustomerService.disableEnable(customer);
		init();
	};
	const init = useCallback(async () => {
		setLoading(true);
		const { data } = await CustomerService.list({
			...searchObj,
			
		});
		if (data.code === 200) {
			setCustomerList(data.data);
			setTotal(data.total);
		}
		setLoading(false);
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const [visible, setVisible] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [tokenInfo, setTokenInfo] = useState("");

	const handleCancel = () => {
		setVisible(false);
	};

	const [modelParam, setModelParam] = useState<CustomerParam>({});

	const [customerInfo, setCustomerInfo] = useState<CustomerEntity>({});

	const update = (record: CustomerEntity) => {
		setVisible(true);
		setModelParam({
			id: record.id,
			type: 0,
			amount: 0,
		});
		setCustomerInfo({ ...record });
	};
	const handleOk = () => {
		if (modelParam.type === 0) {
			message.info("请选择改变类型！");
			return;
		}
		if (modelParam.amount === 0) {
			message.info("请输入修改金额！");
			return;
		}
		CustomerService.balanceChange(modelParam).then((res) => {
			message.success(res.data.msg);
			setVisible(false);
			init();
		});
	};

	const handleTokenOk = () => {
		if (copy(tokenInfo)) {
			message.info("复制成功");
		}
		setIsModalOpen(false);
	};

	const handleTokenCancel = () => {
		setIsModalOpen(false);
	};

	const openModal = async (id: number) => {
		const { data } = await CustomerService.getToken(id);
		setTokenInfo(data.data);
		setIsModalOpen(true);
	};

	const userInfo: any = localStorage.getItem("adminInfo");
	const userInfoObj=JSON.parse(userInfo)

	const columns = [
		{
			title: t("nickName"),
			dataIndex: "nickName",
		},
		{
			title: t("tag"),
			dataIndex: "tag",
			render: (text: number) => {
				return text === 0 ? t("member") : text === 1 ? t("users") : t("customers");
			},
		},
		{
			title: t("mobile"),
			dataIndex: "mobile",
		},
		{
			title: t("defaultLicenseNum"),
			dataIndex: "defaultLicenseNumber",
		},
		{
			title: t("createdTime"),
			dataIndex: "createdTime",
		},
		{
			title: t("rechargeTotal"),
			dataIndex: "rechargeTotal",
		},
		{
			title: t("balance"),
			dataIndex: "balance",
		},
		{
			title: t("totalPoints"),
			dataIndex: "points",
		},
		{
			title: t("quickOrderNum"),
			dataIndex: "quickOrderNum",
		},
		{
			title: t("superOrderNum"),
			dataIndex: "superOrderNum",
		},
		{
			title: t("statusStr"),
			dataIndex: "statusStr",
		},
		{
			title: t("action"),
			render: (text: string, record: any) => {
				return (
					<div>
						<Button type='primary' style={{ marginRight: "20px" }} onClick={() => update(record)}>
							{t("modify")}
						</Button>
						{checkPermission(5) && record.status == "1" && (
							<Button type='primary' danger style={{ marginLeft: "10px" }} onClick={() => disableEnable({ id: record.id, status: 2 })}>
								{t("lock")}
							</Button>
						)}
						{checkPermission(5) && record.status == "2" && (
							<Button type='primary' style={{ marginLeft: "10px" }} onClick={() => disableEnable({ id: record.id, status: 1 })}>
								{t("unlock")}
							</Button>
						)}
						{ userInfoObj.id===1&&(
							<Button type='primary' style={{ marginLeft: "10px" }} onClick={() => openModal(record.id)}>
								{t("copyToken")}
							</Button>
						)}
						
					</div>
				);
			},
		},
	];
	return (
		<Container>
	
			<div className='headers'>
				{checkPermission(135) && (
					<Row gutter={20}>
						<Button type={"primary"} style={{ marginLeft: "10px" }} onClick={download}>
							{t("export")}
						</Button>
					</Row>
				)}

				<Row gutter={20}>
					<Col span={5}>
						<div className='searchList'>
							<div className='searchItem'>{t("nickName")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("nickName", e.target.value);
								}}
								placeholder={t("nickName")}
							/>
						</div>
					</Col>
					<Col span={5}>
						<div className='searchList'>
							<div className='searchItem'>{t("mobile")}：</div>
							<Input
								className='searchInput'
								onChange={(e) => {
									changeAttr("mobile", e.target.value);
								}}
								placeholder={t("mobile")}
							/>
						</div>
					</Col>
					<Col span={5}>
						<div className='searchList'>
							<div className='searchItem'>{t("status")}：</div>
							<SelectCustomerStatus onChange={(e: number) => changeAttr("status", e)} />
						</div>
					</Col>
					<Col span={5}>
						<div className='searchList'>
							<div className='searchItem'>{t("tag")}：</div>
							<Select style={{ width: 200 }} allowClear onChange={(e: number) => changeAttr("tag", e)} placeholder={t("tag")}>
								<Option value={0}>{t("member")}</Option>
								<Option value={1}>{t("users")}</Option>
								<Option value={2}>{t("customers")}</Option>
							</Select>
						</div>
					</Col>

					<Col span={5}>
						<div className='searchList'>
							<div className='searchItem'>{t("period")}：</div>
							<RangePicker
								defaultValue={[
									moment(searchArr.startTime && searchArr.startTime.toString()),
									moment(searchArr.endTime && searchArr.endTime.toString()),
								]}
								showTime={false}
								onChange={onDateChange}
							/>
						</div>
					</Col>
					<Col span={4}>
						<Button type={"primary"} onClick={search}>
							{t("search")}
						</Button>
					</Col>
				</Row>
			</div>
			<Table
				columns={columns}
				loading={loading}
				dataSource={customerList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setSearchObj({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
					// setPaginationObj({
					// 	...paginationObj,
					// 	customPageNum: pagination.current ?? 1,
					// 	customPageSize: pagination.pageSize ?? 15,
					// });
				}}
			/>
			<Modal
				width={"20%"}
				title={t("changeBalance")}
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
				okButtonProps={{ disabled: !checkPermission(323) && !checkPermission(321) }}
			>
				<Form layout='vertical'>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("nickName")}:</div>
							<div style={{ marginRight: 20, width: 150 }}>{customerInfo.nickName}</div>
						</div>
					</Form.Item>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("mobile")}:</div>
							<div style={{ marginRight: 20, width: 150 }}>{customerInfo.mobile}</div>
						</div>
					</Form.Item>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("balance")}:</div>
							<div style={{ marginRight: 20, width: 150 }}>{customerInfo.balance}</div>
						</div>
					</Form.Item>
					{checkPermission(236) && (
						<div>
							<Form.Item label=''>
								<div style={{ display: "flex", alignItems: "center" }}>
									<div style={{ marginRight: 20, width: 150 }}>{t("changeType")}:</div>
									<Radio.Group
										onChange={(e) => {
											changeModal("type", e.target.value);
										}}
										value={modelParam.type}
										style={{ width: "70%" }}
									>
										<Radio value={1}>{t("increase")}</Radio>
										<Radio value={2}>{t("takeOut")}</Radio>
									</Radio.Group>
								</div>
							</Form.Item>
							<Form.Item label=''>
								<div style={{ display: "flex", alignItems: "center" }}>
									<div style={{ marginRight: 20, width: 150 }}>{t("amount")}:</div>
									<InputNumber
										placeholder={t("please.enter.a.name")}
										onChange={(e) => {
											changeModal("amount", e);
										}}
										value={modelParam.amount}
										style={{ width: "70%" }}
									/>
								</div>
							</Form.Item>
						</div>
					)}
				</Form>
			</Modal>

			<Modal
				title='用户Token'
				visible={isModalOpen}
				onCancel={handleTokenCancel}
				footer={[
					<Button key='back' onClick={handleTokenCancel}>
						取消
					</Button>,
					<Button key='copy' type='primary' loading={loading} onClick={handleTokenOk}>
						复制token
					</Button>,
				]}
			>
				{tokenInfo}
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;

	.headers {
		width: 100%;

		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			.searchItem {
				min-width: 100px;
				text-align: right;
			}
		}
	}
`;
