import { http } from "../../common/http";
import { EvaluationEntity } from "./evaluation.entity";
import { EvaluationParam } from "./evaluation.param";

class EvaluationService {
	list = async (params: EvaluationParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order/rate",
			method: "GET",
			params,
		});
	};

	update = async (params: EvaluationEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order/rate-remark/" + params.id,
			method: "PUT",
			data: params,
		});
	};

	export = async (params: EvaluationParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/order/rate/export",
			method: "GET",
			params,
			responseType: "blob",
		});
	};
}
export default new EvaluationService();
