import { http } from "../../../common/http";

class PackageActivityCouponService {

	list = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/activities/activities-package/"+id,
			method: "GET",
		});
	};

}
export default new PackageActivityCouponService();
