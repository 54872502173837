import styled from "@emotion/styled";
import { Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
import { CouponEntity } from "../entity/coupon.entity";
import CouponService from "../service/coupon.service";
import { SelectCouponType } from "./SelectCouponType";

const { Option } = Select;

export const SelectCouponByType = (props: Props) => {
	const [typeInfo, setTypeInfo] = useState<number>();
	const [couponId, setCouponId] = useState<number>();
	const [couponArr, setCouponArr] = useState<CouponEntity[]>([]);
	const [couponTitle, setCouponTitle] = useState<string>("");

	const { t } = useTranslation();

	const choosedCouponList = useCallback(async () => {
		if (props.value) {
			setCouponId(props.value)
			const { data } = await CouponService.noAuth({ couponIds: props.value });
			setTypeInfo(data.data.list[0].couponType);
		}
	}, [props.value]);

	useEffect(() => {
		choosedCouponList();
	}, [choosedCouponList]);

	const couponList = useCallback(async () => {
		if (!typeInfo) {
			return;
		}
		const { data } = await CouponService.noAuth({ title: couponTitle, couponType: typeInfo, type: 1, isDisplay: 1 });
		setCouponArr(data.data.list);

		let flag = false;
		const list = data.data.list;
		list.length! &&
			list.forEach((item: CouponEntity) => {
				if (item.id === props.value) {
					flag = true;
				}
			});

		if (!flag && props.value) {
			const { data } = await CouponService.detail(props.value);
			setCouponArr([...list, data.data]);
		}
	}, [typeInfo, couponTitle, props.value]);

	useEffect(() => {
		couponList();
	}, [couponList]);

	const handleTypeChange = (e: Record<string, any>) => {
		console.log(e.id);

		setTypeInfo(e.id);
		setCouponId(undefined);
		props.onChange(undefined);
	};

	return (
		<Container>
			<div className={"select"}>
				<div className={"left"}>
					<SelectCouponType value={typeInfo} entityChange={(e: Record<string, any>) => handleTypeChange(e)} />
				</div>
				&nbsp;&nbsp;
				<div className={"right"}>
					<Select	
						value={couponId}
						placeholder={t("chooseCoupon")}
						allowClear
						onChange={(e) => {					
							setCouponId(e);
							props.onChange(e);
						}}
						showSearch
						onSearch={setCouponTitle}
						optionFilterProp={"children"}
					>
						{couponArr.length! &&
							couponArr.map((item: CouponEntity) => {
								return (
									<Option key={"coupon" + item.id} value={item.id}>
										{item.title}
									</Option>
								);
							})}
					</Select>
				</div>
			</div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;

	.select {
		display: flex;
	width: 100%;


		.left {
			width: 30%;
		}

		.right {
			width: 70%;
		}

		

		.icon {
			line-height: 32px;
			font-size: 18px;
			cursor: pointer;
		}
	}
`;
