import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import recommendActivityService from "../../module/recommendActivity/service/recommend.activity.service";
import { SelectSite } from "../../module/site/component/select.site";
import { AxiosResponse } from "axios";
import Util from "../../common/Util";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import moment from "moment";
const { Option } = Select;

export const RecommendActivity: FC = () => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const { RangePicker } = DatePicker;
	const [total, setTotal] = useState(0);

	const [dataList, setDataList] = useState();
	const [searchObj, setSearchObj] = useState({ startTime: moment().format("YYYY-MM-DD") + " 00:00:00", endTime: moment().format("YYYY-MM-DD") + " 23:59:59" ,customPageSize: 15,
	customPageNum: 1,});
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });

	useEffect(() => {
		recommendActivityService.list({  ...searchObj }).then((res) => {
			if (res.data.code === 200) {
				setDataList(res.data.data);
				setTotal(res.data.total);
			}
		});

		form.setFieldsValue({
			date: [
				searchObj.startTime?moment(searchObj.startTime):undefined,
				searchObj.endTime?moment(searchObj.endTime):undefined,
			]
		});
	}, [form, searchObj]);

	const onFinish = (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			date: undefined,
			startTime: (value.date && value.date[0]) && value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: (value.date && value.date[0]) && value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageSize: 15,
		customPageNum: 1,
		});
	};

	const download = async () => {
		const res: AxiosResponse<BlobPart> = await recommendActivityService.download(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "recommendActivity");
		message.success(t("export.success"));
	};
	const columns = [
		{
			title: t("id"),
			dataIndex: "id",
		},
		{
			title: t("title"),
			dataIndex: "title",
		},
		{
			title: t("userNickname"),
			dataIndex: "nickName",
		},
		{
			title: t("mobile"),
			dataIndex: "mobile",
		},
		{
			title: t("recommendNickName"),
			dataIndex: "recommendNickName",
		},
		{
			title: t("recommendMobile"),
			dataIndex: "recommendMobile",
		},
		{
			title: t("isParticipateOrder"),
			dataIndex: "isParticipateOrderStr",
		},
		{
			title: t("registerNewGrantCouponName"),
			dataIndex: "registerNewGrantCouponName",
		},
		{
			title: t("registerOldGrantCouponName"),
			dataIndex: "registerOldGrantCouponName",
		},

		{
			title: t("firstOrderNewGrantCouponName"),
			dataIndex: "firstOrderNewGrantCouponName",
		},
		{
			title: t("firstOrderOldGrantCouponName"),
			dataIndex: "firstOrderOldGrantCouponName",
		},

		{
			title: t("createdTime"),
			dataIndex: "createdTime",
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onFinish} form={form}>
				<Row>
					<Col span={8}>
						<Form.Item label={t("title")} name='title'>
							<Input allowClear placeholder={t("title")}></Input>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("userNickname")} name='nickName'>
							<Input allowClear placeholder={t("userNickname")}></Input>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("mobile")} name='mobile'>
							<Input allowClear placeholder={t("mobile")}></Input>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("recommendNickName")} name='recommendNickName'>
							<Input allowClear placeholder={t("recommendNickName")}></Input>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("recommendMobile")} name='recommendMobile'>
							<Input allowClear placeholder={t("recommendMobile")}></Input>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("isParticipateOrder")} name='isParticipateOrder'>
							<Select allowClear placeholder={t("isParticipateOrder")}>
								<Option value={0}>{t("notParticipating")}</Option>
								<Option value={1}>{t("not.complete.the.first.order")}</Option>
								<Option value={2}>{t("complete.the.first.order")}</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("site")} name='shopId'>
							<SelectSite />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("period")} name='date'>
							<RangePicker />
							{/* <RangePicker defaultValue={[moment(),moment()]}/> */}
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item>
							<Space>
								<Button type='primary' htmlType='submit'>
									{t("search")}
								</Button>
								<Button type='primary' onClick={download}>
									{t("export")}
								</Button>
							</Space>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.ant-col-8{margin-bottom:15px}
`;
