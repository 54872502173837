import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
const { Option } = Select;

export const SelectPayStatus = (props:Props) => {
    const { t } = useTranslation();
return(
	<Select style={{ width: 200 }} onChange={props.onChange} allowClear placeholder={t("payStatus")}>
		<Option value="1" >待支付</Option>
        <Option value="3" >已取消</Option>
        <Option value="5" >支付成功</Option>
        <Option value="11" >申请退款</Option>
        <Option value="13" >拒绝退款</Option>
        <Option value="15" >退款中</Option>
        <Option value="17" >退款成功</Option>
        <Option value="19" >已评价</Option>
        <Option value="20" >已过期</Option>

	</Select>)
};
