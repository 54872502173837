import { http } from "../../../common/http";
import {EarlyBirdParam} from "../param/earlyBirdParam";

class EarlyBirdService {
    list = async (param: EarlyBirdParam) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/early-bird",
            method: "GET",
            params: param,
        });
    };
}

export default new EarlyBirdService();
