import React, {FC} from "react";
import EChartsReact from "echarts-for-react";

const Pie:FC = ()=> {
    const getOption = ()=>{
        return {
            title: {
                text: '各分类订单情况',
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'vertical',
                left: 'left',
            },
            series: [
                {
                    name: '分类名称',
                    type: 'pie',
                    radius: '50%',
                    data: [
                        {value: 1348, name: '外洗'},
                        {value: 935, name: '内清'},
                        {value: 580, name: '美容'},
                        {value: 14, name: '维修'}
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        }
    }

    return (
        <EChartsReact option={getOption()} theme={"dark"} style={{height:'100%'}} />
    )
}

export default Pie;