import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Input, Select, Space } from "antd";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { PropsEntity } from "../../../common/entity/props.entity";
import { ActivityRechargesEntity } from "../../activity/entity/activity.entity";
import { CouponEntity } from "../entity/coupon.entity";
import couponService from "../service/coupon.service";
const { Option } = Select;

interface RechargeProps extends PropsEntity {
	value?: ActivityRechargesEntity[];
}

export const RechargeCoupons = (props: RechargeProps) => {
	const [couponArr, setCouponArr] = useState<CouponEntity[]>([]);
	const [rechargeArr, setRechargeArr] = useState<ActivityRechargesEntity[]>([]);
	const [recharge, setRecharge] = useState<ActivityRechargesEntity>({});

	const couponList = useCallback(async () => {
		const { data } = await couponService.noAuth({isDisplay:1});
		setCouponArr(data.data.list);
	}, []);

	useEffect(() => {
		setRechargeArr(props.value ?? []);
		couponList();
	}, [couponList, props.value]);

	const addItem = () => {		
		const arr = rechargeArr?.concat();
		// console.log(rechargeArr?.concat());		
		// console.log(arr);		
		if (recharge.couponId) {
			couponArr.forEach((item) => {
				if (item.id === recharge.couponId) {
					arr?.push({ ...recharge, couponName: item.title });
				}
			});
		} else {
			arr?.push({ ...recharge });
		}

		setRechargeArr(arr);
		props.onChange && props.onChange(arr);
	};

	const remove = (index: number) => {
		const arr = rechargeArr?.concat();
		arr?.splice(index, 1);
		setRechargeArr(arr);
		props.onChange && props.onChange(arr);
	};

	return (
		<Container>
			<div className='select'>
				<Space>
					<div>
						<Input
							placeholder={t("please.enter.recharge.amount")}
							onChange={(e) => {
								setRecharge({ ...recharge, money: Number(e.target.value) });
							}}
						/>
					</div>
					<div>
						<Input
							placeholder={t("please.enter.gift.amount")}
							onChange={(e) => {
								setRecharge({ ...recharge, giveMoney: Number(e.target.value) });
							}}
						/>
					</div>
					<div>
						<Select
							style={{ width: 200 }}
							allowClear
							onChange={(e) => {
								setRecharge({ ...recharge, couponId: e });
							}}
						>
							{couponArr.map((item) => {
								return (
									<Option key={item.id} value={item.id}>
										{item.title}
									</Option>
								);
							})}
						</Select>
					</div>
					<PlusOutlined style={{ color: "#096dd9" }} onClick={addItem} />
				</Space>
			</div>

			<div>
				{rechargeArr &&
					rechargeArr.map((item, index) => {
						return (
							<div key={index}>
								充值 {item.money}元/赠送 {item.giveMoney}元/{item.couponName ? item.couponName + "优惠券" : ""}
								<MinusOutlined style={{ color: "#f5222d" }} onClick={() => remove(index)} />
							</div>
						);
					})}
			</div>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	.select {
		display: flex;
	}
`;
