import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Form } from "antd";
import invoiceService from "../../module/invoice/service/invoice.service";
import { useParams } from "react-router-dom";
import { InvoiceEntity } from "../../module/invoice/entity/invoice.entity";

export const InvoiceDetail: FC = () => {
	const { t } = useTranslation();
	const params = useParams();

	const [info, setInfo] = useState<InvoiceEntity>({});

	const init = useCallback(async () => {
		const { data } = await invoiceService.detail(Number(params.id));
		setInfo(data.data);
	}, [params.id]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<Container>
			<div style={{ textAlign: "center", fontSize: "20px", marginBottom: 20 }}>{t("invoiceApplicationDetail")}</div>
			<Form labelCol={{ span: 10 }} wrapperCol={{ span: 10 }}>
				<Form.Item label={t("invoiceTitle")}>
					<div>{info.name}</div>
				</Form.Item>
				<Form.Item label={t("taxNumber")}>
					<div>{info.taxNumber}</div>
				</Form.Item>
				<Form.Item label={t("address")}>
					<div>{info.address}</div>
				</Form.Item>
				<Form.Item label={t("bank")}>
					<div>{info.bank}</div>
				</Form.Item>
				<Form.Item label={t("bankAccount")}>
					<div>{info.bankAccount}</div>
				</Form.Item>
				<Form.Item label={t("email")}>
					<div>{info.email}</div>
				</Form.Item>
				<Form.Item label={t("applicantTelephone")}>
					<div>{info.createdPhone}</div>
				</Form.Item>
				<Form.Item label={t("money")}>
					<div>{info.money}</div>
				</Form.Item>
				<Form.Item label={t("applicationTime")}>
					<div>{info.createdTime}</div>
				</Form.Item>
				<Form.Item label={t("applicantName")}>
					<div>{info.createdName}</div>
				</Form.Item>
				<Form.Item label={t("invoiceStatus")}>
					<div>{info.status === 1 ? t("underReview") : info.status === 2 ? t("Invoiced") : t("InvoiceRejected")}</div>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
