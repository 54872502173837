import { http } from "../../../common/http";
import { RecommendActivityListEntity } from "../entity/recommendActivityListEntity";
import { RecommendActivityListParam } from "../param/recommendActivityListParam";

class RecommendActivityListService {
	list = async (param: RecommendActivityListParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/recommend-activity",
			method: "GET",
			params: param,
		});
	};

	changeStatus = async (data: RecommendActivityListEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/recommend-activity/up-down/" + data.id,
			method: "PUT",
			data,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/recommend-activity/" + id,
			method: "DELETE",
			data: id,
		});
	};

	export = async (param: RecommendActivityListParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/recommend-activity/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};

	add = async (data: RecommendActivityListEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/recommend-activity",
			method: "POST",
			data,
		});
	};


	update = async (data: RecommendActivityListEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/recommend-activity/"+data.id,
			method: "PUT",
			data,
		});
	};


	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/recommend-activity/"+id,
			method: "GET",
			data:id
		});
	};





}

export default new RecommendActivityListService();
