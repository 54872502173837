import moment, { Moment } from "moment";

const nowDate = new Date();
const cloneNowDate = new Date();
const date = nowDate.getDate();
const month = nowDate.getMonth() + 1; // getMonth 方法返回 0-11，代表1-12月
const fullYear = nowDate.getFullYear();
const endOfMonth = new Date(fullYear, month, 0).getDate(); // 获取本月最后一天
const getFullDate = (targetDate: any) => {
	let D, y, m, d;
	if (targetDate) {
		D = new Date(targetDate);
		y = D.getFullYear();
		m = D.getMonth() + 1;
		d = D.getDate();
	} else {
		y = fullYear;
		m = month;
		d = date;
	}
	m = m > 9 ? m : '0' + m;
	d = d > 9 ? d : '0' + d;
	return y + '-' + m + '-' + d;
};
const AddDays = (d: any, n: any) => {
	const t = new Date(d); //复制并操作新对象，避免改动原对象
	t.setDate(t.getDate() + n);
	return t;
};
const AddMonths = (d: any, n: any) => {
	const t = new Date(d);
	t.setMonth(t.getMonth() + n);
	if (t.getDate() !== d.getDate()) {
		t.setDate(0);
	}
	return t;
};
const getQuarterStartMonth = () => {
	const nowMonth = nowDate.getMonth();
	if (nowMonth <= 2) {
		return 0;
	} else if (nowMonth <= 5) {
		return 3;
	} else if (nowMonth <= 8) {
		return 6;
	} else {
		return 9;
	}
};
// 月初到月末 [开始时间，结束时间]
export const getMonth = () => {
	const endDate = endOfMonth > new Date().getDate() ? getFullDate(null) : getFullDate(cloneNowDate.setDate(endOfMonth)); //当月最后一天
	const starDate = getFullDate(cloneNowDate.setDate(1)); //当月第一天
	return [starDate, endDate];
};
// 当前季节初到季节末  [开始时间，结束时间]
export const getQuarterS = () => {
	const getQuarterStartDate = new Date(fullYear, getQuarterStartMonth(), 1);
	const getQuarterEndDate = AddMonths(getQuarterStartDate, 3) > new Date() ? new Date() : AddDays(AddMonths(getQuarterStartDate, 3), -1);
	return [getFullDate(getQuarterStartDate), getFullDate(getQuarterEndDate)];
};
// 当年的第一天和最后一天
export const getYearFirstLastDay = () => {
	const firstDay = new Date();
	firstDay.setDate(1);
	firstDay.setMonth(0);
	const lastDay = new Date();
	return [getFullDate(firstDay), getFullDate(lastDay)];
};

export const softwareStartDate = moment("2019-09-15 00:00:00");

export const formatTime = (value: Moment) => value.format("YYYY-MM-DD HH:mm:ss");