import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, message, Modal, Select, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { SelectSite } from "../../module/site/component/select.site";
import productCombService from "../../module/product/service/product.comb.service";
import { ProductCombParams } from "../../module/product/param/product.comb.params";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { ProductCombEntity } from "../../module/product/entity/product.comb.entity";
import Util from "../../common/Util";
import { useNavigate } from "react-router-dom";
import { checkPermission } from "src/module/admin/service/permission.service";

export const ProductComb: FC = () => {
	const { t } = useTranslation();
	const { Option } = Select;
	const { confirm } = Modal;
	const navigate = useNavigate();
	const [dataList, setDataList] = useState<ProductCombEntity[]>([]);
	const [searchObj, setsearchObj] = useState({customPageNum: 1, customPageSize: 15});

	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageNum: 1,
	// 	customPageSize: 15,
	// });

	const [total, setTotal] = useState(0);

	const onSearch = (value: ProductCombParams) => {
		setsearchObj({ ...value, customPageNum: 1, customPageSize: 15 });
	};

	const downLoad = async () => {
		const { data } = await productCombService.export(searchObj);
		const blob = new Blob([data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "productComb");
		message.success(t("export.success"));
	};

	const handleDelete = (param: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await productCombService.delete(param);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const init = useCallback(async () => {
		const { data } = await productCombService.list(searchObj);
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const columns = [
		{
			title: t("image"),
			dataIndex: "imageUrl",
			render: (text: string) => {
				return <img src={text} width='100' style={{ border: "1px solid #e1e1e1" }} />;
			},
		},
		{ title: t("productCombName"), dataIndex: "name" },
		{ title: t("site.name"), dataIndex: "shopName" },
		{ title: t("productName"), dataIndex: "productName" },
		{ title: t("originPrice"), dataIndex: "originalPrice" },
		{ title: t("salePrice"), dataIndex: "currentPrice" },
		{ title: t("startTime"), dataIndex: "beginDate" },
		{ title: t("endTime"), dataIndex: "endDate" },
		{
			title: t("businessTime"),
			render: (record: ProductCombEntity) => {
				return record.businessHoursStart + "~" + record.businessHoursEnd;
			},
		},
		{
			title: t("SaleStatus"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? t("onSale") : t("off");
			},
		},
		{
			title: t("displayStatus"),
			dataIndex: "displayStatus",
			render: (text: number) => {
				return text === 0 ? t("noDisplay") : t("display");
			},
		},
		{ title: t("sort"), dataIndex: "sort" },
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(325) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/site/combo/${record.id}`);
								}}
							>
								{t("edit")}
							</Button>
						)}
						{checkPermission(326) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onSearch}>
				<Form.Item label={t("productCombName")} name='name'>
					<Input placeholder={t("productCombName")} allowClear />
				</Form.Item>
				<Form.Item label={t("status")} name='status'>
					<Select allowClear placeholder={t("status")}>
						<Option value='1'>{t("onSale")}</Option>
						<Option value='2'>{t("off")}</Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("site.name")} name='shopId'>
					<SelectSite level={1} />
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						{checkPermission(327) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/site/combo/add`);
								}}
							>
								{t("add")}
							</Button>
						)}

						<Button type='primary' onClick={downLoad}>
							{t("export")}
						</Button>
					</Space>
				</Form.Item>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id + ""}
				pagination={{
					current: searchObj.customPageNum,
					pageSize: searchObj.customPageSize,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")}
								{total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setsearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
	.ant-form {
		margin-bottom: 20px;
	}
`;
