import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { InputNumber, message, Modal, Space } from "antd";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { SelectAllUser } from "../../../module/admin/components/SelectAllUser";
import { PerformanceEntity } from "../../../module/performance/performance.entity";
import performanceService from "../../../module/performance/service/performance.service";

interface SalePerformanceEntity {
	orderNum?: string;
	visible?: boolean;
	onCancel?: any;
	saleMoney?: number;
}

export const SalePerformanceMadal = (props: SalePerformanceEntity) => {
	const [dataList, setDataList] = useState<PerformanceEntity[]>([]);
	const [addItem, setAddItem] = useState<PerformanceEntity>({});
	const [allMoney, setAllMoney] = useState<number>(0);

	const init = useCallback(async () => {
		if (!props.orderNum) return;
		const { data } = await performanceService.list({ serviceType: 1, orderNum: props.orderNum });
		if (data.code === 200) {
			setDataList(data.data);
			if (!isNaN(Number(props.saleMoney))) {
				setAllMoney(props.saleMoney!);
			}
		}
	}, [props.orderNum, props.saleMoney]);

	useEffect(() => {
		init();
	}, [init]);

	const handleClose = () => {
		props.onCancel();
		setAddItem({});
		init();
	};

	const handleOk = async () => {
		const sumMoney = dataList.reduce((a, b) => {
			return a + b.money!;
		}, 0);
		if (sumMoney > allMoney) {
			message.error("超过销售绩效总额");
			return;
		} else {
			if (props.orderNum?.includes("PO")) {
				const { data } = await performanceService.updatePackageSale({
					serviceType: 1,
					adminPerformanceManage: JSON.stringify(dataList),
					orderNum: props.orderNum,
				});
				if (data.code === 200) {
					message.success("修改销售绩效成功");
				} else {
					message.error(data.msg);
				}
			} else {
				const { data } = await performanceService.updatePerformance({
					serviceType: 1,
					adminPerformanceManage: JSON.stringify(dataList),
					orderNum: props.orderNum,
				});
				if (data.code === 200) {
					message.success("修改销售绩效成功");
				} else {
					message.error(data.msg);
				}
			}
		}

		props.onCancel();
		init();
	};

	const addPerformance = () => {
		const { userId, money } = addItem;
		if (userId !== void 0 && money !== void 0) {
			const newArr = [...dataList, addItem];
			setDataList(newArr);
			setAddItem({});
		} else {
			message.error("请输入完整内容");
		}
	};

	const remove = (index: number) => {
		dataList.splice(index, 1);
		setDataList(dataList.concat());
	};

	// 修改的时候
	const changeInfo = (index: number, type: string, e: any) => {
		const newArr = dataList.map((item, key) => {
			if (index == key) {
				return { ...item, [type]: e };
			} else {
				return item;
			}
		});
		setDataList(newArr);
	};
	return (
		<Container>
			<Modal title='销售绩效' visible={props.visible} onCancel={handleClose} onOk={handleOk}>
				<div className='setMargin'>销售人员</div>
				<Space style={{ marginTop: "20px", marginBottom: "20px" }}>
					<SelectAllUser
						value={addItem.userId}
						onChange={(e: number) => {
							setAddItem({ ...addItem, userId: e });
						}}
					/>
					<InputNumber
						min={0}
						value={addItem.money}
						onChange={(e) => {
							setAddItem({ ...addItem, money: Number(e) });
						}}
					/>
					<PlusOutlined style={{ color: "#096dd9" }} onClick={addPerformance} />
				</Space>
				<div>已选择的销售人员</div>
				{dataList.map((item, index) => {
					return (
						<div key={index}>
							<Space style={{ marginTop: "5px", marginBottom: "5px" }}>
								<SelectAllUser
									value={item.userId}
									onChange={(e: number) => {
										changeInfo(index, "userId", e);
									}}
								/>
								<InputNumber
									placeholder={t("money")}
									value={item.money}
									min={0}
									onChange={(e: number | null) => {
										changeInfo(index, "money", e || 0);
									}}
								/>
								<MinusOutlined style={{ color: "#f5222d" }} onClick={() => remove(index)} />
							</Space>
						</div>
					);
				})}
				<div style={{ marginTop: "20px" }}>销售管理绩效：{allMoney}元</div>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
