import { useEffect, useCallback, useState } from "react";
import styled from "@emotion/styled";
import equipmentsService from "../service/equipments.service";
import { EquipmentsEntity } from "../entity/equipments.entity";
import { Select } from "antd";
import { Props } from "src/common/entity/props.entity";
import { t } from "i18next";
const { Option } = Select;

export const SelectEquipment = (props: Props) => {
	const [list, setList] = useState<EquipmentsEntity[]>([]);
	const [nameInfo, setNameInfo] = useState("");

	const init = useCallback(async () => {
		const { data } = await equipmentsService.List({ name: nameInfo });
		setList(data.data.list);
	}, [nameInfo]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<Container>
			<Select
			allowClear
				onSearch={(e) => {
					setNameInfo(e);
				}}
				showSearch
				onBlur={()=>{setNameInfo('')}}
				style={{ width: 300 }}
				onChange={(e) => {
					props.onChange(e);
				}}
				placeholder={t("equipmentName")}
			>
				{list.map((item: EquipmentsEntity) => {
					return (
						<Option key={item.id} value={item.name}>
							{item.name}
						</Option>
					);
				})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
