import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, message, Select, Space, Table, Tabs } from "antd";
import { t } from "i18next";
import { ActivityParam } from "../../module/activity/param/activity.param";
import activityService from "../../module/activity/service/activity.service";
import { ActivityEntity } from "../../module/activity/entity/activity.entity";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import registrationService from "../../module/registration/service/registration.service";
import { RegistrationEntity } from "../../module/registration/entity/registration.entity";
import { RegistrationParam } from "../../module/registration/param/registration.param";
import { useNavigate } from "react-router-dom";
import { SelectRechargeActivity } from "../../module/activity/component/selectRechargeActivity";
import { checkPermission } from "src/module/admin/service/permission.service";

export const RechargeActivity: FC = () => {
	const navigate = useNavigate();
	const { TabPane } = Tabs;
	const { Option } = Select;
	const [total, setTotal] = useState<number>(0);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });

	const [staffTotal, setStaffTotal] = useState<number>(0);
	// const [staffPaginationObj, setStaffPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });

	const [searchObj, setSearchObj] = useState<ActivityParam>({ type: 1, customPageSize: 15, customPageNum: 1 });
	const [searchInfo, setSearchInfo] = useState<RegistrationParam>({ customPageSize: 15, customPageNum: 1 });
	const [dataList, setDataList] = useState<ActivityEntity[]>([]);
	const [staffList, setStaffList] = useState<RegistrationEntity[]>([]);

	const onfinish = (value: any) => {
		setSearchObj({ ...searchObj, ...value, customPageSize: 15, customPageNum: 1 });
	};

	const updateStatus = async (id: number, status: number) => {
		const { data } = await activityService.changeStatus({ id: id, status: status });
		if (data.code === 200) {
			message.success(t("success"));
			init();
		} else {
			message.error(data.msg);
		}
	};

	const init = useCallback(async () => {
		const { data } = await activityService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const registrationSearch = async (param: ActivityParam) => {
		const { data } = await registrationService.list({ ...param, type: 1 });
		if (data.code === 200) {
			setStaffList(data.data.list);
			setStaffTotal(data.data.total);
		}
	};

	const handleClick = async (key: string) => {
		console.log(key);
		// setStaffPaginationObj({ customPageSize: 15, customPageNum: 1 });
		registrationSearch({ customPageSize: 15, customPageNum: 1 });
	};

	const onSearch = (value: any) => {
		setSearchInfo({ ...value, customPageSize: 15, customPageNum: 1 });
		registrationSearch({ ...value, customPageSize: 15, customPageNum: 1 });
	};

	const rechargeColumns = [
		{
			title: t("activityId"),
			dataIndex: "id",
		},
		{
			title: t("activityName"),
			dataIndex: "title",
		},
		{
			title: t("rechargePackage"),
			render: (record: any) => {
				return (
					<div>
						{record.recharges.map((item: any, index: number) => {
							return (
								<div key={item.id}>
									套餐{index + 1}：充值{item.money} / 赠送{item.giveMoney} /{item.couponName ? item.couponName + " 优惠券" : ""}
								</div>
							);
						})}
					</div>
				);
			},
		},
		{
			title: t("startTime"),
			dataIndex: "beginDate",
		},
		{
			title: t("endTime"),
			dataIndex: "endDate",
		},
		{
			title: t("sort"),
			dataIndex: "sort",
		},
		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? t("onSale") : t("off");
			},
		},
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(51) && record.status === 1 && (
							<Button
								type='primary'
								danger
								onClick={() => {
									updateStatus(record.id, 2);
								}}
							>
								{t("off")}
							</Button>
						)}
						{checkPermission(50) && record.status === 2 && (
							<Button
								type='primary'
								onClick={() => {
									updateStatus(record.id, 1);
								}}
							>
								{t("onSale")}
							</Button>
						)}
						{checkPermission(49) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/activity/recharge/" + record.id);
								}}
							>
								{t("edit")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	const staffColumns = [
		{
			title: t("activityId"),
			dataIndex: "activityId",
		},
		{
			title: t("userNickname"),
			dataIndex: "createdName",
		},
		{
			title: t("activityName"),
			dataIndex: "activityName",
		},
		{
			title: t("mobile"),
			dataIndex: "mobile",
		},
		{
			title: t("rechargeMoney"),
			dataIndex: "money",
		},
		{
			title: t("giveMoney"),
			dataIndex: "giveMoney",
		},
		{
			title: t("createdTime"),
			dataIndex: "createdTime",
		},
	];

	return (
		<Container>
			<Tabs
				defaultActiveKey='1'
				onTabClick={() => {
					handleClick("2");
				}}
			>
				<TabPane tab={t("rechargeActivity")} key='1'>
					<Form layout='inline' onFinish={onfinish}>
						<Form.Item label={t("activityName")} name='title'>
							<Input placeholder={t("activityName")} allowClear />
						</Form.Item>
						<Form.Item label={t("activityStatus")} name='status'>
							<Select placeholder={t("activityStatus")} allowClear>
								<Option value={1}>{t("onSale")}</Option>
								<Option value={2}>{t("off")}</Option>
							</Select>
						</Form.Item>
						<Form.Item>
							<Space>
								<Button type='primary' htmlType='submit'>
									{t("search")}
								</Button>
								{checkPermission(48) && (
									<Button
										type='primary'
										onClick={() => {
											navigate("/activity/recharge/add");
										}}
									>
										{t("addActivity")}
									</Button>
								)}
							</Space>
						</Form.Item>
					</Form>
					<Table
						columns={rechargeColumns}
						dataSource={dataList}
						rowKey={(record) => record.id + ""}
						style={{ marginTop: 20 }}
						pagination={{
							pageSize: searchObj.customPageSize,
							current: searchObj.customPageNum,
							total: total,
							showTotal: () => {
								return (
									<div>
										{t("total")} {total}
									</div>
								);
							},
						}}
						onChange={(pagination) => {
							setSearchObj({
								...searchObj,
								customPageNum: pagination.current ?? 1,
								customPageSize: pagination.pageSize ?? 15,
							});
						}}
					/>
				</TabPane>
				<TabPane tab={t("registrationStaff")} key='2'>
					<Form layout='inline' onFinish={onSearch}>
						<Form.Item label={t("mobile")} name='mobile'>
							<Input placeholder={t("mobile")} allowClear />
						</Form.Item>
						<Form.Item label={t("activityName")} name='activityId'>
							<SelectRechargeActivity type={1} />
						</Form.Item>
						<Form.Item>
							<Button type='primary' htmlType='submit'>
								{t("search")}
							</Button>
						</Form.Item>
					</Form>
					<Table
						columns={staffColumns}
						dataSource={staffList}
						rowKey={(record) => record.id + ""}
						style={{ marginTop: 20 }}
						pagination={{
							pageSize: searchInfo.customPageSize,
							current: searchInfo.customPageNum,
							total: staffTotal,
							showTotal: () => {
								return (
									<div>
										{t("total")} {staffTotal}
									</div>
								);
							},
						}}
						onChange={(pagination) => {
							// setStaffPaginationObj({
							// 	...staffPaginationObj,
							// 	customPageNum: pagination.current,
							// 	customPageSize: pagination.pageSize,
							// });
							console.log({
								...searchInfo,
								customPageNum: pagination.current ,
								customPageSize: pagination.pageSize ,
							});
							setSearchInfo({
								...searchInfo,
								customPageNum: pagination.current ,
								customPageSize: pagination.pageSize ,
							});
							registrationSearch({ ...searchInfo, customPageNum: pagination.current, customPageSize: pagination.pageSize  });
						}}
					/>
				</TabPane>
			</Tabs>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
