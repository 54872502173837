import {Select} from "antd";
import {PropsEntity} from "../../../common/entity/props.entity";
import MembershipCardService from "../service/membership.card.service";
import {useTranslation} from "react-i18next";

const {Option} = Select;

export const MembershipCardType = (props: PropsEntity) => {
    const {t} = useTranslation();

    return (
        <>
            <Select value={props.value ? Number(props.value) : undefined} allowClear onChange={props.onChange} placeholder={t("selectCardType")}>
                {
                    Object.keys(MembershipCardService.cardType).map((row) => {
                        return <Option key={row} value={Number(row)}>{t(MembershipCardService.cardType[Number(row)])}</Option>
                    })
                }
            </Select>
        </>
    )
}