import {FC, useEffect, useState} from "react";
import styled from "@emotion/styled";
import {Button, DatePicker, Form, Input, message, Radio} from "antd";
import {UploadImg} from "../../../common/components/UploadImg";
import {useTranslation} from "react-i18next";
import MyEditor from "../../../common/components/MyEditor";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "antd/es/form/Form";
import {SelectCity} from "../../../module/overview/components/select.city.all";
import activityService from "../../../module/activity/service/activity.service";
import moment from "moment";
import {MultiSelectSite} from "../../../module/site/component/multi.select.site";
import {MultiSelectPayWay} from "../../../module/finance/component/MultiSelectPayWay";
import {SingleSelectCompany} from "src/module/company/component/single.select.company";
import {PackageActivityCoupon} from "src/module/packageActicityCoupon/component/packageActivityCoupon";

export const PackageActivityDetail: FC = () => {
    const [editorLoading, setEditorLoading] = useState<boolean>(false);
    const {TextArea} = Input;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const params = useParams();
    const [form] = useForm();
    const [pointerArr, setPointerArr] = useState<[number, number?, number?][]>([]);

    useEffect(() => {
        if (params.id) {
            activityService.detail(Number(params.id)).then((res) => {
                if (res.data.code === 200) {
                    form.setFieldsValue({
                        ...res.data.data,
                        activityTime: [moment(res.data.data.beginDate), moment(res.data.data.endDate)],
                        activityArea: [res.data.data.provinceId, res.data.data.cityId, res.data.data.districtId],
                    });
                    setEditorLoading(true);
                }
            });
        } else {
            setEditorLoading(true);
        }
    }, [form, params.id]);

    const onFinish = async (value: any) => {
        const param = {
            ...value,
            activityArea: undefined,
            provinceId: value.activityArea && value.activityArea[0],
            cityId: value.activityArea && value.activityArea[1],
            districtId: value.activityArea && value.activityArea[2],
            activityTime: undefined,
            beginDate: value.activityTime[0].format("YYYY-MM-DD") + " 00:00:00",
            endDate: value.activityTime[1].format("YYYY-MM-DD") + " 23:59:59",
            type: 6,
            packageCategory: formatData(value),
        };
        if (!checkValid(param)) return;
        delete param.activityPackageCategoriesCopy;
        // delete param.activityPackageCategoriesCopy;
        // setCommitFn(formatData)


        if (!params.id) {
            const {data} = await activityService.add(param);
            if (data.code === 200) {
                message.success(t("success"));
                navigate("/activity/package");
            } else {
                message.error(data.msg);
            }
        } else {
            const {data} = await activityService.update({
                ...param,
                id: Number(params.id),
            });
            if (data.code === 200) {
                message.success(t("success"));
                navigate("/activity/package");
            } else {
                message.error(data.msg);
            }
        }
    };

    const changePoint = (pointers: [number, number?, number?][]) => {
        setPointerArr(pointers);
    };

    const formatData = (value: any) => {
        const [classArr, comboArr, couponArr] = formatArr();
        couponArr.map((pointers: any) => {
            value.activityPackageCategoriesCopy[pointers[0]].activityPackageCombos[pointers[1]].activityPackageCoupons.splice(pointers[2], 1);
        });
        comboArr.map((pointers: any) => {
            value.activityPackageCategoriesCopy[pointers[0]].activityPackageCombos.splice(pointers[1], 1);
        });
        classArr.map((classIndex) => {
            value.activityPackageCategoriesCopy.splice(classIndex, 1);
        });

		return JSON.stringify(value.activityPackageCategoriesCopy);
		// console.log(classArr, comboArr, couponArr, value);
	};

    const formatArr = () => {
        // [1,undefined, undefined] 意味着 [1, x,x] 都无效了  [1,1,undefined] 意味着 [1,1,x]无效
        // 做一个简单的无效去除
        const classArr: number[] = [];
        const comboArr: number[][] = [];
        const couponArr: [number, (number | undefined)?, (number | undefined)?][] = [];
        pointerArr.map((arr) => {
            if (arr[1] === void 0) {
                classArr.push(arr[0]);
            } else if (arr[2] === void 0) {
                comboArr.push([arr[0], arr[1]]);
            } else {
                couponArr.push(arr);
            }
        });
        classArr.map((item) => {
            comboArr.map((combo, index) => {
                if (combo[0] === item) {
                    comboArr.splice(index, 1);
                }
            });
            couponArr.map((combo, index) => {
                if (combo[0] === item) {
                    couponArr.splice(index, 1);
                }
            });
        });
        comboArr.map((item) => {
            couponArr.map((coupon, index) => {
                if (item[0] === coupon[0] && item[1] === coupon[1]) {
                    couponArr.splice(index, 1);
                }
            });
        });
        return [classArr, comboArr, couponArr];
    };

    const checkValid = (param: any) => {
        // 粗略检查一下提交的数据合法性, 尽可能不提交脏数据
        let flag = true;
        const {activityPackageCategoriesCopy, shopId} = param;
        // 检查门店是否已选
        if (!shopId) {
            flag = false;
            message.error('请选择门店!')
            return flag;
        }
        // 检查是否有提交空券
        activityPackageCategoriesCopy.forEach((category: { activityPackageCombos: any[]; }) => {
            category.activityPackageCombos.forEach(combo => {
                combo.activityPackageCoupons.forEach((coupon: { couponId: number; }) => {
                    if (!coupon.couponId) {
                        console.log(coupon)
                        flag = false;
                        message.error('配置优惠券数据异常!')
                        return flag;
                    }
                })
            })
        })
        return flag;
    }

    return (
        <Container>
            <Form labelCol={{span: 4}} wrapperCol={{span: 12}} style={{padding: 20}} onFinish={onFinish} form={form}>
                <Form.Item label={t("image")} name='imageUrl'>
                    <UploadImg/>
                </Form.Item>
                <Form.Item label={t("displayImage")} name='displayImage'>
                    <UploadImg size={"1125*1125"}/>
                </Form.Item>
                <Form.Item label={t("activityName")} name='title'>
                    <Input placeholder={t("activityName")}/>
                </Form.Item>
                <Form.Item label={t("price")} name='money'>
                    <Input placeholder={t("money")}/>
                </Form.Item>
                <Form.Item label={t("salesPerformance")} name='saleMoney'>
                    <Input placeholder={t("saleMoney")}/>
                </Form.Item>
                <Form.Item label={t("promoteMoney")} name='promoteMoney'>
                    <Input placeholder={t("promoteMoney")}/>
                </Form.Item>
                <Form.Item label={t("sort")} name='sort'>
                    <Input placeholder={t("sort")}/>
                </Form.Item>
                <Form.Item label={t("activityArea")} name='activityArea'>
                    <SelectCity useArray/>
                </Form.Item>
                <Form.Item label={t("shopName")} name='shopId'>
                    <MultiSelectSite/>
                </Form.Item>
                <Form.Item label={t("activityTime")} name='activityTime'>
                    <DatePicker.RangePicker/>
                </Form.Item>
                <Form.Item label={t("绑定券")} name='packageCategory'>
                    <PackageActivityCoupon id={Number(params.id)} form={form} commitPointers={changePoint}/>
                </Form.Item>
                <Form.Item label={t("payWay")} name='payTypes'>
                    <MultiSelectPayWay/>
                </Form.Item>
                <Form.Item label={t("limitedPurchaseTime")} name='limitNum'>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("issueQuantity")} name='grantNum'>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("InventoryQuantity")} name='remainingNum'>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("collectingcompany")} name='companyFinanceId'>
                    <SingleSelectCompany/>
                </Form.Item>
                <Form.Item label={t("status")} name='status'>
                    <Radio.Group>
                        <Radio value={1}>{t("onSale")}</Radio>
                        <Radio value={2}>{t("off")}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={t("url")} name='contentUrl'>
                    <Input placeholder={t("url")}/>
                </Form.Item>


                <Form.Item label={t("showCustomer")} name='showCustomer'>
                    <Radio.Group>
                        <Radio value={0}>{t("no")}</Radio>
                        <Radio value={1}>{t("yes")}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={t("isHomePageShow")} name='isHomePageShow'>
                    <Radio.Group>
                        <Radio value={0}>{t("no")}</Radio>
                        <Radio value={1}>{t("yes")}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={t("activityBriefly")} name='sketch'>
                    <TextArea rows={4} placeholder={t("activityBriefly")}/>
                </Form.Item>
                <Form.Item label={t("activityRule")} name='rule'>
                    {editorLoading && <MyEditor/>}
                </Form.Item>
                <Form.Item label={t("activityContent")} name='content'>
                    {editorLoading && <MyEditor/>}
                </Form.Item>
                <Form.Item wrapperCol={{span: 12, offset: 4}}>
                    <Button type='primary' htmlType='submit'>
                        {t("submit")}
                    </Button>
                </Form.Item>
            </Form>
        </Container>
    );
};
const Container = styled.div`
  width: 100%;
`;
