import React, {FC, useEffect, useState} from "react";
// import {Map, APILoader, Marker} from '@uiw/react-baidu-map';
import ShopService from "../../../module/site/service/site.service";
import {AxiosResponse} from "axios";
import {ResponseEntity} from "../../../common/entity/response.entity";
import {message} from "antd";

interface ShopEntity{
    lng: number,
    lat: number,
    title: string
}

const BaiduMap:FC = () => {
    const [data, setData] = useState<ShopEntity[]>([]);

    useEffect(() => {
        if (data.length <= 0){
            init();
        }
    })
    const init = async () => {
        const {data}:AxiosResponse<ResponseEntity> = await ShopService.all({lever:2});
        if (data.code !== 1){
            message.error(data.msg);
        }else{
            const resData:ShopEntity[] = data.data.data;
            setData(resData);
        }
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {/*<APILoader akay={"kQt0MvquD6Xq9Iqbg4cXHGRSdNZ2CTN1"}>*/}
            {/*    <Map center={'point'} zoom={8}  enableScrollWheelZoom={true}>*/}
            {/*    /!*<Map center={'point'} zoom={8} mapStyleV2={{styleId:'7aa4d00109d3cb41e9e129ce5e0d6223'}} enableScrollWheelZoom={true}>*!/*/}
            {/*        {*/}
            {/*            data.length > 0 && data.map( (row,index) => {*/}
            {/*                let positionData:BMap.Point = { lng: row.lng, lat: row.lat };*/}
            {/*                return <Marker key={index} position={positionData} title={row.title} />*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </Map>*/}
            {/*</APILoader>*/}
        </div>
    )
}
export default BaiduMap;