import styled from "@emotion/styled";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
import { ProductTypeListEntity } from "../entity/product.type.entity";
import productTypeService from "../service/product.type.service";
import i18n from "src/common/config/i18n";
const { Option } = Select;

export const MultiSelectProductType = (props: Props) => {
	const { t } = useTranslation();
	const [list, setList] = useState<ProductTypeListEntity[]>([]);

	useEffect(() => {
		productTypeService.listAll().then((res) => {
			if (res.data.code === 200) {
				setList(res.data.data);
			}
		});
	}, []);

	const onChange = (value: number[]) => {
		props.onChange&&props.onChange(value.join(","));	
	};

	return (
		<Container>
			<Select
				style={{ width: 250 }}
				mode='multiple'
				allowClear
				placeholder={t("product.type")}
				onChange={onChange}
				value={props.value?props.value.split(",").map((item: string) => Number(item)):undefined}
			>
				{list.map((item) => {
					return (
						
						<Option value={item.id} key={item.id}>
							{i18n.language === "zh-CN" ? item.title : item.enTitle}
						</Option>
						
					);
				})}
				s
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
