class ScaleService {
    static widthRadio = 1;
    static getWidthRadio() {
        return this.widthRadio;
    }
    static setWidthRadio(val:number) {
        this.widthRadio = val;
    }
}

export default ScaleService;