import Axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios";
import { message } from "antd";

// 默认配置
const defaultOptions = {
	baseURL: process.env.REACT_APP_DATA_API,
	timeout: 1000 * 60 * 2,
	// baseURL: 'http://192.168.1.30:8086/api/v1/manage',
};

// 添加一个请求拦截器
const requestFilter = (instance: AxiosInstance) => {
	instance.interceptors.request.use(
		(config) => {
			const token = localStorage.token ?? "";
			if (token && config.headers) {
				config.headers.Authorization = token;
			} else {
				if (!window.location.pathname.includes("/login") && !window.location.pathname.includes("/loginOut")) {
					window.location.href = "/login";
					return Promise.reject();
				}
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
};

// 添加一个响应拦截器
const responseFilter = (instance: AxiosInstance) => {
	instance.interceptors.response.use(
		(response) => {
			if (response.config.responseType === "blob") {
				return response;
			}
			if (response.status === 200) {
				if (response.data.code !== 200) {
					message.error(response.data.msg);
					return Promise.reject(response.data.msg);
				}
			} else {
				message.error(response.data.msg);
			}
			return response;
		},
		(error) => {
			if (error.response && error.response.status) {
				// 请求已发出，但服务器响应的状态码不在 2xx 范围内
				switch (Number(error.response.status)) {
					case 401:
					case 403:
						localStorage.clear();
						message.error("用户信息过期，请重新登录!");
						window.location.href = "/login";
						return Promise.reject("用户信息过期");
					case 500:
						if (error.response.config.responseType === "blob") {
							return Promise.reject(error.response.data);
						}
						message.error(error.response.data.msg || "网络错误，请稍后尝试。");
						return Promise.reject(error.response.data.msg);
					default:
						message.error("网络错误，请稍后尝试。");
						return Promise.reject(error.response.data.msg || "Request Error");
				}
			} else {
				return Promise.reject("Request Error");
			}
		}
	);
};

const server = Axios.create(defaultOptions);
requestFilter(server);
responseFilter(server);

export function http<T = any>(config: AxiosRequestConfig): AxiosPromise<T extends never ? any : { code: 200 | 401 | 403 | 500; msg: string; data: T }> {
	return server(config);
}
