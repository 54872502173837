import { PaginationEntity } from "../../../common/entity/pagination.entity";
import {http} from "../../../common/http";
import { BannerEntity } from "../entity/banner.entity";

class BannerService {
    
    list = async (param: PaginationEntity) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/banner',
            method: 'GET',
            params: param,
        })
    }
    
    detail = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+`/banner/${id}`,
            method: 'GET'
        })
    }
    
    addOrUpdate = async (data: BannerEntity) => {
        if (data.id) {
            return http({
                url: process.env.REACT_APP_ADMIN_API+`/banner/${data.id}`,
                method: 'PUT',
                data
            })
        }else{
            return http({
                url: process.env.REACT_APP_ADMIN_API+`/banner`,
                method: 'POST',
                data
            })
        }
    }
    delete = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/banner/'+id,
            method: 'DELETE'
        })
    }
}
export default new BannerService();