/*
 * @Author: Yujia fyujia0815@gmail.com
 * @Date: 2022-10-21 11:13:56
 * @LastEditors: Yujia fyujia0815@gmail.com
 * @LastEditTime: 2022-12-01 11:02:09
 * @FilePath: \data-center-ts\src\module\product\component\single.select.product.tsx
 */
import styled from "@emotion/styled";
import { Select } from "antd";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { PropsEntity } from "../../../common/entity/props.entity";
import { ProductEntity } from "../entity/product.entity";
import ProductService from "../service/product.service";

interface InternalProp extends PropsEntity {
	siteId?: any;
	selected?: string;
	className?: string;
	labelInValue?: boolean;
}

export const SingleSelectProduct = (props: InternalProp) => {
	const [productList, setProductList] = useState<ProductEntity[]>([]);
	// const [name, setName] = useState<string | undefined>(undefined);
	const [productId, setProductId] = useState<number | undefined>(undefined);

	const selectedArr = props.selected ? props.selected.split(",") : [];

	useEffect(() => {
		setProductId(props.value);
	}, [props.siteId, props.value]);

	const init = useCallback(async () => {
		const { data } = await ProductService.all({ shopId: props.siteId });
		setProductList(data.data);
	}, [props.siteId]);

	const onChange = (value: number) => {
		props.onChange && props.onChange(value);
		setProductId(value);

		productList.forEach((row) => {
			if (row.id === value) {
				props.entityChange && props.entityChange(row);
			}
		});
	};

	useEffect(() => {
		init();
	}, [init]);

	return (
		<Container className={props.className}>
			<Select
				optionFilterProp={"children"}
				style={{ width: "100%" }}
				value={productId}
				onChange={onChange}
				allowClear
				showSearch
				placeholder={t("selectProduct")}
				labelInValue={props.labelInValue}
			>
				{productList.map((row) => {
					return (
						<Select.Option
							key={row.id}
							value={row.id}
							style={{
								color: selectedArr.indexOf(row.id + "") > -1 && "#40a9ff",
								fontWeight: selectedArr.indexOf(row.id + "") > -1 && 600,
							}}
						>
							{row.name}
						</Select.Option>
					);
				})}
			</Select>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
`;
