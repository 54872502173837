import { Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
import { CustomerEntity } from "../entity/customer.entity";
import customerService from "../service/customer.service";
const { Option } = Select;

export const SelectCustomer = (props: Props) => {
	const { t } = useTranslation();
	const [customerList, setCustomerList] = useState<CustomerEntity[]>([]);
	const [info, setInfo] = useState<CustomerEntity>({});

	// const init = useCallback(async () => {
	// 	const { data } = await customerService.list(info);
	// 	if (data.code === 200) {
	// 		setCustomerList(data.data);
	// 	}
	// }, [info]);

	const init = useCallback(async () => {
		const res = await customerService.list(info);
		const listFlag = res.data.data;
		let flag = false;
		listFlag.forEach((item: CustomerEntity) => {
			if (item.id === props.value) {
				flag = true;
			}
		});
		if (!flag && props.value) {
			const {data} = await customerService.detail(props.value);
			listFlag.push(data.data);
			
		}
		setCustomerList(listFlag);
		console.log("2",listFlag);
		
	}, [info, props.value]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<Select
			onBlur={() => setInfo({})}
			showSearch
			optionFilterProp={"children"}
			onSearch={(e) => {
				setInfo({ mobile: e });
			}}
			value={props.value}
			onChange={(e) => {
				props.onChange(e);
			}}
			allowClear
			placeholder={t("carOwners")}
		>
			{customerList &&
				customerList.map((item) => {
					return (
						<Option key={item.id} value={item.id}>
							{item.nickName} - {item.mobile}
						</Option>
					);
				})}
		</Select>
	);
};
