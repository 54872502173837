import {http} from "../../../common/http";
import {MembershipCardParam} from "../params/membership.card.param";
import {MembershipCardEntity} from "../entity/membership.card.entity";

class MembershipCardService {

    cardType: Record<number, string> = {
        1: "limitation_card",
        4: "times_card"
    }

    list = async (param: MembershipCardParam) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/card-config',
            method: 'GET',
            params: param,
        })
    }

    add = async (data: MembershipCardEntity) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/card-config',
            method: 'POST',
            data
        })
    }

    update = async (data: MembershipCardEntity) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+`/card-config/${data.id}`,
            method: 'PUT',
            data
        })
    }

    delete = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/card-config/'+id,
            method: 'DELETE',
        })
    }

    detail = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+`/card-config/${id}`,
            method: 'GET',
        })
    }
}

export default new MembershipCardService();