import styled from "@emotion/styled";
import { Button, Form, Input, InputNumber, message, Modal, Radio, Space, Tooltip, Typography } from "antd";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UploadImg } from "src/common/components/UploadImg";
import { MultiSelectCouponByType } from "src/module/coupon/component/MultiSelectCouponByType";
import { MultiSelectProductBySite } from "src/module/product/component/multi.select.product.by.shop";
import { DatePeriod } from "../../common/components/date.period";
import MyEditor from "../../common/components/MyEditor";
import { OnOff } from "../../common/components/on.off";
import { dateFormat } from "../../common/Util";
import { SingleSelectCompany } from "../../module/company/component/single.select.company";
import { MembershipCardType } from "../../module/membershipCard/component/membership.card.type";
import { cardConfigListEntity, MembershipCardEntity } from "../../module/membershipCard/entity/membership.card.entity";
import MembershipCardService from "../../module/membershipCard/service/membership.card.service";
import { MultiSelectSite } from "../../module/site/component/multi.select.site";
import { QuestionCircleOutlined } from "@ant-design/icons";

const formItemLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 20 },
};
const { confirm } = Modal;

// const itemLayout = {
// 	labelCol: { span: 5 },
// 	wrapperCol: { span: 13 },
// };

export const MembershipCardAdd: FC = () => {
	const [cardInfo, setCardInfo] = useState<MembershipCardEntity>({
		status: 1,
		effectiveDay: 30,
		times: 0,
		price: 0.0,
		originPrice: 0.0,
		grantNum: 1,
		limitNum: 1,
		saleMoney: 0.0,
		sort: 1,
	});

	const [childCardInfo, setChildCardInfo] = useState<cardConfigListEntity[]>([]);
	const [editorLoading, setEditorLoading] = useState<boolean>(false);
	const [details, setDetails] = useState<string>("");

	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams();

	const init = useCallback(async () => {
		if (Number(id) === 0) {
			setEditorLoading(true);
		} else {
			const { data } = await MembershipCardService.detail(Number(id));
			setCardInfo(data.data);
			setChildCardInfo(data.data.cardConfigList);
			setDetails(data.data.description);
			setEditorLoading(true);
		}
	}, [id]);

	useEffect(() => {
		init();
	}, [init]);

	const finish = async () => {
		if (cardInfo.id) {
			const { data } = await MembershipCardService.update({
				...cardInfo,
				description: details,
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/activity/membership-card");
			} else {
				message.error(data.msg);
			}
		} else {
			const { data } = await MembershipCardService.add({
				...cardInfo,
				description: details,
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/activity/membership-card");
			} else {
				message.error(data.msg);
			}
		}
	};

	const onChange = (field: string, e: any) => {
		setCardInfo({
			...cardInfo,
			[field]: e,
		});
	};

	const onDateChange = (str: string[]) => {
		setCardInfo({
			...cardInfo,
			startTime: str[0] ? moment(str[0]).format(dateFormat) + " 00:00:00" : "",
			expirationTime: str[1] ? moment(str[1]).format(dateFormat) + " 23:59:59" : "",
		});
	};

	const addCard = () => {
		const obj = {
			title: "",
			couponId: "",
			price: undefined,
			originPrice: undefined,
			effectiveDay: undefined,
			saleMoney: undefined,
			promoteMoney: undefined,
		};

		if (!childCardInfo) {
			setChildCardInfo([obj]);
		} else setChildCardInfo([...childCardInfo, obj]);
	};

	// const deleteCard = (index: number) => {

	// 	childCardInfo.splice(index, 1);
	// 	// console.log(childCardInfo);
	// 	// const newArr=childCardInfo.concat()
	// 	// setChildCardInfo(newArr)
	// 	setChildCardInfo([...childCardInfo]);
	// 	setCardInfo({...cardInfo,cardConfigDTOList:[...childCardInfo]})
	// };

	const deleteCard = (index: number) => {
		confirm({
			title: t("delete"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				childCardInfo.splice(index, 1);
				setChildCardInfo([...childCardInfo]);
				setCardInfo({ ...cardInfo, cardConfigList: [...childCardInfo] });
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const changeChildCardInfo = (type: keyof cardConfigListEntity, num: number) => {
		return (e: any) => {
			childCardInfo.map((item, index) => {
				if (index === num) {
					item[type] = ["title", "newUser"].includes(type) ? e.target.value : e;
				}
				return item;
			});
			// console.log(childCardInfo);
			setChildCardInfo([...childCardInfo]);
			setCardInfo({ ...cardInfo, cardConfigList: [...childCardInfo] });
		};
	};

	return (
		<Container>
			<Form>
				<Form.Item label={t("image")} {...formItemLayout}>
					<UploadImg value={cardInfo?.imageUrl} onChange={(e: string) => onChange("imageUrl", e)} />
				</Form.Item>
				<Form.Item label={t("detailImage")} {...formItemLayout}>
					<UploadImg value={cardInfo?.detailImageUrl} onChange={(e: string) => onChange("detailImageUrl", e)} />
				</Form.Item>
				<Form.Item label={t("title")} {...formItemLayout}>
					<Input value={cardInfo?.title} onChange={(e) => onChange("title", e.target.value)} />
				</Form.Item>

				<Form.Item label={t("cardType")} {...formItemLayout}>
					<MembershipCardType value={cardInfo?.cardType} onChange={(e: number) => onChange("cardType", e)} />
				</Form.Item>

				<Form.Item label={t("status")} {...formItemLayout}>
					<OnOff value={cardInfo?.status} onChange={(e: any) => onChange("status", e.target.value)} />
				</Form.Item>
				<Form.Item label={t("product")} {...formItemLayout}>
					<MultiSelectProductBySite
						value={cardInfo?.productId}
						onChange={(e: string) =>
							setCardInfo({
								...cardInfo,
								productId: e,
							})
						}
					/>
				</Form.Item>
				<Form.Item label={t("displaySite")} {...formItemLayout}>
					<MultiSelectSite value={cardInfo?.displayShopIds} level={1} onChange={(e: string) => onChange("displayShopIds", e)} />
				</Form.Item>
				<Form.Item label={t("autoChooseProduct")} {...formItemLayout}>
					<Radio.Group value={cardInfo?.isDefault} buttonStyle='solid' onChange={(e) => onChange("isDefault", e.target.value)}>
						<Radio.Button value={1}>{t("yes")}</Radio.Button>
						<Radio.Button value={0}>{t("no")}</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("membershipInformation")} {...formItemLayout}>
					{childCardInfo?.map((item: cardConfigListEntity, index: number) => {
						item.newUser = item.newUser || 2;
						return (
							<div key={index} className='container'>
								<div className='class'>
									{t("childCard ")}:{index + 1}
									&nbsp;&nbsp;
									<Button
										type='primary'
										danger
										onClick={() => {
											deleteCard(index);
										}}
									>
										{t("delete")}
									</Button>
								</div>

								<div className='form'>
									<Form.Item label={t("title")} labelCol={{ span: 4 }} required>
										<Input value={item.title} onChange={changeChildCardInfo("title", index)} />
									</Form.Item>
									<Form.Item label={t("giftCoupons")} labelCol={{ span: 4 }}>
										<MultiSelectCouponByType
											IsQuantity
											entityChange={changeChildCardInfo("couponList", index)}
											withIsQuantityValue={item.couponList}
											value={item.couponId}
											onChange={changeChildCardInfo("couponId", index)}
										/>
									</Form.Item>
									<Form.Item label={t("price")} labelCol={{ span: 4 }} required>
										<InputNumber value={item.price} onChange={changeChildCardInfo("price", index)} />
										<span className='gapAndQuality'>元</span>
										{cardInfo.cardType === 1 && (
											<>
												<span className='customLabel'>{t("effectiveDays")}：</span>
												<InputNumber value={item.effectiveDay} onChange={changeChildCardInfo("effectiveDay", index)} />
												<span className='gapAndQuality'>天</span>
											</>
										)}
										{cardInfo.cardType === 4 && (
											<>
												<span className='customLabel'>{t("times")}：</span>
												<InputNumber value={item.times} onChange={changeChildCardInfo("times", index)} />
												<span className='gapAndQuality'>次</span>
											</>
										)}
										<>
											<span className='customLabel'>{t("saleMoney")}：</span>
											<InputNumber value={item.saleMoney} onChange={changeChildCardInfo("saleMoney", index)} />
											<span className='gapAndQuality'>元</span>
										</>
										<>
											<span className='customLabel'>{t("promoteMoney")}：</span>
											<InputNumber value={item.promoteMoney} onChange={changeChildCardInfo("promoteMoney", index)} />
											<span className='gapAndQuality'>元</span>
										</>
									</Form.Item>
									<Form.Item label='仅限新用户'>
										<Space>
											<Form.Item labelCol={{ span: 4 }}>
												<Radio.Group value={item.newUser} buttonStyle='solid' onChange={changeChildCardInfo("newUser", index)}>
													<Radio.Button value={1}>{t("yes")}</Radio.Button>
													<Radio.Button value={2}>{t("no")}</Radio.Button>
												</Radio.Group>
											</Form.Item>
											<Tooltip title='仅限未购买过月卡的新用户'>
												{/* <Typography.Link href='#API'>Need Help?</Typography.Link> */}
												<QuestionCircleOutlined className="questionIcon" size={25} />
											</Tooltip>
										</Space>
									</Form.Item>
								</div>
							</div>
						);
					})}
					<Button type='primary' block style={{ marginTop: "20px" }} onClick={addCard}>
						增加子卡
					</Button>
				</Form.Item>

				<Form.Item label={t("grantNum")} {...formItemLayout}>
					<InputNumber value={cardInfo?.grantNum} min={1} onChange={(e: number | null) => onChange("grantNum", e || 0)} />
				</Form.Item>
				<Form.Item label={t("buyLimitNum")} {...formItemLayout}>
					<InputNumber value={cardInfo?.limitNum} min={1} onChange={(e: number | null) => onChange("limitNum", e || 0)} />
				</Form.Item>

				<Form.Item label={t("company")} {...formItemLayout}>
					<SingleSelectCompany value={cardInfo?.companyFinanceId} onChange={(e: string) => onChange("companyFinanceId", e)} />
				</Form.Item>
				<Form.Item label={t("availablePeriod")} {...formItemLayout}>
					<DatePeriod value={[cardInfo?.startTime, cardInfo?.expirationTime]} onChange={onDateChange} />
				</Form.Item>
				<Form.Item label={t("sort")} {...formItemLayout}>
					<InputNumber value={cardInfo?.sort} min={0} defaultValue={1} onChange={(e: number | null) => onChange("sort", e || 0)} />
				</Form.Item>
				<Form.Item label={t("content")} {...formItemLayout}>
					{editorLoading && <MyEditor value={details} onChange={(e: string) => setDetails(e)} />}
				</Form.Item>
				<div>
					<Button type={"primary"} onClick={finish}>
						{t("submit")}
					</Button>
				</div>
			</Form>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	padding: 10px;
	.container {
		border: 2px dashed gray;
		padding: 20px;
		margin-bottom: 15px;
		.class {
			font-size: 18px;
			font-weight: 800;
			margin-bottom: 20px;
		}
		.form {
			width: 70%;
			margin: auto;
			.gapAndQuality {
				margin: 0 20px 0 10px;
				position: relative;
			}
			.questionIcon {
				position: relative;
				bottom: 15px;
				color: #1890ff;
			}
			.customLabel {
				position: relative;
				&::before {
					content: "*";
					color: #ff4d4f;
					font-size: 14px;
					font-family: SimSun, sans-serif;
					position: absolute;
					left: -10px;
					top: 0px;
				}
			}
		}
	}
`;
