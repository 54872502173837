import {http} from "../../../common/http";
import {currentInfo} from "./admin.service";

class PermissionService {
    list = async () => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/permission/tree',
            method: 'GET',
        })
    }
}

export default new PermissionService();

/**
 * Check admin user permission
 * true: has permission
 * false: no permission
 * @param id
 */
export const checkPermission = (id: number): boolean => {
    return currentInfo().id === 1 || currentInfo().permissionIds?.split(",").indexOf(String(id)) !== -1;
}