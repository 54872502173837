import '@wangeditor/editor/dist/css/style.css';
import "antd/dist/antd.min.css";
import ReactDOM from "react-dom";
import BasicRoute from "./common/route/BasicRoute";
import "./index.css";

ReactDOM.render(
    <BasicRoute />,
  document.getElementById('root')
);

