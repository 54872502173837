import { DouyinParam } from "src/module/douyin/douyin.param ";
import { http } from "../../../common/http";

class DidiAuditService {
	list = async (param: DouyinParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + "/didi",
			params: param,	
		});
	};

	audit = async (data: {id:number,status:string}) => {
		return http({
			method: "PUT",
			url: process.env.REACT_APP_ADMIN_API + `/didi/${data.id}/${data.status}`,
			data:data,
		});
	};

	delete = async (id: number) => {
		return http({
			method: "DELETE",
			url: process.env.REACT_APP_ADMIN_API + "/didi/"+id,
			data:id,
		});
	};
}
export default new DidiAuditService();
