import { http } from "../../../common/http";
import { CouponReceivedEntity } from "../entity/coupon.received.entity ";
import { CouponReceivedParam } from "../param/coupon.received.param";

class CouponReceivedService {
	list = async (param: CouponReceivedParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon/user-coupon-received",
			method: "GET",
			params: param,
		});
	};

	update=async (param: CouponReceivedEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/coupon/give-away",
			method: "POST",
			data: param,
		});

	};
}
export default new CouponReceivedService();
