import {Radio} from "antd";
import {useTranslation} from "react-i18next";
import {PropsEntity} from "../entity/props.entity";

export const OnOff = (props: PropsEntity) => {
    const {t} = useTranslation();

    const options = [
        {label: t("onSale"), value: 1},
        {label: t("off"), value: 2}
    ];

    return (
        <Radio.Group value={props.value} options={options} optionType={"button"} onChange={props.onChange} />
    );
}