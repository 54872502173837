import styled from "@emotion/styled";
import { BorderBox1, Decoration3, FullScreenContainer, ScrollBoard } from '@jiaminghi/data-view-react';
import { message } from 'antd';
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import AdminService from "../../../module/admin/service/admin.service";
import {
    ConnectEquipmentList,
    EquipmentWaterPowerConnectList, EquipmentWaterPowerUseList, MaintainEquipmentList,
    MaintenanceShopList, OrderWashList, OrderWashListInterface
} from "../../../module/screen/entity/warning.entity";
let num: number;

interface ConfigEntity {
    header: string[],
    data: string[][],
    align: string[],
    headerBGC: string,
    oddRowBGC: string,
    evenRowBGC: string,
    rowNum: number,
    hoverPause: boolean,
}

const defaultConfig = {
    header: ['<span style="color:#28B9FF;">站点名称</span>', '<span style="color:#28B9FF;">设备状态</span>'],
    data: [],
    align: ['center', 'center'],
    headerBGC: "#0B2048",
    oddRowBGC: "",
    evenRowBGC: "",
    rowNum: 5,
    hoverPause: false
}

export const Warning: FC = () => {
    const ws = useRef<WebSocket | null>(null);
    // 1设备维护站点
    const [equipmentConfig, setEquipmentConfig] = useState<ConfigEntity>({
        ...defaultConfig
    })
    const [maintainEquipmentList, setMaintainEquipmentList] = useState<MaintainEquipmentList>();
    // 2链接异常站点
    const [abnormalSiteConfig, setAbnormalSiteConfig] = useState<ConfigEntity>({
        ...defaultConfig,
        header: ['<span style="color:#28B9FF;">站点名称</span>', '<span style="color:#28B9FF;">连接异常</span>'],
    })
    const [connectEquipmentList, setConnectEquipmentList] = useState<ConnectEquipmentList>();
    // 3用户下单异常
    const [userOrderAbnormalConfig, setUserOrderAbnormalConfig] = useState<ConfigEntity>({
        ...defaultConfig,
        header: [
            '用户昵称',
            '手机号',
            '站点名称',
            '下单次数'
        ],
        align: ['center', 'center', 'center', 'center'],
        oddRowBGC: "#131E37",
        evenRowBGC: "#083358",
        rowNum: 15,
    })
    const [orderWashListStatus, setOrderWashListStatus] = useState<OrderWashList>();
    // 4站点5S到期
    const [fiveSConfig, setFiveSConfig] = useState<ConfigEntity>({
        ...defaultConfig,
        header: ['<span style="color:#28B9FF;">站点名称</span>', '<span style="color:#28B9FF;">到期日期</span>'],
    })
    const [maintenanceShopList, setMaintenanceShopList] = useState<MaintenanceShopList>();
    // 5水表读数异常
    const [waterAbnormalConfig, setWaterAbnormalConfig] = useState<ConfigEntity>({
        ...defaultConfig,
        header: ['<span style="color:#28B9FF;">站点名称</span>', '<span style="color:#28B9FF;">水表读数状态</span>'],
    })
    const [equipmentWaterPowerConnectList, setEquipmentWaterPowerConnectList] = useState<EquipmentWaterPowerConnectList>();
    // 6电量使用预警
    const [electricityAbnormalConfig, setElectricityAbnormalConfig] = useState<ConfigEntity>({
        ...defaultConfig,
        header: [
            '站点名称',
            '电量(当前/阈值)',
            '水量(当前/阈值)',
        ],
        align: ['center', 'center', 'center'],
        oddRowBGC: "#131E37",
        evenRowBGC: "#083358",
    })
    const [equipmentWaterPowerUseList, setEquipmentWaterPowerUseList] = useState<EquipmentWaterPowerUseList>();
    // 7站点洗车异常
    const [siteCarWashConfig, setSiteCarWashConfig] = useState<ConfigEntity>({
        ...defaultConfig,
        header: [
            '用户名称',
            '手机号',
            '站点名称',
            '订单号',
            '下单日期',
            '洗车状态',
        ],
        align: ['center', 'center', 'center', 'center', 'center', 'center'],
        oddRowBGC: "#131E37",
        evenRowBGC: "#083358",
    })
    const [siteCarWashList, setSiteCarWashList] = useState<OrderWashListInterface>();
    //启动
    const init = useCallback(async () => {
        const token: any = localStorage.getItem("token")
        const userInfo: any = localStorage.getItem("adminInfo")
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_API + '/websocket/warn?account=' + JSON.parse(userInfo).account, [token]);
        ws.current.onmessage = e => {
            const data = JSON.parse(e.data)
            if (!data.data) {
                return
            }
            switch (data.data.type) {
                //设备维护站点
                case 1:
                    setMaintainEquipmentList(data.data)
                    break;
                //连接异常站点
                case 2:
                    setConnectEquipmentList(data.data)
                    break;
                //用户下单异常
                case 3:
                    setOrderWashListStatus(data.data)
                    break;
                // 站点5S到期
                case 4:
                    setMaintenanceShopList(data.data)
                    break;
                // 水电表读数
                case 5:
                    setEquipmentWaterPowerConnectList(data.data)
                    break;
                // 电量使用预警
                case 6:
                    setEquipmentWaterPowerUseList(data.data)
                    break;
                case 7:
                    setSiteCarWashList(data.data)
                    break;
                default:
                    break;
            }
        };
        // 心跳机制
        ws.current.onopen = () => {
            setInterval(() => {
                ws.current?.send("1")
            }, 30000)
        }
        ws.current.onclose = () => {
            num++
            if (num > 2) {
                message.error({
                    content: '请重新登录打开当前页面!',
                    duration: 0
                });
                return;
            }
            setTimeout(() => {
                init()
            }, 30000);
        }
        // 已关闭
        ws.current.onerror = () => {
            ws.current?.close();
        }
        return () => {
            ws.current?.close();
        };
    }, [])
    useLayoutEffect(() => {
        AdminService.currentDetail().then(() => {
            init()
        })
        return () => {
            ws.current?.close();
        };
    }, [ws, init]);

    useEffect(() => {
        document.addEventListener('dblclick', () => {
            const DE = document.documentElement
            DE.requestFullscreen()
        })
    }, [])

    useEffect(() => {
        // 1设备维护站点
        if (maintainEquipmentList && maintainEquipmentList.maintainEquipmentList.length > 0) {
            const data: string[][] = maintainEquipmentList.maintainEquipmentList.map((item) => {
                return [item.shopName ?? "", item.machineStatus === 1 || item.machineStatus === 2 ? '设备维护' : '低温保护'];
            });
            setEquipmentConfig((e) => {
                return {
                    ...e,
                    data
                }
            });
        } else {
            setEquipmentConfig((e) => {
                return {
                    ...e,
                    data: []
                }
            })
        }
        // 2连接异常站点
        if (connectEquipmentList && connectEquipmentList.connectEquipmentList.length > 0) {
            const isPlcConnect = `
                <img style="width:20px;height:20px;margin-right:3px" src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/duankai.png" alt="" />
                <span style="color:#CF363A ;">PLC异常</span>
            `
            const isCameraConnect = `
                <img  style="width:18px;height:21px;margin-right:3px" src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/shexiangtou.png" alt="" />
                <span style="color:#FF6C25 ;">摄像头异常</span>
            `
            const data: string[][] = connectEquipmentList.connectEquipmentList.map((item) => {
                return [
                    `<span style="color:#23CEFD;">${item.shopName}</span>`, `${item.isPlcConnect === 0 ? isPlcConnect : ''}${item.isCameraConnect === 0 ? isCameraConnect : ''}`
                ]
            })
            setAbnormalSiteConfig((e) => {
                return {
                    ...e,
                    data
                }
            })
        } else {
            setAbnormalSiteConfig((e) => {
                return {
                    ...e,
                    data: []
                }
            })
        }
        // 3用户下单异常
        if (orderWashListStatus && orderWashListStatus.orderWashList.length > 0) {
            const data: any[][] = orderWashListStatus.orderWashList.map((item) => {
                return [item.nickName ?? "", item.mobile ?? "", item.shopName ?? "", item.num ?? 0];
            })
            setUserOrderAbnormalConfig((e) => {
                return {
                    ...e,
                    data
                }
            })
        } else {
            setUserOrderAbnormalConfig((e) => {
                return {
                    ...e,
                    data: []
                }
            })
        }
        // 4站点5s到期
        if (maintenanceShopList && maintenanceShopList.maintenanceShopList.length > 0) {

            const data: string[][] = maintenanceShopList.maintenanceShopList.map((item) => {
                return [item.title ?? "", item.maintenanceTime ?? ""];
            })
            setFiveSConfig((e) => {
                return {
                    ...e,
                    data
                }
            })
        } else {
            setFiveSConfig((e) => {
                return {
                    ...e,
                    data: []
                }
            })
        }
        // 5水电表读数
        if (equipmentWaterPowerConnectList && equipmentWaterPowerConnectList.equipmentWaterPowerConnectList.length > 0) {

            const data: string[][] = equipmentWaterPowerConnectList.equipmentWaterPowerConnectList.map((item) => {
                return [item.shopName as string, '异常'];
            })
            setWaterAbnormalConfig((e) => {
                return {
                    ...e,
                    data
                }
            })
        } else {
            setWaterAbnormalConfig((e) => {
                return {
                    ...e,
                    data: []
                }
            })
        }
        // 6电量使用预警
        if (equipmentWaterPowerUseList && equipmentWaterPowerUseList.equipmentWaterPowerUseList.length > 0) {
            const data: string[][] = equipmentWaterPowerUseList.equipmentWaterPowerUseList.map((item) => {
                const powerThreshold = `
                <div>
                    <img style="width:13px;height:23px;margin-right:3px" src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/dianliang.png" alt="" />
                    <span>${item.power}kW·h</span>/<span style="color:#F04F50;">${item.powerThreshold}kW·h</span>
                </div>`
                const waterThreshold = `<div style="display:flex;align-items: center;justify-content: center;">
                    <img style="width:16px;height:21px;margin-right:3px" src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/shuiliang.png" alt="" />
                    <span style="color:#27E9CB;">${item.water}L/</span><span>${item.waterThreshold}L</span>
                </div>`
                return [item.shopName ?? "", powerThreshold, waterThreshold];
            })
            setElectricityAbnormalConfig((e) => {
                return {
                    ...e,
                    data
                }
            })
        } else {
            setElectricityAbnormalConfig((e) => {
                return {
                    ...e,
                    data: []
                }
            })
        }
        // 7 站点洗车异常
        if (siteCarWashList && siteCarWashList.orderWashList.length > 0) {

            const data: string[][] = siteCarWashList.orderWashList.map((item) => {
                return [item.nickName ?? "", item.mobile ?? "", item.shopName ?? "", item.orderNum ?? "", item.payTime ?? "", '异常'];
            })
            setSiteCarWashConfig((e) => {
                return {
                    ...e,
                    data
                }
            })
        } else {
            setSiteCarWashConfig((e) => {
                return {
                    ...e,
                    data: []
                }
            })
        }

    }, [maintainEquipmentList, maintenanceShopList, connectEquipmentList, equipmentWaterPowerConnectList, orderWashListStatus, equipmentWaterPowerUseList, siteCarWashList]);
    return (
        <Container>
            <div className={'warningSon'}>
                <FullScreenContainer>
                    <div className={'headers'} style={{ width: '100%', height: "7%" }}>
                        <div className={'left'}>
                            <img className={'img'}
                                src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/left.png"
                                alt="" />
                            <Decoration3
                                style={{ width: '250px', height: '30px', position: "absolute", top: 0, left: 0 }} />
                        </div>
                        <div className={'center'}>
                            <img className={'img'}
                                src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/center.png"
                                alt="" />
                            <div className={'title'}>
                                Splash睿洗预警数据大屏
                            </div>
                        </div>
                        <div className={'right'}>
                            <img className={'img'}
                                src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/right.png"
                                alt="" />
                            <Decoration3
                                style={{ width: '250px', height: '30px', position: "absolute", top: 0, right: 0 }} />
                        </div>
                    </div>
                    <div className={'content'} style={{ width: '100%', height: "55%", marginTop: '-20px' }}>
                        <div className={'left'}>
                            <div className={'topDiv'}>
                                <div className={'nav'}>
                                    设备维护站点
                                </div>
                                <div className={'equipmentList'}>
                                    <ScrollBoard config={equipmentConfig} style={{ width: '95%', height: '200px' }} />
                                </div>
                            </div>
                            <div className={'bottomDiv'}>
                                <div className={'nav'}>
                                    连接异常站点
                                </div>
                                <div className={'equipmentList'}>
                                    <ScrollBoard config={abnormalSiteConfig} style={{ width: '95%', height: '200px' }} />
                                </div>

                            </div>
                        </div>
                        <div className={'center'}>
                            <BorderBox1 style={{ width: "100%", height: "100%" }}>
                                <div className={'header'}>
                                    <div style={{ width: 100, height: 30 }}> </div>
                                    <div className={'nav'}>
                                        用户下单异常
                                    </div>
                                    <div className={'equipmentList'}>
                                        <ScrollBoard config={userOrderAbnormalConfig}
                                            style={{ width: '90%', height: '436px' }} />
                                    </div>
                                </div>
                            </BorderBox1>
                        </div>
                        <div className={'right'}>
                            <div className={'topDiv'}>
                                <div className={'nav'}>
                                    站点5S到期
                                </div>
                                <div className={'equipmentList'}>
                                    <ScrollBoard config={fiveSConfig} style={{ width: '95%', height: '200px' }} />
                                </div>
                            </div>
                            <div className={'bottomDiv'}>
                                <div className={'nav'}>
                                    水表读数异常
                                </div>
                                <div className={'equipmentList'}>
                                    <ScrollBoard config={waterAbnormalConfig} style={{ width: '95%', height: '200px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'footer'} style={{ width: '100%', height: "30%", marginTop: '20px' }}>
                        <div className={'left'}>
                            <div className={'nav'}>
                                电量使用预警
                            </div>
                            <div className={'equipmentList'}>
                                <ScrollBoard config={electricityAbnormalConfig}
                                    style={{ width: '90%', height: '230px' }} />
                            </div>
                        </div>
                        <div className={'right'}>
                            <div className={'nav'}>
                                站点洗车异常
                            </div>
                            <div className={'equipmentList'}>
                                <ScrollBoard config={siteCarWashConfig} style={{ width: '90%', height: '230px' }} />
                            </div>
                        </div>
                    </div>
                </FullScreenContainer>
            </div>
        </Container>
    )
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/indexBg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;

  .warningSon {
    width: 97%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .headers {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .left {
        width: 27%;
        height: 100%;
        position: absolute;
        top: 10px;
        left: 0;
        z-index: 90;

        .img {
          width: 100%;
          height: 30px;
        }
      }

      .center {
        width: 50%;
        height: 100%;
        position: relative;

        .img {
          width: 100%;
          height: 100px;
        }

        .title {
          position: absolute;
          width: 100%;
          height: 100%;
          text-align: center;
          left: 0;
          top: 0;
          z-index: 90;
          font-size: 30px;
          font-family: PingFang SC,serif;
          font-weight: 600;
          color: #23CEFD;
        }
      }

      .right {
        width: 27%;
        height: 100%;
        position: absolute;
        top: 10px;
        right: 0;
        z-index: 90;

        .img {
          width: 100%;
          height: 30px;
        }
      }
    }

    .content {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 0;

      .left {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        .topDiv {
          width: 100%;
          height: 48%;
          background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/minBk.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .nav {
            width: 183px;
            height: 27px;
            margin-top: 30px;
            margin-left: 13px;
            background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/nav.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            font-family: PingFang SC,serif;
            font-weight: bold;
            color: #23CEFD;
            line-height: 27px;
            padding-left: 20px;
          }

          .equipmentList {
            width: 90%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;

            .row-item {
              border-bottom: 1px dashed #868A93;
            }

            .ceil {
              color: #23CEFD;
            }

            .ceil:last-child {
              color: #FCB223;
            }
          }
        }

        .bottomDiv {
          width: 100%;
          height: 48%;
          margin: 0 auto;
          background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/leftTwo.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .nav {
            width: 183px;
            height: 27px;
            margin-top: 30px;
            margin-left: 13px;
            background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/nav.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #23CEFD;
            line-height: 27px;
            padding-left: 20px;
          }

          .equipmentList {
            width: 90%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;

            .row-item {
              border-bottom: 1px dashed #868A93;
            }
          }
        }
      }

      .center {
        width: 48%;
        height: 95%;
        background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/centerBGImg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .header {
          .nav {
            width: 183px;
            height: 27px;
            background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/nav.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #23CEFD;
            line-height: 27px;
            padding-left: 20px;
            margin-left: 20px;
          }

          .equipmentList {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;

            .header {
              background: linear-gradient(90deg, #006AE1 0%, #16B7FF 100%);
            }

            .ceil {
              color: #23CEFD;
            }

            .ceil:last-child {
              color: #CF363A;
            }
          }
        }
      }

      .right {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        .topDiv {
          width: 100%;
          height: 48%;
          background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/minBk.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .nav {
            width: 183px;
            height: 27px;
            margin-top: 30px;
            margin-left: 13px;
            background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/nav.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #23CEFD;
            line-height: 27px;
            padding-left: 20px;
          }

          .equipmentList {
            width: 90%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;

            .row-item {
              border-bottom: 1px dashed #868A93;
            }

            .ceil {
              color: #23CEFD;
            }

            .ceil:last-child {
              color: #FD895B;
            }
          }
        }

        .bottomDiv {
          width: 100%;
          height: 48%;
          background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/leftTwo.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .nav {
            width: 183px;
            height: 27px;
            margin-top: 30px;
            margin-left: 13px;
            background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/nav.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #23CEFD;
            line-height: 27px;
            padding-left: 20px;
          }

          .equipmentList {
            width: 90%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;

            .row-item {
              border-bottom: 1px dashed #868A93;
            }

            .ceil {
              color: #23CEFD;
            }

            .ceil:last-child {
              color: #CF363A;
            }
          }
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        width: 40%;
        height: 100%;
        background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/leftTwo.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .nav {
          width: 183px;
          height: 27px;
          margin-top: 30px;
          margin-left: 13px;
          background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/nav.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          font-size: 14px;
          font-family: PingFang SC, serif;
          font-weight: bold;
          color: #23CEFD;
          line-height: 27px;
          padding-left: 20px;
        }

        .equipmentList {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 20px;

          .header {
            background: linear-gradient(90deg, #006AE1 0%, #16B7FF 100%);
          }

          .ceil {
            color: #23CEFD;
          }

          .ceil:nth-child(2) {
            color: #27E9CB;
          }

          .ceil:last-child {
            color: #CF363A;
          }
        }
      }

      .right {
        width: 58%;
        height: 100%;
        background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/leftTwo.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .nav {
          width: 183px;
          height: 27px;
          margin-top: 30px;
          margin-left: 13px;
          background-image: url("https://splashprod.oss-cn-shanghai.aliyuncs.com/img/data-center-ts/warning/nav.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          font-size: 14px;
          font-family: PingFang SC, serif;
          font-weight: bold;
          color: #23CEFD;
          line-height: 27px;
          padding-left: 20px;
        }

        .equipmentList {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 20px;

          .header {
            background: linear-gradient(90deg, #006AE1 0%, #16B7FF 100%);
          }

          .ceil {
            color: #23CEFD;
          }

          .ceil:last-child {
            color: #CF363A;
          }
        }
      }
    }
  }
`