import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Image, Input, message, Modal, Space, Table } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { checkPermission } from "src/module/admin/service/permission.service";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { AdminEntity } from "../../module/admin/entity/admin.entity";
import { AdminParams } from "../../module/admin/params/admin.params";
import adminService from "../../module/admin/service/admin.service";
import AdminService from "../../module/admin/service/admin.service";

const { confirm } = Modal;
export const AdminList: FC = () => {
	const [adminList, setAdminList] = useState([] as AdminEntity[]);
	const [searchObj, setSearchObj] = useState<AdminParams>({ customPageSize: 15, customPageNum: 1 });
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({ customPageSize: 15, customPageNum: 1, total: 0 });
	const [total, setTotal] = useState<number>(0);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const { t } = useTranslation();

	const deleteAdmin = (id: number) => {
		confirm({
			title: t("delete"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await adminService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const resetPassword = async (id: number) => {
		confirm({
			title: "确定初始化密码吗？",
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await adminService.resetPassWord(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const columns = [
		{
			title: 'id',
			dataIndex: "id",
			key: "id",
		},
		{
			title: t("admin.name"),
			dataIndex: "name",
			key: "name",
		},
		{
			title: t("mobile"),
			dataIndex: "mobile",
			key: "mobile",
		},
		{
			title: t("superiorName"),
			dataIndex: "parentName",
			key: "parentName",
		},
		// {
		// 	title: t("admin.workStatus"),
		// 	dataIndex: "workStatus",
		// 	render:(text:number)=>{return text===1?t("vacancy"):t("busy")}
		// },

		{
			title: t("admin.shops"),
			key: "shopName",
			dataIndex: "shopName",
			width: 400,
		},

		{
			title: t("appletQcCodeUrl"),
			key: "appletQcCodeUrl",
			dataIndex: "appletQcCodeUrl",
			render: (appletQcCodeUrl: string) => appletQcCodeUrl && <Image width={100} src={appletQcCodeUrl} />,
		},
		{
			title: t("action"),
			key: "action",
			render: (text: string, record: Record<string, any>) => (
				<Space size='middle'>
					{checkPermission(89) && (
						<Button type={"primary"} onClick={() => navigate(`/admin/add/${record.id}`)}>
							{t("modify")}
						</Button>
					)}

					{checkPermission(90) && (
						<Button danger={true} onClick={() => deleteAdmin(record.id)}>
							{t("delete")}
						</Button>
					)}

					{checkPermission(91) && (
						<Button type='primary' danger onClick={() => resetPassword(record.id)}>
							{t("resetPassword")}
						</Button>
					)}

					{checkPermission(89) && (
						<Button type={"primary"} onClick={() => navigate(`/admin/add/${record.id}/1`)}>
							{t("copy")}
						</Button>
					)}
				</Space>
			),
		},
	];

	const init = useCallback(async () => {
		setLoading(true);
		const { data } = await AdminService.list({ ...searchObj });
		if (data.code === 200) {
			setAdminList(data.data);
			setTotal(data.total);
		}
		setLoading(false);
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	let searchArr: AdminParams = {};

	const onChange = (type: string, e: any) => {
		searchArr = {
			...searchArr,
			[type]: e.target.value,
		};
	};

	const search = () => {
		setSearchObj({
			...searchObj,
			...searchArr,
			customPageSize: 15,
			customPageNum: 1,
		});
	};

	return (
		<Container>
			<div>
				<Space>
					<Space>
						{t("admin.name")}: <Input onChange={(e) => onChange("name", e)} />
					</Space>
					<Space>
						{t("mobile")}: <Input onChange={(e) => onChange("mobile", e)} />
					</Space>
					<Space>
						<Button type={"primary"} icon={<SearchOutlined />} onClick={search}>
							{t("search")}
						</Button>
					</Space>
					{checkPermission(88) && (
						<Space>
							<Button type={"primary"} icon={<PlusOutlined />} onClick={() => navigate("/admin/add")}>
								{t("add")}
							</Button>
						</Space>
					)}
				</Space>
			</div>
			<Table
				columns={columns}
				loading={loading}
				dataSource={adminList}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={(record) => record.id + ""}
				onChange={(pagination) => {
					setSearchObj({ ...searchObj, customPageNum: pagination.current, customPageSize: pagination.pageSize });
					
				}}
			/>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	padding: 10px;
`;
