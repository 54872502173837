import { FC, useEffect } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, message, Radio, Select, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { SelectSite } from "../../module/site/component/select.site";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import equipmentsService from "../../module/equipments/service/equipments.service";
import { EquipmentsEntity } from "../../module/equipments/entity/equipments.entity";
const { Option } = Select;
export const EquipmentDetail: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();
	const [form] = useForm();

	useEffect(() => {
		if (params.id) {
			equipmentsService.detail(Number(params.id)).then((res) => {
				const { data } = res;
				if (data.code === 200) {
					form.setFieldsValue(data.data);
				}
			});
		}
	}, [form, params.id]);

	const onFinish = async (value: EquipmentsEntity) => {
		if (!params.id) {
			console.log(value);

			const { data } = await equipmentsService.add({ ...value, autoStatus: value.autoStatus ? 1 : 0 });
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/equipments/list");
			} else {
				message.error(data.msg);
			}
		} else {
			const { data } = await equipmentsService.update({ ...value, id: Number(params.id), autoStatus: value.autoStatus ? 1 : 0 });
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/equipments/list");
			} else {
				message.error(data.msg);
			}
		}
	};

	return (
		<Container>
			<Form style={{ marginTop: 20 }} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }} onFinish={onFinish} form={form}>
				<Form.Item label={t("equipmentName")} name='name'>
					<Input placeholder={t("equipmentName")} allowClear />
				</Form.Item>
				<Form.Item label={t("brand")} name='brand'>
					<Select>
						<Option value={0}>待定</Option>
						<Option value={1}>万洗得</Option>
						<Option value={2}>竹美</Option>
						<Option value={3}>自研</Option>
						<Option value={4}>喜多邦</Option>
						<Option value={5}>仿万洗得</Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("site.name")} name='shopId'>
					<SelectSite />
				</Form.Item>
				<Form.Item label={t("equipmentAdress")} name='address'>
					<Input placeholder={t("equipmentAdress")} allowClear />
				</Form.Item>
				<Form.Item label={t("plcIp")} name='ipAddress'>
					<Input placeholder={t("ipAddress")} allowClear />
				</Form.Item>
				<Form.Item label={t("machineIp")} name='machineIp'>
					<Input placeholder={t("machineIp")} allowClear />
				</Form.Item>
				<Form.Item label={t("cameraIp")} name='cameraToken'>
					<Input placeholder={t("cameraIp")} allowClear />
				</Form.Item>
				<Form.Item label='水循环ip' name='recycleSystemIp'>
					<Input placeholder='水循环ip' allowClear />
				</Form.Item>
				<Form.Item label={t("autoUpdate")} name='autoStatus' valuePropName='checked'>
					<Switch checkedChildren={t("autoUpdateOpen")} unCheckedChildren={t("autoUpdateClose")} />
				</Form.Item>

				<Form.Item label={t("equipmentStatus")} name='status'>
					<Radio.Group>
						<Radio value={1}>{t("enable")}</Radio>
						<Radio value={2}> {t("Disable")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("Restoration")} name='isRestoration'>
					<Radio.Group>
						<Radio value={1}>{t("yes")}</Radio>
						<Radio value={0}> {t("no")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("MonitorWaterPower")} name='isMonitorWaterPower'>
					<Radio.Group>
						<Radio value={1}>{t("enable")}</Radio>
						<Radio value={0}> {t("Disable")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("MonitorWaterCycle")} name='isMonitorWaterCycle'>
					<Radio.Group>
						<Radio value={1}>{t("enable")}</Radio>
						<Radio value={0}> {t("Disable")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("waterThreshold")} name='waterThreshold'>
					<Input placeholder={t("waterThreshold")} allowClear />
				</Form.Item>
				<Form.Item label={t("powerThreshold")} name='powerThreshold'>
					<Input placeholder={t("powerThreshold")} allowClear />
				</Form.Item>
				<Form.Item label={t("isLog")} name='isLog'>
					<Radio.Group>
						<Radio value={1}>{t("yes")}</Radio>
						<Radio value={0}> {t("no")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("MonitorPlc")} name='isMonitorPlc'>
					<Radio.Group>
						<Radio value={1}>{t("enable")}</Radio>
						<Radio value={0}> {t("Disable")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("MonitorCamera")} name='isMonitorCamera'>
					<Radio.Group>
						<Radio value={1}>{t("enable")}</Radio>
						<Radio value={0}> {t("Disable")}</Radio>
					</Radio.Group>
				</Form.Item>

				<Form.Item wrapperCol={{ span: 8, offset: 8 }}>
					<Button type='primary' htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
