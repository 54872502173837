import { http } from "../../../common/http";
import { ProductParam } from "../param/product.param";
import { ProductEntity } from "../entity/product.entity";

class ProductService {
	all = async (params: ProductParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + `/product/all`,
			params,
		});
	};

	list = async (params: ProductParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + `/product`,
			params,
		});
	};

	export = (params: ProductParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + `/product/export`,
			params,
			responseType: "blob",
		});
	};

	exportSku = (params: ProductParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + `/product/export/sku`,
			params,
			responseType: "blob",
		});
	};


	add = async (data: ProductEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/product",
			method: "POST",
			data,
		});
	};

	upDown = async (data: ProductEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/product/upDown/${data.id}`,
			method: "PUT",
			data,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/product/${id}`,
			method: "GET",
		});
	};

	update = async (data: ProductEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/product`,
			method: "PUT",
			data,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/product/` + id,
			method: "DELETE",
		});
	};

	getProductEntityByValue = (value = { shopId: "", shopName: "", productId: "", productName: "" }): ProductEntity[] => {
		if (value.productId === "") {
			return [];
		}

		const shopIdArr = value.shopId.split(",");
		const shopNameArr = value.shopName.split(",");
		const productIdArr = value.productId.split(",");
		const productNameArr = value.productName.split(",");

		const productArr: ProductEntity[] = [];
		shopIdArr.length > 0 &&
			shopIdArr.forEach((item, index) => {
				const product: ProductEntity = {};
				product.shopId = Number(item);
				product.shopName = shopNameArr[index];
				product.id = Number(productIdArr[index]);
				product.name = productNameArr[index];

				productArr.push(product);
			});

		return productArr;
	};
}

export default new ProductService();
