import { useCallback, useState, useEffect } from "react";
import { Select } from "antd";
import { Props } from "../../../common/entity/props.entity";
import { t } from "i18next";
import AdminService from "../service/admin.service";
import { AdminEntity } from "../entity/admin.entity";

const { Option } = Select;

export const MultiSelectAdmin = (props: Props & {shopId?: number}) => {
	const [dataArr, setDataArr] = useState<AdminEntity[]>([]);
	const [data, setData] = useState<number[]>([]);

	const init = useCallback(async () => {
		const params = props.shopId ? {shopId: props.shopId} : {};
		const res = await AdminService.noAuth(params);
		setDataArr(res.data.data);
		setData(props.value?.split(",").map((item: string) => Number(item)));
	}, [props.shopId, props.value]);

	const changeVal = (e: number[]) => {
		setData(e);
		props.onChange && props.onChange(e.join(","));
	};

	useEffect(() => {
		init();
	}, [init]);

	return (
		<>
			<Select value={data} onChange={changeVal} allowClear mode='multiple' placeholder={t("pleaseSelectAdmin")} style={{ width: 450 }}>
				{dataArr &&
					dataArr.map((item) => {
						return (
							<Option key={item.id} value={item.id}>
								{item.name}
							</Option>
						);
					})}
			</Select>
		</>
	);
};
