import styled from "@emotion/styled";
import { Select } from "antd";
import { t } from "i18next";
import { disCountArr } from "src/module/finance/enum/discount.way.enum";
import { Props } from "../../../common/entity/props.entity";

export const SelectDiscountType = (props: Props) => {
	const { Option } = Select;
	return (
		<Container>
			<Select
				placeholder={t("discountType")}
				onChange={props.onChange}
				value={props.value}
				allowClear
			>
				{Object.keys(disCountArr).map((item) => {
					return (
						<Option key={item} value={item}>
							{t(disCountArr[Number(item)])}
						</Option>
					);
				})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
