/*
 * @Author: Yujia fyujia0815@gmail.com
 * @Date: 2022-11-23 10:58:43
 * @LastEditors: Yujia fyujia0815@gmail.com
 * @LastEditTime: 2022-12-01 10:16:30
 * @FilePath: \data-center-ts\src\module\site\component\select.site.tsx
 */
import styled from "@emotion/styled";
import { Select } from "antd";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PropsEntity } from "../../../common/entity/props.entity";
import { ResponseEntity } from "../../../common/entity/response.entity";
import { SiteEntity } from "../entity/site.entity";
import SiteService from "../service/site.service";

const { Option } = Select;

interface InternalProp extends PropsEntity {
	selected?: any;
	level?: number;
	isMultiple?: boolean;
	shopId?: string;
	labelInValue?: boolean;
	//显示省市区名称的适合用，一般不用（dashBoard 中省市区的标签）
	labelName?: any;
	//
	className?: string
}

export const SelectSite = (props: InternalProp) => {
	const [arr, setArr] = useState<SiteEntity[]>([]);
	const [multipleObj, setMultipleObj] = useState({});
	const { t, i18n } = useTranslation();

	let selectedArr: string[] = [];
	if (props.selected && typeof props.selected === "string") {
		selectedArr = props.selected?.split(",");
	}

	const onChange = (value: number) => {
		if (!value) {
			props.onChange && props.onChange(void 0);
			return;
		}
		// if (selectedArr.includes(value.toString())) return;
		props.onChange && props.onChange(value);

		arr.forEach((row) => {
			if (row.id === value) {
				props.entityChange && props.entityChange(row);
			}
		});
	};
	useEffect(() => {
		if (props.isMultiple) {
			setMultipleObj({
				mode: "multiple",
				labelInValue: true,
			});
			return;
		}
	}, [props.isMultiple]);

	const list = useCallback(async () => {
		const { data }: AxiosResponse<ResponseEntity> = await SiteService.all({ level: props.level ? props.level : 2, shopIds: props.shopId });
		if (data.code === 200) {
			setArr(data.data);
		}
	}, [props.level, props.shopId]);

	useEffect(() => {
		list();
	}, [list]);

	return (
		<Container style={{ maxWidth: props.isMultiple ? "none" : "300px" }} className={props.className || ''}>
			<Select
				showSearch
				style={{ width: "100%" }}
				placeholder={t("please.select") + t("site")}
				onChange={onChange}
				value={props.value}
				allowClear
				labelInValue={props.labelInValue}
				optionFilterProp={"children"}
				{...multipleObj}
			>
				{arr &&
					arr.map((item, index: number) => {
						return (
							<Option
								value={item.id}
								key={index}
								style={{
									color: selectedArr.indexOf(item.id + "") > -1 && "#40a9ff",
									fontWeight: selectedArr.indexOf(item.id + "") > -1 && 600,
								}}
							>
								{i18n.language === "zh-CN" ? item.title : item.shopNumber}
							</Option>
						);
					})}
				;
			</Select>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
`;
