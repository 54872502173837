import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";

const { Option } = Select;

export const SingleSelectPayWay = (props: Props) => {
	const { t } = useTranslation();
	return (
		<div>
			<Select style={{ width: 200 }} onChange={props.onChange} allowClear placeholder={t("payWay")}>
				<Option value='0'>{t("undefined")}</Option>
				<Option value='10'>{t("balance")}</Option>
				<Option value='30'>{t("vipCard")}</Option>
				<Option value='50'>{t("freeCoupon")}</Option>
				<Option value='54'>{t("priceOffCoupon")}</Option>
				<Option value='56'>{t("discountCoupon")}</Option>
				<Option value='60'>{t("fixedPriceCoupon")}</Option>
				<Option value='70'>{t("Alipay")}</Option>
				<Option value='80'>{t("weChat")}</Option>
				<Option value='90'>{t("Cash")}</Option>
				<Option value='100'>{t("cooperator")}</Option>
				<Option value='110'>{t("offlineWechat")}</Option>
				<Option value='120'>{t("offlineAliPay")}</Option>
				<Option value='130'>{t("thirdPartener")}</Option>
				<Option value='140'>{t("partner")}</Option>
				<Option value='150'>{t("pointsRedemption")}</Option>
				<Option value='160'>{t("meiTuan")}</Option>
				<Option value='170'>{t("douYin")}</Option>
				<Option value='180'>{t("CCB")}</Option>
			</Select>
		</div>
	);
};
