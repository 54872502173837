import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import Util from "../../common/Util";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { ThirdOrderParam } from "src/module/thirdOrder/param/third.order.param";
import thirdOrderService from "src/module/thirdOrder/service/third.order.service";
import { ThirdOrderEntity } from "src/module/thirdOrder/entity/third.order.entity";
import moment from "moment";
import { MultiSelectSite } from "src/module/site/component/multi.select.site";
const { RangePicker } = DatePicker;
const { Option } = Select;

export const ThirdOrderList: FC = () => {
	const { confirm } = Modal;
	const { t } = useTranslation();
	const [dataList, setDataList] = useState([]);
	const [searchObj, setSearchObj] = useState<ThirdOrderParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageNum: 1, customPageSize: 15
	});
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({ customPageNum: 1, customPageSize: 15 });
	const [total, setTotal] = useState(0);
	const search = (value: any) => {
		setSearchObj({
			...value,
			creatTime: undefined,
			startTime: value.creatTime && value.creatTime[0] && value.creatTime[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.creatTime && value.creatTime[1] && value.creatTime[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageNum: 1, customPageSize: 15 
		});
		console.log(searchObj);
	};

	const init = useCallback(async () => {
		const { data } = await thirdOrderService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await thirdOrderService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const downLoad = async () => {
		const { data } = await thirdOrderService.export(searchObj);
		const blob = new Blob([data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "thirdOrderList");
		message.success(t("export.success"));
	};

	const columns = [
		{
			title: t("thirdOrderNum"),
			dataIndex: "thirdOrderNum",
		},
		{
			title: t("license"),
			dataIndex: "license",
		},
		{
			title: t("originPrice"),
			dataIndex: "originalPrice",
		},
		{
			title: t("discount"),
			dataIndex: "originalDiscount",
		},

		{
			title: t("netTotal"),
			render: (record: any) => {
				return (record.originalPrice * 10 - record.originalDiscount * 10) / 10;
			},
		},

		{
			title: t("constructionTime"),
			render: (record: ThirdOrderEntity) => {
				return moment(record.startTime).format("YYYY-MM-DD HH:mm:ss") + " — " + moment(record.completeTime).format("YYYY-MM-DD HH:mm:ss");
			},
		},

		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Button
						type='primary'
						onClick={() => {
							handleDelete(record.id);
						}}
					>
						{t("delete")}
					</Button>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' style={{ marginBottom: 20 }} onFinish={search} initialValues={{ creatTime: [moment(), moment()] }}>
				<Row gutter={[30, 20]}>
					<Col span={8}>
						<Form.Item label={t("orderNum")} name='orderNum'>
							<Input placeholder={t("orderNum")} allowClear></Input>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("period")} name='creatTime'>
							<RangePicker />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("source")} name='source'>
							<Select placeholder={t("source")} allowClear>
								<Option value={1}> kaka </Option>
								<Option value={2}> 易捷 </Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("site.name")} name='shopIds'>
							<MultiSelectSite />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item>
							<Space>
								<Button htmlType='submit' type='primary'>
									{t("search")}
								</Button>
								<Button type='primary' onClick={downLoad}>
									{t("export")}
								</Button>
							</Space>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record: any) => record.id + ""}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({ ...searchObj, customPageNum: pagination.current, customPageSize: pagination.pageSize });
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
