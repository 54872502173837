import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "@emotion/styled";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Tabs,
    TimePicker
} from "antd";
import {UploadImg} from "../../common/components/UploadImg";
import {useTranslation} from "react-i18next";
import MyEditor from "../../common/components/MyEditor";
import productService from "../../module/product/service/product.service";
import {useNavigate, useParams} from "react-router-dom";
import {SelectSite} from "../../module/site/component/select.site";
import {SelectProductType} from "../../module/product/component/select.product.type";
import {useForm} from "antd/es/form/Form";

import moment from "moment";
import {DiffrentTimePrice} from "src/module/site/component/diffrentTimePrice";
import {skuGroupsEntity, skusEntity, skuTypesEntity} from "src/module/product/entity/product.entity";
import {MinusCircleOutlined} from "@ant-design/icons";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

export const ProductDetail: FC = () => {
    const {Option} = Select;
    const navigate = useNavigate();
    const {t} = useTranslation();
    const params = useParams();
    const [form] = useForm();
    const [editorLoading, setEditorLoading] = useState<boolean>(false);
    const [skuInfo, setSkuInfo] = useState<skuGroupsEntity[]>([]);
    const {confirm} = Modal;
    const [unSelectImgModal, setUnSelectImgModal] = useState(false);
    const [img, setImg] = useState<string | undefined>();
    const addSkuClass = () => {
        if (skuInfo.length) {
            console.log(skuInfo.length)
            const newSkuGroupList = skuInfo.map((ele, index) => {
                if (index == activeKey) {
                    ele.skuTypes = [
                        ...ele.skuTypes,
                        {
                            title: "",
                            sort: undefined,
                        },
                    ]
                }
                return ele;
            })
            setSkuInfo(newSkuGroupList);
        } else {
            console.log('aaa')
            setSkuInfo([
                {
                    title: "",
                    price: 0,
                    sort: 1,
                    skuTypes: [
                        {
                            title: "",
                            sort: undefined,
                        },
                    ],
                }
            ]);
        }
    };

    const changeClass = (type: keyof skuTypesEntity, classIndex: number) => {
        return (e: any) => {
            console.log(e);
            const newArr = skuInfo[activeKey].skuTypes.map((item, index) => {
                if (index === classIndex) {

                    item[type] = type === "sort" ? e : e.target.value;


                }
                return item;
            });
            const newSkuGroupList = skuInfo.map((ele, index) => {
                if (index == activeKey) {
                    ele.skuTypes = newArr;
                }
                return ele;
            })
            setSkuInfo(newSkuGroupList);
        };
    };

    const changeSelect = (type: keyof skusEntity, classIndex: number, selectIndex: number) => {
        //换图片的时候没有e
        return (e: any) => {
            console.log(e);

            const newArr = skuInfo[activeKey].skuTypes.map((item, index) => {
                if (index === classIndex) {
                    item.skus?.map((selectItem, index2) => {
                        if (index2 === selectIndex) {
                            if (e == undefined) {
                                type === "img" ? (selectItem.img = img) : (selectItem.selectedImg = img);
                            } else {
                                selectItem[type] = type === "title" || type === "basicOption" || type === "financeCode" || type === "remark" ? e.target.value : e;
                            }
                        }

                        return selectItem;
                    });
                }
                return item;
            });
            const newSkuGroupList = skuInfo.map((ele, index) => {
                if (index == activeKey) {
                    ele.skuTypes = newArr;
                }
                return ele;
            })
            setSkuInfo(newSkuGroupList);
        };
    };

    const changeGroupInfo = (type: keyof skuGroupsEntity) => {
        return (e: any) => {
            console.log(e, activeKey);

            const newSkuGroupList = skuInfo.map((ele, index) => {
                if (index == activeKey) {
                    console.log(ele, type)
                    const stringTypeLabelName = ["title", "financeCode", "remark"];
                    ele = {
                        ...ele,
                        [type]: stringTypeLabelName.includes(type) ? e.target.value : e,
                    };
                }
                return ele;
            })
            setSkuInfo(newSkuGroupList);

        }
    }

    const [activeKey, setActiveKey] = useState<number>(0);


    const getSkuContent = (key?: number) => {
        if (key !== void 0) {
            const groupItem = skuInfo[key];
            if (!groupItem) return (<></>);
            console.log(skuInfo, key)
            return (
                <>
                    <div className='mainSkuContainer'>
                        <Row gutter={[16, 2]} style={{marginBottom: "16px"}}>
                            <Col span={1}></Col>
                            <Col span={4} className="label">
                                {t("typeName")}
                            </Col>
                            <Col span={2} className="label">
                                {t("sort")}
                            </Col>
                            <Col span={2} className="label">
                                {t("price")}
                            </Col>
                            <Col span={4} className="label">
                                {t("financeCode")}
                            </Col>
                            <Col span={3} className="label">
                                {t("skuRemark")}
                            </Col>
                            <Col span={2} className="label">
                                {t("salesPerformance")}
                            </Col>
                            <Col span={2} className="label">
                                {t("constrPerformance")}
                            </Col>
                            <Col span={3} className="label">
                                {t("freeConstructionMoney")}
                            </Col>
                            {/*<Col span={3.5} className="label">*/}
                            {/*    {t("basicOption")}*/}
                            {/*</Col>*/}
                        </Row>
                        <Row gutter={[16, 2]}>
                            <Col span={1}></Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Input value={skuInfo[activeKey]?.title} onChange={changeGroupInfo('title')}/>
                            </Col>
                            <Col span={2}>
                                <InputNumber min={0} value={skuInfo[activeKey]?.sort}
                                             onChange={changeGroupInfo('sort')}
                                             style={{width: "100%"}}
                                />
                            </Col>
                            <Col span={2}>
                                <InputNumber min={0} value={skuInfo[activeKey]?.price}
                                             onChange={changeGroupInfo('price')}
                                             style={{width: "100%"}}
                                />
                            </Col>
                            <Col span={4}>
                                <Input value={skuInfo[activeKey]?.financeCode}
                                       onChange={changeGroupInfo('financeCode')}
                                />
                            </Col>
                            <Col span={3}>
                                <Input value={skuInfo[activeKey]?.remark} onChange={changeGroupInfo('remark')}/>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <InputNumber min={0} value={skuInfo[activeKey]?.saleMoney}
                                             onChange={changeGroupInfo('saleMoney')}
                                             style={{width: "100%"}}
                                />
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <InputNumber min={0} value={skuInfo[activeKey]?.constructionMoney}
                                             onChange={changeGroupInfo('constructionMoney')}
                                             style={{width: "100%"}}
                                />
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <InputNumber min={0} value={skuInfo[activeKey]?.freeConstructionMoney}
                                             onChange={changeGroupInfo('freeConstructionMoney')}
                                             style={{width: "100%"}}
                                />
                            </Col>
                        </Row>
                    </div>
                    {skuInfo[key].skuTypes?.map((item, index) => {
                        return (
                            <div className='skuContainer' key={index}>
                                <Row gutter={[16, 2]}>
                                    <Col span={2}>
                                        <div style={{
                                            height: "32px",
                                            lineHeight: "32px",
                                            fontSize: "16px",
                                            textAlign: "right",
                                            fontWeight: "550"
                                        }}>SKU组{index + 1}</div>
                                    </Col>
                                    <Col span={3}>
                                        <Input value={item?.title} onChange={changeClass("title", index)}/>
                                    </Col>
                                    <Col span={2.5}>
                                        <Form.Item label={t("sort")}>
                                            <InputNumber min={0} value={item?.sort}
                                                         onChange={changeClass("sort", index)}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3.5}>
                                        <Form.Item label={t("isMultiple")}>
                                            <Radio.Group value={item?.multiple}
                                                         onChange={changeClass("multiple", index)}>
                                                <Radio value={0}>{t("yes")}</Radio>
                                                <Radio value={1}>{t("no")}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Button
                                            onClick={() => {
                                                deleteClass(index);
                                            }}
                                            type='primary'
                                            danger
                                        >
                                            {t("deleteAttribute")}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row style={{paddingLeft: "20px", boxSizing: "border-box"}}>
                                    <Divider className="divider" dashed></Divider>
                                </Row>
                                <Row gutter={[16, 2]} style={{marginBottom: "16px"}}>
                                    <Col span={1}></Col>
                                    <Col span={4} className="label">
                                        {t("name")}
                                    </Col>
                                    <Col span={2} className="label">
                                        {t("sort")}
                                    </Col>
                                    <Col span={2} className="label">
                                        {t("price")}
                                    </Col>
                                    <Col span={4} className="label">
                                        {t("financeCode")}
                                    </Col>
                                    <Col span={3} className="label">
                                        {t("skuRemark")}
                                    </Col>
                                    <Col span={2} className="label">
                                        {t("salesPerformance")}
                                    </Col>
                                    <Col span={2} className="label">
                                        {t("constrPerformance")}
                                    </Col>
                                    <Col span={3} className="label">
                                        {t("freeConstructionMoney")}
                                    </Col>
                                    {/*<Col span={3.5} className="label">*/}
                                    {/*    {t("basicOption")}*/}
                                    {/*</Col>*/}
                                </Row>
                                {item?.skus?.map((skuItem, index1) => {
                                    return (
                                        <div key={index1}>
                                            <Row gutter={[16, 2]}>
                                                <Col span={1}>
                                                    <div className='flexBox_item1'>
                                                        <div style={{
                                                            height: "32px",
                                                            lineHeight: "32px",
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center"
                                                        }}>
                                                            <MinusCircleOutlined
                                                                style={{color: "red", fontSize: "20px"}}
                                                                onClick={() => {
                                                                    deleteSelect(index, index1);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Input value={skuItem?.title} placeholder="请输入SKU名称"
                                                           onChange={changeSelect("title", index, index1)}/>
                                                </Col>
                                                <Col span={2}>
                                                    <Form.Item>
                                                        <InputNumber min={0} value={skuItem?.sort}
                                                                     style={{width: "100%"}}
                                                                     onChange={changeSelect("sort", index, index1)}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Form.Item>
                                                        <InputNumber min={0} value={skuItem?.price}
                                                                     style={{width: "100%"}}
                                                                     onChange={changeSelect("price", index, index1)}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item>
                                                        <Input value={skuItem?.financeCode}
                                                               onChange={changeSelect("financeCode", index, index1)}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3}>
                                                    <Form.Item>
                                                        <Input value={skuItem?.remark}
                                                               onChange={changeSelect("remark", index, index1)}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Form.Item>
                                                        <InputNumber min={0} value={skuItem?.saleMoney}
                                                                     style={{width: "100%"}}
                                                                     onChange={changeSelect("saleMoney", index, index1)}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <Form.Item>
                                                        <InputNumber min={0} value={skuItem?.constructionMoney}
                                                                     style={{width: "100%"}}
                                                                     onChange={changeSelect("constructionMoney", index, index1)}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3}>
                                                    <Form.Item>
                                                        <InputNumber min={0} value={skuItem?.freeConstructionMoney}
                                                                     style={{width: "100%"}}
                                                                     onChange={changeSelect("freeConstructionMoney", index, index1)}/>
                                                    </Form.Item>
                                                </Col>
                                                {/*<Col span={3.5}>*/}
                                                {/*    <Form.Item>*/}
                                                {/*        <Radio.Group value={skuItem?.basicOption}*/}
                                                {/*                     onChange={changeSelect("basicOption", index, index1)}>*/}
                                                {/*            <Radio value={1}>{t("yes")}</Radio>*/}
                                                {/*            <Radio value={0}>{t("no")}</Radio>*/}
                                                {/*        </Radio.Group>*/}
                                                {/*    </Form.Item>*/}
                                                {/*</Col>*/}
                                            </Row>
                                        </div>
                                    );
                                })}
                                <div className='button'>
                                    <Button
                                        type='primary'
                                        onClick={() => {
                                            addSkuSelect(index);
                                        }}
                                    >
                                        {t("addOption")}
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                    <Button type='primary' block onClick={addSkuClass}>
                        {t('addSkuGroup')}
                    </Button>
                </>
            )
        } else {
            return (
                <>
                    <div className='mainSkuContainer'>
                        <Row gutter={[16, 2]}>
                            <Col span={1}></Col>
                            <Col span={3}>
                                <Form.Item label={t("typeName")}>
                                    <Input value={skuInfo[activeKey]?.title} onChange={changeGroupInfo('title')}/>
                                </Form.Item>
                            </Col>
                            <Col span={3.5}>
                                <Form.Item label={t("price")}>
                                    <InputNumber min={0} value={skuInfo[activeKey]?.price}
                                                 onChange={changeGroupInfo('price')}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={t("financeCode")}>
                                    <Input value=""
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item label={t("salesPerformance")}>
                                    <InputNumber min={0} value={0}
                                                 style={{width: "100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item label={t("constrPerformance")}>
                                    <InputNumber min={0} value={0}
                                                 style={{width: "100%"}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item label={t("freeConstructionMoney")}>
                                    <InputNumber min={0} value={0}
                                                 style={{width: "100%"}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <Button type='primary' block onClick={addSkuClass}>
                        {t('addSkuGroup')}
                    </Button>
                </>
            );
        }
    };

    const initialItems = useMemo(() => {
        return [
            {label: '类1', children: (<></>), key: '0'},
        ];
    }, []);
    const [items, setItems] = useState(initialItems);


    const init = useCallback(async () => {
        if (params.id) {
            const {data} = await productService.detail(Number(params.id));
            if (data.code === 200) {
                setSkuInfo(data.data.skuGroups);
                const itemsArr = data.data.skuGroups.map((ele: skuGroupsEntity, index: number) => {
                    return {
                        label: '类' + (index + 1),
                        children: (<></>),
                        key: index + '',
                    };
                })
                setItems(itemsArr);
                form.setFieldsValue({
                    ...data.data,
                    time: [moment(data.data.businessHoursStart, "HH:mm:ss"), moment(data.data.businessHoursEnd, "HH:mm:ss")],
                });

                setEditorLoading(true);
            }
        } else {
            setEditorLoading(true);
            setSkuInfo([
                {
                    title: "",
                    price: 0,
                    sort: 1,
                    skuTypes: [],
                }
            ]);
        }
    }, [form, params.id]);

    useEffect(() => {
        init();
    }, [init]);

    useEffect(() => {
        console.log(skuInfo);
        if (skuInfo.length === 0) {
            setItems(initialItems);
        } else {
            const itemsArr = skuInfo.map((ele, index) => {
                return {
                    label: '类' + (index + 1),
                    children: (<></>),
                    key: index + '',
                };
            })
            setItems(itemsArr);
        }
    }, [initialItems, skuInfo]);

    const onFinish = async (value: any) => {
        if (!params.id || params.copy) {
            const {data} = await productService.add({
                ...value,
                time: undefined,
                businessHoursStart: value.time[0] && value.time[0].format("HH:mm:ss"),
                businessHoursEnd: value.time[1] && value.time[1].format("HH:mm:ss"),
                thumb: value.thumb || 'https://splashprod.oss-cn-shanghai.aliyuncs.com/images/2024-02-06/1707199339.png',
                skuGroups: skuInfo,
            });
            if (data.code === 200) {
                message.success(t("success"));
                navigate("/site/product");
            } else {
                message.error(data.msg);
            }
        } else {
            const {data} = await productService.update({
                ...value,
                id: params.id,
                time: undefined,
                businessHoursStart: value.time[0] && value.time[0].format("HH:mm:ss"),
                businessHoursEnd: value.time[1] && value.time[1].format("HH:mm:ss"),
                skuGroups: skuInfo,
            });
            if (data.code === 200) {
                message.success(t("success"));
                navigate("/site/product");
            } else {
                message.error(data.msg);
            }
        }
    };

    const addSkuSelect = (data: number) => {
        const newArr = skuInfo[activeKey].skuTypes.map((item, index) => {
            if (index == data) {
                if (!item.skus) {
                    item.skus = [{title: "", img: "", price: void 0, selectedImg: "", sort: void 0}];
                } else {
                    const a = item.skus as skusEntity[];
                    item.skus = [...a, {title: "", img: "", price: void 0, selectedImg: "", sort: void 0}];
                }
            }
            return item;
        });
        const newSkuGroupList = skuInfo.map((ele, index) => {
            if (index == activeKey) {
                ele.skuTypes = newArr;
            }
            return ele;
        })
        setSkuInfo(newSkuGroupList);
    };


    const deleteClass = (index: number) => {
        confirm({
            title: t("delete"),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                skuInfo[activeKey].skuTypes.splice(index, 1);
                setSkuInfo([...skuInfo]);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const deleteSelect = (classIndex: number, selectIndex: number) => {
        confirm({
            title: t("delete"),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => {
                const newArr = skuInfo[activeKey].skuTypes.map((item, index) => {
                    if (index === classIndex) {
                        item.skus?.splice(selectIndex, 1);
                    }
                    return item;
                });
                const newSkuGroupList = skuInfo.map((ele, index) => {
                    if (index == activeKey) {
                        ele.skuTypes = newArr;
                    }
                    return ele;
                })
                setSkuInfo(newSkuGroupList);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const changeImg = (e: string) => {
        setImg(e);
    };

    const handleOk = () => {
        //改变展示的小图片，undefined是changeSelect函数的e

        // color == "dark"
        // 	? changeSelect("img", Number(typeIndex), Number(itemIndex))(undefined)
        // 	: changeSelect("selectedImg", Number(typeIndex), Number(itemIndex))(undefined);

        setUnSelectImgModal(false);
    };
    const handleCancel = () => {
        setUnSelectImgModal(false);
    };


    const onChangeTab = (newActiveKey: string) => {
        setActiveKey(Number(newActiveKey));
        const itemsArr = skuInfo.map((ele, index) => {
            return {
                label: '类' + (index + 1),
                children: (<></>),
                key: index + '',
            };
        })
        setItems(itemsArr);
    }

    const onEditTab = (
        targetKey: React.MouseEvent | React.KeyboardEvent | string,
        action: 'add' | 'remove'
    ) => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };

    const add = () => {
        const newActiveKey = items.length;
        const newPanes = [...items];
        newPanes.push({
            label: '类' + (newActiveKey + 1),
            children: (<></>),
            key: newActiveKey + '',
        });
        setItems(newPanes);
        const skuGroupEle = skuInfo.find((_, index) => index === activeKey);
        skuGroupEle?.skuTypes.forEach(skuType => {
            skuType.id = undefined;
            skuType.skus?.forEach(sku => sku.id = undefined);
        });
        if (skuGroupEle) setSkuInfo([
            ...skuInfo,
            JSON.parse(JSON.stringify({...skuGroupEle, title: '', id: undefined}))
        ]);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: TargetKey) => {
        // 只剩一个的时候不删除
        if (skuInfo.length === 1) return;
        console.log(targetKey);
        const newSkuGroups = skuInfo.filter((_, index) => index !== Number(targetKey));
        setSkuInfo(newSkuGroups);
        setActiveKey(0);
    };

    return (
        <Container>
            <Form labelCol={{span: 2}} wrapperCol={{span: 22}} style={{padding: 20}} onFinish={onFinish} form={form}>
                <Form.Item label={t("image")} name='thumb' required>
                    <UploadImg/>
                </Form.Item>
                <Form.Item label={t("bigImage")} name='bigImageUrl' required>
                    <UploadImg size={"1125*840"}/>
                </Form.Item>
                <Form.Item label={t("productName")} name='name' required>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("price")} name='price' required>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("originPrice")} name='originalPrice' required>
                    <Input/>
                </Form.Item>

                <Form.Item label={t("sku")} name='sku' required>
                    <Tabs
                        type="editable-card"
                        onChange={onChangeTab}
                        activeKey={String(activeKey)}
                        onEdit={onEditTab}
                        items={items}
                    />
                    {getSkuContent(activeKey)}
                </Form.Item>

                <Form.Item label={t("relatedInstruction")} name='instruction'>
                    <Select>
                        <Option value={0}>无指令</Option>
                        <Option value={1}>快洁洗/基础洗</Option>
                        <Option value={2}>雨蜡洗/洁净洗</Option>
                        <Option value={3}>炫亮洗/闪亮洗</Option>
                        <Option value={4}>至尊洗</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={t("constructionMoney")} name='constructionMoney'>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("freeConstructionMoney")} name='freeConstructionMoney'>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("differentTimePrice")} name='productPrices'>
                    <DiffrentTimePrice/>
                </Form.Item>
                <Form.Item label={t("saleMoney")} name='saleMoney'>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("promoteMoney")} name='promoteMoney'>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("product.type")} name='typeId' required>
                    <SelectProductType/>
                </Form.Item>
                <Form.Item label={t("status")} name='upDown' required>
                    <Radio.Group>
                        <Radio value={1}>{t("onSale")}</Radio>
                        <Radio value={2}>{t("off")}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={t("saleMethod")} name='saleMethod' required>
                    <Radio.Group>
                        <Radio value={1}>{t("selfOrder")}</Radio>
                        <Radio value={2}>{t("instoreConsultation")}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={t("showInClient")} name='showInClient' required>
                    <Radio.Group>
                        <Radio value={1}>{t("onShow")}</Radio>
                        <Radio value={2}>{t("showMore")}</Radio>
                        <Radio value={3}>{t("noShow")}</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label={t("shopName")} name='shopId' required>
                    <SelectSite/>
                </Form.Item>
                <Form.Item label={t("businessHours")} name='time' required>
                    <TimePicker.RangePicker/>
                </Form.Item>
                <Form.Item label={t("sort")} name='sort'>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("productNum")} name='productNum'>
                    <Input/>
                </Form.Item>
                <Form.Item label={t("Brief") + "（限2行）"} name={editorLoading ? "description" : void 0}>
                    <Input.TextArea rows={4} showCount maxLength={30}/>
                </Form.Item>
                <Form.Item label={t("productDetails")} name={editorLoading ? "content" : void 0}>
                    {editorLoading && <MyEditor/>}
                </Form.Item>
                <Form.Item wrapperCol={{span: 12, offset: 4}}>
                    <Button type='primary' htmlType='submit'>
                        {t("submit")}
                    </Button>
                </Form.Item>
            </Form>
            <Modal title='上传图片' visible={unSelectImgModal} onOk={handleOk} onCancel={handleCancel}>
                <Form.Item label={t("title")}>
                    <UploadImg
                        value={img}
                        size={"210*138"}
                        onChange={(e: string) => {
                            changeImg(e);
                        }}
                    />
                </Form.Item>
            </Modal>
        </Container>
    );
};
const Container = styled.div`
  width: 100%;

  .mainSkuContainer {
    background-color: #f9f9f9;
    padding: 20px;
    padding-left: 0;
    margin-bottom: 15px;

    .ant-form-item-label > label {
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      text-align: right;
      font-weight: 550;
    }

    .label {
      font-weight: 550;
      font-size: 16px;
    }
  }

  .skuContainer {
    background-color: #f9f9f9;
    padding: 20px;
    padding-left: 0px;
    margin-bottom: 15px;

    .label {
      font-weight: 550;
      font-size: 16px;
    }

    .ant-form-item-label {
      font-weight: 550;
      font-size: 16px;
    }

    .flexBox {
      display: flex;

      .flexBox_item1 {
        flex: 2;
      }

      .flexBox_item {
        flex: 4;
      }
    }

    .button {
      width: 85%;
      margin: 0 40px;
    }

    .divider {
      margin: 0 0 16px 0;
      min-width: 100%;
      width: 100%;
    }
  }

  .container {
    border: 2px dashed gray;
    padding: 20px;
    margin-bottom: 15px;

    .select {
      border: 2px solid gray;
      padding: 20px;
      width: 85%;
      margin: 0 auto;
      margin-top: 20px;
    }

    .button {
      width: 85%;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
`;
