export const disCountArr: Record<number, string> = {
	10: "balanceDiscount",
	30: "monthDiscount",
	50: "freeCouponDiscount",
	54: "reductionDiscount",
	56: "discountCouponDiscount",
	60: "quotaDiscount",
	100: "comboDiscount",
	102: "firstOrderDiscount",
	999: "vipDiscount",
	110: "thirdPartyDiscount",
};
