import { http } from "src/common/http";
import { MaintenanceRecordsEntity } from "./maintenanceRecordsEntity";
import { MaintenanceRecordsParam } from "./maintenanceRecordsParam";

class maintenanceRecordsService {
	MaintenanceData = [
		{
			icon: "washArea",
			title: "洗车区域",
			children: "地面清洁,广告墙清洁,轨道清洁及检查间隙,地面停车到位挡块检查,地面限位杆间隙检查及紧固,识别摄像头清洁及固定检查,监控摄像头清洁",
		},
		{
			icon: "washIcon",
			title: "洗车机（外部）",
			children: "机身清洁,顶刷及侧刷清洁,轮刷抽出检查工作是否正常,传感器清洁及检查,喷嘴拆卸及清洁,外部连接至设备间的气管，水管检查",
		},
		{
			icon: "washIcon",
			title: "洗车机（内部）",
			children: "机柜清洁,检查设备内部有无漏水，漏气，堵塞,药剂泵工作状态检查,洗车液添加",
		},
		{
			icon: "ER",
			title: "设备间",
			children:
				"网络断电重启,空压机检查及排水,气管，水管检查,气管，水管检查,添加絮凝，除臭剂,工艺桶清洁,水循环工作状态检查,更换循环水,外部连接至设备间的气管，水管检查",
		},
		{
			icon: "washIcon",
			title: "洗车机工作测试",
			children: "药剂喷洒检查,活动部件工作检查（晃动，异响，不工作）,整理收尾（工具整理，站点物品归整，各门锁关闭确认）",
		},
		// {
		//   icon: "remark",
		//   title: "本次维保备注",
		//   remark: "",
		// },
		// {
		//   title: "下次维保截止时间",
		//   nextTime: "",
		// },
	];

	list = async (param: MaintenanceRecordsParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/shop-maintenance",
			method: "GET",
			params: param,
		});
	};

	add = async (data: MaintenanceRecordsEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/shop-maintenance",
			method: "POST",
			data,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/shop-maintenance/" + id,
			method: "GET",
			data: id,
		});
	};

	update = async (data: MaintenanceRecordsEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/shop-maintenance/" + data.id,
			method: "PUT",
			data,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/shop-maintenance/" + id,
			method: "DELETE",
			data: id,
		});
	};
}
export default new maintenanceRecordsService();
