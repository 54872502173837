import { DeleteOutlined, EditOutlined, InsuranceOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Card, Col, Form, Input, message, Modal, Pagination, Row, Select } from "antd";
import { AxiosResponse } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Util from "../../common/Util";
import { checkPermission } from "../../module/admin/service/permission.service";
import { SiteParam } from "../../module/site/param/site.param";
import SiteService from "../../module/site/service/site.service";
const { confirm } = Modal;
const { Option } = Select;
let SearchObj: SiteParam = {
	title: "",
	shopNumber: "",
	type: "",
	openType: "",
	level: "",
	customPageNum: 1, // 当前是第一页
	customPageSize: 20, // 每页条数
};
export const SiteList: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	//  数据列表
	const [dataSource, setDataSource] = useState([]);
	// 总页数
	const [total, setTotal] = useState(1);
	const handleInputChange = (type: string, e: any) => {
		SearchObj = {
			...SearchObj,
			customPageNum: 1,
			customPageSize: 20,
			[type]: type === "type" || type === "openType" || type === "level" ? e : e.target.value,
		};
	};
	const handlePaginationChange = (page: number, pageSize: number) => {
		SearchObj = {
			...SearchObj,
			customPageNum: page,
			customPageSize: pageSize ?? 20,
		};
		init();
	};
	const DSite = (e: any) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				SiteService.delete({
					id: e.id,
				}).then((res) => {
					message.success(res.data.msg);
					init();
				});
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};
	const searchClick = () => {
		init();
	};
	const download = async () => {
		const res: AxiosResponse<BlobPart> = await SiteService.export();
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "site");
		message.success(t("export.success"));
	};
	const openTypeMap: any = {
		1: { title: t("open"), color: "green" },
		2: { title: t("takeABreak"), color: "red" },
		3: { title: t("UnderConstruction"), color: "blue" },
		4: { title: t("UnderMaintenance"), color: "blue" },
		5: { title: t("freezeProtect"), color: "blue" },
		6: { title: t("municipalConstruction"), color: "blue" },
	};
	const init = useCallback(() => {
		SiteService.list(SearchObj).then((res) => {
			if (res.data.code === 200) {
				setDataSource(res.data.data);
				setTotal(res.data.total);
			}
		});
	}, []);
	useEffect(() => {
		init();
	}, [init]);
	return (
		<Container>
			<div className='headers'>
				{checkPermission(264) && (
					<Button
						type='primary'
						onClick={() => {
							navigate(`/site/site/0`);
						}}
						style={{ marginBottom: "20px", marginLeft: "10px" }}
					>
						{t("add")}
					</Button>
				)}

				<Button type='primary' danger style={{ marginBottom: "20px", marginLeft: "10px" }} onClick={download}>
					{t("export")}
				</Button>
			</div>
			<Form layout='inline' style={{ marginBottom: "20px" }}>
				<Form.Item label={t("name")}>
					<Input placeholder={t("please.enter.a.name")} allowClear onChange={(e) => handleInputChange("title", e)} />
				</Form.Item>
				<Form.Item label={t("number")}>
					<Input placeholder={t("please.enter.site.number")} onChange={(e) => handleInputChange("shopNumber", e)} />
				</Form.Item>
				<Form.Item label={t("type")}>
					<Select placeholder={t("please.select")} allowClear style={{ width: 140 }} onChange={(e) => handleInputChange("type", e)}>
						<Option value={1}>{t("express")}</Option>
						<Option value={2}>{t("superCenter")}</Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("status")}>
					<Select placeholder={t("please.select")} allowClear style={{ width: 140 }} onChange={(e) => handleInputChange("openType", e)}>
						<Option value={1}>{t("open")}</Option>
						<Option value={2}>{t("takeABreak")}</Option>
						<Option value={3}>{t("UnderConstruction")}</Option>
						<Option value={4}>{t("UnderMaintenance")}</Option>
						<Option value={5}>{t("freezeProtect")}</Option>
						<Option value={6}>{t("municipalConstruction")}</Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("level")}>
					<Select placeholder={t("please.select")} allowClear style={{ width: 140 }} onChange={(e) => handleInputChange("level", e)}>
						{SiteService.levelArray.map((item, index) => {
							return (
								<Option key={index} value={item.value}>
									{t(item.title)}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item>
					<Button type='primary' onClick={searchClick}>
						{t("search")}
					</Button>
				</Form.Item>
			</Form>
			<div style={{ width: "100%" }}>
				<Row
					gutter={20}
					justify='center'
					align={"middle"}
					style={{
						display: "flex",
						alignItems: "stretch",
						flexWrap: "wrap",
					}}
				>
					{dataSource.map((v: any, index: number) => {
						const { title, color } = openTypeMap[v.openType] ?? "";
						return (
							<Col
								span={6}
								key={index}
								style={{
									width: "24.8%",
									marginBottom: "20px",
								}}
							>
								<Card
									title={v.shopNumber ?? ""}
									style={{
										height: "100%",
										position: "relative",
									}}
									actions={[
										checkPermission(22) && (
											<DeleteOutlined
												onClick={() => {
													DSite(v);
												}}
												style={{ color: "red" }}
											/>
										),
										checkPermission(21) && (
											<EditOutlined
												onClick={() => {
													navigate(`/site/site/${v.id}`);
												}}
												key='edit'
											/>
										),
										checkPermission(359) && (
											<InsuranceOutlined
												onClick={() => {
													navigate(`/site/maintenance/${v.id}`);
												}}
											/>
										),
										//   <EllipsisOutlined key="ellipsis" />,
									]}
									hoverable={true}
									cover={null}
								>
									<div>
										<div>
											{t("name")}： {v.title}
										</div>
										<div>
											{t("status")}： <span style={{ color: color }}>{title}</span>
										</div>
										<div>
											{t("level")}: {t(SiteService.getLevelTitleByValue(v.level))}
										</div>
										<div>
											{t("type")}：{v.type === 1 ? t("quickWash") : t("superCenter")}
										</div>
										<div>
											{t("contact")}：{v.telephone}
										</div>
										<div>
											{t("freeParkingPeriod")}：{v.freeParkingPeriod}
										</div>
										<div>
											{t("businessTime")}：{v.businessHoursStart || ""}-{v.businessHoursEnd || ""}
										</div>
										<div>
											{t("maintenanceTime")}: {v.maintenanceTime}
										</div>
										<div style={{ height: "46px" }}>
											{t("address")}：{v.address}
										</div>
									</div>
								</Card>
							</Col>
						);
					})}
				</Row>
			</div>
			<div>
				<Pagination
					style={{ textAlign: "right" }}
					defaultCurrent={1}
					total={total}
					current={SearchObj.customPageNum}
					pageSize={SearchObj.customPageSize}
					showTotal={(total) => `${t("total")} ${total}`}
					onChange={handlePaginationChange}
				/>
			</div>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 10px;
	.ant-row-middle {
		justify-content: space-between;
	}
`;
