import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Table } from "antd";
import { t } from "i18next";
import { PaginationEntity } from "../../common/entity/pagination.entity";
import { RoleEntity } from "src/module/admin/entity/role.entity";
import adminService from "src/module/admin/service/admin.service";
import permissionService from "src/module/admin/service/permission.service";
import { MenuTreeEntity } from "src/module/menu/entity/menu.entity";



export const PermissionCheck: FC = () => {
	const [dataList, setDataList] = useState<RoleEntity[]>([]);
	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
		customPageSize: 15,
		customPageNum: 1,
	});
	const [total, setTotal] = useState(0);
	const [menuList, setMenuList] = useState({ title: "", dataIndex: "", width: 0, fixed: true });

	const init = useCallback(async () => {
		const { data } = await adminService.list({ ...paginationObj });
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [paginationObj]);

	const getPermissionList = useCallback(async () => {
		const { data } = await permissionService.list();
		if (data.code === 200) {
			// 树形结构数据扁平化循环中循环
			const arr: any = [];
			data.data.forEach((item1: MenuTreeEntity) => {
				arr.push({ name: item1.name, id: item1.id });
				item1.children &&
					item1.children.forEach((item2: MenuTreeEntity) => {
						const permissionTitle2 = item1.name + "-" + item2.name;
						arr.push({ name: permissionTitle2, id: item2.id });
						item2.children &&
							item2.children.forEach((item3: MenuTreeEntity) => {
								const permissionTitle3 = item1.name + "-" + item2.name + "-" + item3.name;
								arr.push({ name: permissionTitle3, id: item3.id });
							});
					});
			});
			const menuArr = arr.map((item: any) => {
				return { title: item.name, dataIndex: "permissionIds", width: 200, render: (text: string) => checkPermission(text, item.id) };
			});

			setMenuList(menuArr);
			console.log("444", menuArr);
		}
	}, []);

	useEffect(() => {
		init();
		getPermissionList();
	}, [getPermissionList, init]);

	const checkPermission = (value: string, params: string) => {
		return value.split(",").indexOf(params) !== -1 ? <div>&nbsp;&nbsp;{" ✔"}</div> : "";
	};

	const firstColums = [{ title: t("admin.name"), dataIndex: "name", width: 150, fixed: true }];

	const columns = firstColums.concat(menuList);

	return (
		<Container>
			<Table
				scroll={{ x: "max-content", y: 750 }}
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id}
				pagination={{
					pageSize: paginationObj.customPageSize,
					current: paginationObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setPaginationObj({
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: calc(100vw - 220px);
	max-height: 100%;
	padding: 20px;
	overflow-x: auto;
	/* .ant-table-content {
		overflow: auto !important;
	} */
	::-webkit-scrollbar {
		/* display: block !important; */
		width: 100% !important;
		height: 20px !important;
		background: #ec1414;
	}
	.ant-form-item {
		margin-bottom: 15px;
	}
`;
