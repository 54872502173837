import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Input, message, Row, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import RechargeService from "../../module/recharge/service/recharge.service";
import { SingleSelectPayWay } from "../../module/finance/component/SingleSelectPayWay";
import moment from "moment";
import { AxiosResponse } from "axios";
import Util from "../../common/Util";

const { RangePicker } = DatePicker;

export const RechargeList: FC = () => {
	const { t } = useTranslation();
	const [dataList, setDataList] = useState([]);
	const [searchArr, setSearchArr] = useState({});
	const [total, setTotal] = useState(0);
	// const [paginationObj, setPaginationObj] = useState({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });

	const [searchObj, setSearchObj] = useState({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD ") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	const changeArr = (type: string, e: any) => {
		setSearchArr({ ...searchArr, [type]: e });
	};

	const handleSearch = () => {
		setSearchObj({ ...searchObj, ...searchArr, customPageSize: 15, customPageNum: 1 });
	};

	const download = async () => {
		const res: AxiosResponse<BlobPart> = await RechargeService.exportRecharge({
			...searchObj,
		});
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "rechargeList");
		message.success(t("export.success"));
	};

	const init = useCallback(async () => {
		const { data } = await RechargeService.list({ ...searchObj });

		if (data.code === 200) console.log();
		setDataList(data.data);
		setTotal(data.total);
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const changeDate = (date: any, dateString: any) => {
		setSearchArr({ ...searchArr, startTime: dateString[0] && dateString[0] + " 00:00:00", endTime: dateString[1] && dateString[1] + " 23:59:59" });
	};

	const columns = [
		{ title: t("orderNum"), dataIndex: "orderNum" },
		{ title: t("carOwners"), dataIndex: "nickName" },
		{ title: t("mobile"), dataIndex: "mobile" },
		{ title: t("fee"), dataIndex: "price" },
		{ title: t("payWay"), dataIndex: "payWayStr" },
		{ title: t("updatedTime"), dataIndex: "updatedTime" },
	];

	return (
		<Container>
			<Row gutter={30}>
				<Col span={8}>
					<div className='search'>
						<div className='searchItem'>{t("orderNum")} : </div>
						<Input
							placeholder={t("orderNum")}
							onChange={(e) => {
								changeArr("orderNum", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='search'>
						<div className='searchItem'>{t("mobile")} :</div>
						<Input
							placeholder={t("mobile")}
							onChange={(e) => {
								changeArr("mobile", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='search'>
						<div className='searchItem'>{t("payWay")} :</div>
						<SingleSelectPayWay
							onChange={(e: number) => {
								changeArr("payWay", e);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='search'>
						<div className='searchItem'>{t("carOwners")} :</div>
						<Input
							placeholder={t("carOwners")}
							onChange={(e) => {
								changeArr("nickName", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='search'>
						<div className='searchItem'>{t("period")} :</div>
						<RangePicker defaultValue={[moment(), moment()]} onChange={changeDate} />
					</div>
				</Col>
				<Col>
					<div className='search'>
						<Space>
							<div className='searchItem'>
								<Button type={"primary"} onClick={handleSearch}>
									{t("search")}
								</Button>
							</div>
							<Button type={"primary"} onClick={download}>
								{t("export")}
							</Button>
						</Space>
					</div>
				</Col>
			</Row>
			<Table
				columns={columns}
				dataSource={dataList}
				pagination={{
					pageSize: 15,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				rowKey={(_, index) => index + ""}
				onChange={(pageInfo: any) => setSearchObj({ ...searchObj,customPageNum: pageInfo.current, customPageSize: pageInfo.pageSize })}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;

	.search {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		.searchItem {
			min-width: 150px;
			text-align: right;
		}
	}
`;
