import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Modal, Row, Select, Space, Table, message } from "antd";
import moment from "moment";
import { CustomerFeedbackEntity } from "src/module/customerFeedback/customer.feedback.entity";
// import { PaginationEntity } from "src/common/entity/pagination.entity";
import { useNavigate } from "react-router-dom";
import { FC, useCallback, useEffect, useState } from "react";
import { SelectSite } from "src/module/site/component/select.site";
import { RepairFeedbackEntity } from "src/module/repairFeedback/repair.feedback.entity";
import repairFeedbackService from "src/module/repairFeedback/repair.feedback.service";
import { checkPermission } from "src/module/admin/service/permission.service";
import Util from "src/common/Util";
const { RangePicker } = DatePicker;
const { confirm } = Modal;
export const RepairFeedback: FC = () => {
	const StatusEntity:Record<number,string>={
		0: "待处理",
		1: "已处理",
		2: "待观察",
		3: "待跟进",
	}

	const { Option } = Select;
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const [dataList, setDataList] = useState<CustomerFeedbackEntity[]>([]);
	const [total, setTotal] = useState(0);
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [searchObj, setSearchObj] = useState({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	const init = useCallback(async () => {
		const { data } = await repairFeedbackService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.records);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const onSearch = async (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			date: undefined,
			startTime: value.date && value.date[0] && value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.date && value.date[1] && value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			time: undefined,
			completeStartTime: value.time && value.time[0] && value.time[0].format("YYYY-MM-DD") + " 00:00:00",
			completeEndTime: value.time && value.time[1] && value.time[1].format("YYYY-MM-DD") + " 23:59:59",
		});
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await repairFeedbackService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const exportList = async () => {
		const res = await repairFeedbackService.export({ ...searchObj });
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "repairFeedback");
		message.success(t("export.success"));
	};
	const columns = [
		{ title: t("site"), dataIndex: "shopTitle" },
		{
			title: t("failureMode"),
			dataIndex: "type",
			render: (text: keyof typeof repairFeedbackService.faultType) => {
				return repairFeedbackService.faultType[text][0];
			},
		},
		{
			title: t("concreteFault"),
			render: (record: any) => {
				return repairFeedbackService.faultType[record.type][record.fault] || "未知";
			},
		},
		{ title: t("problemDescription"), dataIndex: "description" },
		{ title: t("createdAdmin"), dataIndex: "createdName" },
		{
			title: t("createdTime") + "&" + t("resolveTime"),
			render: (record: RepairFeedbackEntity) => {
				return (
					<div>
						<div> {record.createdTime}</div>
						<div> {record.completeTime ? record.completeTime : "尚未解决"}</div>
					</div>
				);
			},
		},

		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return StatusEntity[text];
			},
		},

		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(30904) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
						{checkPermission(30903) && record.status !== 1 && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/repairFeedback/${record.id}`);
								}}
							>
								{t("resolveFailure")}
							</Button>
						)}

						<Button
							type='primary'
							onClick={() => {
								// navigate(`/repairFeedback/${record.id}/detail`);
								window.open(`/repairFeedback/${record.id}/detail`, '_blank')
							}}
						>
							{t("detail")}
						</Button>
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onSearch} form={form} initialValues={{ date: [moment(), moment()] }}>
				<Row gutter={[8, 24]}>
					<Col span={8}>
						<Form.Item label={t("site")} name='shopId'>
							<SelectSite level={1} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("status")} name='status'>
							<Select allowClear>
								<Option value={0}>待处理</Option>
								<Option value={1}>已处理</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label={t("failureMode")} name='type'>
							<Select allowClear>
								{Object.keys(repairFeedbackService.type).map((item: any) => {
									return (
										<Option key={item} value={Number(item)}>
											{repairFeedbackService.type[item]}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>

					<Col span={8} >
						<Form.Item label={t("createdTime")} name='date'>
							<RangePicker allowClear />
						</Form.Item>
					</Col>
					<Col span={8} >
						<Form.Item label={t("resolveTime")} name='time'>
							<RangePicker allowClear />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item>
							<Space>
								<Button type='primary' htmlType='submit'>
									{t("search")}
								</Button>
								{checkPermission(30902) && (
									<Button
										type='primary'
										onClick={() => {
											navigate(`/repairFeedback/add`);
										}}
									>
										{t("add")}
									</Button>
								)}
								<Button
									type='primary'
									onClick={() => {
										exportList();
									}}
								>
									{t("export")}
								</Button>
							</Space>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table
				style={{ marginTop: "30px" }}
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => Number(record.id)}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
