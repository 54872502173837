import styled from "@emotion/styled";
import { Button, Form, Input, message, Radio } from "antd";
import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { checkPermission } from "src/module/admin/service/permission.service";
import { VipBandSkus } from "src/module/CarLicense/component/vipBandSku";
import carLicenseService from "../../module/CarLicense/service/carLicense.service";
import { SelectCustomer } from "../../module/customer/component/SelectCustomer";

export const CarLicenseDetail: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();
	const [form] = Form.useForm();
	// const [carLicenseId, setCarLicenseId] = useState<number>(0)

	const init = useCallback(async () => {
		if (params.id) {
			const { data } = await carLicenseService.detail(Number(params.id));
			console.log(data.data);
			// setCarLicenseId(data.data.id)
			form.setFieldsValue({ ...data.data, licenseVipProducts: data.data.licenseVipProductList });
		}
	}, [form, params.id]);

	useEffect(() => {
		init();
	}, [init]);

	const onFinish = async (value: any) => {
		console.log(value);
		if (!params.id) {
			const { data } = await carLicenseService.add(value);
			message.success(data.msg);
		} else {
			value.id = params.id;
			const { data } = await carLicenseService.update(value);
			message.success(data.msg);
		}

		navigate("/customer/carlicense/");
	};

	return (
		<Container>
			<Form labelCol={{ span: 5 }} wrapperCol={{ span: 14 }} onFinish={onFinish} form={form}>
				<Form.Item label={t("alias")} name='alias'>
					<Input allowClear placeholder={t("alias")} />
				</Form.Item>
				<Form.Item label={t("companyNum")} name='companyNum'>
					<Input allowClear placeholder={t("companyNum")} />
				</Form.Item>
				<Form.Item label={t("licenseNumber")} name='licenseNumber' getValueFromEvent={(event) => event.target.value.replace(/\s*/g, "")}>
					<Input allowClear placeholder={t("licenseNumber")} />
				</Form.Item>
				<Form.Item label={t("defaultCar")} name='defaultCar'>
					<Radio.Group>
						<Radio value={1}>{t("no")}</Radio>
						<Radio value={2}>{t("yes")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("vip")} name='vip'>
					<Radio.Group>
						<Radio value={1}>{t("no")}</Radio>
						<Radio value={2}>{t("yes")}</Radio>
					</Radio.Group>
				</Form.Item>
				{checkPermission(235) && (
					<Form.Item label={t("bindItem")} name='licenseVipProducts'>
						<VipBandSkus />
					</Form.Item>
				)}
				{params.id && (
					<Form.Item label={t("carOwners")} name='name'>
						<Input disabled></Input>
					</Form.Item>
				)}
				{!params.id && (
					<Form.Item label={t("carOwners")} name='userId'>
						<SelectCustomer />
					</Form.Item>
				)}
				<Form.Item wrapperCol={{ span: 12, offset: 6 }}>
					<Button type={"primary"} htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
