import { useEffect, useState, useMemo } from "react";
import { Pie } from "@ant-design/plots";
interface Props {
	list?: any;
}
export const PieComponents = (props: Props) => {
	const [pieData, setPieData] = useState([]);
	const PieConfig = {
		width: 200,
		height: 200,
		appendPadding: 10,
		data: pieData,
		angleField: "value",
		colorField: "type",
		radius: 1,
		innerRadius: 0.6,
		statistic: {
			content: {
				style: {
					whiteSpace: "pre-wrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
				},
				content: "",
			},
			title: {
				content: "",
			},
		},
		pieStyle: ({ type }: { type: any }) => {
			if (type === "会员卡") {
				return {
					fill: "#F8D31A",
				};
			} else if (type === "订单") {
				return {
					fill: "#2264F6",
				};
			} else if (type === "充值") {
				return {
					fill: "#36C6EC",
				};
			} else if (type === "套餐") {
				return {
					fill: "#FF6945",
				};
			}
		},
	};
	useEffect(() => {
		setPieData(props.list);
	}, [props]);
	// eslint-disable-next-line
	const component = useMemo(() => <Pie {...PieConfig} legend={false} />, [pieData]);
	return component;
};
