import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, message, Modal, Select, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { SelectSite } from "../../module/site/component/select.site";
import productService from "../../module/product/service/product.service";
import Util from "../../common/Util";
import { Link, useNavigate } from "react-router-dom";
import { SelectProductType } from "../../module/product/component/select.product.type";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { checkPermission } from "src/module/admin/service/permission.service";
const { confirm } = Modal;
export const Product: FC = () => {
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [dataList, setDataList] = useState();
	const [searchObj, setsearchObj] = useState({ customPageSize: 15, customPageNum: 1 });
	const onFinish = (value: any) => {
		// setPaginationObj({customPageSize: 15, customPageNum: 1 })
		setsearchObj({ ...value, customPageSize: 15, customPageNum: 1 });
	};

	const [total, setTotal] = useState(0);
	const updateStatus = async (param: any) => {
		const { data } = await productService.upDown(param);
		if (data.code === 200) {
			init();
		}
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await productService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const init = useCallback(async () => {
		const { data } = await productService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const download = async () => {
		const res = await productService.export(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "product");
		message.success(t("export.success"));
	};

	const downloadSku = async () => {
		const res = await productService.exportSku(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "productSku");
		message.success(t("export.success"));
	};

	const columns = [
		{
			title: t("image"),
			dataIndex: "thumb",
			render: (text: string | undefined) => {
				return <img src={text} width='100' alt='' style={{ border: "1px solid #e1e1e1" }} />;
			},
		},
		{
			title: t("productName"),
			dataIndex: "name",
		},
		{
			title: t("price"),
			dataIndex: "price",
		},
		{
			title: t("originPrice"),
			dataIndex: "originalPrice",
		},
		{
			title: t("salesAmount"),
			dataIndex: "count",
		},
		{
			title: t("product.type"),
			dataIndex: "type",
		},
		{
			title: t("shopName"),
			dataIndex: i18n.language === 'zh-CN' ? "shopName" : "shopNumber",
		},
		{
			title: t("onSaleTime"),
			render: (record: any) => {
				return record.businessHoursStart + "-" + record.businessHoursEnd;
			},
		},

		{
			title: t("sort"),
			dataIndex: "sort",
		},

		{
			title: t("productNum"),
			dataIndex: "productNum",
		},
		{
			title: t("status"),
			dataIndex: "upDown",
			render: (text: number) => {
				return text === 1 ? t("onSale") : t("off");
			},
		},
		{
			title: t("freeConstructionMoney"),
			dataIndex: "freeConstructionMoney",
		},
		{
			title: t("promoteMoney"),
			dataIndex: "promoteMoney",
		},
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(244) && record.upDown === 1 && (
							<Button
								type='primary'
								danger
								onClick={() => {
									updateStatus({ ...record, upDown: 2 });
								}}
							>
								{t("off")}
							</Button>
						)}
						{checkPermission(243) && record.upDown === 2 && (
							<Button type='primary' onClick={() => updateStatus({ ...record, upDown: 1 })}>
								{t("onSale")}
							</Button>
						)}
						{checkPermission(16) && (
							<Button
								type='primary'
								onClick={() => {
									navigate(`/site/product/${record.id}`);
								}}
							>
								{t("edit")}
							</Button>
						)}
						{checkPermission(17) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}

						<Button
							type='primary'
							onClick={() => {
								navigate(`/site/product/${record.id}/1`);
							}}
						>
							{t("copy")}
						</Button>

						{/* <Button
							type='primary'
							onClick={() => {
								navigate(`/site/product/sku/${record.id}`);
							}}
						>
							{t("SKU")}
						</Button> */}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onFinish} style={{ marginBottom: 20 }}>
				<Form.Item label={t("productName")} name='productName'>
					<Input placeholder={t("productName")} allowClear></Input>
				</Form.Item>
				<Form.Item label={t("product.type")} name='typeId'>
					<SelectProductType />
				</Form.Item>
				<Form.Item label={t("site")} name='shopId'>
					<SelectSite />
				</Form.Item>
				<Form.Item label={t("status")} name='upDown'>
					<Select allowClear placeholder={t("status")} style={{ width: "100px" }}>
						<Select.Option value={2}> {t("off")}</Select.Option>
						<Select.Option value={1}> {t("onSale")}</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						<Button type='primary' onClick={download}>
							{t("export")}
						</Button>
						<Button type='primary' onClick={downloadSku}>
							{t("exportSku")}
						</Button>
						{checkPermission(15) && (
							<Link to='/site/product/add'>
								<Button type='primary'>{t("add")}</Button>
							</Link>
						)}
					</Space>
				</Form.Item>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}

				
				onChange={(pagination) => {
					setsearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
