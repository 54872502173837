import styled from "@emotion/styled";
import { Button, message, Table } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { http } from "../../common/http";

const shArr = [
    {
        key: 0,
        title: '数据中心API',
        sh: 'data-api'
    },
    {
        key: 1,
        title: '三方合作API',
        sh: 'partner-api'
    },
    {
        key: 2,
        title: '基础项目API',
        sh: 'api'
    },
    {
        key: 3,
        title: '新后台API',
        sh: 'admin-api'
    },
    {
        key: 4,
        title: '综合管理后台',
        sh: 'admin_web'
    },
    {
        key: 5,
        title: '用友',
        sh: 'yongyou'
    },
    {
        key: 6,
        title: 'Flyway',
        sh: 'flyway'
    },
    {
        key: 7,
        title: '文件传输API',
        sh: 'file'
    }
]

export const Sh:FC = () => {
    const {t} = useTranslation();
    const [loadingArr, setLoadingArr] = useState<Record<string, boolean>>({});

    const columns = [
        {
            title: t('title'),
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: t('sh'),
            dataIndex: 'sh',
            key: 'sh',
        },
        {
            title: t('sh'),
            dataIndex: 'sh',
            render: (sh: string) => {
                console.log(loadingArr[sh])
                return <Button key={sh} loading={loadingArr[sh]} danger onClick={() => run(sh)} >{t("run")}</Button>
            }
        }
    ]

    const run = async (sh: string) => {
        setLoadingArr({
            ...loadingArr,
            [sh]: true
        });
        const res = await http({
            url: process.env.REACT_APP_SH_API + '/auto/' + sh,
            method: 'POST',
            headers: {
                "X-Gitlab-Token": process.env.REACT_APP_SH_TOKEN??""
            }
        });
        setLoadingArr({
            ...loadingArr,
            [sh]: false
        });
        if (res.status !== 200){
            message.error(res.data.msg)
        }else{
            message.success(t("operate_success"));
        }
    }

    return (
        <Container>
            <Table dataSource={shArr} columns={columns} />
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 10px;
`