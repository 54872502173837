import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MembershipCardAdd } from "../../views/activity/MembershipCardAdd";
import { MembershipCardList } from "../../views/activity/MembershipCardList";
import { AdminAdd } from "../../views/admin/admin.add";
import { AdminList } from "../../views/admin/admin.list";
import Login from "../../views/admin/login";
import { CouponAdd } from "../../views/coupon/CouponAdd";
import { CouponList } from "../../views/coupon/CouponList";
import { CarLicense } from "../../views/customer/CarLicense";
import { CarLicenseDetail } from "../../views/customer/CarLicenseDetail";
import { CarwashList } from "../../views/customer/CarwashList";
import { CustomerMembershipCard } from "../../views/customer/customer.membership.card";
import { CustomerList } from "../../views/customer/CustomerList";
import { LoginActivity } from "../../views/customer/loginActivity";
import { PackagePurchase } from "../../views/customer/PackagePurchase";
import { RecommendActivity } from "../../views/customer/recommendActivity";
import { BillList } from "../../views/finance/BillList";
import { Company } from "../../views/finance/Company";
import { ExpressSiteReport } from "../../views/finance/express.site.report";
import { Invoice } from "../../views/finance/Invoice";
import { InvoiceDetail } from "../../views/finance/InvoiceDetail";
import { MembershipCardRecord } from "../../views/finance/membership.card.record";
import { PerformanceList } from "../../views/finance/PerformanceList";
import { RechargeList } from "../../views/finance/RechargeList";
import { SiteSaleReport } from "../../views/finance/site.sale.report";
import Home from "../../views/home/home";
import { OrderList } from "../../views/order/OrderList";
import { Statistics } from "src/views/overview/statistics/statistics";
import { Dashboard } from "../../views/overview/dashboard/dashboard";
import { InQinDynasty } from "../../views/screen/InQinDynasty/InQinDynasty";
import Top from "../../views/screen/top";
import { Warning } from "../../views/screen/warning/warning";
import { Evaluation } from "../../views/site/Evaluation";
import { InteriorCleaning } from "../../views/site/InteriorCleaning";
import { Product } from "../../views/site/product";
import { ProductType } from "../../views/site/product.type";
import { ProductDetail } from "../../views/site/productDetail";
import { SiteDetail } from "../../views/site/site.detail";
import { SiteList } from "../../views/site/site.list";
import { AppletQcCode } from "../../views/tools/AppletQcCode";
import { BannerDetail } from "../../views/tools/BannerDetail";
import { BannerList } from "../../views/tools/BannerList";
import { Sh } from "../../views/tools/sh";
import { MtPage } from "../../views/tools/MtPage";
import { MtShopPage } from "../../views/tools/MtShopPage";
import "../config/i18n";
import { BasicLayout } from "../layout/BasicLayout";
import { FullGiftDetail } from "../../views/activity/FullGiftDetail";
import { FullGiftList } from "../../views/activity/FullGiftList";
import { RateGift } from "../../views/activity/RateGift";
import { RateGiftDetail } from "../../views/activity/RateGiftDetail";
import { PromoterAuditList } from "../../views/customer/PromoterAuditList ";
import { UserCoupon } from "../../views/customer/UserCoupon";
import { RechargeActivity } from "../../views/activity/RechargeActivity";
import { RechargeActivityDetail } from "../../views/activity/RechargeActivityDetail";
import { WithdrawalAuditList } from "../../views/customer/WithdrawalAuditList";
import { Equipment } from "../../views/equipment/Equipment";
import { EquipmentDetail } from "../../views/equipment/EquipmentDetail";
import { IdentifyRecords } from "../../views/equipment/IdentifyRecords";
import { InstructionRecognition } from "../../views/equipment/instructionRecognition";
import { ProductComb } from "../../views/site/productComb";
import { ProductCombDetail } from "../../views/site/productCombDetail";

import { RegistrationActivity } from "../../views/activity/registrationActivity/RegistrationActivity";
import { RegistrationActivityDetail } from "../../views/activity/registrationActivity/RegistrationActivityDetail";
import { CouponActivity } from "../../views/activity/couponActivity/CouponActivity";

import { CouponActivityDetail } from "../../views/activity/couponActivity/CouponActivityDetail";

import { PropagateActivityDetail } from "../../views/activity/propagateActivity/PropagateActivityDetail";
import { PackageActivityList } from "../../views/activity/packageActivity/PackageActivityList";
import { PackageActivityDetail } from "../../views/activity/packageActivity/PackageActivityDetail";

import { MonthlyPlan } from "src/views/finance/MonthPlan";
import { ThirdOrderList } from "src/views/order/ThirdOrderList";
import { FirstOrderList } from "src/views/activity/firstOrderList";
import { LoginActivityList } from "src/views/activity/loginActivity/loginActivityList";
import { LoginActivityListDetail } from "src/views/activity/loginActivity/loginActivityListDetail";
import { RecommendActivitylist } from "src/views/activity/recommendActivity/recommendActivity";
import { RecommendActivitylistDetail } from "src/views/activity/recommendActivity/recommendActivityDetail";
import { GiveAwayCoupon } from "src/views/coupon/GiveAwayCoupon";
import { RoleList } from "src/views/admin/roleList";
import { Question } from "src/views/tools/Question";
import { QuestionDetail } from "src/views/tools/QuestionDetail";
import { PropagateActivityList } from "src/views/activity/propagateActivity/PropagateActivityList";
import { PointDetailList } from "src/views/point/PointDetailList";
import { PointExchangeList } from "src/views/point/PointExchangeList";

import { PointProduct } from "src/views/point/PointProduct";
import { PointType } from "src/views/point/PointType";
import { PointProductDetail } from "src/views/point/PointProductDetail";
import { PermissionCheck } from "src/views/admin/permissionCheck";
import { ProductSku } from "src/views/site/productSku";
import { CarBrandList } from "src/views/carBrand/carBrandList";
import { CarBrandDetail } from "src/views/carBrand/carBrandDetail";
import { ShopOffLog } from "src/views/site/ShopOffLog";
import { ThirdCoupon } from "src/views/coupon/ThirdCoupon";
import { ThirdCouponAdd } from "src/views/coupon/ThirdCouponAdd";
import { CustomerFeedback } from "src/views/tools/CustomerFeedback";
import { CustomerFeedbackDetail } from "src/views/tools/CustomerFeedbackDetail";
import { RepairFeedback } from "src/views/tools/RepairFeedback";
import { RepairFeedbackAdd } from "src/views/tools/RepairFeedbackAdd";
import { UpdateProduct } from "src/views/site/upDateProduct";
import { UpdateProductDetail } from "src/views/site/updateProductDetail";
import { MaintenanceRecords } from "src/views/site/MaintenanceRecords";
import { MaintenanceDetail } from "src/views/site/MaintenanceDetail";
import { MaintenanceAdd } from "src/views/site/MaintenanceAdd";
import { Douyin } from "src/views/activity/douyin/Douyin";
import { DouyinAdd } from "src/views/activity/douyin/DouyinAdd";
import { RideHailingAudit } from "src/views/activity/RideHailingAudit";
import { EarlyBirdList} from "../../views/activity/earlyBird/earlyBird";
import { IndexPopup } from "../../views/activity/indexPopup/indexPopup";
import { IndexPopupAdd } from "../../views/activity/indexPopup/indexPopupAdd";
import { GroupMessage } from "../../views/tools/GroupMessage";

function BasicRoute() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/login' element={<Login />} />
				<Route path='/screen/top' element={<Top />} />
				<Route path='/screen/warning' element={<Warning />} />
				<Route path='/screen/InQinDynasty' element={<InQinDynasty />} />
				<Route path='/' element={<BasicLayout />}>
					<Route path='/' element={<Home />} />
					{/* overview*/}
					<Route path='/overview'>
						<Route path='statistics' element={<Statistics />} />
						<Route path='dashboard' element={<Dashboard />} />
					</Route>
					{/* site*/}
					<Route path='/site/product/type' element={<ProductType />} />
					<Route path='/site/site' element={<SiteList />} />
					<Route path='/site/site/:id' element={<SiteDetail />} />
					<Route path='/site/product' element={<Product />} />
					<Route path='/site/product/add' element={<ProductDetail />} />
					<Route path='/site/product/:id' element={<ProductDetail />} />
					<Route path='/site/product/:id/:copy' element={<ProductDetail />} />
					<Route path='/site/evaluation' element={<Evaluation />} />
					<Route path='/site/product/sku/:id' element={<ProductSku />} />
					<Route path='/site/offLog' element={<ShopOffLog />} />
					<Route path='/site/maintenance/:id' element={<MaintenanceRecords />} />
					<Route path='/site/maintenance/detail/:id' element={<MaintenanceDetail/>} />
					<Route path='/site/maintenance/:id/add' element={<MaintenanceAdd/>} />




					<Route path='/site/combo' element={<ProductComb />} />
					<Route path='/site/combo/add' element={<ProductCombDetail />} />
					<Route path='/site/combo/:id' element={<ProductCombDetail />} />
					{/* <Route path='/site/workStation' element={<WorkStation />} />
					<Route path='/site/workStation/add' element={<WorkStationDetail />} />
					<Route path='/site/workStation/:id' element={<WorkStationDetail />} /> */}
					<Route path='/site/interiorCleaning' element={<InteriorCleaning />} />
					<Route path='/updateProduct' element={<UpdateProduct />} />
					<Route path='/updateProduct/:id' element={<UpdateProductDetail />} />
					<Route path='/updateProduct/add' element={<UpdateProductDetail />} />

					{/*customer*/}
					<Route path='/customer/customerList' element={<CustomerList />} />
					<Route path='/customer/membership-card' element={<CustomerMembershipCard />} />
					<Route path='/customer/carwashlist' element={<CarwashList />} />
					<Route path='/customer/carlicense' element={<CarLicense />} />
					<Route path='/customer/carlicense/add' element={<CarLicenseDetail />} />
					<Route path='/customer/carlicense/:id' element={<CarLicenseDetail />} />
					<Route path='/customer/recommendActivity' element={<RecommendActivity />} />
					<Route path='/customer/loginActivity' element={<LoginActivity />} />
					<Route path='/customer/rideHailingAudit' element={<RideHailingAudit />} />
					<Route path='/customer/promoterAuditList' element={<PromoterAuditList />} />
					<Route path='/customer/withdrawalAuditList' element={<WithdrawalAuditList />} />
					<Route path='/customer/PackagePurchase' element={<PackagePurchase />} />
					<Route path='/customer/userCoupon' element={<UserCoupon />} />
					<Route path='/customer/earlyBird' element={<EarlyBirdList />} />

					{/* Activity management */}
					<Route path='/activity/membership-card' element={<MembershipCardList />} />
					<Route path='/activity/membership-card/:id' element={<MembershipCardAdd />} />
					<Route path='/activity/rate-gift' element={<RateGift />} />
					<Route path='/activity/rate-gift/add' element={<RateGiftDetail />} />
					<Route path='/activity/rate-gift/:id' element={<RateGiftDetail />} />
					<Route path='/activity/recharge' element={<RechargeActivity />} />
					<Route path='/activity/recharge/add' element={<RechargeActivityDetail />} />
					<Route path='/activity/recharge/:id' element={<RechargeActivityDetail />} />

					<Route path='/activity/coupon' element={<CouponActivity />} />
					<Route path='/activity/coupon/add' element={<CouponActivityDetail />} />
					<Route path='/activity/coupon/:id' element={<CouponActivityDetail />} />



					<Route path='/activity/fullGift' element={<FullGiftList />} />
					<Route path='/activity/fullGift/add' element={<FullGiftDetail />} />
					<Route path='/activity/fullGift/:id' element={<FullGiftDetail />} />
					<Route path='/activity/registration' element={<RegistrationActivity />} />
					<Route path='/activity/registration/add' element={<RegistrationActivityDetail />} />
					<Route path='/activity/registration/:id' element={<RegistrationActivityDetail />} />
					<Route path='/activity/propagate' element={<PropagateActivityList />} />
					<Route path='/activity/propagate/add' element={<PropagateActivityDetail />} />
					<Route path='/activity/propagate/:id' element={<PropagateActivityDetail />} />

					<Route path='/activity/package' element={<PackageActivityList />} />
					<Route path='/activity/package/add' element={<PackageActivityDetail />} />
					<Route path='/activity/package/:id' element={<PackageActivityDetail />} />
					<Route path='/activity/firstOrderList' element={<FirstOrderList />} />

					<Route path='/activity/loginActivity' element={<LoginActivityList />} />
					<Route path='/activity/loginActivity/add' element={<LoginActivityListDetail />} />
					<Route path='/activity/loginActivity/:id' element={<LoginActivityListDetail />} />

					<Route path='/activity/recommendActivity' element={<RecommendActivitylist />} />
					<Route path='/activity/recommendActivity/add' element={<RecommendActivitylistDetail />} />
					<Route path='/activity/recommendActivity/:id' element={<RecommendActivitylistDetail />} />

					<Route path='/activity/douyin' element={<Douyin />} />
					<Route path='/activity/douyin/add' element={<DouyinAdd />} />
					<Route path='/activity/douyin/:id' element={<DouyinAdd />} />

					<Route path='express-site-report' element={<ExpressSiteReport />} />
					<Route path='site-sale-report' element={<SiteSaleReport />} />
					<Route path='/finance/membership-card-records' element={<MembershipCardRecord />} />
					<Route path='/finance/collectingcompany' element={<Company />} />
					<Route path='/finance/performance' element={<PerformanceList />} />
					<Route path='/finance/company' element={<Company />} />

					<Route path='/finance/performanceList' element={<PerformanceList />} />
					<Route path='/finance/invoice' element={<Invoice />} />
					<Route path='/finance/invoice/:id' element={<InvoiceDetail />} />
					<Route path='/finance/rechargeList' element={<RechargeList />} />
					<Route path='/finance/billList' element={<BillList />} />
					<Route path='/finance/monthlyPlan' element={<MonthlyPlan />} />
					<Route path='/activity/indexPopup' element={<IndexPopup />} />
					<Route path='/activity/indexPopup/add' element={<IndexPopupAdd />} />
					<Route path='/activity/indexPopup/:id' element={<IndexPopupAdd />} />

					{/* equmint*/}
					<Route path='/equipments/list' element={<Equipment />} />
					<Route path='/equipments/add' element={<EquipmentDetail />} />
					<Route path='/equipments/:id' element={<EquipmentDetail />} />
					<Route path='/equipments/identifyRecords' element={<IdentifyRecords />} />
					<Route path='/equipments/instructionRecognition' element={<InstructionRecognition />} />

					{/* admin management*/}
					<Route path='/admin' element={<AdminList />} />
					<Route path='/admin/add' element={<AdminAdd />} />
					<Route path='/admin/add/:id' element={<AdminAdd />} />
					<Route path='/admin/add/:id/:copy' element={<AdminAdd />} />
					<Route path='/roleList' element={<RoleList />} />
					<Route path='/permission' element={<PermissionCheck />} />

					{/* coupon management*/}
					<Route path='/coupon/coupon' element={<CouponList />} />
					<Route path='/coupon/coupon-add' element={<CouponAdd />} />
					<Route path='/coupon/coupon-add/:id' element={<CouponAdd />} />
					<Route path='/coupon/coupon-add/:id/:copy' element={<CouponAdd />} />
					<Route path='/coupon/give-away-coupon' element={<GiveAwayCoupon />} />
					<Route path='/coupon/thirdCoupon' element={<ThirdCoupon />} />
					<Route path='/coupon/third-coupon-add' element={<ThirdCouponAdd />} />
					<Route path='/coupon/third-coupon-add/:id' element={<ThirdCouponAdd />} />


					<Route path='/tools/sh' element={<Sh />} />
					<Route path='/tools/MtPage' element={<MtPage />}></Route>
					<Route path='/tools/MtShopPage' element={<MtShopPage />}></Route>
					<Route path='/tools/appletQcCode' element={<AppletQcCode />} />
					<Route path='/banner' element={<BannerList />} />
					<Route path='/banner/detail' element={<BannerDetail />} />
					<Route path='/banner/detail/:id' element={<BannerDetail />} />
					<Route path='/question' element={<Question />} />
					<Route path='/question/:id' element={<QuestionDetail />} />
					<Route path='/question/add' element={<QuestionDetail />} />
					<Route path='/customerFeedback' element={<CustomerFeedback />} />
					<Route path='/customerFeedback/add' element={<CustomerFeedbackDetail />} />
					<Route path='/customerFeedback/:id' element={<CustomerFeedbackDetail />} />
					<Route path='/repairFeedback' element={<RepairFeedback />} />
					<Route path='/repairFeedback/add' element={<RepairFeedbackAdd />} />
					<Route path='/repairFeedback/:id' element={<RepairFeedbackAdd />} />
					<Route path='/repairFeedback/:id/:detail' element={<RepairFeedbackAdd />} />
					<Route path='/groupMessage' element={<GroupMessage />} />



					{/** Order */}
					<Route path='/order' element={<OrderList />} />
					<Route path='/third-order' element={<ThirdOrderList />} />
					{/** point */}
					<Route path='/customer/pointDetailList' element={<PointDetailList />} />
					<Route path='/customer/pointExchangeList' element={<PointExchangeList />} />
					<Route path='/point/pointType' element={<PointType />} />
					<Route path='/point/pointProduct' element={<PointProduct />} />
					<Route path='/point/pointProduct/add' element={<PointProductDetail />} />
					<Route path='/point/pointProduct/:id' element={<PointProductDetail />} />
					<Route path='/point/pointProduct/:id/:copy' element={<PointProductDetail />} />

					<Route path='/carBrand' element={<CarBrandList />} />
					<Route path='/carBrand/detail' element={<CarBrandDetail />} />
					<Route path='/carBrand/detail/:id' element={<CarBrandDetail />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}
export default BasicRoute;
