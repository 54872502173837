import { http } from "../../../common/http";
import { PromoterAuditParam } from "../param/promoter.audit.param";

class PromoterAuditService {
	list = async (params: PromoterAuditParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + "/promoter/pending",
			params,
		});
	};

	pass = async (id: number) => {
		return http({
			method: "POST",
			url: process.env.REACT_APP_ADMIN_API + "/promoter/pass/"+id,
			data:id,
		});
	};

	refuse = async (id: number) => {
		return http({
			method: "POST",
			url: process.env.REACT_APP_ADMIN_API + "/promoter/refuse/"+id,
			data:id,
		});
	};
}
export default new PromoterAuditService();
