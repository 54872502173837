import { FC, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Form, Input, InputNumber, message } from "antd";
import { UploadImg } from "../../common/components/UploadImg";
import { BannerEntity } from "../../module/tools/entity/banner.entity";
import bannerService from "../../module/tools/service/banner.service";
import { useNavigate, useParams } from "react-router-dom";

const formItemLayout = {
	labelCol: { span: 3 },
	wrapperCol: { span: 20 },
};

export const BannerDetail: FC = () => {
    const [info, setInfo] = useState<BannerEntity>({});

    const {id} = useParams();
	const { t } = useTranslation();
    const navigate = useNavigate();

	const init = useCallback(async () => {
        if (id){
            const {data} = await bannerService.detail(Number(id));
            setInfo(data.data);
        }
    }, [id]);

	useEffect(() => {
		init();
	}, [init]);

    const onChange = (type: string, e: any) => {
        setInfo({
            ...info,
            [type]: e
        });
    }

    const submit = async () => {
        const {data} = await bannerService.addOrUpdate(info);

		if (data.code === 200) {
			message.success(t("success"));
			navigate("/banner");
		} else {
			message.error(data.msg);
		}
    }

	return (
		<Container>
			<Form>
				<Form.Item label={t("bigImage")} required {...formItemLayout}>
                    <UploadImg value={info.imageUrl} size={"750*562"}  onChange={(e: string) => onChange("imageUrl", e)} />
                </Form.Item>
				<Form.Item label={t("middleImage")} required {...formItemLayout}>
                    <UploadImg value={info.secondaryImageUrl} size={"690*232"}  onChange={(e: string) => onChange("secondaryImageUrl", e)} />
                </Form.Item>
                <Form.Item label={t("sort")} {...formItemLayout}>
                    <InputNumber value={info.imageOrder} onChange={(e: any) => onChange("imageOrder", e)} />
                </Form.Item>
                <Form.Item label={t("appletUrl")} {...formItemLayout}>
                    <Input value={info.appletLinkUrl??""} onChange={(e: any) => onChange("appletLinkUrl", e.target.value)} />
                </Form.Item>
                <div>
                    <Button type="primary" onClick={submit}>{t("submit")}</Button>
                </div>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;
`;
