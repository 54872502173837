import { FC, useState, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Row, Col, Button, DatePicker, Table, Input, Space, Modal, message, Select, Form } from "antd";
import { useTranslation } from "react-i18next";
import { PerformanceEntity } from "../../module/performance/performance.entity";
import { PerformanceParam } from "../../module/performance/performance.params";
import PerformanceService from "../../module/performance/service/performance.service";

import moment from "moment";
import Util, { dateFormat } from "../../common/Util";
import { SelectSite } from "../../module/site/component/select.site";
import { AxiosResponse } from "axios";
import { ResponseEntity } from "../../common/entity/response.entity";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { checkPermission } from "src/module/admin/service/permission.service";

const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Option } = Select;

export const PerformanceList: FC = () => {
	const { t } = useTranslation();

	const [searchArr, setSearchArr] = useState<PerformanceParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		
	});

	const [searchObj, setSearchObj] = useState<PerformanceParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// 	// total: 0,
	// });
	const [total, setTotal] = useState<number>(0);

	const changeArr = (type: string, e: any) => {
		setSearchArr({ ...searchArr, [type]: e });
	};

	const onDateChange = (date: any, dateString: string[]) => {
		setSearchArr({
			...searchArr,
			startTime: dateString[0],
			endTime: dateString[1],
		});
	};

	const search = () => {
		setSearchObj({
			...searchArr,
			startTime: searchArr.startTime ? moment(searchArr.startTime).format(dateFormat) + " 00:00:00" : undefined,
			endTime: searchArr.endTime ? moment(searchArr.endTime).format(dateFormat) + " 23:59:59" : undefined,
			customPageSize: 15,
			customPageNum: 1,
		});
	};

	const [visible, setVisible] = useState(false);
	const [performanceInfo, setPerformanceInfo] = useState<PerformanceEntity>({});

	const changeModal = (type: string, e: any) => {
		setPerformanceInfo({ ...performanceInfo, [type]: e });
	};
	const handleOk = async () => {
		const { data } = await PerformanceService.update(performanceInfo);
		if (data.code === 200) {
			message.success(data.msg);
			init();
		}
	};

	const update = (record: PerformanceEntity) => {
		setVisible(true);
		setPerformanceInfo({ ...record });
	};

	const handleCancel = () => {
		setVisible(false);
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data }: AxiosResponse<ResponseEntity> = await PerformanceService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const [dataList, setDataList] = useState<PerformanceEntity[]>([]);

	const init = useCallback(async () => {
		const { data } = await PerformanceService.list({
			...searchObj,
			
		});
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const download = async () => {
		const res: AxiosResponse<BlobPart> = await PerformanceService.exportPerformance({
			...searchArr,
			startTime: moment(searchArr.startTime).format(dateFormat) + " 00:00:00",
			endTime: moment(searchArr.endTime).format(dateFormat) + " 23:59:59",
		});
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "performanceList");
		message.success(t("export.success"));
	};

	const downloadGroup = async () => {
		const res: AxiosResponse<BlobPart> = await PerformanceService.exportGroupPerformance({
			...searchArr,
			startTime: moment(searchArr.startTime).format(dateFormat) + " 00:00:00",
			endTime: moment(searchArr.endTime).format(dateFormat) + " 23:59:59",
		});
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "performanceList");
		message.success(t("export.success"));
	};

	const columns = [
		{
			title: t("account"),
			dataIndex: "account",
		},
		{
			title: t("name"),
			dataIndex: "name",
		},

		{
			title: t("performanceMoney"),
			dataIndex: "money",
		},
		{
			title: t("payFee"),
			dataIndex: "payFee",
		},
		{
			title: t("orderNum"),
			dataIndex: "orderNum",
		},
		{
			title: t("createdTime"),
			dataIndex: "createdTime",
		},
		{
			title: t("serviceType"),
			dataIndex: "serviceType",
			render: (text: number) => {
				return text === 1 ? t("sale") : t("construct");
			},
		},
		{
			title: t("serviceTime"),
			dataIndex: "serviceTime",
		},
		{
			title: t("performanceType"),
			dataIndex: "productType",
			render: (text: number) => {
				return text === 1 ? t("generalgoods") : text === 2 ? t("combo") : text === 3 ? t("rechargegoods") : t("vipcardgoods");
			},
		},
		{
			title: t("action"),
			render: (text: string, record: any) => {
				return (
					<Space>
						{checkPermission(309) && (
							<Button
								onClick={() => {
									update(record);
								}}
								type='primary'
							>
								{t("edit")}
							</Button>
						)}
						{checkPermission(310) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];
	return (
		<Container>
			<Row gutter={20}>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("account")}：</div>
						<Input
							placeholder={t("account")}
							onChange={(e) => {
								changeArr("account", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("name")}：</div>
						<Input
							placeholder={t("name")}
							onChange={(e) => {
								setSearchArr({ ...searchArr, userName: e.target.value });
								changeArr("userName", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("orderNum")}：</div>
						<Input
							placeholder={t("orderNum")}
							onChange={(e) => {
								changeArr("orderNum", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("serviceType")}：</div>
						<Select
							placeholder={t("please.select")}
							allowClear
							style={{ width: 140 }}
							onChange={(e) => {
								changeArr("serviceType", e);
							}}
						>
							<Option value={2}>{t("construct")}</Option>
							<Option value={1}>{t("sale")}</Option>
						</Select>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("performanceType")}：</div>
						<Select
							placeholder={t("please.select")}
							allowClear
							style={{ width: 140 }}
							onChange={(e) => {
								changeArr("productType", e);
							}}
						>
							<Option value={1}>{t("generalgoods")}</Option>
							<Option value={2}>{t("combo")}</Option>
							<Option value={3}>{t("rechargegoods")}</Option>
							<Option value={4}>{t("vipcardgoods")}</Option>
						</Select>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("site")}：</div>
						<SelectSite
							onChange={(e: number) => {
								changeArr("shopId", e);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("createdTime")}：</div>
						<RangePicker
							defaultValue={[
								moment(searchArr.startTime && searchArr.startTime.toString()),
								moment(searchArr.endTime && searchArr.endTime.toString()),
							]}
							showTime={false}
							onChange={onDateChange}
						/>
					</div>
				</Col>

				<Col span={8}>
					<div className='searchList'>
						<Space>
							<div className='searchItem'>
								<Button type='primary' onClick={search}>
									{t("search")}
								</Button>
							</div>
							<Button type='primary' onClick={download}>
								{t("export")}
							</Button>
							<Button type='primary' onClick={downloadGroup}>
								{t("exportGroup")}
							</Button>
						</Space>
					</div>
				</Col>
			</Row>
			<Table
				rowKey={(record) => record.id + ""}
				dataSource={dataList}
				columns={columns}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
					
				}}
			/>
			<Modal width={"40%"} title={t("performanceManagement")} visible={visible} onOk={handleOk} onCancel={handleCancel}>
				<Form layout='vertical'>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("name")}:</div>
							<div style={{ marginRight: 20, width: 300 }}>{performanceInfo.name}</div>
						</div>
					</Form.Item>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("money")}:</div>
							<div style={{ marginRight: 20, width: 300 }}>
								<Input
									onChange={(e) => {
										changeModal("money", e.target.value);
									}}
									value={performanceInfo.money}
								></Input>
							</div>
						</div>
					</Form.Item>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("serviceType")}:</div>
							<div style={{ marginRight: 20, width: 300 }}>{performanceInfo.serviceType === 1 ? t("sale") : t("construct")}</div>
						</div>
					</Form.Item>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("performanceType")}:</div>
							<div style={{ marginRight: 20, width: 300 }}>
								{performanceInfo.productType === 1
									? t("generalgoods")
									: performanceInfo.productType === 2
									? t("combo")
									: performanceInfo.productType === 3
									? t("rechargegoods")
									: t("vipcardgoods")}
							</div>
						</div>
					</Form.Item>

					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("createdTime")}:</div>
							<div style={{ marginRight: 20, width: 300 }}>{performanceInfo.createdTime}</div>
						</div>
					</Form.Item>

					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("orderNum")}:</div>
							<div style={{ marginRight: 20, width: 300 }}>{performanceInfo.orderNum}</div>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 10px;

	.searchList {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		.searchItem {
			min-width: 150px;
			text-align: right;
		}
	}
`;
