import styled from "@emotion/styled";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import skuService from "src/module/sku/service/sku.service";
import { Props } from "../../../common/entity/props.entity";
import { skusEntity, skuTypesEntity } from "../entity/product.entity";

interface skuProps extends Props {
	selected?: string;
	productId?: number;
	labelInValue?: boolean;
	className?: string;
}

export const SelectSku = (props: skuProps) => {
	const { t } = useTranslation();
	const [list, setList] = useState<any[]>([]);
	const [data, setData] = useState();
	const selectedArr = props.selected ? props.selected.split(",") : [];

	// const arr = [
	// 	{ a: "xiaowang", gaga: [1, 2] },
	// 	{ a: "xiaoming", gaga: [7, 8] },
	// ];
	// const newarr = arr.map((item) => {
	// 	return item.gaga.map((item1) => {
	// 		return (item1 +"-"+ item.a)
	// 	});
	// });
	// console.log("new", newarr.flat());

	useEffect(() => {
		setData(undefined);
		props.productId &&
			skuService.skuGroupList(Number(props.productId)).then((res) => {
				const skuArray: { id?: number; title?: string }[] = [];
				res.data.data.map((item: skuTypesEntity) => {
					// item.skus?.map((skuItem: skusEntity) => {
					// 	skuArray.push({ id: skuItem.id, title: item.title + ":" + skuItem.title });
					// });
					skuArray.push({ id: item.id, title: item.title });
				});
				setList(skuArray);
			});
	}, [props.productId]);

	const changeSku = (e: any) => {
		setData(e);
		props.onChange && props.onChange(e);
	};

	return (
		<Container className={props.className}>
			<Select
				mode='multiple'
				labelInValue={props.labelInValue}
				style={{ width: "100%" }}
				allowClear
				placeholder={t("select.sku")}
				onChange={(e: any) => {
					changeSku(e);
				}}
				value={data}
			>
				{list.map((item) => {
					return (
						<Select.Option
							key={item.id}
							style={{
								color: selectedArr.indexOf(item.id + "") > -1 && "#40a9ff",
								fontWeight: selectedArr.indexOf(item.id + "") > -1 && 600,
							}}
						>
							{item.title}
						</Select.Option>
					);
				})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
