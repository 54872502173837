class MenuService {
	menuArray = [
		{
			key: "overview",
			title: "overview",
			permission: 0,
			sub: [
				{
					key: "overview001",
					title: "statistics",
					path: "/overview/statistics",
					permission: 253,
				},
				{
					key: "dashboard",
					title: "dashboard",
					path: "/overview/dashboard",
					permission: 253,
				},
			],
		},

		{
			key: "site",
			title: "siteManagement",
			permission: 0,
			sub: [
				{
					key: "product",
					title: "productManagement",
					path: "/site/product",
					permission: 203,
				},
				{
					key: "site001",
					title: "site",
					path: "/site/site",
					permission: 204,
				},
				{
					key: "interiorClean",
					title: "interiorCleaning",
					path: "/site/InteriorCleaning",
					permission: 205,
				},
				{
					key: "equipment001",
					title: "equipment",
					path: "/equipments/list",
					permission: 221,
				},

				{
					key: "IdentifyRecords",
					title: "identifyRecordsList",
					path: "/equipments/identifyRecords",
					permission: 254,
				},

				
				{
					key: "site002",
					title: "productType",
					path: "/site/product/type",
					permission: 204,
				},
				{
					key: "productComb",
					title: "productComb",
					path: "/site/combo",
					permission: 324,
				},
				{
					key: "instructionRecognition",
					title: "instructionRecognitionList",
					path: "/equipments/instructionRecognition",
					permission: 332,
				},

				{
					key: "customerFeedback",
					title: "customerFeedback",
					path: "/customerFeedback",
					permission: 30801,
				},
				{
					key: "repairFeedback",
					title: "repairFeedback",
					path: "/repairFeedback",
					permission: 30901,
				},

				{
					key: "off",
					title: "offLog",
					path: "/site/offLog",
					permission: 31001,
				},

			

				// {
				// 	key: "workStation",
				// 	title: "workStationList",
				// 	path: "/site/workStation",
				// 	permission: 206,
				// },

				{
					key: "updateProduct",
					title: "updateProduct",
					path: "/updateProduct",
					permission: 31101,
				},
			],
		},

		{
			key: "customer",
			title: "userManagement",
			permission: 0,
			sub: [
				{
					key: "customer001",
					title: "userList",
					path: "/customer/customerList",
					permission: 200,
				},
				{
					key: "customer002",
					title: "membership.card",
					path: "/customer/membership-card",
					permission: 201,
				},
				{
					key: "customer005",
					title: "carlicense",
					path: "/customer/carlicense",
					permission: 202,
				},
				{
					key: "customer004",
					title: "carwashlist",
					path: "/customer/carwashlist",
					permission: 232,
				},
				{
					key: "comboSale",
					title: "comboSaleList",
					path: "/customer/packagePurchase",
					permission: 261,
				},
				{
					key: "userCoupon",
					title: "userCouponList",
					path: "/customer/userCoupon",
					permission: 328,
				},
				{
					key: "customer007",
					title: "loginActivityList",
					path: "/customer/loginActivity",
					permission: 344,
				},

				{
					key: "customer006",
					title: "recommendActivityAttendList",
					path: "/customer/recommendActivity",
					permission: 346,
				},

			
				

				{
					key: "promoterAuditList",
					title: "promoterAuditList",
					path: "/customer/promoterAuditList",
					permission: 367,
				},
				{
					key: "withdrawalAuditList",
					title: "withdrawalAuditList",
					path: "/customer/withdrawalAuditList",
					permission: 371,
				},
				{
					key: "pointDetailList",
					title: "pointDetailList",
					path: "/customer/pointDetailList",
					permission: 21101,
				},
			],
		},

		{
			key: "point",
			title: "pointManagemet",
			permission: 60000,
			sub: [
				{
					key: "pointType",
					title: "point.type",
					path: "/point/pointType",
					permission: 61100,
				},
				{
					key: "pointProduct",
					title: "pointProduct",
					path: "/point/pointProduct",
					permission: 61201,
				},
				{
					key: "pointExchangeList",
					title: "pointExchangeList",
					path: "/customer/pointExchangeList",
					permission: 61301,
				},
			],
		},
		{
			key: "order",
			title: "orderManagement",
			permission: 0,
			sub: [
				{
					key: "evaluation",
					title: "evaluationList",
					path: "/site/evaluation",
					permission: 250,
				},
				{
					key: "order001",
					title: "orderManagement",
					path: "/order",
					permission: 246,
				},
				{
					key: "thirdOrder",
					title: "thirdOrdeList",
					path: "/third-order",
					permission: 296,
				},
			],
		},
		{
			key: "activity",
			title: "activityManagement",
			permission: 0,
			sub: [
				{
					key: "rechargeActivity",
					title: "rechargeActivityList",
					path: "/activity/recharge",
					permission: 217,
				},
				{
					key: "couponActivity",
					title: "couponActivityList",
					path: "/activity/coupon",
					permission: 218,
				},
				{
					key: "registrationActivity",
					title: "registrationActivityList",
					path: "/activity/registration",
					permission: 219,
				},
				{
					key: "propagateActivity",
					title: "propagateActivityList",
					path: "/activity/propagate",
					permission: 220,
				},
				{
					key: "couponList",
					title: "couponList",
					path: "/coupon/coupon",
					permission: 222,
				},

				{
					key: "giveAwayCoupon",
					title: "give-away-coupon",
					path: "/coupon/give-away-coupon",
					permission: 223,
				},
				{
					key: "packageActivity",
					title: "packageActivityList",
					path: "/activity/package",
					permission: 238,
				},

				{
					key: "firstOrder",
					title: "firstOrderList",
					path: "/activity/firstOrderList",
					permission: 268,
				},

				{
					key: "activity001",
					title: "subscriptionConfig",
					path: "/activity/membership-card",
					permission: 275,
				},

				{
					key: "rateGift",
					title: "rate.gift.list",
					path: "/activity/rate-gift",
					permission: 281,
				},
				{
					key: "fullGift",
					title: "fullGiftlist",
					path: "/activity/fullGift",
					permission: 292,
				},

				{
					key: "loginActivityList",
					title: "login.activity.list",
					path: "/activity/loginActivity",
					permission: 334,
				},
				{
					key: "RecommendActivitylist",
					title: "recommend.activity.list",
					path: "/activity/recommendActivity",
					permission: 339,
				},

				{
					key: "thirdCoupon",
					title: "thirdCoupon",
					path: "/coupon/thirdCoupon",
					permission: 51501,
				},
				{
					key: "douyin",
					title: "douyin",
					path: "/activity/douyin",
					permission: 51601,
					
				},
				{
					key: "rideHailingAudit",
					title: "rideHailingAudit",
					path: "/customer/rideHailingAudit",
					permission: 51701,
				},
				{
					key: "earlyBird",
					title: "earlyBird",
					path: "/customer/earlyBird",
					permission: 51701,
				},
				{
					key: "indexPopup",
					title: "indexPopup",
					path: "/activity/indexPopup",
					permission: 51701,
				},



			],
		},
		// {
		// 	key: "equipment",
		// 	title: "equipment",
		// 	permission: 0,
		// 	sub: [],
		// },
		// {
		// 	key: "coupon",
		// 	title: "coupon",
		// 	permission: 0,
		// 	sub: [
				
		// 	],
		// },
		{
			key: "finance",
			title: "financeManagement",
			permission: 0,
			sub: [
				{
					key: "finance007",
					title: "billList",
					path: "/finance/billList",
					permission: 224,
				},
				{
					key: "finance006",
					title: "rechargeList",
					path: "/finance/rechargeList",
					permission: 225,
				},

				{
					key: "company",
					title: "collectingcompany",
					path: "/finance/company",
					permission: 303,
				},
				{
					key: "finance008",
					title: "performanceList",
					path: "/finance/performanceList",
					permission: 308,
				},
				{
					key: "finance003",
					title: "membersCardRecord",
					path: "/finance/membership-card-records",
					permission: 313,
				},
				{
					key: "finance005",
					title: "InvoiceApplication",
					path: "/finance/invoice",
					permission: 315,
				},

				{
					key: "finance001",
					title: "site.report",
					path: "/express-site-report",
					permission: 361,
				},
				{
					key: "finance002",
					title: "site.sale.report",
					path: "/site-sale-report",
					permission: 362,
				},

				{
					key: "monthlyPlanConfig",
					title: "monthlyPlanConfig",
					path: "/finance/monthlyPlan",
					permission: 60901,
				},
			],
		},
		{
			key: "admin",
			title: "admin.admin",
			permission: 0,
			sub: [
				{
					key: "admin001",
					title: "admin.admin",
					path: "/admin",
					permission: 226,
				},
				{
					key: "role",
					title: "roleList",
					path: "/roleList",
					permission: 227,
				},
				{
					key: "permission",
					title: "permissionCheck",
					path: "/permission",
					permission: 226,
				},
			],
		},
		{
			key: "tool",
			title: "tool",
			permission: 0,
			sub: [
				{
					key: "tool002",
					title: "bannerAdmin",
					path: "/banner",
					permission: 228,
				},
				{
					key: "tool003",
					title: "appletQcCode",
					path: "/tools/appletQcCode",
					permission: 330,
				},

				{
					key: "question",
					title: "question",
					path: "/question",
					permission: 355,
				},

				// {
				// 	key: "toolMT",
				// 	title: "MtPage",
				// 	path: "/tools/MtPage",
				// 	permission: 80101,
				// },
				// {
				// 	key: "toolMtShop",
				// 	title: "MtShopPage",
				// 	path: "/tools/MtShopPage",
				// 	permission: 80201,
				// },

				{
					key: "carBrand01",
					title: "carBrand",
					path: "/carBrand",
					permission: 80301,
				},

				{
					key: "tool001",
					title: "sh",
					path: "/tools/sh",
					permission: -1,
				},
				{
					key: "finance008",
					title: "groupMessage",
					path: "/groupMessage",
					permission: 228,
				},
			],
		},
		// {
		// 	key: "carBrand",
		// 	title: "carBrand",
		// 	permission: 0,
		// 	sub: [
				
		// 	],
		// },
	];
}

export default new MenuService();
