import { FC, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Col, Input, Modal, Row, Select, Space, Table, message } from "antd";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { checkPermission } from "src/module/admin/service/permission.service";
import { Image } from "antd";
import didiAuditService from "src/module/didiAudit/service/didi.audit.service";
const { Option } = Select;
const { confirm } = Modal;

export const RideHailingAudit: FC = () => {
	const StatusEntity: Record<number, string> = {
		0: "待审核",
		1: "审核通过",
		2: "审核拒绝",
	};
	const { t } = useTranslation();

	const [searchArr, setSearchArr] = useState({});
	const [searchObj, setSearchObj] = useState({ customPageSize: 15, customPageNum: 1 });

	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState(0);
	const [dataList, setDataList] = useState();

	const audit = (param: { id: number; status: string }) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await didiAuditService.audit(param);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await didiAuditService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const init = useCallback(async () => {
		const { data } = await didiAuditService.list({ ...searchObj });
		console.log(data, "345");
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const changeSearchArr = (type: any, e: any) => {
		setSearchArr({ ...searchArr, [type]: e });
	};
	const handleSearch = () => {
		setSearchObj({ ...searchObj, ...searchArr, customPageSize: 15, customPageNum: 1 });
	};

	const columns = [
		{ title: t("id"), dataIndex: "id" },
		{ title: t("mobile"), dataIndex: "mobile" },
		{ title: t("createdName"), dataIndex: "createdName" },
		{ title: t("license"), dataIndex: "license" },

		{
			title: t("licenseImage"),
			dataIndex: "licenseImage",
			render: (text: string | undefined) => {
				return <Image width={100} src={text} />;
			},
		},

		{
			title: t("auditStatus"),
			dataIndex: "status",
			render: (text: number) => {
				return StatusEntity[text];
			},
		},
		{ title: t("createdTime"), dataIndex: "createdTime" },
		{ title: t("operateTime"), dataIndex: "operateTime" },
		{ title: t("processor"), dataIndex: "processor" },
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(51702) && record.status == 0 && (
							<>
								<Button
									type='primary'
									onClick={() => {
										audit({ id: record.id, status: "1" });
									}}
								>
									{t("passAudit")}
								</Button>
								<Button
									type='primary'
									danger
									onClick={() => {
										audit({ id: record.id, status: "2" });
									}}
								>
									{t("refuseAudit")}
								</Button>
							</>
						)}
						{checkPermission(51703) && (
							<Button
								type='primary'
								danger
								onClick={() => {
									handleDelete(record.id);
								}}
							>
								{t("delete")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Row gutter={20}>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("mobile")}：</div>
						<Input
							placeholder={t("mobile")}
							onChange={(e) => {
								changeSearchArr("mobile", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("license")}：</div>
						<Input
							placeholder={t("license")}
							onChange={(e) => {
								changeSearchArr("license", e.target.value);
							}}
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className='searchList'>
						<div className='searchItem'>{t("auditStatus")}：</div>
						<Select
							style={{ width: 150 }}
							placeholder={t("auditStatus")}
							allowClear
							onChange={(e) => {
								changeSearchArr("status", e);
							}}
						>
							<Option value={0}>{t("pendingAudit")}</Option>
							<Option value={1}>{t("passAudit")}</Option>
							<Option value={2}>{t("refuseAudit")}</Option>
						</Select>
					</div>
				</Col>

				<Col span={8}>
					<Button type={"primary"} onClick={handleSearch}>
						{t("search")}
					</Button>
				</Col>
			</Row>
			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={dataList}
				rowKey={(record: any) => {
					return record.id;
				}}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({ ...searchObj, customPageNum: pagination.current ?? 1, customPageSize: pagination.pageSize ?? 15 });
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
	.searchList {
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		.searchItem {
			min-width: 200px;
			text-align: right;
		}
	}
`;
