export const OrderStatusArr: Record<number, string> = {
    1: "unpaid",
    3: "canceled",
    5: "paied",
    7: "doing",
    9: "complete",
    11:  "applyRefund",
    13:  "refused",
    15:  "refunding",
    17:  "refunded",
    19:  "evaluated",
    20:  "expired"
}

export const OrderStatusColorArr: Record<number, string> = {
    1: "#07C160",
    3: "#AAAAAA",
    5: "#07C160",
    7: "#197EFF",
    9: "#197EFF",
    11:  "#FF4D4F",
    13:  "#FF4D4F",
    15:  "#FF4D4F",
    17:  "#AAAAAA",
    19:  "#FFA100",
    20:  "#AAAAAA"
}