import { FC, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Radio, message } from "antd";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import updateProductService from "src/module/updateProduct/updateProductService";
import { MultiSelectComboProductBySite } from "src/module/product/component/multi.select.comboProduct.by.shop";
import { useForm } from "antd/es/form/Form";
import moment from "moment";

export const UpdateProductDetail: FC = () => {
	const navigate = useNavigate();
	const [form] = useForm();
	const params = useParams();
	const init = useCallback(async () => {
		if (params.id) {
			const { data } = await updateProductService.detail(Number(params.id));
			if (data.code === 200) {
				console.log(data.data);

				const oldProductArr = data.data.productRelationDetails.filter((item: any) => {
					return item.type === 0;
				});
				console.log("oldProductArr", oldProductArr);

				const newProductArr = data.data.productRelationDetails.filter((item: any) => {
					return item.type === 1;
				});

				form.setFieldsValue({
					...data.data,
					date: [moment(data.data.startTime), moment(data.data.endTime)],
					beforeUpdateProduct: oldProductArr,
					updateProduct: newProductArr,
				});
			}
		}
	}, [form, params.id]);

	useEffect(() => {
		init();
	}, [init]);

	const onFinish = async (value: any) => {
		console.log(value);
		const newBeforeUpdateProduct = value.beforeUpdateProduct.map((item: any) => {
			return { ...item, type: 0 };
		});

		const newUpdateProduct = value.updateProduct.map((item: any) => {
			return { ...item, type: 1 };
		});

		const productRelationDetails = [...newBeforeUpdateProduct, ...newUpdateProduct];
		const addInfo = {
			...value,
			date: undefined,
			beforeUpdateProduct: undefined,
			updateProduct: undefined,
			startTime: value.date && value.date[0] && value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.date && value.date[1] && value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			productRelationDetails,
		};

		if (!params.id) {
			const { data } = await updateProductService.add({
				...addInfo,
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/updateProduct");
			} else {
				message.error(data.msg);
			}
		} else {
			const { data } = await updateProductService.update({
				...addInfo,
				id: Number(params.id),
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/updateProduct");
			} else {
				message.error(data.msg);
			}
		}
	};
	return (
		<Container>
			<Form onFinish={onFinish} labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} style={{ padding: 20 }} form={form}>
				<Form.Item label={t("beforeUpdateProduct")} name='beforeUpdateProduct'>
					<MultiSelectComboProductBySite />
				</Form.Item>
				<Form.Item label={t("UpdateProduct")} name='updateProduct'>
					<MultiSelectComboProductBySite />
				</Form.Item>
				<Form.Item label={t("status")} name='status'>
					<Radio.Group>
						<Radio value={0}>{t("onSale")}</Radio>
						<Radio value={1}>{t("off")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("availablePeriod")} name='date'>
					<DatePicker.RangePicker />
				</Form.Item>
				<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
					<Button type='primary' htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
