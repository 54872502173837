import { MinusCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Col, Input, Row, TimePicker } from "antd";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { PropsEntity } from "src/common/entity/props.entity";
import { ProductPricesEntity } from "src/module/product/entity/product.entity";
const { RangePicker } = TimePicker;
interface productPriceProps extends PropsEntity {
	value?: ProductPricesEntity[];
}

export const DiffrentTimePrice = (props: productPriceProps) => {
	const [ timePriceArr, setTimePriceArr] = useState<ProductPricesEntity[]>([]);
	

	useEffect(() => {
		console.log(props.value);
		setTimePriceArr(props.value??[])
	}, [props.value])
	

	const addItem = () => {
		const addArr = {
			endTime: "",
			price: "",
			remark: "",
			startTime: "",
		};
		const newArr: ProductPricesEntity[] = [...timePriceArr, addArr];
		setTimePriceArr(newArr);
	};

	const changeInfo = (index: number, type: string, e: string|number) => {		
		// 修改替换原数组中的对象，变成新的一个数组
		const newPriceArr = timePriceArr.map((item, key) => {
			if (index == key) {
				return { ...item, [type]: e };
			} else return item;
		});
		// props.onChange()会导致props.value的变化，导致timePriceArr的变化
		props.onChange(newPriceArr);
	};
	const changeTime = (index: number, timeString: any) => {		
		const newPriceArr = timePriceArr.map((item, key) => {
			if (index == key) {
				return { ...item, startTime: timeString[0].format("HH:mm:ss"), endTime: timeString[1].format("HH:mm:ss") };
			} else return item;
		});
		props.onChange(newPriceArr);
	};

	const deleteObj = (index: number) => {
		const Arr = [...timePriceArr]
		Arr.splice(index, 1);
		props.onChange(Arr);
	};


	
	return (
		<Container>
			{timePriceArr.map((item: ProductPricesEntity, index: number) => {
				return (
					<div className='frame' key={index}>
						<Row gutter={[10, 24]}>
							<Col span={13}>
								<div className='top'>
									<div className='title'>{t("period")}：</div>
									<div>
										<RangePicker
											onChange={(timeString) => changeTime(index, timeString)}
											value={item.startTime?.length == 0 ? null : [moment(item.startTime, "HH:mm:ss"), moment(item.endTime, "HH:mm:ss")]}
										></RangePicker>
									</div>
								</div>
							</Col>
							<Col span={9}>
								<div className='top'>
									<div className='title'>{t("price")}：</div>
									<div>
										<Input  value={item.price} onChange={(e) => changeInfo(index, "price", Number(e.target.value))}></Input>
									</div>
								</div>
							</Col>
							<Col span={2}>
								<div>
									<MinusCircleOutlined style={{ color: "red", fontSize: "23px" }} onClick={() => deleteObj(index)} />
								</div>
							</Col>
							<Col>
								<div className='top'>
									<div className='title'>{t("noteDescription")}：</div>
									<div>
										<Input value={item.remark} onChange={(e) => changeInfo(index, "remark", e.target.value)}></Input>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				);
			})}

			<Button type='primary' onClick={addItem}>
			+{t("diffrentTimePrice")}
			</Button>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	.frame {
		display: flex;
		width: 100%;
		margin-bottom: 20px;
		background-color: rgb(249, 249, 249);
		padding: 20px;
		border-radius: 4px;
		.top {
			display: flex;
			align-items: center;
			.title {
				min-width: 90px;
				text-align: right;
			}
		}
	}
`;
