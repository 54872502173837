import { http } from "../../../common/http";
import { IdentifyRecordsParam } from "../param/identify.records.param";


class IdentifyRecordsService {
	list = async (params: IdentifyRecordsParam) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + "/equipmentCameraLog",
			params,
		});
	};
}
export default new IdentifyRecordsService();
