import { http } from "src/common/http";
import { CustomerFeedbackParam } from "./customer.feedback.param";
import { CustomerFeedbackEntity } from "./customer.feedback.entity";

class CustomerFeedbackService {
	// questionType: Record<number, string> = {
	// 	1: "如何洗车",
	// 	2: "如何下单",
	// 	3: "机器不启动",
	// 	4: "如何参加活动",
	// 	5: "活动券未到账",
	// 	6: "优惠券延期",
	// 	7: "月卡延期",
	// 	8: "订单退款",
	// 	9: "服务投诉",
	// 	10: "加盟咨询",
	// };

	businessType: Record<number, string> = {
		0: "旧版本",
		1: "智能洗车业务",
		2: "美容业务",
		3: "维保业务",
		4: "其他",
	};

	questionType: Record<number, string[]> = {
		0: ["无法洗车-客户问题", "无法洗车-平台/网络/识别摄像头问题", "设备故障", "预约 ", "投诉/反馈", "咨询"],
		1: ["无法洗车-客户问题",'无法洗车-设备原因', "咨询", "投诉"],
		2: ["咨询", "预约", "投诉"],
		3: ["咨询", "预约", "投诉"],
		4: ["咨询", "投诉"],
	};

	concreteIssue: Record<number, string[]> = {
		11: ["设备不启动-停车位置", "设备不启动-车牌错误", "设备不启动-站点选择错误", '设备不启动-月卡/优惠券使用',"设备中途停止-碰撞限位杆", "其它"],
		12: ['设备不启动-识别摄像头无法正常识别', '设备不启动-网络问题', '设备不启动-软件问题无法正常下单','设备中途停止-其他故障', '其它'],
		13: ["如何下单/洗车咨询", "价格/门店位置/营业时间", "卡券使用", "其它"],
		14: ["洗车水异味", "洗不干净", "车辆损坏", "产生停车费",'化学液不喷洒或喷洒量小', '下架状态无法提供服务' ,"其它"],

		21: ["门店位置/营业时间", "卡券使用", "美容项目了解", "车况有问题咨询对应的美容项目", "其它"],
		22: ["无"],
		23: ["施工质量", "服务态度", "其它"],

		31: ["门店位置/营业时间", "卡券使用", "维保项目了解", "车况有问题咨询对应的维修方案", "其它"],
		32: ["无"],
		33: ["施工质量", "服务态度", "其它"],

		41: ["无"],
		42: ["无"],
	};

	channelType: Record<number, string> = {
		0: "电话呼入",
		1: "在线客服",
		2: "其他",
		3: "门店现场",
		4: "第三方平台",
		5: "回访",
	};
	programmeList: Record<number, string> = {
		1: "待处理",
		2: "待观察",
		3: "已处理",
	};

	list = async (param: CustomerFeedbackParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-feedback",
			method: "GET",
			params: param,
		});
	};

	add = async (data: CustomerFeedbackEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-feedback",
			method: "POST",
			data,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-feedback/" + id,
			method: "GET",
			data: id,
		});
	};

	update = async (data: CustomerFeedbackEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-feedback/" + data.id,
			method: "PUT",
			data,
		});
	};
	handle = async (data: CustomerFeedbackEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-feedback/process/" + data.id,
			method: "PUT",
			data,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-feedback/" + id,
			method: "DELETE",
			data: id,
		});
	};
	export = async (param: CustomerFeedbackParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-feedback/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};
}
export default new CustomerFeedbackService();
