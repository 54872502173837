import {
	AccountBookOutlined,
	AlertOutlined,
	ApartmentOutlined,
	AppstoreAddOutlined,
	CarOutlined,
	CreditCardOutlined,
	DashboardOutlined,
	DownOutlined,
	HomeOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	QrcodeOutlined,
	KeyOutlined,
	TeamOutlined,
	ToolOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Breadcrumb, ConfigProvider, Dropdown, Form, Input, Layout, Menu, message, Modal, Radio, RadioChangeEvent, Space } from "antd";
import moment from "moment";
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";
import AdminService, { currentInfo } from "../../module/admin/service/admin.service";
import { checkPermission } from "../../module/admin/service/permission.service";
import MenuService from "../../module/menu/service/menu.service";
import { setRem } from "../Util";
import zhCN from "antd/lib/locale/zh_CN";
import enUS from "antd/lib/locale/en_US";
import { IconFont } from "../config/IconFont";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

interface MenuArrEntity {
	key: string[];
	bread: string[];
}

export const BasicLayout: FC = () => {
	const adminInfo = currentInfo();
	const [collapsed, setCollapsed] = useState(false);
	const [menuArray] = useState(MenuService.menuArray);
	const [defaultKeyArr, setDefaultKeyArr] = useState<MenuArrEntity>({ key: [], bread: [] });
	const { t, i18n } = useTranslation();
	const [lng, setLng] = useState<string>(i18n.language);
	const location = useLocation();
	// const [status, setStatus] = useState(adminInfo.workStatus);

	// 分别引入moment语言包

	useEffect(() => {
		if (lng == "zh-CN") {
			require('moment/locale/zh-cn')
			return
		}
	}, [lng])

	const iconArr: Record<string, ReactNode> = {
		overview: <DashboardOutlined />,
		overview001: <DashboardOutlined />,
		dashboard: <DashboardOutlined />,
		site: <IconFont type='icon-zhandianguanli' />,
		site001: <IconFont type='icon-zhandianguanli' />,
		site002: <ApartmentOutlined />,
		product: <IconFont type='icon-shangpin' />,
		evaluation: <IconFont type='icon-comment-w' />,
		interiorClean: <IconFont type='icon-qicheqingjie' />,
		customer: <TeamOutlined />,
		customer001: <TeamOutlined />,
		customer002: <CreditCardOutlined />,
		customer004: <CarOutlined />,
		customer005: <CreditCardOutlined />,
		customer006: <AppstoreAddOutlined />,
		customer007: <AppstoreAddOutlined />,
		comboSale: <AccountBookOutlined />,
		order: <IconFont type='icon-wodedingdan' />,
		order001: <IconFont type='icon-wodedingdan' />,
		activity: <IconFont type='icon-liwuhuodong' />,
		activity001: <IconFont type='icon-huiyuanqia' />,
		coupon: <IconFont type='icon-weibiaoti2fuzhi02' />,
		couponList: <IconFont type='icon-weibiaoti2fuzhi02' />,
		finance: <IconFont type='icon-caiwuguanli' />,
		finance001: <IconFont type='icon-shujufenxi' />,
		finance002: <IconFont type='icon-shujufenxi' />,
		finance003: <IconFont type='icon-huiyuanqiamingxiguanli' />,
		company: <IconFont type='icon-gongsixinxi' />,
		finance005: <IconFont type='icon-fapiaofuwuxianxing' />,
		finance006: <IconFont type='icon-qianbao' />,
		finance007: <IconFont type='icon-liushui' />,
		finance008: <IconFont type='icon-renyuanjixiao' />,
		admin: <IconFont type='icon-ren' style={{ color: "#fff" }} />,
		admin001: <IconFont type='icon-ren' />,
		tool: <ToolOutlined />,
		tool001: <IconFont type='icon-console' />,
		tool002: <IconFont type='icon-tupian_huaban' />,
		tool003: <QrcodeOutlined />,
		toolMT: <KeyOutlined />,
		toolMtShop: <IconFont type='icon-zhandianguanli' />,
		equipment: <AlertOutlined />,
		equipment001: <AlertOutlined />,
		productComb: <IconFont type='icon-shangpinzuhe' />,
		rateGift: <IconFont type='icon-pingjiayouli' />,
		workStation: <IconFont type='icon-airudiantubiaohuizhi-zhuanqu_changdiyuding' />,
		pointDetailList: <IconFont type='icon-jifen' />,
		pointExchangeList: <IconFont type='icon-jifenduihuan' />,
		IdentifyRecords: <IconFont type='icon-saomiaoshibie380' />,
		instructionRecognition: <IconFont type='icon-hangdongzhiling' />,
		rechargeActivity: <IconFont type='icon-chongzhi' />,
		couponActivity: <IconFont type='icon-youhuiquanliebiao' />,
		userCoupon: <IconFont type='icon-youhuiquan-xianxing' />,
		fullGift: <IconFont type='icon-zengsongyouhuiquan' />,
		thirdOrder: <IconFont type='icon-hezuo' />,
		promoterAuditList: <IconFont type='icon-shenhe' />,
		withdrawalAuditList: <IconFont type='icon-tixian' />,
		firstOrder: <IconFont type='icon-youhuiquanguanli' />,
		question: <IconFont type='icon-wentiguanli-01' />,
		role: <IconFont type='icon-biaoshilei_jiaosepeizhi' />,
		registrationActivity: <IconFont type='icon-zaixianbaoming' />,
		propagateActivity: <IconFont type='icon-xuanchuan' />,
		packageActivity: <IconFont type='icon-shangpintaocanguanli' />,
		loginActivityList: <IconFont type='icon-denglurizhi' />,
		RecommendActivitylist: <IconFont type='icon-tuijian' />,
		giveAwayCoupon: <IconFont type='icon-zengsong' />,
		monthlyPlanConfig: <IconFont type='icon-gongzuojihua' />,
		pointType: <IconFont type='icon-leibieguanli' />,
		pointProduct: <IconFont type='icon-shangpin1' />,
		point: <IconFont type='icon-jifen' />,
		off: <IconFont type='icon-mendianyichangdingdan' />,
		permission: <IconFont type="icon-quanxianguanli" />,
		repairFeedback: <IconFont type="icon-baoxiu" />,
		customerFeedback: <IconFont type="icon-kehufankui" />,
		updateProduct: <IconFont type="icon-shangpinnew-copy" />,
		thirdCoupon: <IconFont type="icon-meituan14px" />,
		douyin: <IconFont type="icon-douyinquan" />,
		rideHailingAudit: <IconFont type="icon-sijirenzheng" />,
		earlyBird: <IconFont type="icon-hexiao" />,
		indexPopup: <IconFont type="icon-haibaodanchuang" />,




	};

	const chooseMenu = useCallback(
		(pathName: string): void => {
			let defaultKeyArr: string[] = [];
			let breadArr: string[] = [];

			MenuService.menuArray.some(function (row) {
				row.sub.some(function (row1) {
					if (pathName.indexOf(row1.path) > -1) {
						defaultKeyArr = [row.key, row1.key];
						breadArr = [row.title, row1.title];
					}
					return defaultKeyArr.length > 0;
				});
				return defaultKeyArr.length > 0;
			});
			defaultKeyArr = defaultKeyArr.length > 0 ? defaultKeyArr : ["home"];
			breadArr = breadArr.length > 0 ? breadArr : ["home"];
			document.title = t(breadArr[breadArr.length - 1]) + "-" + t("app-name");
			setDefaultKeyArr({ key: defaultKeyArr, bread: breadArr });
		},
		[t]
	);

	useEffect(() => {
		setRem();
		chooseMenu(location.pathname);
	}, [location.pathname, chooseMenu]);

	window.onresize = () => {
		setRem();
	};

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [originPassword, setOriginPassword] = useState("");
	const [newPassWord, setNewPassWord] = useState("");
	const [confirmPassWord, setConfirmPassWord] = useState("");

	const handleOk = async (param: any) => {
		const re = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{8,16}$/;
		if (originPassword === "") {
			message.error("密码不能为空");
		} else if (newPassWord === "") {
			message.error("密码不能为空");
		} else if (!re.test(newPassWord)) {
			message.error("密码必须是8-16位数字英文混合");
		} else if (newPassWord !== confirmPassWord) {
			message.error("两次密码不一致");
		} else {
			const { data } = await AdminService.updatePassWord(param);
			if (data.code === 200) {
				message.success(t("success"));
				setIsModalVisible(false);
			}
		}
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const UpdatePassWord = () => {
		setIsModalVisible(true);
	};

	const dropMenu = (
		<Menu>
			<Menu.Item key='0' onClick={UpdatePassWord}>
				<span>{t("update.password")}</span>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item
				key='3'
				danger={true}
				onClick={() => {
					AdminService.logOut();
				}}
			>
				{t("exit")}
			</Menu.Item>
		</Menu>
	);

	const lngArr = [
		{
			value: "zh-CN",
			label: "中文",
		},
		{
			value: "en-US",
			label: "English",
		},
	];

	// const statusArr = [
	// 	{
	// 		value: 1,
	// 		label: "空闲",
	// 	},
	// 	{
	// 		value: 2,
	// 		label: "忙碌",
	// 	},
	// ];

	const toggle = () => {
		setCollapsed(!collapsed);
	};

	const updateLanguage = (e: RadioChangeEvent) => {
		i18n.changeLanguage(e.target.value);
		setLng(e.target.value);
	};

	// const updateStatusArr = async () => {
	// 	const { data } = await AdminService.updateWorkStatus(Number(adminInfo.id));
	// 	if (data.code === 200) {
	// 		localStorage.setItem("adminInfo", JSON.stringify({ ...adminInfo, workStatus: status === 1 ? 2 : 1 }));
	// 		setStatus(currentInfo().workStatus);
	// 		message.success("修改状态成功");
	// 	}
	// };

	return (
		<Container>
			<Layout style={{ height: "100%" }}>
				<Sider trigger={null} collapsible collapsed={collapsed}>
					<Menu mode='inline' theme={"dark"} selectedKeys={defaultKeyArr?.key}>
						<Menu.Item key='home' icon={<HomeOutlined />}>
							<Link to={"/"}>{t("home")}</Link>
						</Menu.Item>
						{menuArray.map((row) => {
							// Check only admin
							const onlyAdmin: boolean = row.permission === -1;
							// Check has permission
							const hasPermission: boolean = row.permission > 0 && !checkPermission(row.permission);
							if (currentInfo().id !== 1 && (onlyAdmin || hasPermission)) {
								return "";
							}
							return (
								<SubMenu key={row.key} title={t(row.title)} icon={iconArr[row.key]}>
									{row.sub.map((row1) => {
										// Check only admin
										const onlyAdmin1: boolean = row1.permission === -1;
										// Check has permission
										const hasPermission1: boolean = row1.permission > 0 && !checkPermission(row1.permission);
										if (currentInfo().id !== 1 && (onlyAdmin1 || hasPermission1)) {
											return "";
										}
										return (
											<Menu.Item key={row1.key} icon={iconArr[row1.key]}>
												<Link to={row1.path}>{t(row1.title)}</Link>
											</Menu.Item>
										);
									})}
								</SubMenu>
							);
						})}
					</Menu>
				</Sider>
				<Layout className='site-layout'>
					<Header className='site-layout-background' style={{ padding: 0 }}>
						<div>
							{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
								className: "trigger",
								onClick: toggle,
							})}
						</div>
						<div>
							<Space>
								{/* <Radio.Group options={statusArr} onChange={updateStatusArr} value={status} optionType='button' buttonStyle='solid' /> */}
								<Radio.Group options={lngArr} onChange={updateLanguage} value={lng} optionType='button' buttonStyle='solid' />
								<Dropdown overlay={dropMenu}>
									<span className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
										{adminInfo.name} <DownOutlined />
									</span>
								</Dropdown>
							</Space>
						</div>
					</Header>
					<Breadcrumb style={{ margin: "16px 16px" }}>
						{defaultKeyArr?.bread.map((row: any, index: number) => {
							return <Breadcrumb.Item key={index}>{t(row)}</Breadcrumb.Item>;
						})}
					</Breadcrumb>
					<Content className='site-layout-background'>
						<ConfigProvider locale={lng == "zh-CN" ? zhCN : enUS}>
							<Outlet key={lng} />
						</ConfigProvider>
					</Content>
				</Layout>
			</Layout>

			<Modal
				width={"40%"}
				title={t("update.password")}
				visible={isModalVisible}
				onOk={() => {
					handleOk({ id: adminInfo.id, password: newPassWord, originalPassword: originPassword });
				}}
				onCancel={handleCancel}
			>
				<Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
					<Form.Item label={t("originPassword")}>
						<Input.Password
							allowClear
							placeholder={t("please.enter.originPassword")}
							onChange={(e) => {
								setOriginPassword(e.target.value);
							}}
						/>
					</Form.Item>
					<Form.Item label={t("newPassword")}>
						<Input.Password
							allowClear
							placeholder={t("please.enter.newPassword")}
							onChange={(e) => {
								setNewPassWord(e.target.value);
							}}
						/>
					</Form.Item>
					<Form.Item label={t("confirm.new.password")}>
						<Input.Password
							allowClear
							placeholder={t("please.confirm.newPassword")}
							onChange={(e) => {
								setConfirmPassWord(e.target.value);
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};

const Container = styled.div`
	height: 100%;

	#components-layout-demo-custom-trigger .trigger {
		padding: 0 24px;
		font-size: 18px;
		line-height: 64px;
		cursor: pointer;
		transition: color 0.3s;
	}

	#components-layout-demo-custom-trigger .trigger:hover {
		color: #1890ff;
	}

	#components-layout-demo-custom-trigger .logo {
		height: 32px;
		margin: 16px;
		background: rgba(255, 255, 255, 0.3);
	}

	.site-layout {
		min-width: 1166px;

		.site-layout-background {
			background: #fff;
			display: flex;
			justify-content: space-between;
			margin: 0 16px;
			overflow-y: scroll;
		}

		header.site-layout-background {
			overflow: hidden;
		}
	}
`;
