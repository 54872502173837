import { http } from "../../../common/http";
import { RechargeParam } from "../param/recharge.param";

class RechargeService{

    list = async (param:RechargeParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/rechargeRecord",
			method: "GET",
			params: param,
		});
	};



    exportRecharge=async(param:RechargeParam)=>{
        return http({
			url: process.env.REACT_APP_ADMIN_API + "/rechargeRecord/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};

    }


export default new RechargeService()