import { FC, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Button, Form, Input, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import douyinService from "src/module/douyin/douyin.service";
import { SelectCouponByType } from "src/module/coupon/component/SelectCouponByType";
import { DouyinEntity } from "src/module/douyin/douyin.coupon.entity ";
import { SelectMembershipCardByType } from "src/module/membershipCard/component/SelectMembershipCardByType";
export const DouyinAdd: FC = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { t } = useTranslation();
	const [info, setInfo] = useState<DouyinEntity>({});

	const init = useCallback(async () => {
		if (id) {
			const { data } = await douyinService.detail(Number(id));
			if (data.code === 200) {
				
				setInfo(data.data);
			}
		}
	}, [id]);

	useEffect(() => {
		init();
	}, [init]);

	const onFinish = async () => {
		console.log("4421", info);

		const { data } = await douyinService.douyinAddOrUpdate({ id: Number(id), ...info });
		if (data.code === 200) {
			message.success(t("success"));
			navigate("/activity/douyin");
		} else {
			message.error(data.msg);
		}
	};

	return (
		<Container>
			<Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
				<Form.Item label={t("douyinProductId")}>
					<Input
						value={info?.productId}
						onChange={(e) => {
							setInfo({ ...info, productId: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("douyinProductName")}>
					<Input
						value={info?.productName}
						onChange={(e) => {
							setInfo({ ...info, productName: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("couponName")}>
					<SelectCouponByType
						value={info?.couponId}
						onChange={(e: number) => {
							console.log("e11",e);
							setInfo({ ...info, couponId: e });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("bandMembershipCard")} >
					<SelectMembershipCardByType readonly={true}
					
					value={info?.cardList}
						onChange={(e: any) => {
							
							setInfo({ ...info, subscriptionId: e[0]?.cardId||undefined,cardList:e});
						}} />
				</Form.Item>

				<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
					<Button type='primary' onClick={onFinish}>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
