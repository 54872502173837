import { http } from "../../../common/http";

class MeiTuanService {
	async sendAuthCode(code: string) {
		return await http({
			url: process.env.REACT_APP_ADMIN_API + `/meituan/auth/${code}`,
			method: "POST",
		});
	}
	async meiTuanShopList() {
		const res = await http({
			url: process.env.REACT_APP_ADMIN_API + "/meituan/shop",
			method: "GET",
		});
		return res.data;
	}
}

export default new MeiTuanService();
