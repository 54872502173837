import { http } from "../../../common/http";
import { ProductCombEntity } from "../entity/product.comb.entity";
import { ProductCombParams } from "../param/product.comb.params";

class ProductCombService {
	list = async(params: ProductCombParams) => {
		return http({ method: "GET", url: process.env.REACT_APP_ADMIN_API + `/productCombo`, params });
	};

  export=async(params: ProductCombParams) => {
    return http({ method: "GET", url: process.env.REACT_APP_ADMIN_API + `/productCombo/export`, params,	responseType: "blob", });
  };

  delete=async(id: number) => {
    return http({ method: "DELETE", url: process.env.REACT_APP_ADMIN_API + `/productCombo/${id}`,data:id });
  };

  add=async(params:  ProductCombEntity) => {
    return http({ method: "POST", url: process.env.REACT_APP_ADMIN_API + `/productCombo`,data:params });
  };


  detail=async(id:number) => {
    return http({ method: "GET", url: process.env.REACT_APP_ADMIN_API + `/productCombo/${id}`,data:id });
  };

update=async(data:ProductCombEntity) => {
  return http({ method: "PUT", url: process.env.REACT_APP_ADMIN_API + `/productCombo/${data.id}`,data });
};

}

export default new ProductCombService();
