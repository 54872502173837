import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, InputNumber, message, Radio, Space, Tooltip } from "antd";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SelectPromoter } from "src/module/promoterAudit/components/SelectPromoter";
import { UploadImg } from "../../common/components/UploadImg";
import { MultiSelectRole } from "../../module/admin/components/MultiSelectRole";
import { SelectCouponType } from "../../module/coupon/component/SelectCouponType";
import { CouponEntity } from "../../module/coupon/entity/coupon.entity";
import couponService from "../../module/coupon/service/coupon.service";
import { MultiSelectPayWay } from "../../module/finance/component/MultiSelectPayWay";
import { MultiSelectComboProductBySite } from "src/module/product/component/multi.select.comboProduct.by.shop";
import MyEditor from "src/common/components/MyEditor";
import { MultiSelectProductSkuBySiteUpgrade } from "src/module/product/component/multi.select.productSku.by.shop.upgrade copy";
import { QuestionCircleOutlined } from "@ant-design/icons";

const formItemLayout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 18 },
};

const { TextArea } = Input;

export const CouponAdd: FC = () => {
	const [info, setInfo] = useState<CouponEntity>({
		couponType: 50,
		deductMoney: 0.0,
		discount: 0.0,
		effectiveDay: 0,
		expirationTime: "",
		limitNum: 1,
		maxMoney: 0,
		productId: "",
		productName: "",
		remainingNum: 0,
		shopId: "",
		shopName: "",
		startTime: "",
		upgradeInfo: "",
		title: "",
		type: 1, // 1 Master 2 Sub
		grantNum: 1,
		isDefault: 1,
		effectiveWay: 1,
		useStyle: 2,
		newUser: 2,
		// couponType: 50,
		// deductMoney: 0.0,
		// discount: 0.0,
		// effectiveDay: 0,
		// expirationTime: "",
		// limitNum: 1,
		// maxMoney: 0,
		// productId: "",
		// productName: "",
		// remainingNum: 0,
		// shopId: "",
		// shopName: "",
		// startTime: "",
		// title: "",
		// type: 1, // 1 Master 2 Sub
		// grantNum: 1,
		// isDefault: 1,
		// effectiveWay: 1,
		// worth:0
	});
	// const [comboProducts, setComboProducts] = useState()

	const [editorLoading, setEditorLoading] = useState<boolean>(false);
	const [rule, setRule] = useState<string | undefined>("");

	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id, copy } = useParams();
	const init = useCallback(async () => {
		const paramId = Number(id);
		if (paramId > 0) {
			const { data } = await couponService.detail(paramId);

			if (!copy) {
				setInfo({
					...data.data,
				});
				setRule(data.data.useRule);
			} else {
				setInfo({
					...data.data,
					id: undefined,
				});
				setRule(data.data.useRule);
			}
		}
		setEditorLoading(true);
	}, [copy, id]);

	useEffect(() => {
		init();
	}, [init]);

	const finish = async () => {
		if (info.couponType === 64) {
			const productIdArr: number[] = [];
			const upgradeInfoArr: { shopId?: number; shopTitle?: string; sourceId?: number; sourceTitle?: string; targetId?: number; targetTitle?: string }[] =
				JSON.parse(info.upgradeInfo!);
			upgradeInfoArr.map((item) => {
				productIdArr.push(item.sourceId!);
			});
			info.productId = productIdArr.toString();
		}

		const { data } = await couponService.addOrUpdate({ ...info, useRule: rule });
		if (data.code === 200) {
			message.success(t("success"));
			navigate("/coupon/coupon");
		} else {
			message.error(data.msg);
		}
	};

	const onChange = (field: string, e: any) => {
		setInfo({
			...info,
			[field]: e,
		});
	};

	return (
		<Container>
			<Form>
				<Form.Item label={t("image")} {...formItemLayout}>
					<UploadImg size='750*422' value={info?.url} onChange={(e: string) => onChange("url", e)} />
				</Form.Item>
				<Form.Item label={t("title")} {...formItemLayout}>
					<Input value={info?.title} onChange={(e) => onChange("title", e.target.value)} />
				</Form.Item>
				<Form.Item label={t("bindedPromoter")} {...formItemLayout}>
					<SelectPromoter value={info?.promoterId} onChange={(e: any) => onChange("promoterId", e)} />
				</Form.Item>
				<Form.Item label={t("admin.role")} {...formItemLayout}>
					<MultiSelectRole value={info?.roleIds} onChange={(e: number) => onChange("roleIds", e)} />
				</Form.Item>
				<Form.Item label={t("couponType")} {...formItemLayout}>
					<SelectCouponType value={info?.couponType} onChange={(e: number) => onChange("couponType", e)} />
				</Form.Item>
				{info.couponType === 54 && (
					<Form.Item label={t("money_off.title")} {...formItemLayout}>
						<Space>
							{t("money_off.spent")}
							<Input type={"number"} value={info?.maxMoney} onChange={(e) => onChange("maxMoney", e.target.value)} />
							{t("money_off.off")}
							<Input type={"number"} value={info?.deductMoney} onChange={(e) => onChange("deductMoney", e.target.value)} />
						</Space>
					</Form.Item>
				)}
				{info.couponType === 56 && (
					<Form.Item label={t("discount")} {...formItemLayout} tooltip={t("discountTip")}>
						<Input type={"number"} value={info?.discount} onChange={(e) => onChange("discount", e.target.value)} />
					</Form.Item>
				)}
				{info.couponType === 60 && (
					<Form.Item label={t("fixedMoney")} {...formItemLayout} tooltip={t("fixedMoneyTip")}>
						<Input type={"number"} value={info?.deductMoney} onChange={(e) => onChange("deductMoney", e.target.value)} />
					</Form.Item>
				)}
				{/* {info.couponType === 64 && (
					<Form.Item label={t("upgrade")} {...formItemLayout}>
						<MultiSelectProductBySiteUpgrade value={info.upgradeInfo} onChange={(e: string) => setInfo({ ...info, upgradeInfo: e })} />
					</Form.Item>
				)} */}

				{info.couponType === 64 && (
					<Form.Item label={t("upgrade")} {...formItemLayout}>
						<MultiSelectProductSkuBySiteUpgrade value={info.upgradeInfo} onChange={(e: string) => setInfo({ ...info, upgradeInfo: e })} />
					</Form.Item>
				)}

				<Form.Item label={t("product")} {...formItemLayout}>
					<MultiSelectComboProductBySite value={info?.couponProductList} onChange={(e: any) => onChange("couponProductList", e)} />
				</Form.Item>

				<Form.Item label={t("worth")} {...formItemLayout}>
					<InputNumber step='0.1' value={info?.worth} onChange={(e) => onChange("worth", e)} /> 元
				</Form.Item>
				<Form.Item label={t("grantNum")} {...formItemLayout}>
					<Input type={"number"} value={info?.grantNum} onChange={(e) => onChange("grantNum", e.target.value)} />
				</Form.Item>
				<Form.Item label={t("limitNum")} {...formItemLayout}>
					<Input type={"number"} value={info?.limitNum} onChange={(e) => onChange("limitNum", e.target.value)} />
				</Form.Item>
				<Form.Item label={t("startTime")} {...formItemLayout}>
					<DatePicker
						showTime
						value={info?.startTime ? moment(info?.startTime) : undefined}
						onChange={(_, value: string) => onChange("startTime", value)}
					/>
				</Form.Item>
				<Form.Item label={t("autoChooseProduct")} {...formItemLayout}>
					<Radio.Group value={info?.isDefault} buttonStyle='solid' onChange={(e) => onChange("isDefault", e.target.value)}>
						<Radio.Button value={1}>{t("yes")}</Radio.Button>
						<Radio.Button value={0}>{t("no")}</Radio.Button>
					</Radio.Group>
				</Form.Item>
				{/* <Form.Item label="仅限新用户使用" {...formItemLayout}>
					<Radio.Group value={info?.newUser} buttonStyle='solid' onChange={(e) => onChange("newUser", e.target.value)}>
						<Radio.Button value={1}>{t("yes")}</Radio.Button>
						<Radio.Button value={2}>{t("no")}</Radio.Button>
					</Radio.Group>
				</Form.Item> */}

				<Form.Item label='仅限新用户' {...formItemLayout}>
					<Space>
						<Form.Item labelCol={{ span: 4 }}>
							<Radio.Group value={info.newUser} buttonStyle='solid' onChange={(e) => onChange("newUser", e.target.value)}>
								<Radio.Button value={1}>{t("yes")}</Radio.Button>
								<Radio.Button value={2}>{t("no")}</Radio.Button>
							</Radio.Group>
						</Form.Item>
						<Tooltip title='仅限未下过任何普通订单的新用户'>
							{/* <Typography.Link href='#API'>Need Help?</Typography.Link> */}
							<QuestionCircleOutlined className='questionIcon' size={25} />
						</Tooltip>
					</Space>
				</Form.Item>

				{info.couponType !== 54 && (
					<Form.Item label={t("all.goods.discount")} {...formItemLayout}>
						<Radio.Group value={info?.useStyle} buttonStyle='solid' onChange={(e) => onChange("useStyle", e.target.value)}>
							<Radio.Button value={1}>{t("yes")}</Radio.Button>
							<Radio.Button value={2}>{t("no")}</Radio.Button>
						</Radio.Group>
					</Form.Item>
				)}
				<Form.Item label={t("effectivenessConfig")} {...formItemLayout}>
					<Radio.Group value={info?.effectiveWay} onChange={(e) => onChange("effectiveWay", e.target.value)}>
						<Radio value={"2"}>
							<Space>
								{t("day")}
								<Input type={"number"} step='1' value={info?.effectiveDay} onChange={(e) => onChange("effectiveDay", e.target.value)} />
							</Space>
						</Radio>
						<Radio value={"1"}>
							<Space>
								{t("date")}
								<DatePicker
									value={info?.expirationTime && info?.expirationTime !== " 23:59:59" ? moment(info?.expirationTime) : undefined}
									onChange={(_, value: string) => onChange("expirationTime", value ? value + " 23:59:59" : undefined)}
								/>
							</Space>
						</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("payWay")} {...formItemLayout}>
					<MultiSelectPayWay value={info?.payTypes} onChange={(e: string) => onChange("payTypes", e)} />
				</Form.Item>
				<Form.Item label={t("remark")} {...formItemLayout}>
					<TextArea value={info?.remark} onChange={(e) => onChange("remark", e.target.value)} />
				</Form.Item>
				<Form.Item label={t("useRule")} {...formItemLayout}>
					{editorLoading && <MyEditor value={rule} onChange={(e: any) => setRule(e)} />}
				</Form.Item>

				<div>
					<Button type={"primary"} onClick={finish}>
						{t("submit")}
					</Button>
				</div>
			</Form>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	padding: 10px;

	.questionIcon {
		position: relative;
		bottom: 15px;
		color: #1890ff;
	}
`;
