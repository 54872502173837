import { http } from '../../../common/http';
import { CarLicenseEntity } from '../entity/carlicense.entity';
import { CarLicenseParam } from '../params/carlicense.param';

class CarLicenseService {
	carType = {
		0: '未知',
		1: '小轿车',
		2: 'SUV',
		3: '面包车',
		4: '中巴车',
		5: '大巴车',
		6: '小货车',
		7: '大货车',
	};
	export = async (params: CarLicenseParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + '/license/export',
			method: 'GET',
			params,
			responseType: 'blob',
		});
	};

	list = async (param: CarLicenseParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + '/license',
			method: 'GET',
			params: param,
		});
	};
	add = async (param: CarLicenseEntity) => {
		return http({ url: process.env.REACT_APP_ADMIN_API + '/license', method: 'POST', data: param });
	};

	detail = async (id: number) => {
		return http({ url: process.env.REACT_APP_ADMIN_API + `/license/` + id, method: 'GET' });
	};

	update = async (param: CarLicenseEntity) => {
		return http({ url: process.env.REACT_APP_ADMIN_API + `/license/${param.id}`, method: 'PUT', data: param });
	};

	licenseType = async () => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + '/licensesTypeConfig/all',
			method: 'GET',
		});
	};
	carLogo = async () => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + '/licensesCarLogoConfig/all',
			method: 'GET',
		});
	};

	binded = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + '/license/vip-bind/' + id,
			method: 'GET',
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/license/` + id,
			method: 'DELETE',
		});
	};
}
export default new CarLicenseService();
