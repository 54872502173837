import {http} from "../../../common/http";

class RoleService {
    all = async () => {
        return http({
            method: 'GET',
            url: process.env.REACT_APP_ADMIN_API+`/role/all`
        });
    }
}

export default new RoleService();