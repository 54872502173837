/*
 * @Author: Yujia fyujia0815@gmail.com
 * @Date: 2022-08-30 10:34:07
 * @LastEditors: Yujia fyujia0815@gmail.com
 * @LastEditTime: 2022-11-30 14:25:08
 * @FilePath: \data-center-ts\src\module\coupon\service\coupon.type.service.ts
 */
class CouponTypeService {
    typeArray: Record<number, string> = {
        50: "free",
        54: "money_off.title",
        56: "discount",
        60: "fixedMoney",
        64: "upgrade"
    }

}
export default new CouponTypeService();