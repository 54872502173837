import { http } from "../../../common/http"
import { CarwashParam } from "../params/carwash.param"

class CarwashService {
    list = async (param: CarwashParam) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API+'/equipmentOperateLog/washCar',
            method: 'GET',
            params: param,
        })
    }

    export =async (param: CarwashParam)=>{
        return http({ url: process.env.REACT_APP_ADMIN_API+'/equipmentOperateLog/exportWashCar',
        method: 'GET',
        params: param,
        responseType: "blob",})
    }
}


			
export default new CarwashService();