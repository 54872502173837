/*
 * @Author: Yujia fyujia0815@gmail.com
 * @Date: 2022-11-30 14:29:05
 * @LastEditors: Yujia fyujia0815@gmail.com
 * @LastEditTime: 2022-12-01 11:48:57
 * @FilePath: \data-center-ts\src\module\product\component\multi.select.product.by.shop.upgrade.tsx
 */
import { MinusOutlined, PlusOutlined, ArrowRightOutlined } from "@ant-design/icons";

import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { SelectSite } from "src/module/site/component/select.site";
import { SingleSelectProduct } from "./single.select.product";
import { Props } from "../../../common/entity/props.entity";
import { SelectSku } from "./select.sku";

interface ProductsProp extends Props {
	onChange?: (arg: string) => void;
	value?: string;
}

interface proTypes {
	productId: null | LabelObj;
	upgradeProductId: null | LabelObj;
	targetSkuIds: null | LabelObj[];
	sourceSkuIds: null | LabelObj[];
}
interface LabelObj {
	label: string;
	value: number;
}
interface UpgradeProduct {
	shopId?: number;
	shopTitle?: string;
	sourceId?: number;
	sourceTitle?: string;
	targetId?: number;
	targetTitle?: string;
	sourceSkuIds?: string ;
	targetSkuIds?: string ;
	sourceSkuTitle?: string;
	targetSkuTitle?: string;
}

export const MultiSelectProductSkuBySiteUpgrade = (props: ProductsProp) => {
	const [siteObj, setSiteObj] = useState<LabelObj>();
	const [productObj, setProductObj] = useState<proTypes>({
		productId: null,
		upgradeProductId: null,
		targetSkuIds: null,
		sourceSkuIds: null,
	});
	const [upgradeProductArr, setUpgradeProductArr] = useState<UpgradeProduct[]>([]);
	const [pointerArr, setPointerArr] = useState<string[]>([]);

	const init = useCallback(() => {
		if (props.value) {
			const productArr: UpgradeProduct[] = JSON.parse(props.value);
			setUpgradeProductArr(productArr);
			const newArray: string[] = [];
			productArr.map((item) => {
				newArray.push([item.shopId, item.sourceId, item.targetId, item.sourceSkuIds, item.targetSkuIds].toString());
			});
			setPointerArr(newArray);
		}
	}, [props.value]);

	useEffect(() => {
		init();
	}, [init]);

	const changeSite = (e: { label: string; value: number }) => {
		setSiteObj(e);
		setProductObj({
			productId: null,
			upgradeProductId: null,
			targetSkuIds: null,
			sourceSkuIds: null,
		});
	};
	const changeProduct = (name: keyof proTypes) => {
		return (val: LabelObj) => {
			console.log(name,val);
			
			setProductObj({
				...productObj,
				[name]: val,
			});

			console.log({
				...productObj,
				[name]: val,
			});
			
		};
	};
	const addItem = () => {
		if (!siteObj?.value || !productObj.productId?.value || !productObj.upgradeProductId?.value) return;
		if (pointerArr.includes([siteObj?.value, productObj.productId?.value, productObj.upgradeProductId?.value].toString())) {
			return;
		} else {

			

			setPointerArr([
				...pointerArr,
				[
					siteObj?.value,
					productObj.productId?.value,
					productObj.upgradeProductId?.value,

					// productObj.targetSkuIds?.value,
					// productObj.sourceSkuIds?.value,
				].toString(),
			]);
		}

		const skuIdsArr= productObj.sourceSkuIds?.map((item)=>{return item.value})
		const skuTitleArr= productObj.sourceSkuIds?.map((item)=>{return item.label})

		const tarSkuIdsArr= productObj.targetSkuIds?.map((item)=>{return item.value})
		const tarSkuTitleArr= productObj.targetSkuIds?.map((item)=>{return item.label})
	
	
		

		const obj: UpgradeProduct = {
			shopId: siteObj?.value,
			shopTitle: siteObj?.label,
			sourceId: productObj.productId?.value,
			sourceTitle: productObj.productId?.label,
			targetId: productObj.upgradeProductId?.value,
			targetTitle: productObj.upgradeProductId?.label,
			sourceSkuIds: skuIdsArr?.toString(),
			targetSkuIds: tarSkuIdsArr?.toString(),
			sourceSkuTitle:skuTitleArr?.toString(),
			targetSkuTitle: tarSkuTitleArr?.toString(),
		};
		console.log(obj);

		// setSiteObj(void 0);
		setProductObj({
			productId: null,
			upgradeProductId: null,
			targetSkuIds: null,
			sourceSkuIds: null,
		});
		setUpgradeProductArr([...upgradeProductArr, obj]);
		props.onChange && props.onChange(JSON.stringify([...upgradeProductArr, obj]));
	};

	const removeItem = (index: number) => {
		const delArr = [...upgradeProductArr];
		const delPoint = [...pointerArr];
		delPoint.splice(index, 1);
		delArr.splice(index, 1);
		setUpgradeProductArr(delArr);
		setPointerArr(delPoint);
		props.onChange && props.onChange(JSON.stringify(delArr));
	};

	return (
		<Container>
			<div className='firstLine'>
				<div className='container'>
					<SelectSite onChange={changeSite} value={siteObj} labelInValue></SelectSite>
				</div>
				<div className='container'>
					<SingleSelectProduct
						labelInValue
						siteId={siteObj?.value}
						value={productObj.productId}
						onChange={changeProduct("productId")}
						className='selectProduct'
					></SingleSelectProduct>
				</div>
				<div className='container'>
					<SelectSku
						value={productObj.sourceSkuIds}
						productId={productObj.productId?.value}
						labelInValue
						onChange={changeProduct("sourceSkuIds")}
						className='selectProduct'
					></SelectSku>
				</div>

				<ArrowRightOutlined className='right rightOne' />
				<div className='container'>
					<SingleSelectProduct
						labelInValue
						siteId={siteObj?.value}
						value={productObj.upgradeProductId}
						onChange={changeProduct("upgradeProductId")}
						className='selectProduct'
					></SingleSelectProduct>
				</div>

				<div className='container'>
					<SelectSku
						value={productObj.targetSkuIds}
						productId={productObj.upgradeProductId?.value}
						labelInValue
						onChange={changeProduct("targetSkuIds")}
						className='selectProduct'
					></SelectSku>
				</div>
				<PlusOutlined className='plus' onClick={addItem} />
			</div>
			<div className='secondLine'>
				{upgradeProductArr.map((item, index) => {
					return (
						<div className='upgradeList' key={index}>
							{item.shopTitle} - {item.sourceTitle} - {item.sourceSkuTitle} <ArrowRightOutlined className='right' /> {item.targetTitle} - 
							{item.targetSkuTitle}
							<MinusOutlined className='remove' onClick={() => removeItem(index)} />
						</div>
					);
				})}
			</div>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	.firstLine {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	.selectProduct {
		width: 0;
		min-width: 200px;
	}
	.plus {
		color: rgb(9, 109, 217);
	}
	.right {
		color: rgb(9, 109, 217);
		width: 30px;
	}
	.rightOne {
		margin-right: 20px;
	}
	.container {
		margin-right: 30px;
		cursor: pointer;
	}
	.secondLine {
		.upgradeList {
			margin-bottom: 5px;
		}
	}
	.remove {
		color: rgb(245, 34, 45);
		margin-left: 30px;
		cursor: pointer;
	}
`;
