import { http } from "../../../common/http";
import { PointProductEntity } from "../entity/point.product.entity ";
import { PointProductParam } from "../param/point.product.param ";

class PointProductService{

    list = async (param:PointProductParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/points-product",
			method: "GET",
			params: param,
		});
	};

	add = async (data:PointProductEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/points-product",
			method: "POST",
			data
		});
	};

	detail=async (id:number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API +"/points-product/"+ id,
			method: "GET",
			data:id
		});
	};

	update=async (data:PointProductEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API +"/points-product/"+ data.id,
			method: "PUT",
			data,
		});
	};


	delete=async (id:number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API +"/points-product/"+ id,
			method: "DELETE",
			data:id
		});
	};




    }


export default new PointProductService()