import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Space, Table, message } from "antd";
import { useTranslation } from "react-i18next";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import { SelectFaultType } from "src/module/site/component/select.faul.type";
import siteService from "src/module/site/service/site.service";
import { SiteOffParam } from "src/module/site/param/site.off.param";
import { MultiSelectSite } from "src/module/site/component/multi.select.site";
import moment from "moment";
import Util from "src/common/Util";
// import moment from "moment";

const { RangePicker } = DatePicker;
export const ShopOffLog: FC = () => {
	const faultType: Record<number, string> = {
		1: "手工下架",
		2: "非营业时间",
		3: "维护",
		4: "低温保护",
		5: "网络异常",
		6: "市政施工",
	};
	const { t } = useTranslation();
	const [dataList, setDataList] = useState([]);

	const [searchObj, setSearchObj] = useState<SiteOffParam>({
		startTime: moment().startOf("months").format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageNum: 1, customPageSize: 15
	});
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({ customPageNum: 1, customPageSize: 15 });
	const [total, setTotal] = useState(0);

	const search = (value: any) => {
		const info = {
			...searchObj,
			...value,
			abnormalDate: undefined,
			recoverDate: undefined,
			startTime: value.abnormalDate && value.abnormalDate[0] && value.abnormalDate[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.abnormalDate && value.abnormalDate[1] && value.abnormalDate[1].format("YYYY-MM-DD") + " 23:59:59",
			recoverStartTime: value.recoverDate && value.recoverDate[0] && value.recoverDate[0].format("YYYY-MM-DD") + " 00:00:00",
			recoverEndTime: value.recoverDate && value.recoverDate[1] && value.recoverDate[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageNum: 1, customPageSize: 15
			
		};
		
		
		setSearchObj(info);
	};




	const init = useCallback(async () => {
		const res = await siteService.shopOffLog({...searchObj});
		if (res.data.code === 200) {
			setDataList(res.data.data.records);
			setTotal(res.data.data.total);
		} else {
			message.error(res.data.msg);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const download = async () => {
		const res = await siteService.shopOffExport(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "product");
		message.success(t("export.success"));
	};

	const columns = [
		{
			title: t("site.number"),
			dataIndex: "shopNumber",
		},
		{
			title: t("site.name"),
			dataIndex: "title",
		},

		{
			title: t("faultType"),
			dataIndex: "offCode",
			render: (text: number) => {
				return faultType[text];
			},
		},
		{
			title: t("startTime"),
			dataIndex: "startTime",
		},
		{
			title: t("restoreTime"),
			dataIndex: "endTime",
		},
	];

	return (
		<Container>
			<Form layout='inline' style={{ marginBottom: 20 }} onFinish={search} initialValues={{ date: [moment().startOf("months"), moment()] }}>
				<Form.Item label={t("site")} name='shopIds'>
					<MultiSelectSite />
				</Form.Item>

				<Form.Item label={t("faultType")} name='offCode'>
					<SelectFaultType />
				</Form.Item>

				<Form.Item label={t("abnormalDate")} name='abnormalDate'>
					<RangePicker  />
				</Form.Item>
				<Form.Item label={t("recoverDate")} name='recoverDate'>
					<RangePicker  />
				</Form.Item>

				<Form.Item>
					<Space>
						<Button htmlType='submit' type='primary'>
							{t("search")}
						</Button>
						<Button type='primary' onClick={download}>
							{t("export")}
						</Button>
					</Space>
				</Form.Item>
			</Form>

			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(_, index) => index + ""}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({ ...searchObj, customPageNum: pagination.current, customPageSize: pagination.pageSize });
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
