import styled from "@emotion/styled";
import { Tooltip } from "antd";
import { FC, useEffect, useState } from "react";

interface props {
	isShow: boolean;
	type: string;
}

const Notification: FC<props> = (props) => {
	const [isShow, setIsShow] = useState<boolean>(false);
	const clickCard = () => {
		console.log(props.type);
	};
	useEffect(() => {
		setIsShow(props.isShow);
	}, [props.isShow]);
	return (
		<Main>
			<Tooltip
				title={<div className='tooltipTitle' dangerouslySetInnerHTML={{ __html: props.type }}></div>}
				autoAdjustOverflow
				// overlayInnerStyle={{ minWidth: "400px" }}
			>
				<div className='container' style={{ display: isShow ? "flex" : "none" }} onClick={clickCard}>
					<div className='main'>?</div>
				</div>
			</Tooltip>
		</Main>
	);
};

const Main = styled.div`
	.container {
		width: 1vmax;
		height:1vmax;
		border-radius: 50%;
		border: 1px solid #aaa;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 0.5vmax;
		top: 0.5vmax;
		color: #aaa;
		cursor: pointer;
	}
`;
export default Notification;
