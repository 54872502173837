import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { t } from "i18next";

import { SelectSite } from "src/module/site/component/select.site";
import { FeedbackHandingsEntity, RepairFeedbackEntity } from "src/module/repairFeedback/repair.feedback.entity";
import repairFeedbackService from "src/module/repairFeedback/repair.feedback.service";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";

export const RepairFeedbackAdd: FC = () => {
	const { confirm } = Modal;
	const navigate = useNavigate();
	const { Option } = Select;
	const { TextArea } = Input;
	const [info, setInfo] = useState<RepairFeedbackEntity>({});
	const [feedBackArr, setFeedBackArr] = useState<FeedbackHandingsEntity[]>([]);

	const params = useParams();

	const init = useCallback(async () => {
		if (params.id) {
			const { data } = await repairFeedbackService.detail(Number(params.id));
			if (data.code === 200) {
				setInfo(data.data);
				setFeedBackArr(data.data.feedbackHandings);
			}
		}
	}, [params.id]);

	useEffect(() => {
		init();
	}, [init]);

	const startWorking = async () => {
		await repairFeedbackService.startWorking(Number(params.id));
		init();
	};

	const onFinish = async () => {
		if (!info.type || !info.fault) {
			message.error("请选择故障");
			return;
		}
		if (!params.id) {
			const { data } = await repairFeedbackService.add({
				...info,
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/repairFeedback");
			} else {
				message.error(data.msg);
			}
		} else {
			console.log("11", feedBackArr);
			if (feedBackArr.length == 0) {
				message.error("请增加故障信息反馈");
				return;
			}

			const { data } = await repairFeedbackService.update({
				...info,
				feedbackHandings: feedBackArr,
				id: Number(params.id),
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/repairFeedback");
			} else {
				message.error(data.msg);
			}
		}
	};

	const addFeedback = () => {
		setFeedBackArr([
			...feedBackArr,
			{
				description: "",
				number: "",
				position: "",
				type: undefined,
				id: undefined,
				feedbackId: Number(params.id),
			},
		]);
	};

	// const deteleInfo = (index: number) => {
	// 	feedBackArr.splice(index, 1);
	// 	setFeedBackArr([...feedBackArr]);
	// };

	const deteleInfo = (index: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				feedBackArr.splice(index, 1);
				setFeedBackArr([...feedBackArr]);
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const changeFeedBack = (type: keyof FeedbackHandingsEntity, feedBackIndex: number) => {
		return (e: any) => {
			const newArr = feedBackArr.map((item, index) => {
				if (index === feedBackIndex) {
					item[type] = type === "description" ? e.target.value : e;
				}
				return item;
			});
			setFeedBackArr([...newArr]);
		};
	};
	return (
		<Container>
			{params.detail && <div className="mask"></div>  }
			{params.id && (
				<div className='head'>
					<div className='icon'></div>
					<div className='title'>{t("repairFeedback")}</div>
				</div>
			)}
			<Form labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} style={{ padding: 20 }}>
				<Form.Item label={t("site")}>
					<SelectSite
						level={1}
						value={info?.shopId}
						onChange={(e: number) => {
							setInfo({ ...info, shopId: e });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("failureMode")} required>
					<Select
						allowClear
						value={info?.type}
						onChange={(e: number) => {
							setInfo({ ...info, type: e, fault: undefined });
						}}
					>
						{Object.keys(repairFeedbackService.type).map((item: any) => {
							return (
								<Option key={item} value={Number(item)}>
									{repairFeedbackService.type[item]}
								</Option>
							);
						})}
					</Select>
				</Form.Item>

				<Form.Item label={t("concreteFault")} required>
					<Select
						allowClear
						value={info?.fault}
						onChange={(e) => {
							setInfo({ ...info, fault: e });
						}}
					>
						{info.type &&
							Object.keys(repairFeedbackService.faultType[Number(info.type)]).map((item: any) => {
								return (
									Number(item) !== 0 && (
										<Option key={item} value={Number(item)}>
											{repairFeedbackService.faultType[Number(info.type)][item]}
										</Option>
									)
								);
							})}
					</Select>
				</Form.Item>

				<Form.Item label={t("problemDescription")}>
					<TextArea
						allowClear
						value={info?.description}
						onChange={(e) => {
							setInfo({ ...info, description: e.target.value });
						}}
					/>
				</Form.Item>

				{params.id && (
					<>
						<Form.Item label={t("createdName")}>{info?.createdName}</Form.Item>
						<Form.Item label={t("createdTime")}>{info?.createdTime}</Form.Item>
						{info.startTime && (
							<>
								<Form.Item label={t("status")}>
									<Select
										allowClear
										value={info?.status}
										onChange={(e) => {
											setInfo({ ...info, status: e });
										}}
									>
										<Option value={0}>待处理</Option>
										<Option value={1}>已处理</Option>
										<Option value={2}>待观察</Option>
										<Option value={3}>待跟进</Option>
									</Select>
								</Form.Item>
								<div className='head feedback'>
									<div className='icon detail'></div>
									<div className='title  '>{t("failureFeedback")}</div>
								</div>
							</>
						)}
					</>
				)}

				{feedBackArr.length !== 0 && !!info.startTime && (
					<Form.Item wrapperCol={{ span: 16, offset: 4 }}>
						{feedBackArr?.map((item: FeedbackHandingsEntity, index: number) => {
							return (
								<div className='container' key={index}>
									<Row gutter={20}>
										<Col span={8}>
											<Form.Item label={t("failureMode")} required>
												<Select allowClear value={item?.type} onChange={changeFeedBack("type", index)}>
													{Object.keys(repairFeedbackService.professionalType).map((item: any) => {
														return (
															<Option key={item} value={Number(item)}>
																{repairFeedbackService.professionalType[item]}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>

										<Col span={8}>
											<Form.Item label={t("position")} required>
												<Select allowClear value={item?.position} onChange={changeFeedBack("position", index)}>
													{repairFeedbackService.professionalFaultType[Number(item.type)]?.map((item: string) => {
														return (
															<Option key={item} value={item}>
																{item}
															</Option>
														);
													})}
												</Select>
											</Form.Item>
										</Col>

										<Col span={8}>
											<Form.Item label={t("handleWay")} required>
												<Select allowClear value={item?.number} onChange={changeFeedBack("number", index)}>
													<Option value='调整'>调整</Option>
													<Option value='维修'>维修</Option>
													<Option value='更换'>更换</Option>
												</Select>
											</Form.Item>
										</Col>

										<Col span={8}>
											<Form.Item label={t("handleName")}>{item?.createdName}</Form.Item>
										</Col>

										<Col span={8}>
											<Form.Item label={t("handleTime")}>{item?.createdTime}</Form.Item>
										</Col>

										<Col span={24}>
											<Form.Item label={t("problemDescription")}>
												<TextArea allowClear value={item?.description} onChange={changeFeedBack("description", index)} />
											</Form.Item>
										</Col>
									</Row>
									{!params.detail && (
										<Col offset={23}>
											<DeleteOutlined
												onClick={() => {
													deteleInfo(index);
												}}
												style={{ fontSize: "20px", color: "red" }}
											/>
										</Col>
									)}
								</div>
							);
						})}
					</Form.Item>
				)}

				{params.id && !params.detail && info.startTime && (
					<Form.Item wrapperCol={{ span: 16, offset: 4 }}>
						<Button type='primary' onClick={addFeedback} style={{ width: "100%" }}>
							{t("add.failure.feedback")}
						</Button>
					</Form.Item>
				)}

				{!params.detail && (
					<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
						<Button type='primary' className={(!info.startTime && params.id)? "startWorking" : ""} onClick={!info.startTime && params.id ?  startWorking : onFinish}>
							{(!info.startTime && params.id) ? "开始处理" : t("submit") }
						</Button>
					</Form.Item>
				)}
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding-top: 50px;
	.mask {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 999;
		cursor: not-allowed;
	}
	.head {
		display: flex;
		align-items: center;
		.icon {
			width: 3px;
			height: 18px;
			background: #1b78f2;
			margin-left: 15%;
			margin-right: 5px;
		}
		.detail {
			margin-left: 17%;
		}
	}
	.feedback {
		margin-bottom: 10px;
	}
	.container {
		margin-top: 10px;
		border-radius: 6px;
		background: #f9f9f9;
		padding: 20px;
	}
	.startWorking {
		background: orange;
		border-color: orange;
	}
`;
