/*
 * @Author: Yujia fyujia0815@gmail.com
 * @Date: 2023-02-01 14:43:27
 * @LastEditors: Yujia fyujia0815@gmail.com
 * @LastEditTime: 2023-02-01 14:43:38
 * @FilePath: \data-center-ts\src\common\hooks\usePrevious.jsx
 */
import { useRef, useEffect } from "react";

const usePrevious = <T,>(value: T) => {
	const ref = useRef<T | undefined>(void 0);
	// useEffect 是副作用，所以会先执行 return ，然后在执行 useEffect，ref.current 的值正好晚了一步。
	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export default usePrevious;
