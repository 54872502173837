import {FC, useCallback, useEffect, useState} from "react";
import styled from "@emotion/styled";
import indexPopupService from "../../../module/indexPopup/service/indexPopupService";
import {IndexPopupEntity} from "../../../module/indexPopup/entity/indexPopupEntity";
import {Button, message, Space, Table} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const IndexPopup: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [dataList, setDataList] = useState<IndexPopupEntity[]>([]);
    const columns = [
        {
            title: t("title"),
            dataIndex: "title",
        },
        {
            title: t("startTime"),
            dataIndex: "startTime",
        },
        {
            title: t("endTime"),
            dataIndex: "endTime",
        },
        {
            title: t("sort"),
            dataIndex: "sort",
        },
        {
            title: t("action"),
            render: (record: any) => {
                return (
                    <Space>
                        <Button
                            type='primary'
                            onClick={() => {
                                deleteRecord(record.id);
                            }}
                        >
                            {t("delete")}
                        </Button>
                        <Button
                            type='primary'
                            onClick={() => {
                                navigate("/activity/indexPopup/" + record.id);
                            }}
                        >
                            {t("edit")}
                        </Button>
                    </Space>
                );
            },
        },
    ];

    const init = useCallback(async () => {
        const { data } = await indexPopupService.list();
        setDataList(data.data)
    }, []);
    useEffect(() => {
        init()
    }, [init]);

    const deleteRecord = async (id: number) => {
        console.log(id);
        const { data } = await indexPopupService.delete(id);
        if (data.code === 200) {
            message.success(t("success"));
            init();
        } else message.error(data.msg)
    }

    return (
        <Container>
            <Space>
                <Button
                    type='primary'
                    onClick={() => {
                        navigate("/activity/indexPopup/add");
                    }}
                >
                    {t("add")}
                </Button>
            </Space>
            <Table
                columns={columns}
                dataSource={dataList}
                rowKey={(record) => record.id + ""}
                style={{ marginTop: 20 }}
                pagination={false}
            />
        </Container>
    );
}

const Container = styled.div`
  width: 100%;
  padding: 20px;
`;