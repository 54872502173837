import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import invoiceService from "../../module/invoice/service/invoice.service";
import { useNavigate } from "react-router-dom";
import { InvoiceEntity } from "../../module/invoice/entity/invoice.entity";
import moment from "moment";
import { checkPermission } from "src/module/admin/service/permission.service";
// import { PaginationEntity } from "src/common/entity/pagination.entity";
const { Option } = Select;

export const Invoice: FC = () => {
	const { t } = useTranslation();
	const { RangePicker } = DatePicker;
	const [dataList, setDatalist] = useState();
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
    //     customPageSize: 15,
    //     customPageNum: 1,
    // });
	const [total, setTotal] = useState(0);
	const [searchObj, setSearchObj] = useState({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
        customPageNum: 1,
	});
	const navigate = useNavigate();

	const [form] = Form.useForm();

	const onFinish = (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			date: undefined,
			startTime: value.date && value.date[0] && value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.date && value.date[0] && value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageSize: 15,
			customPageNum: 1,
		});
	};

	const init = useCallback(async () => {
		const { data } = await invoiceService.list({ ...searchObj});
		if (data.code === 200) {
			setDatalist(data.data);
			setTotal(data.total);
		}

		form.setFieldsValue({
			date: [searchObj.startTime ? moment(searchObj.startTime) : undefined, searchObj.endTime ? moment(searchObj.endTime) : undefined],
		});
	}, [form, searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const updateStatus = async (id: number | undefined, params: any) => {
		const { data } = await invoiceService.updateStatus(id, params);
		if (data.code === 200) {
			init();
		}
	};

	const columns = [
		{
			title: t("id"),
			dataIndex: "id",
		},
		{
			title: t("invoiceTitle"),
			dataIndex: "name",
		},
		{
			title: t("money"),
			dataIndex: "money",
		},
		{
			title: t("applicationTime"),
			dataIndex: "createdTime",
			render: (text: string) => {
				return moment(text).format("YYYY-MM-DD HH:mm:ss");
			},
		},
		{
			title: t("applicantName"),
			dataIndex: "createdName",
		},
		{
			title: t("applicantTelephone"),
			dataIndex: "createdPhone",
		},
		{
			title: t("invoiceType"),
			dataIndex: "category",
			render: (text: number) => {
				return text === 1 ? t("generalgoods") : text === 2 ? t("combo") : text === 3 ? t("vipcardgoods") : t("rechargegoods");
			},
		},
		{
			title: t("invoiceStatus"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? t("underReview") : text === 2 ? t("Invoiced") : t("InvoiceRejected");
			},
		},

		{
			title: t("action"),
			render: (record: InvoiceEntity) => {
				return (
					<Space>
						{record.status === 1 && checkPermission(316) && (
							<>
								<Button
									type='primary'
									onClick={() => {
										updateStatus(record.id, {
											category: record.category,
											status: 2,
										});
									}}
								>
									{t("Invoiced")}
								</Button>
								<Button
									type='primary'
									danger
									onClick={() => {
										updateStatus(record.id, {
											category: record.category,
											status: 3,
										});
									}}
								>
									{t("InvoiceRejected")}
								</Button>
							</>
						)}
						<Button
							type='primary'
							onClick={() => {
								navigate("/finance/invoice/" + record.id);
							}}
						>
							{t("detail")}
						</Button>
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' form={form} onFinish={onFinish} style={{ marginBottom: 20 }}>
				<Row gutter={[30, 20]}>
					<Col span={6}>
						<Form.Item name='name' label={t("invoiceTitle")}>
							<Input placeholder={t("please.enter.invoice.title")} allowClear />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name='createdPhone' label={t("mobile")}>
							<Input placeholder={t("please.enter.mobile")} allowClear />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name='createdName' label={t("applicantName")}>
							<Input placeholder={t("please.enter.nick.name")} allowClear />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name='date' label={t("applicationTime")}>
							<RangePicker />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name='category' label={t("invoiceType")}>
							<Select placeholder={t("please.choose.invoice.type")} allowClear>
								<Option value={1}>{t("generalgoods")}</Option>
								<Option value={2}>{t("combo")}</Option>
								<Option value={3}>{t("vipcardgoods")}</Option>
								<Option value={4}>{t("rechargegoods")}</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name='status' label={t("invoiceStatus")}>
							<Select placeholder={t("please.choose.invoice.status")} allowClear>
								<Option value={1}>{t("underReview")}</Option>
								<Option value={2}>{t("Invoiced")}</Option>
								<Option value={3}>{t("InvoiceRejected")}</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item>
							<Button type='primary' htmlType='submit'>
								{t("search")}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table columns={columns} dataSource={dataList} rowKey={(record) => record.id} pagination={{
                    pageSize: searchObj.customPageSize,
                    current: searchObj.customPageNum,
                    total: total,
                    showTotal: () => {
                        return (
                            <div>
                                {t("total")} {total}
                            </div>
                        );
                    },
                }}
                onChange={(pagination) => {
                    setSearchObj({
                        ...searchObj,
                        customPageNum: pagination.current ?? 1,
                        customPageSize: pagination.pageSize ?? 15,
                    });
                }}/>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
