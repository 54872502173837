import styled from "@emotion/styled";
import { Select } from "antd";
import { t } from "i18next";
import { Props } from "../../../common/entity/props.entity";
import { cardBuyPayWayArr } from "src/module/finance/enum/pay.way.enum copy";

export const SelectCardBuyPayWay = (props: Props) => {
	const { Option } = Select;
	return (
		<Container>
			<Select
				style={{ width: "100%" }}
				placeholder={t("payWay")}
				onChange={(e: number) => {
					props.onChange && props.onChange(e);
				}}
				allowClear
			>
				{Object.keys(cardBuyPayWayArr).map((item) => {
					return (
						<Option key={item} value={item}>
							{t(cardBuyPayWayArr[Number(item)])}
						</Option>
					);
				})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
