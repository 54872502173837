import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, message, Space, Table } from "antd";
import { t } from "i18next";
import loginActivityService from "../../module/loginActivity/login.activity.service";
import moment from "moment";
import { LoginActivityParam } from "../../module/loginActivity/login.activity.params";
import { AxiosResponse } from "axios";
import Util from "../../common/Util";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
const { RangePicker } = DatePicker;

export const LoginActivity: FC = () => {
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState(0);

	const [form] = Form.useForm();

	const [searchObj, setSearchObj] = useState<LoginActivityParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	const onSearch = async (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			date: undefined,
			startTime: value.date && value.date[0] && value.date[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.date && value.date[1] && value.date[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageSize: 15,
		customPageNum: 1,
		});
	};



	const handleExport = async () => {
		const res: AxiosResponse<BlobPart> = await loginActivityService.exportLoginActivity(searchObj);
		const blob = new Blob([res.data]);
		const blobUrl = window.URL.createObjectURL(blob);
		Util.download(blobUrl, "loginActivityList");
		message.success(t("export.success"));
	};

	const init = useCallback(async () => {
		const { data } = await loginActivityService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}

		form.setFieldsValue({
			date: [searchObj.startTime ? moment(searchObj.startTime) : undefined, searchObj.endTime ? moment(searchObj.endTime) : undefined],
		});
	}, [form,searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const [dataList, setDataList] = useState();

	const columns = [
		{ title: t("id"), dataIndex: "id" },
		{ title: t("userName"), dataIndex: "nickName" },
		{ title: t("activeTitle"), dataIndex: "title" },
		{ title: t("mobile"), dataIndex: "mobile" },
		{ title: t("couponName"), dataIndex: "couponName" },
		{ title: t("createdTime"), dataIndex: "createdTime" },
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onSearch} form={form}>
				<Form.Item label={t("activeTitle")} name='title'>
					<Input />
				</Form.Item>
				<Form.Item label={t("userName")} name='nickName'>
					<Input />
				</Form.Item>
				<Form.Item label={t("mobile")} name='mobile'>
					<Input />
				</Form.Item>
				<Form.Item label={t("period")} name='date'>
					<RangePicker />
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						<Button type='primary' onClick={handleExport}>
							{t("export")}
						</Button>
					</Space>
				</Form.Item>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;

	.ant-form-item {
		margin-bottom: 15px;
	}
`;
