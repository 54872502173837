import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, message, Select, Space, Table } from "antd";
import { t } from "i18next";

import { useNavigate } from "react-router-dom";
// import { PaginationEntity } from "../../../common/entity/pagination.entity";
import { ActivityParam } from "../../../module/activity/param/activity.param";
import { ActivityEntity } from "../../../module/activity/entity/activity.entity";
import activityService from "../../../module/activity/service/activity.service";
import { checkPermission } from "src/module/admin/service/permission.service";
export const Coupon: FC = () => {
	const navigate = useNavigate();
	const { Option } = Select;
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState<number>(0);
	const [searchObj, setSearchObj] = useState<ActivityParam>({ type: 3 ,customPageSize: 15,
		customPageNum: 1,});
	const [dataList, setDataList] = useState<ActivityEntity[]>([]);
	const onfinish = (value: any) => {
		setSearchObj({ ...searchObj, ...value ,customPageSize: 15,
			customPageNum: 1,});
	};
	const init = useCallback(async () => {
		const { data } = await activityService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const updateStatus = async (params: ActivityEntity) => {
		const { data } = await activityService.changeStatus(params);
		if (data.code === 200) {
			message.success(t("success"));
			init();
		} else {
			message.error(data.msg);
		}
	};

	const columns = [
		{
			title: t("activityId"),
			dataIndex: "id",
		},
		{
			title: t("activityName"),
			dataIndex: "title",
		},
		{
			title: t("couponCode"),
			dataIndex: "code",
		},

		{
			title: t("startTime"),
			dataIndex: "beginDate",
		},
		{
			title: t("endTime"),
			dataIndex: "endDate",
		},
		{
			title: t("sort"),
			dataIndex: "sort",
		},
		{
			title: t("status"),
			dataIndex: "status",
			render: (text: number) => {
				return text === 1 ? t("onSale") : t("off");
			},
		},
		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						{checkPermission(55) && record.status === 1 && (
							<Button
								type='primary'
								danger
								onClick={() => {
									updateStatus({ id: record.id, status: 2 });
								}}
							>
								{t("off")}
							</Button>
						)}
						{checkPermission(56) && record.status === 2 && (
							<Button
								type='primary'
								onClick={() => {
									updateStatus({ id: record.id, status: 1 });
								}}
							>
								{t("onSale")}
							</Button>
						)}
						{checkPermission(54) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/activity/coupon/" + record.id);
								}}
							>
								{t("edit")}
							</Button>
						)}
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onfinish}>
				<Form.Item label={t("activityName")} name='title'>
					<Input placeholder={t("activityName")} allowClear />
				</Form.Item>
				<Form.Item label={t("activityStatus")} name='status'>
					<Select placeholder={t("activityStatus")} allowClear>
						<Option value={1}>{t("onSale")}</Option>
						<Option value={2}>{t("off")}</Option>
					</Select>
				</Form.Item>
				<Form.Item>
					<Space>
						<Button type='primary' htmlType='submit'>
							{t("search")}
						</Button>
						{checkPermission(53) && (
							<Button
								type='primary'
								onClick={() => {
									navigate("/activity/coupon/add");
								}}
							>
								{t("addActivity")}
							</Button>
						)}
					</Space>
				</Form.Item>
			</Form>
			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record) => record.id + ""}
				style={{ marginTop: 20 }}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
