import { FC, useCallback, useEffect, useState} from "react";
import styled from "@emotion/styled";
import { Button, Form, Input,Table, } from "antd";
import { t } from "i18next";
// import { PaginationEntity } from "../../../common/entity/pagination.entity";
import { ActivityParam } from "../../../module/activity/param/activity.param";
import { ActivityEntity } from "../../../module/activity/entity/activity.entity";
import { SelectRechargeActivity } from "../../../module/activity/component/selectRechargeActivity";
import registrationService from "../../../module/registration/service/registration.service";

export const RegistrationStaff: FC = () => {
	
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState<number>(0);
	const [searchObj, setSearchObj] = useState<ActivityParam>({ type: 3 ,customPageSize: 15,
		customPageNum: 1,});
	const [dataList, setDataList] = useState<ActivityEntity[]>([]);
	const onfinish = (value: any) => {
		setSearchObj({ ...searchObj, ...value,customPageSize: 15,
			customPageNum: 1, });
	};
	const init = useCallback(async () => {
		const { data } = await registrationService.list({ ...searchObj });
		if (data.code === 200) {
			setDataList(data.data.list);
			setTotal(data.data.total);
		}
	}, [searchObj]);

	useEffect(() => {
		init();
	}, [init]);



	const columns = [
		{
			title: t("activityId"),
			dataIndex: "activityId",
		},
		{
			title: t("userNickname"),
			dataIndex: "createdName",
		},
		{
			title: t("activityName"),
			dataIndex: "activityName",
		},
		{
			title: t("mobile"),
			dataIndex: "mobile",
		},
		{
			title: t("createdTime"),
			dataIndex: "createdTime",
		},
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onfinish}>
						<Form.Item label={t("mobile")} name='mobile'>
							<Input placeholder={t("mobile")} allowClear />
						</Form.Item>
						<Form.Item label={t("activityName")} name='activityId'>
						<SelectRechargeActivity type={3} />
						</Form.Item>
						<Form.Item>
							<Button type='primary' htmlType='submit'>
								{t("search")}
							</Button>
						</Form.Item>
					</Form>
					
					<Table
						columns={columns}
						dataSource={dataList}
						rowKey={(record) => record.id + ""}
						style={{ marginTop: 20 }}
						pagination={{
							pageSize: searchObj.customPageSize,
							current: searchObj.customPageNum,
							total: total,
							showTotal: () => {
								return (
									<div>
										{t("total")} {total}
									</div>
								);
							},
						}}
						onChange={(pagination) => {
							setSearchObj({
								...searchObj,
								customPageNum: pagination.current ?? 1,
								customPageSize: pagination.pageSize ?? 15,
							});
						}}
					/>
				
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
