import { http } from "../../../common/http";
import { QuestionEntity } from "../entity/question.entity";
import { QuestionParam } from "../param/question.param";

class QuestionService {
	list = async (param: QuestionParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/question",
			method: "GET",
			params: param,
		});
	};

	add = async (data: QuestionEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/question",
			method: "POST",
			data,
		});
	};

	detail = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/question/"+id,
			method: "GET",
			data:id
		});
	};


	update = async (data:QuestionEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/question/"+data.id,
			method: "PUT",
			data
		});
	};


	delete = async (id:number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/question/"+id,
			method: "DELETE",
			data:id
		});
	};



}

export default new QuestionService();
