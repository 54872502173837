import styled from "@emotion/styled";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
import { payWayArr } from "../enum/pay.way.enum copy";


const { Option } = Select;

export const MultiSelectPayWay = (props: Props) => {
	const { t } = useTranslation();

	return (
		<Container>
			<Select  style={{width:"100%"}} value={props.value ? props.value?.split(",") : undefined}
			onChange={(e: number[]) => props.onChange && props.onChange(e.join(","))} 
			allowClear mode='multiple' placeholder={t("selectPayWay")}>
				{Object.keys(payWayArr).map((item) => {
						return (
							<Option key={item} value={item}>
								{t(payWayArr[Number(item)])}
							</Option>
						);
					})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
