import {skusEntity, skuTypesEntity} from "src/module/product/entity/product.entity";
import {http} from "../../../common/http";


class skuService {
    addSkuType = async (data: skuTypesEntity) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/sku-type",
            method: "POST",
            data: data,
        });
    };


    skuTypeList = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/sku-type",
            method: "GET",
            params: {productId: id},
        });
    };

    // 适配skuGroup改动 现在这个接口会返回所有sku的id和title 包括轿车 SUV等
    skuGroupList = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/sku-type/selection-list",
            method: "GET",
            params: {productId: id},
        });
    };


    deleteSkuType = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/sku-type/" + id,
            method: "DELETE",
            data: id,
        });
    };

    changeSkuType = async (data: skuTypesEntity) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/sku-type/" + data.id,
            method: "PUT",
            data: data,
        });
    };


    addSku = async (data: skusEntity) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/sku",
            method: "POST",
            data: data,
        });
    };

    deleteSku = async (id: number) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/sku/" + id,
            method: "DELETE",
            data: id,
        });
    };

    changeSku = async (data: skuTypesEntity) => {
        return http({
            url: process.env.REACT_APP_ADMIN_API + "/sku/" + data.id,
            method: "PUT",
            data: data,
        });
    };

}

export default new skuService();
