import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, DatePicker, Form, Input, Table } from "antd";
import { useTranslation } from "react-i18next";

import moment from "moment";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import identifyRecordsService from "../../module/identifyRecords/service/identify.records.service";
import { IdentifyRecordsParam } from "../../module/identifyRecords/param/identify.records.param";
const { RangePicker } = DatePicker;

export const IdentifyRecords: FC = () => {
	const { t } = useTranslation();
	const [dataList, setDataList] = useState([]);
	const [form] = Form.useForm();
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
	// 	customPageSize: 15,
	// 	customPageNum: 1,
	// });
	const [total, setTotal] = useState(0);

	const [searchObj, setSearchObj] = useState<IdentifyRecordsParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageSize: 15,
		customPageNum: 1,
	});

	const onFinish = (value: any) => {
		setSearchObj({
			...searchObj,
			...value,
			time: undefined,
			startTime: value.time && value.time[0] && value.time[0].format("YYYY-MM-DD") + " 00:00:00",
			endTime: value.time && value.time[1] && value.time[1].format("YYYY-MM-DD") + " 23:59:59",
			customPageSize: 15,
		customPageNum: 1,
		});
	};

	const init = useCallback(async () => {
		const { data } = await identifyRecordsService.list({...searchObj});
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total)

		}

		form.setFieldsValue({
			time: [searchObj.startTime ? moment(searchObj.startTime) : undefined, searchObj.endTime ? moment(searchObj.endTime) : undefined],
		});
	}, [form, searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const columns = [
		{ title: t("equipmentName"), dataIndex: "name" },
		{ title: t("licenseNumber"), dataIndex: "licenseNumber" },
		{ title: t("identifyTime"), dataIndex: "captureDatetime" },
	];

	return (
		<Container>
			<Form layout='inline' onFinish={onFinish} form={form}>
				<Form.Item label={t("period")} name='time'>
					<RangePicker />
				</Form.Item>
				<Form.Item label={t("equipmentName")} name='equipmentName'>
					<Input placeholder={t("equipmentName")} allowClear/>
				</Form.Item>
				<Form.Item label={t("licenseNumber")} name='licenseNumber'>
					<Input placeholder={t("licenseNumber")} allowClear/>
				</Form.Item>
				<Form.Item>
					<Button type='primary' htmlType='submit'>
						{t("search")}
					</Button>
				</Form.Item>
			</Form>

			<Table
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={dataList}
				rowKey={(record: any) => {
					return record.id;
				}}

				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({
						...searchObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
