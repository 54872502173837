import { http } from "../../../common/http";
import { CustomerMembershipCardEntity } from "../entity/customer.membership.card.entity";
import { CustomerMembershipCardParam } from "../params/customer.membership.card.param";

class CustomerMembershipCardService {
	list = async (param: CustomerMembershipCardParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-card",
			method: "GET",
			params: param,
		});
	};
	exportMonthlyCard = async (param: CustomerMembershipCardParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-card/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};
	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-card/" + id,
			method: "DELETE",
		});
	};

	updateExpiredTime = async (data: CustomerMembershipCardEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + `/user-card/expired-time/${data.id}?endTime=${data.endTime}`,
			method: "PUT",
		});
	};
}

export default new CustomerMembershipCardService();
