import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
// import { PaginationEntity } from "../../common/entity/pagination.entity";
import moment from "moment";
import firstOrderService from "src/module/firstOrder/service/first.order.service";
import { FirstOrderParam } from "src/module/firstOrder/param/first.order.param";
// import { SelectSite } from "src/module/site/component/select.site";
import { SingleSelectProduct } from "src/module/product/component/single.select.product";
import { FirstOrderEntity } from "src/module/firstOrder/entity/first.order.entity";
import { MultiSelectProductBySite } from "../../module/product/component/multi.select.product.by.shop";
import { SelectSite } from "src/module/site/component/select.site";
import { checkPermission } from "src/module/admin/service/permission.service";
const { RangePicker } = DatePicker;
const { Option } = Select;

export const FirstOrderList: FC = () => {
	const [visible, setVisible] = useState(false);
	const { confirm } = Modal;
	const { t } = useTranslation();
	const [dataList, setDataList] = useState([]);
	const [searchArr, setSearchArr] = useState<FirstOrderParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
	});
	const [searchObj, setSearchObj] = useState<FirstOrderParam>({
		startTime: moment().format("YYYY-MM-DD") + " 00:00:00",
		endTime: moment().format("YYYY-MM-DD") + " 23:59:59",
		customPageNum: 1, customPageSize: 15
	});
	const [info, setInfo] = useState<FirstOrderEntity>({});
	// const [paginationObj, setPaginationObj] = useState<PaginationEntity>({ customPageNum: 1, customPageSize: 15 });
	const [total, setTotal] = useState(0);

	const changeAttr = (type: string, e: any) => {
		setSearchArr({
			...searchArr,
			[type]: e,
		});
	};

	const onDateChange = (date: any, dateString: any) => {
		setSearchArr({
			...searchArr,
			startTime: dateString[0] + " 00:00:00",
			endTime: dateString[1] + " 23:59:59",
		});
	};

	const handleSerach = () => {
		setSearchObj({
			...searchArr,
		});
	};

	const init = useCallback(async () => {
		const { data } = await firstOrderService.list({ ...searchObj, });
		if (data.code === 200) {
			setDataList(data.data);
			setTotal(data.total);
		}
	}, [ searchObj]);

	useEffect(() => {
		init();
	}, [init]);

	const handleDelete = (id: number) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: async () => {
				const { data } = await firstOrderService.delete(id);
				if (data.code === 200) {
					message.success(data.msg);
					init();
				}
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const handleEdit = async (id: any) => {
		const { data } = await firstOrderService.detail(id);
		if (data.code === 200) {
			setInfo(data.data);
			console.log(data.data);
		}
		setVisible(true);
	};

	const add = () => {

		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
		setInfo({});
	};

	const handleOK = async () => {
		if (info.id) {
			const { data } = await firstOrderService.update({ ...info, productIds: info.productId?.toString() });
			if (data.code === 200) {
				message.success(t("success"));
				setVisible(false);
				init();
			} else {
				message.error(data.msg);
			}
		} else {
			const { data } = await firstOrderService.add(info);
			if (data.code === 200) {
				message.success(t("success"));
				setVisible(false);
				init();
			} else {
				message.error(data.msg);
			}
		}
		setInfo({});
	};
	const columns = [
		{
			title: t("site.name"),
			dataIndex: "shopName",
		},
		{
			title: t("productName"),
			dataIndex: "productName",
		},
		{
			title: t("couponType"),
			dataIndex: "couponTypeStr",
		},
		{
			title: t("status"),
			dataIndex: "statusStr",
		},
		{
			title: t("discountedPrice"),
			render: (record: FirstOrderEntity) => {
				return record.couponType == 2 && record.couponPrice;
			},
		},
		{
			title: t("discountPercent"),
			render: (record: FirstOrderEntity) => {
				return record.couponType == 3 && record.discountPercent;
			},
		},
		{
			title: t("effectiveDate"),
			dataIndex: "endTime",
		},

		{
			title: t("action"),
			render: (record: any) => {
				return (
					<Space>
						<Button
							type='primary'
							onClick={() => {
								handleDelete(record.id);
							}}
						>
							{t("delete")}
						</Button>
						{checkPermission(270)&&<Button
							type='primary'
							onClick={() => {
								handleEdit(record.id);
							}}
						>
							{t("edit")}
						</Button>}
						
					</Space>
				);
			},
		},
	];

	return (
		<Container>
			<div className='headers'>
				<Row gutter={20}>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("site.name")}：</div>
							<SelectSite onChange={(e: number) => changeAttr("shopId", e)} />
						</div>
					</Col>

					{searchArr.shopId && (
						<Col span={8}>
							<div className='searchList'>
								<div className='searchItem'>{t("productName")}：</div>
								<SingleSelectProduct onChange={(e: number) => changeAttr("productId", e)} siteId={searchArr.shopId} />
							</div>
						</Col>
					)}
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("discountType")}：</div>
							<Select  placeholder={t("discountType")} allowClear style={{ width: 300 }} onChange={(e) => changeAttr("couponType", e)}>
								<Option value={1}>{t("free")}</Option>
								<Option value={2}>{t("discountedPrice")}</Option>
								<Option value={3}>{t("discountPercent")}</Option>
							</Select>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("status")}：</div>
							<Select  placeholder={t("status")} allowClear style={{ width: 300 }} onChange={(e) => changeAttr("status", e)}>
								<Option value={1}>{t("unlock")}</Option>
								<Option value={2}>{t("lock")}</Option>
							</Select>
						</div>
					</Col>
					<Col span={8}>
						<div className='searchList'>
							<div className='searchItem'>{t("effectiveDate")}：</div>
							<RangePicker allowClear defaultValue={[moment(), moment()]} onChange={onDateChange} />
						</div>
					</Col>
					<Col span={8}>
						<Space>
							<Button htmlType='submit' type='primary' onClick={handleSerach}>
								{t("search")}
							</Button>
							{checkPermission(269)&& <Button type='primary' onClick={add}>
								{t("add")}
							</Button>}
							
						</Space>
					</Col>
				</Row>
			</div>

			<Table
				columns={columns}
				dataSource={dataList}
				rowKey={(record: any) => record.id + ""}
				pagination={{
					pageSize: searchObj.customPageSize,
					current: searchObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setSearchObj({ ...searchObj, customPageNum: pagination.current, customPageSize: pagination.pageSize });
				}}
			/>

			<Modal width={"50%"} title={t("首单优惠配置")} visible={visible} onOk={handleOK} onCancel={handleCancel}>
				<Form layout='vertical'>
					{!info.id && (
						<Form.Item label=''>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{ marginRight: 20, width: 150 }}>{t("productName")}:</div>
								<div style={{ marginRight: 20, width: 300 }}>
									<MultiSelectProductBySite
										value={info.productIds}
										onChange={(e: any) => {
											setInfo({ ...info, productIds: e });
										}}
									/>
								</div>
							</div>
						</Form.Item>
					)}
					{info.id && (
						<Form.Item label=''>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{ marginRight: 20, width: 150 }}>{t("productName")}:</div>
								<div style={{ marginRight: 20, width: 300 }}>
									<SingleSelectProduct
										value={info.productId}
										siteId={info.shopId}
										onChange={(e: number) => {
											setInfo({ ...info, productId: e });
										}}
									/>
								</div>
							</div>
						</Form.Item>
					)}

					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("couponType")}:</div>
							<div style={{ marginRight: 20, width: 300 }}>
								<Select
								placeholder={t("couponType")}
									allowClear
									value={info.couponType}
									style={{ width: 300 }}
									onChange={(e: number) => {
										setInfo({ ...info, couponType: e });
									}}
								>
									<Option value={1}>{t("free")}</Option>
									<Option value={2}>{t("discountedPrice")}</Option>
									<Option value={3}>{t("discountPercent")}</Option>
								</Select>
							</div>
						</div>
					</Form.Item>

					{info.couponType == 2 && (
						<Form.Item label=''>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{ marginRight: 20, width: 150 }}>{t("discountedPrice")}:</div>
								<div style={{ marginRight: 20, width: 300 }}>
									<Input
									placeholder={t("discountedPrice")}
										allowClear
										value={info.couponPrice}
										onChange={(e) => {
											setInfo({ ...info, couponPrice: Number(e.target.value) });
										}}
									/>
								</div>
							</div>
						</Form.Item>
					)}
					{info.couponType == 3 && (
						<Form.Item label=''>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{ marginRight: 20, width: 150 }}>{t("discountPercent")}:</div>
								<div style={{ marginRight: 20 }}>
									<InputNumber
										step='0.01'
										value={info.discountPercent}
										onChange={(e) => {
											setInfo({ ...info, discountPercent: e });
										}}
									/>
									范围 0.00 ~ 1.00, 0表示免费, 1表示原价
								</div>
							</div>
						</Form.Item>
					)}

					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("status")}:</div>
							<div style={{ marginRight: 20, width: 300 }}>
								<Select
								placeholder={t("status")}
									allowClear
									value={info.status}
									style={{ width: 300 }}
									onChange={(e: number) => {
										setInfo({ ...info, status: e });
									}}
								>
									<Option value={1}>{t("unlock")}</Option>
									<Option value={2}>{t("lock")}</Option>
								</Select>
							</div>
						</div>
					</Form.Item>
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>{t("effectiveDate")}:</div>
							<div style={{ marginRight: 20, width: 300 }}>
								<RangePicker
									allowClear
									value={info.startTime ? [moment(info.startTime), moment(info.endTime)] : undefined}
									onChange={(date: any, dateString: any) => {
										setInfo({
											...info,
											startTime: dateString[0] + " 00:00:00",
											endTime: dateString[1] + " 23:59:59",
										});
									}}
								/>
							</div>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	padding: 10px;

	.headers {
		width: 100%;

		.searchList {
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			.searchItem {
				min-width: 150px;
				text-align: right;
			}
		}
	}
`;
