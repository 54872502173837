import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, Table } from "antd";
import { useTranslation } from "react-i18next";
import interiorCleaningService from "../../module/interiorCleaning/interiorCleaning.service";
import { PaginationEntity } from "../../common/entity/pagination.entity";
import { InteriorCleaningParam } from "../../module/interiorCleaning/interiorCleaning.param";

export const InteriorCleaning: FC = () => {
	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
		customPageNum: 1,
		customPageSize: 15,
	});
	const [searchObj, setSearchObj] = useState<InteriorCleaningParam>({});

	const [total, setTotal] = useState(0);
	const onSearch = (value: InteriorCleaningParam) => {
		setSearchObj(value);
	};

	const { t } = useTranslation();
	const columns = [
		{ title: t("adminName"), dataIndex: "adminName" },
		{ title: t("stationName"), dataIndex: "stationName" },
		{ title: t("orderNum"), dataIndex: "orderNum" },
		{ title: t("licenseNumber"), dataIndex: "licenseNumber" },
	];

	const [list, setList] = useState();

	useEffect(() => {
		interiorCleaningService.list(searchObj).then((res) => {
			setList(res.data.data);
			setTotal(res.data.total);
		});
	}, [searchObj]);

	return (
		<Container>
			<Form layout='inline' onFinish={onSearch}>
				<Form.Item label={t("stationName")} name='stationName'>
					<Input placeholder={t("stationName")} allowClear />
				</Form.Item>
				<Form.Item label={t("adminName")} name='adminName'>
					<Input placeholder={t("adminName")} allowClear />
				</Form.Item>
				<Form.Item>
					<Button type='primary' htmlType='submit'>
						{t("search")}
					</Button>
				</Form.Item>
			</Form>
			<Table
				rowKey={(record, index) => index+""}
				style={{ marginTop: 20 }}
				columns={columns}
				dataSource={list}
				pagination={{
					current: paginationObj.customPageNum,
					pageSize: paginationObj.customPageSize,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")}:{total}
							</div>
						);
					},
				}}
				onChange={(pagination) => {
					setPaginationObj({ ...paginationObj, customPageNum: pagination.current, customPageSize: pagination.pageSize });
				}}
			/>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	padding: 20px;
`;
