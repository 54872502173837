import styled from "@emotion/styled";
import { Button, Form, Input, message,Tabs, Tree } from "antd";
import { FC, Key, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { SelectAdmin } from "../../module/admin/components/SelectAdmin";
import { SelectRole } from "../../module/admin/components/SelectRole";
import { AdminEntity } from "../../module/admin/entity/admin.entity";
import AdminService, { currentInfo } from "../../module/admin/service/admin.service";
import PermissionService from "../../module/admin/service/permission.service";
import { MenuTreeEntity } from "../../module/menu/entity/menu.entity";
import { MultiSelectSite } from "../../module/site/component/multi.select.site";

const { TabPane } = Tabs;

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

export const AdminAdd: FC = () => {
	const [menuList, setMenuList] = useState([]);
	const [shops, setShops] = useState("");
	const [roleId, setRoleId] = useState<number | undefined>(undefined);
	const [parentId, setParentId] = useState(undefined);
	const [permissionArr] = useState(currentInfo().permissionIds?.split(","));
	const [adminInfo, setAdminInfo] = useState<AdminEntity>({});

	const params = useParams();

	const { t } = useTranslation();
	const navigate = useNavigate();

	const onSelect = (selectedKeys: Key[], info: any) => {
		console.log("selected", selectedKeys, info);
	};

	const onCheck = (checkedKeys: any): void => {
		setAdminInfo({
			...adminInfo,
			permissionIds: checkedKeys.join(","),
		});
	};

	const init = useCallback(async () => {
		const { data } = await PermissionService.list();
		if (currentInfo().id === 1) {
			setMenuList(
				data.data.map((row: MenuTreeEntity) => {
					row.name = row.name + " - " + row.id;
					row.children?.map((row1: MenuTreeEntity) => {
						row1.name = row1.name + " - " + row1.id;
						row1.children?.map((row2: MenuTreeEntity) => {
							row2.name = row2.name + " - " + row2.id;
							return row2;
						});
						return row1;
					});
					return row;
				})
			);
		} else {
			setMenuList(
				data.data.map((row: MenuTreeEntity) => {
					row.name = row.name + " - " + row.id;
					if (permissionArr && row.id && permissionArr.indexOf(row.id.toString()) < 0) {
						row.disableCheckbox = true;
					}

					row.children?.map((row1: MenuTreeEntity) => {
						row1.name = row1.name + " - " + row1.id;
						if (permissionArr && row1.id && permissionArr.indexOf(row1.id.toString()) < 0) {
							row1.disableCheckbox = true;
						}

						row1.children?.map((row2: MenuTreeEntity) => {
							row2.name = row2.name + " - " + row2.id;
							if (permissionArr && row2.id && permissionArr.indexOf(row2.id.toString()) < 0) {
								row2.disableCheckbox = true;
							}
							return row2;
						});
						return row1;
					});
					return row;
				})
			);
		}

		const id = params.id;
		if (id) {
			const adminInfoRes = await AdminService.detail(Number(id));
			const data = adminInfoRes.data.data;
			setAdminInfo(data);
			setShops(data.shopId);
			setRoleId(data.roleId);
			setParentId(data.parentId);
		}
	}, [params.id, permissionArr]);

	const onFinish = async () => {
		const arr: AdminEntity = {
			...adminInfo,
			shopId: shops,
			roleId: roleId,
			parentId: parentId,
		};
		let res;
		if (!params.id || params.copy) {
			res = await AdminService.add(arr);
		} else {
			res = await AdminService.update(arr);
		}
		if (res.data.code === 200) {
			message.success(t("process_success"));
			navigate("/admin");
		} else {
			message.error(res.data.msg);
		}
	};

	useEffect(() => {
		init();
	}, [init]);

	const onChange = (field: string, e: any) => {
		setAdminInfo({
			...adminInfo,
			[field]: e.target.value,
		});
	};

	const defaultKeys = () => {
		if (!permissionArr || currentInfo().id === 1) {
			return adminInfo.permissionIds?.split(",");
		}
		return adminInfo.permissionIds?.split(",").filter((item) => {
			return permissionArr.indexOf(item) > -1;
		});
	};

	return (
		<Container>
			<Form {...layout} onFinish={onFinish}>
				<Tabs defaultActiveKey='1'>
					<TabPane tab={t("basic") + t("info")} key='1'>
						<Form.Item label={t("admin.name")}>
							<Input value={adminInfo.name} onChange={(e) => onChange("name", e)} />
						</Form.Item>
						<Form.Item label={t("mobile")}>
							<Input value={adminInfo.mobile} onChange={(e) => onChange("mobile", e)} />
						</Form.Item>
						{currentInfo().id === 1 && (
							<Form.Item label={t("admin.leader")}>
								<SelectAdmin value={parentId} onChange={setParentId} />
							</Form.Item>
						)}
						<Form.Item label={t("admin.role")}>
							<SelectRole value={roleId} onChange={setRoleId} />
						</Form.Item>
						<Form.Item label={t("admin.shops")}>
							<MultiSelectSite value={shops} onChange={setShops} />
						</Form.Item>
						{/* <Form.Item label={t("admin.workStatus")}>
							<Radio.Group  onChange={(e) => onChange("workStatus", e)} value={adminInfo.workStatus}>
								<Radio value={1}>{t("vacancy")}</Radio>
								<Radio value={2}>{t("busy")}</Radio>
							</Radio.Group>
						</Form.Item> */}
					</TabPane>
					<TabPane tab={t("permissions")} key='2'>
						<Tree
							checkable
							onSelect={onSelect}
							onCheck={onCheck}
							defaultCheckedKeys={defaultKeys()}
							treeData={menuList}
							fieldNames={{ title: "name", key: "id", children: "children" } as object}
						/>
					</TabPane>
				</Tabs>
				<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
					<Button type='primary' htmlType='submit'>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	padding: 10px;
`;
