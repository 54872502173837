import { http } from "../../../common/http";
import { OrderEntity } from "../entity/order.entity";
import { OrderParam } from "../param/order.param";

class OrderService {
	list = async (params: OrderParam = { customPageSize: 15, customPageNum: 1 }) => {
		return http({
			method: "GET",
			url: process.env.REACT_APP_ADMIN_API + `/order`,
			params,
		});
	};

	settle = async (data: any) => {
		return http({
			method: "PUT",
			url: process.env.REACT_APP_ADMIN_API + `/order/complex-order/settle`,
			data,
		});
	};

	export = async (params: OrderParam) => {
		return http({ url: process.env.REACT_APP_ADMIN_API + "/order/export", method: "GET", params, responseType: "blob" });
	};


	exportSku = async (params: OrderParam) => {
		return http({ url: process.env.REACT_APP_ADMIN_API + "/order/export/sku", method: "GET", params, responseType: "blob" });
	}; 

	delete = async (id: number) => {
		return http({ url: process.env.REACT_APP_ADMIN_API + "/order/" + id, method: "DELETE", data: id });
	};

	updateStatus = async (params: OrderEntity) => {
		return http({ url: process.env.REACT_APP_ADMIN_API + "/order", method: "PUT", data: params });
	};

	updateRemark = async (params: any) => {
		return http({ url: process.env.REACT_APP_ADMIN_API + "/order/item/remark", method: "PUT", data: params });
	};

	updateItemsStatus = async (params: any) => {
		return http({ url: process.env.REACT_APP_ADMIN_API + "/order/item/orderStatus/" + params.id, method: "PUT", data: params });
	};
}
export default new OrderService();
