import zh_CN from "./zh-CN.json";
import { Locale } from "./en-US";

const ZH_CN: Locale = {
	...zh_CN,
	test: "测试",
	subscriptionConfig:"会员卡配置",
	enterQuantity:"输入数量",
	basicOption:"是否基础SKU项"


};

export default ZH_CN;
