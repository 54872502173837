import React, { useState, useEffect } from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { StringProp } from "../entity/props.entity";
import { useTranslation } from "react-i18next";

const getBase64 = (img: any, callback: any) => {
	const reader = new FileReader();
	reader.addEventListener("load", () => callback(reader.result));
	reader.readAsDataURL(img);
};

const beforeUpload = (file: any) => {
	const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"  || file.type === "image/gif";
	if (!isJpgOrPng) {
		message.error("You can only upload JPG/PNG file!");
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error("Image must smaller than 2MB!");
	}
	return isJpgOrPng && isLt2M;
};

interface InteriorProps extends StringProp {
	placeholder?: string
	size?: string
}

export const UploadImg = (props: InteriorProps) => {
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState("");
	const { t } = useTranslation();
	const handleChange = (info: any) => {
		if (info.file.status === "uploading") {
			setLoading(true);
			return;
		}
		if (info.file.status === "done") {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, () => {
				setLoading(false);
				setImageUrl(info.file.response.data.url);
				props.onChange && props.onChange(info.file.response.data.url);
			});
		}
	};
	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>{t("upload")}</div>
		</div>
	);
	useEffect(() => {
		setImageUrl(props.value!);
	}, [props]);
	return (
		<>
			<Upload
				name='file'
				listType='picture-card'
				className='avatar-uploader'
				showUploadList={false}
				action={process.env.REACT_APP_UPLOAD_FILE_IMG + "/file"}
				headers={{
					Authorization: localStorage.token ?? "",
				}}
				beforeUpload={beforeUpload}
				onChange={handleChange}>
				{imageUrl ? <img src={imageUrl} alt='avatar' style={{ width: "100%", height: "100%", objectFit: "contain" }} /> : uploadButton}
			</Upload>
			{props.placeholder && <div>{props.placeholder}</div>}
			{props.size?<div>推荐图片尺寸/比例为：{props.size}</div>:<div>推荐图片尺寸/比例为:690*330</div>}
		</>
	);
};
