import { Button, DatePicker, Form, Input, message, Select, TimePicker } from "antd";
import moment from "moment";
import { FC, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import MyEditor from "../../common/components/MyEditor";
import { UploadImg } from "../../common/components/UploadImg";
import { checkPermission } from "../../module/admin/service/permission.service";
import CompanyService from "../../module/company/service/company.service";
import { SelectCity } from "../../module/overview/components/select.city.all";
import ProductTypesService from "../../module/product/service/product.type.service";
import { SelectSite } from "../../module/site/component/select.site";
import { SiteEntity } from "../../module/site/entity/site.entity";
import SiteService from "../../module/site/service/site.service";
const { Option } = Select;

export const SiteDetail: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [editObj, setEditObj] = useState<SiteEntity>({
		companyFinanceId: "",
		id: undefined,
		shopNumber: "",
		address: "",
		businessHoursStart: undefined,
		businessHoursEnd: undefined,
		provinceId: "",
		cityId: "",
		districtId: "",
		description: "",
		image: "",
		titleImage: "",
		lat: "",
		lng: "",
		naviLat: "",
		naviLng: "",
		open: "",
		openColor: "",
		openType: "", // 1营业中 2休息 3自定义
		// storageId: '',
		// wxMerchantId: '',
		// wxPayKey: '',
		recommend: 1,
		telephone: "",
		title: "",
		type: 1,
		content: "",
		instruction: "",
		maintainInfo: "",
		popContent: "",
		qrCode: "",
		video: "",
		openDate: undefined,
		productTypeIds: "",
		maintenanceTime: undefined,
		financialCode: "",
		siteTagUrl: "",
		parentId: 0,
		freeParkingPeriod: "",
	});

	// Editor Fields
	const [description, setDescription] = useState<string | undefined>("");
	const [content, setContent] = useState<string | undefined>("");
	const [instruction, setInstruction] = useState<string | undefined>("");
	const [maintainInfo, setMaintainInfo] = useState<string | undefined>("");
	const [popContent, setPopContent] = useState<string | undefined>("");

	const [typeList, setTypeList] = useState([]);
	const [companyList, setCompanyList] = useState([]);
	const [editorLoading, setEditorLoading] = useState<boolean>(false);
	// const statusList = [
	// 	{ title: "", color: "" },
	// 	{ title: "低温保护", color: "coral" },
	// 	{ title: "站点维护", color: "#FF0000" },
	// 	{ title: "营业中", color: "#0066ff" },
	// 	{ title: "休息中", color: "#cfcfcf" },
	// 	{ title: "敬请期待", color: "#9933ff" },
	// 	{ title: "市政施工", color: "#FF0000" },
	// ];
	const { id } = useParams();
	// 获取商品分类列表
	const getTypeList = () => {
		ProductTypesService.listAll().then((res: any) => {
			if (res.data.code === 200) {
				setTypeList(res.data.data);
			}
		});
	};
	const init = useCallback(() => {
		if (id && Number(id) > 0) {
			SiteService.detail({ id }).then((res) => {
				if (res.data.code === 200) {
					const dataFlag: SiteEntity = res.data.data;
					setEditObj({
						...dataFlag,
						businessHoursStart: res.data.data.businessHoursStart ? moment(res.data.data.businessHoursStart, "HH:mm:ss") : undefined,
						businessHoursEnd: res.data.data.businessHoursEnd ? moment(res.data.data.businessHoursEnd, "HH:mm:ss") : undefined,
						productTypeIds: res.data.data.productTypeIds,
					});
					setDescription(dataFlag.description);
					setContent(dataFlag.content);
					setInstruction(dataFlag.instruction);
					setMaintainInfo(dataFlag.maintainInfo);
					setPopContent(dataFlag.popContent);
					setEditorLoading(true);
				}
			});
		} else {
			setEditorLoading(true);
		}
	}, [id]);
	const changeAttr = (prop: any, value: any) => {
		setEditObj({
			...editObj,
			openDate: value,
		});
	};
	const maintenanceTimeChangeAttr = (prop: any, value: any) => {
		setEditObj({
			...editObj,
			maintenanceTime: value,
		});
	};
	const closeTimeChangeAttr = (prop: any, value: any) => {
		setEditObj({
			...editObj,
			closeTime: value,
		});
	};

	
	// const hangChange = (e: any) => {
	// 	setEditObj({
	// 		...editObj,
	// 		open: JSON.parse(e).title,
	// 		openColor: JSON.parse(e).color,
	// 	});
	// };
	const handleTypeChange = (e: any) => {
		setEditObj({
			...editObj,
			productTypeIds: e.join(","),
		});
	};
	const handleModalInputChange = (type: string, e: any) => {
		setEditObj({
			...editObj,
			[type]:
				type === "close" ||
				type === "type" ||
				type === "businessHoursStart" ||
				type === "businessHoursEnd" ||
				// type === 'storageId' ||
				type === "provinceId" ||
				type === "cityId" ||
				type === "districtId" ||
				type === "openType" ||
				type === "companyFinanceId"||
				type === "riped"
					? e
					: e.target.value,
		});
	};
	const ChangeCitySelect = (e: any) => {
		setEditObj({
			...editObj,
			...e,
		});
	};
	const siteChange = (e: number) => {
		setEditObj({
			...editObj,
			parentId: e,
		});
	};
	const onchangeImg = (type: string, img: string) => {
		setEditObj({
			...editObj,
			[type]: img,
		});
	};
	const getCompanyList = () => {
		CompanyService.all().then((res) => {
			if (res.data.code === 200) {
				setCompanyList(res.data.data);
			}
		});
	};
	const submit = () => {
		// id===0代表新增，否则代表修改
		const obj: any = {
			...editObj,
			maintenanceTime: editObj.maintenanceTime ? `${editObj.maintenanceTime} 00:00:00` : "",
			businessHoursStart: editObj.businessHoursStart ? moment(editObj.businessHoursStart).format("HH:mm:ss") : "",
			businessHoursEnd: editObj.businessHoursEnd ? moment(editObj.businessHoursEnd).format("HH:mm:ss") : "",
			description: description == "<p><br></p>" ? "" : description,
			content: content == "<p><br></p>" ? "" : content,
			instruction: instruction == "<p><br></p>" ? "" : instruction,
			maintainInfo: maintainInfo == "<p><br></p>" ? "" : maintainInfo,
			popContent: popContent == "<p><br></p>" ? "" : popContent,
		};
		const { id, ...otherForm } = obj;
		const validateParams = [
			"image",
			"title",
			"type",
			"telephone",
			"openType",
			"address",
			"lng",
			"lat",
			"naviLng",
			"naviLat",
			"provinceId",
			"cityId",
			"districtId",
			"content",
			"instruction",
			"financialCode",
		];
		for (const i in obj) {
			if (validateParams.includes(i) && (!otherForm[i] || otherForm[i] === "" || otherForm[i].length === 0)) {
				message.error("请完善站点信息");
				return false;
			}
		}
		if (id === "0" || id === undefined || id === null) {
			SiteService.add(obj).then((res) => {
				if (res.data.code === 200) {
					message.success(res.data.msg);
					navigate("/site/site");
				}
			});
		} else {
			SiteService.update(obj).then((res) => {
				if (res.data.code === 200) {
					message.success(res.data.msg);
					navigate("/site/site");
				}
			});
		}
	};
	useLayoutEffect(() => {
		getTypeList();
		getCompanyList();
	}, []);
	useEffect(() => {
		init();
	}, [init]);
	return (
		<div style={{ width: "100%", padding: 10 }}>
			<Form labelCol={{ span: 4 }}>
				<Form.Item label={t("image")}>
					<UploadImg
						onChange={(e: string) => {
							onchangeImg("image", e);
						}}
						value={editObj.image}
					/>
				</Form.Item>
				<Form.Item label={t("gate.head.Image")}>
					<UploadImg
						onChange={(e: string) => {
							onchangeImg("titleImage", e);
						}}
						value={editObj.titleImage}
					/>
				</Form.Item>
				<Form.Item label={t("site.label.Image")}>
					<UploadImg
						onChange={(e: string) => {
							onchangeImg("siteTagUrl", e);
						}}
						value={editObj.siteTagUrl}
					/>
				</Form.Item>
				<Form.Item label={t("site.qrCode")}>
					<UploadImg
						onChange={(e: string) => {
							onchangeImg("qrCode", e);
						}}
						value={editObj.qrCode}
					/>
				</Form.Item>
				<Form.Item label={t("opening.date")}>
					<DatePicker value={editObj.openDate ? moment(editObj.openDate) : undefined} onChange={changeAttr} />
				</Form.Item>
				{id !== "0" && (
					<Form.Item label={t("close")}>
						<Select
							style={{ width: 200 }}
							value={editObj.close}
							onChange={(e) => {
								handleModalInputChange("close", e);
							}}
						>
							<Option value={0}>{t("no")}</Option>
							<Option value={1}>{t("yes")}</Option>
						</Select>
					</Form.Item>
				)}

				{editObj.close === 1 && (
					<Form.Item label={t("closeTime")}>
						<DatePicker showTime value={editObj.closeTime ? moment(editObj.closeTime) : undefined} onChange={closeTimeChangeAttr} />
					</Form.Item>
				)}

				<Form.Item label={t("maintenance.date")}>
					<DatePicker disabled value={editObj.maintenanceTime ? moment(editObj.maintenanceTime) : undefined} onChange={maintenanceTimeChangeAttr} />
				</Form.Item>
				<Form.Item label={t("meiTuanShopId")}>
					<Input  style={{ width: 220 }}  value={editObj.meituanOpenShopUuid } onChange={(e) => handleModalInputChange("meituanOpenShopUuid", e)}  />
				</Form.Item>


				<Form.Item label={t("site.video")}>
					<Input placeholder={t("please.enter.a.site.video.address")} value={editObj.video} onChange={(e) => handleModalInputChange("video", e)} />
				</Form.Item>
				<Form.Item label={t("site.name")}>
					<Input placeholder={t("please.enter.a.site.name")} value={editObj.title} onChange={(e) => handleModalInputChange("title", e)} />
				</Form.Item>
				<Form.Item label={t("parent.site")}>
					<SelectSite value={editObj.parentId === 0 ? null : editObj.parentId} level={1} onChange={siteChange} />
				</Form.Item>
				<Form.Item label={t("site.number")}>
					<Input placeholder={t("please.enter.a.site.number")} value={editObj.shopNumber} onChange={(e) => handleModalInputChange("shopNumber", e)} />
				</Form.Item>
				<Form.Item label={t("financial.number")}>
					<Input
						placeholder={t("please.enter.a.financial.number")}
						value={editObj.financialCode}
						onChange={(e) => handleModalInputChange("financialCode", e)}
					/>
				</Form.Item>
				<Form.Item label={t("type.of.service.provided")}>
					<Select
						mode='multiple'
						allowClear
						style={{ width: "100%" }}
						placeholder={t("please.select.service.type")}
						value={editObj.productTypeIds?.split(",")}
						onChange={(e) => {
							handleTypeChange(e);
						}}
					>
						{typeList.map((item: any) => {
							return <Option key={item.id}>{item.title}</Option>;
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t("siteType")}>
					<Select value={editObj.type} style={{ width: 220 }} onChange={(e) => handleModalInputChange("type", e)}>
						<Option value={1}>{t("admin.type.express")}</Option>
						<Option value={2}>{t("admin.type.headquarters")}</Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("IsRiped")}>
					<Select value={editObj.riped} style={{ width: 220 }} onChange={(e) => handleModalInputChange("riped", e)}>
						<Option value={0}>{t("riping")}</Option>
						<Option value={1}>{t("riped")}</Option>
					</Select>
				</Form.Item>
				<Form.Item label={t("business.status")}>
					<Select
						value={editObj.openType ? editObj.openType : undefined}
						style={{ width: 220 }}
						placeholder={t("please.select.business.status")}
						onChange={(e) => handleModalInputChange("openType", e)}
					>
						<Option value={1}>{t("open")}</Option>
						<Option value={2}>{t("takeABreak")}</Option>
						<Option value={3}>{t("UnderConstruction")}</Option>
						<Option value={4}>{t("UnderMaintenance")}</Option>
						<Option value={5}>{t("freezeProtect")}</Option>
						<Option value={6}>{t("municipalConstruction")}</Option>
					</Select>
				</Form.Item>
				{/*<Form.Item label={t("site.operation.status")}>*/}
				{/*	<div style={{ display: "flex" }}>*/}
				{/*		<Select*/}
				{/*			value={editObj.open ? editObj.open : "自动"}*/}
				{/*			placeholder={t("please.select.the.site.operation.status")}*/}
				{/*			style={{ width: 220 }}*/}
				{/*			onChange={(e) => {*/}
				{/*				hangChange(e);*/}
				{/*			}}*/}
				{/*		>*/}
				{/*			{statusList.map((item: any, index: number) => {*/}
				{/*				return (*/}
				{/*					<Option key={index} value={JSON.stringify(item)}>*/}
				{/*						{item.title==""?"自动":item.title}*/}
				{/*					</Option>*/}
				{/*				);*/}
				{/*			})}*/}
				{/*		</Select>*/}
				{/*		<div style={{ width: "30px", height: "30px", backgroundColor: editObj.openColor, marginLeft: "30px" }}> </div>*/}
				{/*	</div>*/}
				{/*</Form.Item>*/}
				<Form.Item label={t("businessTime")}>
					<TimePicker
						value={editObj.businessHoursStart}
						placeholder={t("opening.time")}
						format={"HH:mm:ss"}
						minuteStep={1}
						onChange={(e) => handleModalInputChange("businessHoursStart", e)}
					/>{" "}
					~
					<TimePicker
						value={editObj.businessHoursEnd}
						placeholder={t("closing.time")}
						format={"HH:mm:ss"}
						minuteStep={1}
						onChange={(e) => handleModalInputChange("businessHoursEnd", e)}
					/>
				</Form.Item>

				<Form.Item label={t("freeParkingPeriod")}>
					<Input  style={{ width: 220 }}  value={editObj.freeParkingPeriod } onChange={(e) => handleModalInputChange("freeParkingPeriod", e)}  />
				</Form.Item>


				<Form.Item label={t("station.longitude.and.latitude")}>
					<div style={{ display: "flex" }}>
						<Input placeholder={t("please.enter.the.site.latitude")} value={editObj.lat} onChange={(e) => handleModalInputChange("lat", e)} />
						&nbsp; &nbsp;
						<Input placeholder={t("please.enter.the.site.longitude")} value={editObj.lng} onChange={(e) => handleModalInputChange("lng", e)} />
					</div>
					<div>
						<a href='https://lbs.qq.com/tool/getpoint/index.html' rel='noopener noreferrer' target='_blank'>
							{t("longitude.and.latitude.pick.tool")}
						</a>
					</div>
				</Form.Item>
				<Form.Item label={t("longitude.and.latitude.of.navigation.station")}>
					<div style={{ display: "flex" }}>
						<Input
							placeholder={t("please.enter.the.latitude.of.the.navigation.site")}
							value={editObj.naviLat}
							onChange={(e) => handleModalInputChange("naviLat", e)}
						/>
						&nbsp; &nbsp;
						<Input
							placeholder={t("please.enter.the.longitude.of.the.navigation.site")}
							value={editObj.naviLng}
							onChange={(e) => handleModalInputChange("naviLng", e)}
						/>
					</div>
					<div>
						<a href='https://lbs.qq.com/tool/getpoint/index.html' rel='noopener noreferrer' target='_blank'>
							{t("longitude.and.latitude.pick.tool")}
						</a>
					</div>
				</Form.Item>
				<Form.Item label={t("station.province.and.city")}>
					<SelectCity onChange={ChangeCitySelect} provinceId={editObj.provinceId} cityId={editObj.cityId} districtId={editObj.districtId} />
				</Form.Item>
				<Form.Item label={t("contact.number")}>
					<Input
						placeholder={t("please.enter.the.contact.number")}
						value={editObj.telephone}
						onChange={(e) => handleModalInputChange("telephone", e)}
					/>
				</Form.Item>
				<Form.Item label={t("receiving.company")}>
					<Select
						placeholder={t("please.select.the.receiving.company")}
						value={editObj.companyFinanceId ? editObj.companyFinanceId : undefined}
						style={{ width: 320 }}
						onChange={(e) => handleModalInputChange("companyFinanceId", e)}
					>
						{companyList.map((item: any, index: number) => {
							return (
								<Option key={index} value={item.id}>
									{item.name}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
				<Form.Item label={t("address.details")}>
					<Input placeholder={t("please.enter.address.details")} value={editObj.address} onChange={(e) => handleModalInputChange("address", e)} />
				</Form.Item>
				<Form.Item label={t("site.description")}>{editorLoading && <MyEditor value={description} onChange={setDescription} />}</Form.Item>
				<Form.Item label={t("site.details")}>{editorLoading && <MyEditor value={content} onChange={setContent} />}</Form.Item>
				<Form.Item label={t("car.washing.instructions")}>{editorLoading && <MyEditor value={instruction} onChange={setInstruction} />}</Form.Item>
				<Form.Item label={t("site.maintenance")}>{editorLoading && <MyEditor value={maintainInfo} onChange={setMaintainInfo} />}</Form.Item>
				<Form.Item label={t("pop.up.reminder.on.order.page")}>{editorLoading && <MyEditor value={popContent} onChange={setPopContent} />}</Form.Item>
			</Form>
			<div style={{ paddingBottom: 40 }}>
				{checkPermission(21) && (
					<Button type='primary' onClick={submit}>
						{t("submit")}
					</Button>
				)}
			</div>
		</div>
	);
};
