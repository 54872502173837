import { http } from "../../../common/http";
import { RecommendActivityParam } from "../param/recommend.activity.param";

class RecommendActivityService {
list=(param:RecommendActivityParam)=>{
    return http({
        url: process.env.REACT_APP_ADMIN_API + "/recommendActivityRecord",
        method: "GET",
        params:param
    })
}

download=async(param:RecommendActivityParam)=>{
    return http({
        url: process.env.REACT_APP_ADMIN_API + "/recommendActivityRecord/export",
        method: "GET",
        params: param,
        responseType: "blob",
    });
};
}
export default new  RecommendActivityService()

