import styled from "@emotion/styled";
import { Button, Form, InputNumber, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PropsEntity } from "../../../common/entity/props.entity";
import { CouponEntity } from "../../coupon/entity/coupon.entity";
import { CouponParam } from "../../coupon/param/coupon.param";
import CouponService from "../../coupon/service/coupon.service";
import { SelectCouponType } from "../../coupon/component/SelectCouponType";

const { Option } = Select;

interface couponEntity extends PropsEntity {
	couponType?: number;
	couponId?: number;
	couponNum?: number;
	couponIndexArr: number[];
	deleteCoupon: () => void;
}

export const SingleSelectCouponByType = (props: couponEntity) => {
	const [couponArr, setCouponArr] = useState<CouponEntity[]>([]);
	const { t } = useTranslation();
	const [searchInfo, setSearchInfo] = useState<CouponParam>({ couponType: props.couponType });
	const [couponType, setCouponType] = useState<string>("");

	const couponList = useCallback(async () => {
		if (!couponType) {
			return;
		}
		const { data } = await CouponService.noAuth({ type:1,...searchInfo, ...{ couponType: couponType as unknown as number } });
		// 解决优惠券ID不在优惠券搜索栏第一页的
		const listFlag = data.data.list;					
		let flag = false;
		listFlag.forEach((item: CouponEntity) => {
			if (item.id === props.couponId) {
				flag = true;
			}
		});		
		if (!flag && props.couponId) {
			const currentCoupons = await CouponService.detail(props.couponId);
			listFlag.push(currentCoupons.data.data);			
		}



		setCouponArr(listFlag)
	}, [couponType, props.couponId, searchInfo]);

	useEffect(() => {
		setCouponType(props.couponType as unknown as string);
	}, [props.couponType]);

	useEffect(() => {
		couponList();
	}, [couponList]);

	const changeCouponType = (val: string) => {
		setCouponType(val);
	};

	return (
		<Container>
			<div className={"select"}>
				<div className={"left"}>
					<Form.Item
						name={[
							`activityPackageCategoriesCopy`,
							props.couponIndexArr[0],
							"activityPackageCombos",
							props.couponIndexArr[1],
							"activityPackageCoupons",
							props.couponIndexArr[2],
							"couponType",
						]}
					>
						<SelectCouponType onChange={changeCouponType} />
					</Form.Item>
				</div>
				&nbsp;&nbsp;
				<div className={"middle"}>
					<Form.Item
						name={[
							`activityPackageCategoriesCopy`,
							props.couponIndexArr[0],
							"activityPackageCombos",
							props.couponIndexArr[1],
							"activityPackageCoupons",
							props.couponIndexArr[2],
							"couponId",
						]}
					>
						<Select
							placeholder={t("chooseCoupon")}
							onBlur={() => {
								setSearchInfo({});
							}}
							allowClear
							onChange={props.onChange}
							showSearch
							optionFilterProp={"children"}
							onSearch={(e) => {
								setSearchInfo({ ...searchInfo, title: e });
							}}
						>
							{couponArr.map((item: CouponEntity) => {
								return (
									<Option key={item.id} value={item.id}>
										{item.title}
									</Option>
								);
							})}
						</Select>
					</Form.Item>
				</div>
				<div className={"right"}>
					<Form.Item
						label={t("数量")}
						name={[
							`activityPackageCategoriesCopy`,
							props.couponIndexArr[0],
							"activityPackageCombos",
							props.couponIndexArr[1],
							"activityPackageCoupons",
							props.couponIndexArr[2],
							"couponNum",
						]}
					>
						<InputNumber type='number' min={1} defaultValue={1}></InputNumber>
					</Form.Item>
					&nbsp;&nbsp;
					<Form.Item>
						<Button type='primary' onClick={() => props.deleteCoupon()}>
							{t("delete")}
						</Button>
					</Form.Item>
				</div>
			</div>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;

	.select {
		display: flex;
		justify-content: left;

		.left {
			width: 140px;
		}

		.middle {
			width: 300px;
		}

		.right {
			display: flex;
			margin-left: 30px;
			width: 30%;
		}
		.icon {
			line-height: 32px;
			font-size: 18px;
			cursor: pointer;
		}
	}
`;
