import {FC,useEffect} from "react";
import {Image, Space} from "antd";
import AdminService from "../../module/admin/service/admin.service";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
const Home:FC = () => {
    useEffect(()=>{
        AdminService.current()
    })
    return (
        <Container>
            <Space>
                <Link to={"/screen/top"}><Image src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/dataScreen.png" preview={false} /></Link>
                <Link to={"/screen/warning"}><Image src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/warningScreen.png" preview={false} /></Link>
                <Link to={"/screen/InQinDynasty"}><Image src="https://splashprod.oss-cn-shanghai.aliyuncs.com/img/interiorCleanScreen.png" preview={false} /></Link>
            </Space>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 10px;
`

export default Home;