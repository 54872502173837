import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Form, Input, InputNumber, message, Radio, Select } from "antd";
import { UploadImg } from "../../common/components/UploadImg";
import { useTranslation } from "react-i18next";
import MyEditor from "../../common/components/MyEditor";
import { useNavigate, useParams } from "react-router-dom";
import { MultiSelectSite } from "src/module/site/component/multi.select.site";
import { SelectPointType } from "src/module/point/component/select.point.type";
import pointProductService from "src/module/point/service/point.product.service";
import { PointProductEntity } from "src/module/point/entity/point.product.entity ";

import { SelectMembershipCard } from "src/module/membershipCard/component/SelectMembershipCard";
import { SelectRechargeActivity } from "src/module/activity/component/selectRechargeActivity";
import { SelectCouponByType } from "src/module/coupon/component/SelectCouponByType";

export const PointProductDetail: FC = () => {
	const { Option } = Select;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const params = useParams();
	const [editorLoading, setEditorLoading] = useState<boolean>(false);
	const [info, setInfo] = useState<PointProductEntity>({});
	const [details, setDetails] = useState<string | undefined>("");

	const init = useCallback(async () => {
		if (params.id) {
			const { data } = await pointProductService.detail(Number(params.id));
			if (data.code === 200) {
				setInfo(data.data);
				setDetails(data.data.content);
				setEditorLoading(true);
			}
		} else {
			setEditorLoading(true);
		}
	}, [params.id]);

	useEffect(() => {
		init();
	}, [init]);

	const onFinish = async () => {
		if (!params.id || params.copy) {
			const { data } = await pointProductService.add({
				...info,
				content: details,
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/point/pointProduct");
			} else {
				message.error(data.msg);
			}
		} else {
			const { data } = await pointProductService.update({
				...info,
				content: details,
				id: Number(params.id),
			});
			if (data.code === 200) {
				message.success(t("success"));
				navigate("/point/pointProduct");
			} else {
				message.error(data.msg);
			}
		}
	};

	return (
		<Container>
			<Form labelCol={{ span: 4 }} wrapperCol={{ span: 12 }} style={{ padding: 20 }}>
				<Form.Item label={t("image")}>
					<UploadImg
						size={"334*250"}
						value={info?.thumb}
						onChange={(e: string) => {
							setInfo({ ...info, thumb: e });
						}}
					/>
				</Form.Item>

				<Form.Item label={t("bigImage")}>
					<UploadImg
						size={"750*750"}
						value={info?.img}
						onChange={(e: string) => {
							setInfo({ ...info, img: e });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("productName")} required>
					<Input
						value={info?.name}
						onChange={(e) => {
							setInfo({ ...info, name: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("stockAmount")} required>
					<InputNumber
						value={info?.stockAmount}
						onChange={(e) => {
							setInfo({ ...info, stockAmount: e });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("price")} required>
					<InputNumber
						value={info?.price}
						onChange={(e) => {
							setInfo({ ...info, price: e });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("usePointsNumber")} required>
					<InputNumber
						value={info?.pointsNumber}
						onChange={(e) => {
							setInfo({ ...info, pointsNumber: e });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("point.type")} required>
					<SelectPointType
						value={info?.typeId}
						onChange={(e: number) => {
							setInfo({ ...info, typeId: e });
						}}
					/>
				</Form.Item>

				<Form.Item label={t("兑换类型")} required>
					<Select value={info?.type} onChange={(e: number) => setInfo({ ...info, type: e, bindId: undefined })}>
						<Option value={1}>{t("generalgoods")}</Option>
						<Option value={2}>{t("vipCard")}</Option>
						<Option value={3}>{t("coupon")}</Option>
						<Option value={4}>{t("combo")}</Option>
					</Select>
				</Form.Item>

				{info.type === 3 && (
					<Form.Item label={t("bindCoupon")} required>
						<SelectCouponByType
							value={info?.bindId}
							onChange={(e: number) => {
								setInfo({ ...info, bindId: e });
							}}
						/>
						
					</Form.Item>



				)}

				{info.type === 2 && (
					<Form.Item label={t("bindCard")} required>
						<SelectMembershipCard
							value={info?.bindId}
							onChange={(e: number) => {
								setInfo({ ...info, bindId: e });
							}}
						/>
					</Form.Item>
				)}
				{info.type === 4 && (
					<Form.Item label={t("bindCombo")} required>
						<SelectRechargeActivity
							type={6}
							value={info?.bindId}
							onChange={(e: number) => {
								setInfo({ ...info, bindId: e });
							}}
						/>
					</Form.Item>
				)}

				<Form.Item label={t("status")} required>
					<Radio.Group
						value={info?.upDown}
						onChange={(e) => {
							setInfo({ ...info, upDown: e.target.value });
						}}
					>
						<Radio value={1}>{t("onSale")}</Radio>
						<Radio value={2}>{t("off")}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item label={t("checkShop")} required>
					<MultiSelectSite
						value={info?.shopIds}
						onChange={(e: string) => {
							setInfo({ ...info, shopIds: e });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("sort")}>
					<Input
						value={info?.sort}
						onChange={(e) => {
							setInfo({ ...info, sort: Number(e.target.value) });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("productNum")}>
					<Input
						value={info?.productNum}
						onChange={(e) => {
							setInfo({ ...info, productNum: e.target.value });
						}}
					/>
				</Form.Item>
				<Form.Item label={t("productDetails")}>
					{editorLoading && (
						<MyEditor
							value={details}
							onChange={(e: string) => {
								setDetails(e);
							}}
						/>
					)}
				</Form.Item>
				<Form.Item wrapperCol={{ span: 12, offset: 4 }}>
					<Button type='primary' onClick={onFinish}>
						{t("submit")}
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
