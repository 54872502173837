/*
 * @Author: Yujia fyujia0815@gmail.com
 * @Date: 2022-08-30 10:34:07
 * @LastEditors: Yujia fyujia0815@gmail.com
 * @LastEditTime: 2022-12-01 13:10:33
 * @FilePath: \data-center-ts\src\module\product\component\select.product.type.tsx
 */
import styled from "@emotion/styled";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Props } from "../../../common/entity/props.entity";
import { PointTypeEntity } from "../entity/point.type.entity";
import pointTypeService from "../service/point.type.service";

const { Option } = Select;

export const SelectPointType = (props: Props) => {
	const { t } = useTranslation();
	const [list, setList] = useState<PointTypeEntity[]>([]);

	useEffect(() => {
		pointTypeService.list({}).then((res) => {
			if (res.data.code === 200) {
				setList(res.data.data.records);
				console.log(res.data.data.records);
				
			}
		});
	}, []);

	const onChange = (value: number) => {
		props.onChange(value);
	};

	return (
		<Container>
			<Select style={{ width: 200 }} allowClear placeholder={t("point.type")} onChange={onChange} value={props.value}>
				{list.map((item) => {
					return (
						<Option value={item.id} key={item.id}>
							{item.title}
						</Option>
					);
				})}
			</Select>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
`;
