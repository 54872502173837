import styled from "@emotion/styled";
import { Button, Form, Input, message, Modal, Table } from "antd";

import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import pointTypeService from "src/module/point/service/point.type.service";
// import { UploadImg } from "../../common/components/UploadImg";
import { PaginationEntity } from "../../common/entity/pagination.entity";

import { checkPermission } from "../../module/admin/service/permission.service";
import { ProductTypeListEntity } from "../../module/product/entity/product.type.entity";

const { confirm } = Modal;

export const PointType: FC = () => {
	const { t } = useTranslation();
	const [pointTypeList, setPointTypeList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState<number>(0);
	const [type, setType] = useState(0);
	const [searchArr, setSearchArr] = useState({});
	const [paginationObj, setPaginationObj] = useState<PaginationEntity>({
		customPageSize: 15,
		customPageNum: 1,
		total: 0,
	});

	const [Modallist, setModallist] = useState<ProductTypeListEntity>({
		title: "",
		sort: 0,
		icon: "",
		
	});
	const [visible, setVisible] = useState(false);
	const columns = [
		{
			title: "id",
			dataIndex: "id",
		},
		{
			title: t("name"),
			dataIndex: "title",
		},
		
		// {
		// 	title: t("image"),
		// 	dataIndex: "icon",
		// 	render: (text: any) => {
		// 		return (
		// 			<img
		// 				src={text}
		// 				width='100'
		// 				alt=''
		// 				style={{ border: "1px solid #e1e1e1" }}
		// 			/>
		// 		);
		// 	},
		// },
		{
			title: t("sort"),
			dataIndex: "sort",
		},
		
		{
			title: t("action"),
			render: (text: any, record: any) => {
				return (
					<div>
						{checkPermission(61102) && (
							<Button
								type='primary'
								style={{ marginRight: "20px" }}
								onClick={() => update(record)}>
								{t("modify")}
							</Button>
						)}
						{checkPermission(61103) && (
							<Button
								type='primary'
								onClick={() => deleteType(record)}
								danger
								style={{
									marginBottom: "20px",
									marginLeft: "10px",
								}}>
								{t("delete")}
							</Button>
						)}
					</div>
				);
			},
		},
	];
	const deleteType = (e: any) => {
		confirm({
			title: t("confirmOperation"),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				pointTypeService.delete(e.id).then((res) => {
					message.success(res.data.msg);
					init();
				});
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};
	const handleCancel = () => {
		setVisible(false);
	};
	
	const handleOk = () => {
		if (Modallist.title === "") {
			message.info("请输入名称！");
			return;
		}
		if (type === 1) {
			pointTypeService.add({ ...Modallist }).then((res) => {
				message.success(res.data.msg);
				setVisible(false);
				init();
			});
		} else {
			pointTypeService.update({ ...Modallist }).then((res) => {
				message.success(res.data.msg);
				setVisible(false);
				init();
			});
		}
	};
	const changeAttr = (type: string, e: any) => {
		setModallist({
			...Modallist,
			[type]: e,
		});
	};
	const addType = () => {
		setModallist({
			title: "",
			sort: 0,
			icon: "",
			
		});
		setVisible(true);
		setType(1);
	};
	const update = (record: any) => {
		setVisible(true);
		setModallist(record);
		setType(2);
	};
	const init = useCallback(async () => {
		setLoading(true);
		const { data } = await pointTypeService.list({ ...paginationObj });
		if (data.code === 200) {
			setPointTypeList(data.data.records);
			setTotal(data.data.total);
		}
		setLoading(false);
	}, [paginationObj]);
	// const onchangeImg = (img: string) => {
	// 	setModallist({ ...Modallist, icon: img });
	// };
	useEffect(() => {
		init();
	}, [init]);
	return (
		<Container>
			<div className='headers'>
				{checkPermission(61102) && (
					<Button
						type='primary'
						onClick={addType}
						style={{ marginBottom: "20px", marginLeft: "10px" }}>
						{t("add")}
					</Button>
				)}
				
			</div>
			<Table
				columns={columns}
				loading={loading}
				dataSource={pointTypeList}
				pagination={{
					pageSize: paginationObj.customPageSize,
					current: paginationObj.customPageNum,
					total: total,
					showTotal: () => {
						return (
							<div>
								{t("total")} {total}
							</div>
						);
					},
				}}
				style={{ width: "100%" }}
				rowKey={"id"}
				onChange={(pagination) => {
					setSearchArr({
						...searchArr,
						customPageNum: pagination.current,
						customPageSize: pagination.pageSize,
					});
					setPaginationObj({
						...paginationObj,
						customPageNum: pagination.current ?? 1,
						customPageSize: pagination.pageSize ?? 15,
					});
				}}
			/>
			<Modal
				title={type === 1 ? t("add") : t("modify")}
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
				okButtonProps={{
					disabled: !checkPermission(61102) && !checkPermission(61103),
				}}
				width={"50%"}>
				<Form layout='vertical'>
					{/* <Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>
								{t("image")}:
							</div>
							<div>
								<UploadImg
									value={Modallist.icon}
									onChange={onchangeImg}
								/>
							</div>
						</div>
					</Form.Item> */}
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 20, width: 150 }}>
								{t("name")}:
							</div>
							<Input
								placeholder={t("please.enter.a.name")}
								onChange={(e) => {
									changeAttr("title", e.target.value);
								}}
								value={Modallist.title}
								style={{ width: "70%" }}
							/>
						</div>
					</Form.Item>
					
					<Form.Item label=''>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ marginRight: 68, width: 100 }}>
								{t("sort")}:
							</div>
							<Input
								placeholder={t("please.enter.a.sort")}
								onChange={(e) => {
									changeAttr("sort", e.target.value);
								}}
								value={Modallist.sort}
								style={{ width: "70%" }}
							/>
						</div>
					</Form.Item>
				</Form>
			</Modal>
		</Container>
	);
};
const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 10px;
	.headers {
	}
`;
