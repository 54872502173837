import { useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import { NumberProp } from "../../../common/entity/props.entity";
import { t } from "i18next";
import promoterAuditService from "../service/promoter.audit.service";
import { PromoterAuditEntity } from "../entity/promoter.audit.entity";

const { Option } = Select;

export const SelectPromoter = (props: NumberProp) => {
	const [dataArr, setDataArr] = useState<PromoterAuditEntity[]>([]);
	const [name, setName] = useState<string | undefined>(undefined);

	const init = useCallback(async () => {
		const res = await promoterAuditService.list({ promoterName: name, promoteApplyState: "2" });
		// const listFlag = res.data.data;
		// let flag = false;
		// listFlag.forEach((item: PromoterAuditEntity) => {
		// 	if (item.id === props.value){
		// 		flag = true;
		// 	}
		// });
		// if (!flag && props.value) {
		// 	const currentAdminInfoRes = await AdminService.detail(props.value);
		// 	listFlag.push(currentAdminInfoRes.data.data);
		// }
		if (res.data.code === 200) {
			setDataArr(res.data.data.records);
		}
	}, [name]);

	useEffect(() => {
		init();
	}, [init]);

	return (
		<>
			<Select
				showSearch
				value={props.value}
				onChange={(e) => {
					props.onChange && props.onChange(e);
					
				}}
				onSearch={setName}
				onBlur={()=>{setName(undefined)}}
				allowClear
				style={{ width: 200 }}
				placeholder={t("pleaseSelectPromoter")}
			>
				{dataArr &&
					dataArr.map((item) => {
						return (
							<Option value={item.id} key={item.id}>
								{item.promoterName}
							</Option>
						);
					})}
			</Select>
		</>
	);
};
