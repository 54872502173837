import { http } from "../../../common/http";
import { UserCouponEntity } from "../entity/user.coupon.entity";
import { UserCouponParam } from "../param/user.coupon.param";

class UserCouponService {
	list = async (param: UserCouponParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-coupon",
			method: "GET",
			params: param,
		});
	};

	delete = async (id: number) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-coupon/" + id,
			method: "DELETE",
			data: id,
		});
	};

	export = async (param: UserCouponParam) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-coupon/export",
			method: "GET",
			params: param,
			responseType: "blob",
		});
	};

	batchDelete = async (data: UserCouponEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-coupon/batch",
			method: "DELETE",
			data,
		});
	};

	changeExpirationTime = async (data: UserCouponEntity) => {
		return http({
			url: process.env.REACT_APP_ADMIN_API + "/user-coupon",
			method: "PUT",
			data,
		});
	};
}
export default new UserCouponService();
