import {http} from "../../../common/http";
import {ReportParam} from "../entity/report.param";

class FinanceDataService {
    expressSiteReport = async (param: ReportParam) => {
        return http({
            url: '/statistics/quick-export',
            method: 'GET',
            params: param,
            responseType: 'blob'
        })
    }

    siteSaleReport = async (param: ReportParam) => {
        return http({
            url: '/statistics/sale-export',
            method: 'GET',
            params: param,
            responseType: 'blob'
        })
    }

    sumSale = async (params: ReportParam) => {
        return http({
            url: '/package/sum-pay-fee',
            method: 'GET',
            params
        })
    }
}
export default new FinanceDataService();